import {
  type ForwardedRef, type ReactElement, type PropsWithChildren,
  forwardRef, memo,
} from 'react';
import {
  type ViewProps as OriginViewProps,
  View as DefaultView,
} from 'react-native';

import { useThemeColor } from 'hooks';

type ViewProps = {
  lightColor?: string;
  darkColor?: string;
} & OriginViewProps;

const View = forwardRef((props: PropsWithChildren<ViewProps>, forwardedRef: ForwardedRef<null>): ReactElement => {
  const {
    style,
    lightColor,
    darkColor,
    ...otherProps
  } = props;

  let backgroundColor: string | undefined = useThemeColor({ light: lightColor, dark: darkColor });
  if (!lightColor || !darkColor) {
    backgroundColor = undefined;
  }

  return (
    <DefaultView ref={forwardedRef} style={[{ backgroundColor }, style]} {...otherProps} />
  );
});

export default memo(View);
