import { memo, useCallback } from 'react';
import { createUseStyles } from 'react-jss';

import { useThemeColor } from 'hooks';

import { Button } from '@mui/joy';
import Icon from 'ui/Icon';
import Text from './Text';

type AddButtonProps ={
  onClick?: () => void
}

const AddButton = (props: AddButtonProps) => {
  const { onClick } = props;

  const iconBackgroundColor = useThemeColor({ dark: '#272727', light: '#E6E4EAAA' });
  const classes = useStyle({
    iconBackgroundColor,
  });

  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <Button
      variant="soft"
      color="neutral"
      onClick={handleClick}
      size="sm"
      sx={{
        '--variant-softBg': (theme) => theme.palette.background.level1,
        '--variant-softHoverBg': (theme) => theme.palette.background.level2,
        '--variant-softActiveBg': (theme) => theme.palette.background.level3,
        border: (theme) => `1px solid ${theme.palette.background.level3}`,
        '&:hover': {
          borderColor: (theme) => theme.palette.background.level2,
        },
        '&:active': {
          borderColor: (theme) => theme.palette.background.level3,
        },
      }}
    >
      <Text size={13} lightColor="#515151" darkColor="#9A99A2">Add to AI Library</Text>
      <div className={classes.backgroundIcon}>
        <Icon name="turn-down-left" color="icon" />
      </div>
    </Button>
  );
};

const useStyle = createUseStyles<
  'backgroundIcon',
  {
    iconBackgroundColor?: string,
  }
>({
  backgroundIcon: {
    display: 'flex',
    height: '1em',
    width: '1em',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '0.5em',
    borderRadius: '0.125em',
  },
});

export default memo(AddButton);
