import {
  memo, type ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';

import {
  type GestureResponderEvent, type LayoutChangeEvent,
  Platform, type StyleProp, StyleSheet, type ViewStyle,
} from 'react-native';

import { useSelector } from 'react-redux';
import * as noteStore from 'store/nodes/note';

import RegularLayout from 'components/Card/variants/NoteCard/layouts/RegularLayout';
import { View } from 'components/Themed';

type NoteCardProps = {
  id?: number,
  style?: StyleProp<ViewStyle>
  radius?: number
  onLayout?: ((event: LayoutChangeEvent, context: { cardId: number }) => void) | undefined,
  onOptionsPress?: (event: GestureResponderEvent, context: { cardId: number }) => void,
  OverlayComponent?: ReactNode,
}

const NoteCard = (props: NoteCardProps) => {
  const {
    id,
    style,
    radius,
    onOptionsPress,
    onLayout,
    OverlayComponent,
  } = props;

  if (!id) {
    return null;
  }
  const containerRef = useRef<HTMLDivElement | any>();
  const [isHover, setHover] = useState(false);
  const data = useSelector(noteStore.selectors.dataById(id));
  const mouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const mouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  const handleOptionsPress = useCallback((event: GestureResponderEvent) => {
    if (!data?.id) {
      return;
    }
    onOptionsPress?.(event, { cardId: data?.id });
  }, [onOptionsPress, data?.id]);

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    if (!data?.id) {
      return;
    }
    onLayout?.(event, { cardId: data?.id });
  }, [onLayout, data?.id]);

  useEffect(() => {
    if (Platform.OS !== 'web' || !containerRef?.current || !(containerRef?.current instanceof HTMLDivElement)) {
      return undefined;
    }
    const { current: container } = containerRef;
    container.addEventListener('mouseenter', mouseEnter);
    container.addEventListener('mouseleave', mouseLeave);
    return () => {
      container.removeEventListener('mouseenter', mouseEnter);
      container.removeEventListener('mouseleave', mouseLeave);
    };
  }, [mouseEnter, mouseLeave]);

  if (!data) {
    return null;
  }

  return (
    <View
      ref={containerRef}
      onLayout={handleLayout}
      style={[style, styles.MaterialCard]}
    >
      <RegularLayout
        onOptionsPress={handleOptionsPress}
        OverlayComponent={OverlayComponent}
        isHoverMode={isHover}
        data={data}
        radius={radius}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  MaterialCard: {
    alignSelf: 'stretch',
    zIndex: Platform.OS === 'web' ? 'unset' as unknown as number : 0,
  },
});

export default memo(NoteCard);
