import {
  useCallback, memo, useState, useEffect, useMemo, useRef,
} from 'react';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import * as noteStore from 'store/nodes/note';
import Box from '@mui/joy/Box';
import {
  ButtonGroup, IconButton, Card, CardActions, Tooltip,
} from '@mui/joy';
import Typography from '@mui/joy/Typography';

import { useEditorState } from 'hooks';

import Icon from 'ui/Icon';
import Avatar from 'ui/Avatar';
import { type EditorInputMethodsType } from 'ui/EditorInput';

import EditorInput from 'ui/EditorInput';

import CircularProgressCounter, { type CircularProgressCounterMethodsType } from '../ui/CircularProgressCounter';

type ItemNoteProps = {
  id: number,
  onRemove?: (id: number, options?: { force?: boolean }) => void,
  onRevert?: (id: number) => void,
  onSave?: (id: number, text: string) => void,
  sx?: {
    flex: number,
  },
};

const ItemNote = (props: ItemNoteProps) => {
  const {
    id,
    onRemove,
    onRevert,
    onSave,
    sx,
  } = props;

  const autoSaveCounterRef = useRef<CircularProgressCounterMethodsType | null>(null);
  const removeCounterRef = useRef<CircularProgressCounterMethodsType | null>(null);

  const data = useSelector(noteStore.selectors.dataById(id));
  const isLoading = useSelector(noteStore.selectors.isLoading({ noteId: id }));
  const editorRef = useRef<EditorInputMethodsType | null>(null);
  const { html, hasContent, onChange } = useEditorState(data?.text);
  const [, setKey] = useState(uuidV4());

  const hasUpdate = useMemo(() => data?.text !== html, [data?.text, html]);

  useEffect(() => {
    const { current: autoSaveCounter } = autoSaveCounterRef;
    const { current: removeCounter } = removeCounterRef;
    if (!autoSaveCounter || !removeCounter) {
      return undefined;
    }
    autoSaveCounter.stop();
    removeCounter.stop();
    if (!hasUpdate) {
      return undefined;
    }
    if (!hasContent) {
      removeCounter.start();
      return undefined;
    }
    autoSaveCounter.start();
    return undefined;
  }, [html, hasUpdate]);

  useEffect(() => {
    const timeout: any = setTimeout(() => {
      setKey(uuidV4());
    }, 1000 * 60);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  useEffect(() => {
    if (!isLoading && data?.text) {
      editorRef.current?.set(data?.text);
    }
  }, [data, isLoading]);

  const handleRevert = useCallback(() => {
    editorRef.current?.set(data?.text || '');
    onRevert?.(id);
  }, [onRevert, id, data?.text]);

  const handleSave = useCallback(() => {
    if (!html) {
      return;
    }
    onSave?.(id, html);
  }, [onSave, id, html]);

  const handleRemove = useCallback(() => {
    onRemove?.(id);
  }, [onRemove, id]);

  const handleRemoveForce = useCallback(() => {
    onRemove?.(id, { force: true });
  }, [onRemove, id]);

  return (
    <Card
      variant="soft"
      color={hasUpdate ? 'warning' : 'neutral'}
      size="sm"
      sx={{
        mx: 3,
        borderRadius: 26,
        marginTop: 2,
        '--Avatar-size': '24px',
        '.MuiIconButton-root': {
          opacity: 0,
          transition: '300ms opacity ease',
        },
        '&:hover .MuiIconButton-root': {
          opacity: 1,
        },
        ...sx,
      }}
    >
      <Box p={0.5} pb={0} fontSize={16}>
        {html !== null && (
          <EditorInput ref={editorRef} onChange={onChange} />
        )}
      </Box>
      <CardActions sx={{ minHeight: 36, '--Card-padding': 0 }}>
        <Box display="flex" gap={1} ml={0.5} mr="auto" alignItems="center">
          {!!data?.user.photo && (
            <Avatar src={data.user.photo.url} thumbnail="small" />
          )}
          <Typography fontSize={12} fontWeight={500}>
            {data?.isMy ? '' : `${data?.user.name || 'User'} `}
            posted
          </Typography>
          <Box height={6} width={6} overflow="hidden" sx={{ '& > i': { transform: 'translateY(-11px)' } }}>
            <Icon name="period" weight="solid" />
          </Box>
          <Typography fontSize={12} fontWeight={400}>{moment(data?.createdAt).fromNow()}</Typography>
        </Box>
        {data?.isMy && (
          <ButtonGroup variant="soft" color={hasUpdate ? 'warning' : 'neutral'} spacing={1} sx={{ '--ButtonGroup-radius': '50%', '--ButtonGroup-separatorSize': 0 }}>
            <Tooltip arrow variant="outlined" size="sm" title="Delete">
              <IconButton onClick={handleRemove} sx={{ opacity: !hasContent ? '1!important' : undefined, color: (theme) => theme.palette.danger['500'] }}>
                <Icon name="trash" color="inherit" />
                <CircularProgressCounter
                  ref={removeCounterRef}
                  determinate
                  color="danger"
                  size="md"
                  sx={{ position: 'absolute', opacity: 0.2 }}
                  onFinish={handleRemoveForce}
                />
              </IconButton>
            </Tooltip>
            {hasUpdate && (
              <Tooltip arrow variant="outlined" size="sm" title="Revert">
                <IconButton onClick={handleRevert} sx={{ opacity: '1!important' }}>
                  <Icon name="rotate-left" weight="solid" color="inherit" />
                </IconButton>
              </Tooltip>
            )}
            {hasUpdate && (
              <Tooltip arrow variant="outlined" size="sm" title="Save">
                <IconButton onClick={handleSave} sx={{ opacity: '1!important' }}>
                  <Icon name="floppy-disk-circle-arrow-right" color="inherit" />
                  <CircularProgressCounter
                    ref={autoSaveCounterRef}
                    determinate
                    color="danger"
                    size="md"
                    sx={{ position: 'absolute', opacity: 0.2 }}
                    onFinish={handleSave}
                  />
                </IconButton>
              </Tooltip>
            )}
          </ButtonGroup>
        )}
      </CardActions>
    </Card>
  );
};

export default memo(ItemNote);
