import type {
  ResultLegacy,
  CollectionType,
} from 'app/entities';

import request from 'services/api/request';

export default async (
  id: number,
  data: Partial<Omit<CollectionType, 'cover'>> & { cover?: { data: string, extension: string } },
): Promise<ResultLegacy<CollectionType>> => {
  const body: typeof data = {
    title: data.title,
    description: data.description,
    isPrivate: data.isPrivate,
  };
  if ('cover' in data) {
    body.cover = data.cover;
  }

  return request.post<CollectionType>(`/user/playlists/${id}`, { body });
};
