import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import type { Layout } from '../types';

const useSizer = (handleResize?: (event: { containerLayout: Layout, contentLayout: Layout }) => void) => {
  const [containerLayout, setContainerLayout] = useState<Layout>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const [contentLayout, setContentLayout] = useState<Layout>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const handleContainerLayout = useCallback(({ nativeEvent }) => {
    const { layout } = nativeEvent;
    setContainerLayout(layout);
  }, []);

  const handleContentLayout = useCallback(({ nativeEvent }) => {
    const { layout } = nativeEvent;
    setContentLayout(layout);
  }, []);

  useEffect(() => {
    handleResize?.({ containerLayout, contentLayout });
  }, [containerLayout, contentLayout, handleResize]);

  return useMemo(() => ({
    handleContainerLayout,
    handleContentLayout,
  }), [containerLayout, contentLayout]);
};

export default useSizer;
