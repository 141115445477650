import {
  type ReactNode,
  memo, useMemo, useCallback,
} from 'react';
import {
  type GestureResponderEvent, type StyleProp, type ViewStyle,
  StyleSheet, TouchableOpacity,
} from 'react-native';

import type {
  MaterialType,
  CollectionType,
} from 'app/entities';
import { View } from 'components/Themed';
import Icon from 'components/LegacyIcon';

import { useThemeColor } from 'hooks';
import { unit } from 'utils';

import { useComments } from '../hooks';

import CommentsBlock from './CommentsBlock';

type FooterBlockProps = {
  style?: StyleProp<ViewStyle>,
  data?: MaterialType | CollectionType | null,
  hasQueue?: boolean,
  hasOptions?: boolean,
  MainComponent?: ReactNode,
  onCommentsPress?: (event: GestureResponderEvent) => void,
  onBookmarkPress?: (event: GestureResponderEvent) => void,
  onOptionsPress?: (event: GestureResponderEvent) => void,
};

const FooterBlock = (props: FooterBlockProps) => {
  const {
    style,
    data,
    hasQueue,
    hasOptions,
    MainComponent,
    onCommentsPress,
    onBookmarkPress,
    onOptionsPress,
  } = props;

  const borderTopColor = useThemeColor({ dark: '#FFFFFF20', light: '#00000020' });

  const comments = useComments(data);

  const handleBookmarkPress = useCallback((event: GestureResponderEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onBookmarkPress?.(event);
  }, [onBookmarkPress]);

  const handleOptionsPress = useCallback((event: GestureResponderEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onOptionsPress?.(event);
  }, [onOptionsPress]);

  const renderMainContainer = useMemo(() => {
    if (MainComponent === false || !data?.id) {
      return null;
    }
    if (MainComponent) {
      return MainComponent;
    }
    return (
      <CommentsBlock
        photos={comments.photos}
        count={comments.count}
        onPress={onCommentsPress}
      />
    );
  }, [data?.id, comments.photos, comments.count, MainComponent, onCommentsPress]);

  const renderRightContainer = useMemo(() => (
    <View style={styles.actions}>
      {hasQueue && (
        <TouchableOpacity style={styles.actionButton} onPress={handleBookmarkPress} activeOpacity={0.8}>
          <Icon name={data?.isBookmarked ? 'BookmarkFill28' : 'BookmarkOutline28'} size={28} />
        </TouchableOpacity>
      )}
      {hasOptions && (
        <TouchableOpacity style={styles.actionButton} onPress={handleOptionsPress} activeOpacity={0.8}>
          <Icon name="Dots" size={32} />
        </TouchableOpacity>
      )}
    </View>
  ), [data?.isBookmarked, hasQueue, hasOptions, handleBookmarkPress, handleOptionsPress]);

  return (
    <View style={[styles.FooterBlock, { borderTopColor }, style]}>
      <View style={styles.mainContainer}>
        {renderMainContainer}
      </View>
      <View style={styles.rightContainer}>
        {renderRightContainer}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  FooterBlock: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainContainer: {
    flex: 1,
  },
  rightContainer: {
  },
  actions: {
    flexDirection: 'row',
    marginVertical: unit(-6),
    marginRight: unit(-8),
  },
  actionButton: {
    width: unit(40),
    height: unit(40),
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default memo(FooterBlock);
