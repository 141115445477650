import {
  memo,
} from 'react';
import {
  StyleSheet,
} from 'react-native';

import { View } from 'components/Themed';

import RegularLayout from './layouts/RegularLayout';

import type { WireframeCardProps } from './types';

/**
 * @param props
 * @constructor
 * @deprecated
 */
const WireframeCard = (props: WireframeCardProps) => {
  const {
    style,
    radius,
  } = props;

  return (
    <View style={[style, styles.WireframeCard]}>
      <RegularLayout radius={radius} />
    </View>
  );
};

const styles = StyleSheet.create({
  WireframeCard: {
    alignSelf: 'stretch',
  },
});

export default memo(WireframeCard);
