import { type KeyboardEvent, useCallback, useMemo } from 'react';

const useOnInputEnter = (onEnterCallback: (event: KeyboardEvent<HTMLInputElement>) => void) => {
  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if ((event.key || event.code) === 'Enter') {
      onEnterCallback?.(event);
    }
  }, [onEnterCallback]);

  return useMemo(() => ({
    onKeyDown: handleKeyDown,
  }), [handleKeyDown]);
};

export default useOnInputEnter;
