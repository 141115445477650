import { type CodeResponse, useGoogleLogin } from '@react-oauth/google';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import network from 'lib/network';

type GoogleSuccess = {
  provider: 'google',
  data: {
    codeResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>,
  },
};

type SuccessType = GoogleSuccess;

type SuccessCallback = (result: { provider: 'google', type: 'success', token: { access: string, refresh: string } }) => void;

type FailCallback = (result: { provider: 'google', type: 'error' | 'warning', message: string }) => void;

const useAuth = (options?: { onSuccess?: SuccessCallback, onFail?: FailCallback }) => {
  const [inProcess, setInProcess] = useState<'google' | null>(null);

  const timerRef = useRef<any | null>(null);

  useEffect(() => () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const requestPair = useCallback(async (result: SuccessType) => {
    const body: Record<string, string | undefined> = {
      provider: result.provider,
      redirectUri: 'postmessage',
    };
    if (result.provider === 'google') {
      body.code = result.data.codeResponse.code;
      body.scope = result.data.codeResponse.scope;
      body.state = result.data.codeResponse.state;
    }
    return network.request<{ access: string, refresh: string }>('/oauth-code/login')
      .body(body)
      .post();
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      requestPair({
        provider: 'google',
        data: {
          codeResponse,
        },
      })
        .catch((error) => {
          return {
            data: null,
            errors: [error],
          };
        })
        .then(({ data, errors }) => {
          if (!data || (errors && errors?.length > 0)) {
            options?.onFail?.({
              provider: 'google',
              type: 'error',
              message: errors?.[0]?.message || 'Sorry, we could not log you in, please try again.',
            });
            return;
          }
          options?.onSuccess?.({
            provider: 'google',
            type: 'success',
            token: data,
          });
          timerRef.current = setTimeout(() => {
            setInProcess(null);
          }, 1000);
        });
    },
    onError: (errorResponse) => {
      options?.onFail?.({
        provider: 'google',
        type: 'error',
        message: 'Sorry, we could not log you in, please try again.',
      });
      setInProcess(null);
    },
    onNonOAuthError: () => {
      options?.onFail?.({
        provider: 'google',
        type: 'warning',
        message: 'Please login into your Google account to continue authentication.',
      });
      setInProcess(null);
    },
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    ux_mode: 'popup',
    select_account: true,
    scope: 'openid profile email https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    include_granted_scopes: true,
  });

  return useMemo(() => ({
    inProcess,
    login: (provider: 'google') => {
      if (provider === 'google') {
        setInProcess('google');
        googleLogin();
      }
    },
  }), [
    inProcess,
    googleLogin,
  ]);
};

export default useAuth;
