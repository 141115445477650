import { createReducer } from '@reduxjs/toolkit';

import type { InteractionType } from 'app/entities';
import type { InteractionStore } from './types';
import * as actions from './actions';

const initialState: InteractionStore = {
  content: {},
  playlist: {},
  meta: {
    loadingMap: {},
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.updateInteraction, (state, action) => {
    const { type, resource, resourceId } = action.payload;
    if (!type || !resource || !resourceId) {
      return;
    }
    state.meta.loadingMap[`${type}-${resource}-${resourceId}`] = true;
  })
  .addCase(actions.updateInteractionDone, (state, action) => {
    const { type, resource, resourceId } = action.payload;
    if (!resource || !resourceId) {
      return;
    }
    delete state.meta.loadingMap[`${type}-${resource}-${resourceId}`];
  })
  .addCase(actions.setValues, (state, action) => {
    const {
      resource, resourceId, type, values,
    } = action.payload;
    const result: InteractionType = {};
    if (type === 'bookmark') {
      result.isBookmarked = values?.isBookmarked;
    }
    if (type === 'like') {
      result.isLiked = values?.isLiked;
      result.isDisliked = values?.isDisliked;
    }
    if (type === 'dislike') {
      result.isLiked = values?.isLiked;
      result.isDisliked = values?.isDisliked;
    }
    if (type === 'complete') {
      result.isCompleted = values?.isCompleted;
    }
    if (type === 'view') {
      result.isViewed = values?.isViewed;
    }
    if (type === 'shown') {
      result.isShown = values?.isShown;
    }
    if (typeof state?.[resource] === 'undefined') {
      state[resource] = {};
    }
    state[resource][resourceId] = { ...state[resource][resourceId], ...result };
  }));
