import {
  type MouseEvent,
  memo, useCallback, type ReactNode,
} from 'react';
import {
  Typography, ChipDelete, Chip, Box, type ChipProps, type BoxProps,
} from '@mui/joy';

type OptionId = number | string;

type OptionsType = {
  id: OptionId,
  label: string,
  deletable?: boolean,
  startDecorator?: ReactNode,
}

type ChipArrayProps = {
  variant: ChipProps['variant'],
  size: ChipProps['size'],
  sx?: BoxProps['sx'],
  options: OptionsType[],
  disabled?: boolean,
  inactive?: boolean,
  onClick: (event: MouseEvent<HTMLElement>, id: OptionId) => void,
  onDelete?: (event: MouseEvent<HTMLElement>, id: OptionId) => void,
}

const ChipArray = (props: ChipArrayProps) => {
  const {
    variant,
    size,
    sx,
    options,
    onClick,
    onDelete,
    disabled,
    inactive,
  } = props;

  const castType = useCallback((value?: number | string) => {
    if (typeof value === 'undefined' || value === null) {
      return null;
    }
    return Number.isNaN(Number(value)) ? value : Number(value);
  }, []);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (inactive) {
      return;
    }
    const id = castType(event.currentTarget.dataset.id);
    if (id) {
      onClick?.(event, id);
    }
  }, [onClick, inactive]);

  const handleDelete = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (inactive) {
      return;
    }
    const id = castType(event.currentTarget.dataset.id);
    if (id) {
      onDelete?.(event, id);
    }
  }, [onDelete, inactive]);

  return (
    <Box sx={sx} display="flex" gap={1} flexWrap="wrap">
      {options.map((option) => (
        <Chip
          key={option.id}
          size={size}
          disabled={disabled}
          variant={variant}
          slotProps={{ action: { 'data-id': option.id } }}
          startDecorator={option.startDecorator}
          endDecorator={(
            option.deletable ? (
              <ChipDelete
                slotProps={{ root: { 'data-id': option.id } }}
                onClick={handleDelete}
              />
            ) : undefined
          )}
          onClick={handleClick}
          sx={{
            pointerEvents: inactive ? 'none' : undefined,
          }}
        >
          <Typography
            fontSize={12}
            fontWeight={400}
          >
            {option.label}
          </Typography>
        </Chip>
      ))}
    </Box>
  );
};

export default memo(ChipArray);
