import { matchPath } from 'react-router-dom';
import sq from 'query-string';

import linking from '../../linking';
import type { ScreenParams } from '../../types';

const getLocation = (): { pathname: string, search: string } => {
  if (typeof window !== 'undefined') {
    return {
      pathname: window.location.pathname,
      search: window.location.search,
    };
  }
  return {
    pathname: '',
    search: '',
  };
};

export const getRoute = <RouteScreenName extends keyof ScreenParams>() => {
  const location = getLocation();
  let params: Record<string, any> = {};
  let name: keyof ScreenParams = 'NotFound';
  Object.entries(linking).forEach(([screenName, pattern]) => {
    if (name !== 'NotFound') {
      return;
    }
    const match = matchPath(pattern, location.pathname);
    if (match) {
      name = screenName as keyof ScreenParams;
      params = match.params;
    }
    const searchParams = sq.parse(location.search);
    params = { ...params, ...searchParams };
  });
  return {
    name,
    params: params as ScreenParams[RouteScreenName],
    isScreen: <ScreenName extends keyof ScreenParams>(screenName: ScreenName): boolean => {
      return screenName === name;
    },
  };
};
