import { useMemo } from 'react';
import { type StyleProp, type ViewStyle } from 'react-native';
import { unit } from 'utils';

const useMarginUnits = (value?: string): StyleProp<ViewStyle> => useMemo(() => {
  if (!value) {
    return {};
  }

  const values = value.split(' ').map((val) => parseFloat(val) * 16);

  let marginTop = 0;
  let marginRight = 0;
  let marginBottom = 0;
  let marginLeft = 0;

  switch (values.length) {
    case 1:
      [marginTop] = values;
      [marginRight] = values;
      [marginBottom] = values;
      [marginLeft] = values;
      break;
    case 2:
      [marginTop, marginRight] = values;
      marginBottom = marginTop;
      marginLeft = marginRight;
      break;
    case 3:
      [marginTop, marginRight, marginBottom] = values;
      marginLeft = marginRight;
      break;
    case 4:
      [marginTop, marginRight, marginBottom, marginLeft] = values;
      break;
    default:
      throw new Error('Invalid margin value');
  }

  return {
    marginTop: unit(marginTop),
    marginRight: unit(marginRight),
    marginBottom: unit(marginBottom),
    marginLeft: unit(marginLeft),
  };
}, [value]);

export default useMarginUnits;
