import { type DefaultRootState } from 'react-redux';

import subscriptionData from 'data/subscription.json';

import { type CurrentPlanStateType } from '../../types';

const currentPlan = (state: DefaultRootState): CurrentPlanStateType => {
  const id = state.subscription.state.plan;
  const { expirationDate, canceledAt, status } = state.subscription.state;
  const isIncomplete = status === 'incomplete';
  const isPaused = !!canceledAt;
  const isLoaded = id !== '-1';
  let period: string | null = null;

  if (!isLoaded) {
    return {
      id,
      period,
      name: null,
      expirationDate,
      canceledAt,
      isIncomplete,
      isPaused,
      isLoaded,
    };
  }

  const {
    identity,
    name = null,
  } = subscriptionData.plans.find((plan) => plan.identity.planId === id) || {};
  subscriptionData.periods.forEach((item) => {
    if (!identity || !!period) {
      return;
    }
    if (item.planIds.includes(identity?.planId)) {
      period = item.id;
    }
  });

  if (!identity) {
    return {
      id,
      period,
      name: null,
      expirationDate,
      canceledAt,
      isIncomplete,
      isPaused,
      isLoaded,
    };
  }

  return {
    id,
    period,
    name,
    expirationDate,
    canceledAt,
    isIncomplete,
    isPaused,
    isLoaded,
  };
};

export default currentPlan;
