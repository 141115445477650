import {
  type FC, type ReactElement,
  memo, useRef, useMemo, cloneElement, useCallback,
} from 'react';

import { Box, Typography } from '@mui/joy';

import SegmentedInput from 'ui/SegmentedInput';

import subscription from 'data/subscription.json';

import { useSelector } from 'store/utils/redux/hooks';
import { selectors } from './store';

import usePeriodState from './model/usePeriodState';
import usePlans from './model/usePlans';
import useBarLabels from './model/useBarLabels';

import ModalLayout, { type ModalLayoutMethods } from './layout/ModalLayout';
import Plans from './ui/Plans';

type PricingProps = {
  layout: 'modal',
  modalOpenerTextProperty: string,
  modalOpenerClickProperty: string,
  modalOpener: ReactElement,
}

const Pricing: FC<PricingProps> = (props) => {
  const {
    layout,
    modalOpenerTextProperty,
    modalOpenerClickProperty,
    modalOpener,
  } = props;

  const ModalLayoutRef = useRef<ModalLayoutMethods>(null);
  const currentPlan = useSelector(selectors.currentPlan);
  const { stateLabel } = useBarLabels();

  const {
    value: periodValue,
    change: handlePeriodChange,
    options: periodOptions,
  } = usePeriodState();

  const plans = usePlans(periodValue);

  const handleModalOpen = useCallback(() => {
    if (currentPlan.period) {
      handlePeriodChange(currentPlan.period);
    }
    ModalLayoutRef.current?.open();
  }, [currentPlan.period]);

  const modalOpenerConnector = useMemo(() => {
    if (layout !== 'modal') {
      return null;
    }
    return cloneElement(modalOpener as ReactElement, {
      [modalOpenerTextProperty]: (
        <Box component="span">
          {stateLabel}
          {currentPlan.isIncomplete ? <Typography component="span" fontSize={10} fontWeight={600} color="danger"> / not paid</Typography> : ''}
        </Box>
      ),
      [modalOpenerClickProperty]: handleModalOpen,
    });
  }, [layout, modalOpener, handleModalOpen, stateLabel, currentPlan.isIncomplete]);

  if (layout === 'modal') {
    return (
      <>
        {modalOpenerConnector}
        <ModalLayout ref={ModalLayoutRef}>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            maxWidth="1280px"
            mx="auto"
            my={8}
          >
            <Typography level="h1" fontSize={46} fontWeight={400} lineHeight={1.3} alignSelf="center" textAlign="center">
              <span dangerouslySetInnerHTML={{ __html: subscription.title }} />
            </Typography>
            <Typography fontSize={28} fontWeight={400} alignSelf="center" mt={2} textAlign="center" textColor="text.secondary" sx={{ opacity: 0.75 }}>
              <span dangerouslySetInnerHTML={{ __html: subscription.note }} />
            </Typography>
            <SegmentedInput
              options={periodOptions}
              value={periodValue}
              onChange={handlePeriodChange}
              sx={{ alignSelf: 'center', mt: 6, mb: 8 }}
            />
            <Plans
              directions={subscription.directions}
              features={subscription.features}
              plans={plans}
            />
          </Box>
        </ModalLayout>
      </>
    );
  }

  return null;
};

export default memo(Pricing);
