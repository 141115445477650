import React, {
  type ReactElement,
  Fragment, useCallback, useMemo, memo,
} from 'react';
import { Box, Typography, useTheme } from '@mui/joy';
import type { CollectionType, UserType } from 'app/entities';

import { useFocusEffect } from 'navigation/hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import { guard } from 'utils';

import TopBarDesktop from './elements/TopBarDesktop';

import CollectionsSwiper from './ui/CollectionsSwiper';
import UsersSwiper from './ui/UsersSwiper';

const Collections = (): ReactElement => {
  const dispatcher = useDispatcher();
  const theme = useTheme();

  const pool = useSelector(((state) => state.community.collections.pool));

  useFocusEffect(useCallback(() => {
    setTimeout(() => {
      dispatcher.community.loadCollections();
    }, 300);
  }, []));

  const poolFinal = useMemo(() => pool, [pool]);

  return (
    <>
      <TopBarDesktop />
      <Box sx={{
        '@media (max-width: 499px)': {
          mt: 2,
          mb: 6,
        },
      }}
      >
        {poolFinal.map((item) => {
          if (!Array.isArray(item.payload) || item.payload.length === 0) {
            return null;
          }
          return (
            <Fragment key={item.title}>
              <Typography fontSize={22} fontWeight={500} mt={2} mb={1}>{item.title}</Typography>
              <Box
                sx={{
                  '--swiper-pagination-bullet-inactive-color': theme.palette.neutral.softColor,
                  '@media (max-width: 767px)': {
                    mx: -2,
                  },
                  '& .swiperCollection, & .swiperUser': {
                    '@media (max-width: 767px)': {
                      pl: 2,
                    },
                  },
                }}
              >
                {guard.isCollection(item.payload[0]) && (
                  <CollectionsSwiper collections={item.payload as CollectionType[]} />
                )}
                {guard.isUser(item.payload[0]) && (
                  <UsersSwiper users={item.payload as UserType[]} />
                )}
              </Box>
            </Fragment>
          );
        })}
      </Box>
    </>
  );
};

export default memo(Collections);
