import { memo } from 'react';
import { StyleSheet } from 'react-native';

import CollectionSingleCover from 'components/CollectionSingleCover';
import Icon from 'components/LegacyIcon';

import { unit } from 'utils';

import type { CollectionType } from 'app/entities';

const ItemSelectInput = (props: { title: string; data: CollectionType; }) => {
  const { title, data } = props;
  return (
    <div style={styles.ItemSelectInput}>
      <CollectionSingleCover data={data} iconSize={24} widthPicture={24} heightPicture={24} />
      {title}
      {data?.isPrivate && <Icon name="LockFill" size={16} style={styles.privateIcon} />}
    </div>
  );
};

const styles = StyleSheet.create({
  ItemSelectInput: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
  },
  privateIcon: {
    position: 'absolute',
    right: unit(12),
    opacity: 0.5,
  },
});

export default memo(ItemSelectInput);
