import request from 'services/api/request';
import type { SuccessResult } from 'app/entities';

export default async (): Promise<SuccessResult> => {
  const { error } = await request.post<SuccessResult>('/auth/register/completed');
  if (error) {
    return {
      data: null,
      error,
    };
  }
  return {
    data: { success: true },
    error,
  };
};
