import { createReducer } from '@reduxjs/toolkit';

import type { ModalStore } from './types';
import * as actions from './actions';

const initialState: ModalStore = {
  opened: {},
  results: {},
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.open, (state, action) => {
    const { name, params } = action.payload;
    if (name in state.opened) {
      return;
    }
    delete state.results[name];
    state.opened[name] = params;
  })
  .addCase(actions.close, (state, action) => {
    const { name, result } = action.payload;
    if (!(name in state.opened)) {
      return;
    }
    state.results[name] = result || 'empty';
    delete state.opened[name];
  }));
