import type { ResultLegacy } from 'app/entities';
import request from 'services/api/request';

interface ServerData {
  rates: {
    rate: number,
    count: number,
  }[],
  avgRate: 0,
  totalRates: 0,
}

interface ResultData {
  rates: {
    rate1: number,
    rate2: number,
    rate3: number,
    rate4: number,
    rate5: number,
  },
  avg: 0,
  total: 0,
}

export default async (
  resource: 'content' | string,
  id: number,
): Promise<ResultLegacy<ResultData>> => {
  const { data, error } = await request.delete<ServerData>(`/content/${id}/reviews/summary`);
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  const resultData: ResultData = {
    rates: {
      rate1: 0,
      rate2: 0,
      rate3: 0,
      rate4: 0,
      rate5: 0,
    },
    avg: data.avgRate,
    total: data.totalRates,
  };

  data.rates.forEach((item) => {
    if (`rate${item.rate}` in resultData.rates) {
      resultData.rates[`rate${item.rate}` as 'rate1' | 'rate2' | 'rate3' | 'rate4' | 'rate5'] = item.count;
    }
  });

  return {
    data: resultData,
    error: null,
  };
};
