import { memo, useState } from 'react';
import { type StyleProp, type ViewStyle, StyleSheet } from 'react-native';

import { View } from 'components/Themed';
import { unit } from 'utils';
import Lottie from 'components/Lottie';

import { useThemeColor } from 'hooks';

type VideoViewerProps = {
  style?: StyleProp<ViewStyle>,
  src?: string,
}

const VideoViewer = (props:VideoViewerProps) => {
  const {
    style,
    src,
  } = props;

  const [loading, setLoading] = useState(true);

  const lottieColor = useThemeColor({ light: '#497CFF', dark: '#497CFF' });

  if (!src) {
    return null;
  }

  return (
    <View style={[styles.component, style]}>
      {loading && (
        <View style={styles.loading}>
          <Lottie name="LoadingCircle" loop width={160} height={160} color={lottieColor} />
        </View>
      )}
      <iframe
        width="100%"
        height="100%"
        src={src?.replace(/.*\?v=(.*)$/, 'https://www.youtube.com/embed/$1')}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    position: 'relative',
    flexDirection: 'row',
    overflow: 'hidden',
    width: '100%',
    aspectRatio: 16 / 9,
    borderRadius: unit(5),
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: unit(320),
  },
});

export default memo(VideoViewer);
