import { toLegacyResourceType } from 'utils';

import type { SuccessResult } from 'app/entities';
import { request } from 'services/api';

export default async (resourceType: 'collection' | 'material' | 'note', resourceId: number): Promise<SuccessResult> => {
  const { error } = await request.delete<SuccessResult>(`user/library/${toLegacyResourceType(resourceType)}/${resourceId}`);

  if (error) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: {
      success: true,
    },
    error,
  };
};
