import moment from 'moment-timezone';

import type { ResultLegacy, CommentType } from 'app/entities';
import request from 'services/api/request';

export default async (ids: number[]): Promise<ResultLegacy<CommentType[]>> => {
  if (!Array.isArray(ids) || ids.length === 0) {
    return {
      data: [],
      error: null,
    };
  }

  const query: Record<string, any> = {
    commentIds: [...new Set(ids)].join(','),
  };

  const { data, error } = await request.get<CommentType[]>('/comments', { query });
  if (error || !data) {
    return {
      data: [],
      error: null,
    };
  }
  return {
    data: data.map((item): CommentType => ({
      ...item,
      createdAt: item.createdAt ? moment.utc(item.createdAt).toISOString() : null,
      updatedAt: item.updatedAt ? moment.utc(item.updatedAt).toISOString() : null,
      deletedAt: item.deletedAt ? moment.utc(item.deletedAt).toISOString() : null,
    })),
    error: null,
  };
};
