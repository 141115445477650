import {
  memo, type MouseEvent, useCallback, useEffect, useState,
} from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, type TextFormatType,
} from 'lexical';
import { IconButton } from '@mui/joy';

import Icon from 'ui/Icon';

type RichTextState = {
  isBold: boolean;
  isCode: boolean;
  isItalic: boolean;
  isStrikethrough: boolean;
  isUnderline: boolean;
  // IsSubscript: boolean;
  // isSuperscript: boolean;
};

type RichTextPanelProps = {
  editor: ReturnType<typeof useLexicalComposerContext>[0];
};

const RichTextPanel = (props: RichTextPanelProps) => {
  const { editor } = props;

  const [state, setState] = useState<RichTextState>({
    isBold: false,
    isCode: false,
    isItalic: false,
    isStrikethrough: false,
    isUnderline: false,
    // IsSubscript: false,
    // isSuperscript: false,
  });

  const buttons = [
    { value: 'bold', isPressed: state.isBold, icon: 'bold' },
    { value: 'italic', isPressed: state.isItalic, icon: 'italic' },
    { value: 'underline', isPressed: state.isUnderline, icon: 'underline' },
    { value: 'strikethrough', isPressed: state.isStrikethrough, icon: 'strikethrough' },
    { value: 'code', isPressed: state.isCode, icon: 'code' },
    // { value: 'subscript', isPressed: state.IsSubscript, icon: 'subscript' },
    // { value: 'superscript', isPressed: state.isSuperscript, icon: 'superscript' },
  ];

  useEffect(() => {
    editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) return;

          setState({
            isBold: selection.hasFormat('bold'),
            isCode: selection.hasFormat('code'),
            isItalic: selection.hasFormat('italic'),
            isStrikethrough: selection.hasFormat('strikethrough'),
            isUnderline: selection.hasFormat('underline'),
            // IsSubscript: selection.hasFormat('subscript'),
            // isSuperscript: selection.hasFormat('superscript'),
          });
        });
      },
    );
  }, [editor]);

  const handleChange = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const type = event.currentTarget.value as TextFormatType;
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, type);
  }, []);

  return (
    <>
      {buttons.map((button) => (
        <IconButton
          key={button.value}
          value={button.value}
          aria-pressed={button.isPressed}
          onClick={handleChange}
        >
          <Icon name={button.icon} fw weight="regular" color="inherit" />
        </IconButton>
      ))}
    </>
  );
};

export default memo(RichTextPanel);
