import type { Result, RegistrationCompletedType } from 'app/entities';
import request from 'services/api/request';

export default async () => {
  const { error, data } = await request.get<RegistrationCompletedType>('/auth/register/completed');
  const result: Result<RegistrationCompletedType> = {
    data: data || null,
    error: {
      hasErrors: false,
      list: [],
    },
  };
  if (error instanceof Error) {
    result.error.hasErrors = true;
    result.error.list = [error];
  }
  if (Array.isArray(error)) {
    result.error.hasErrors = true;
    result.error.list = error;
  }
  return result;
};
