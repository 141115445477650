import {
  takeLatest, put, cancel,
} from 'redux-saga/effects';

import Storage from 'lib/Storage';

import { call } from 'store/utils/saga/effects';

import * as api from 'services/api';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: actions.loadFilters.type,
  method: takeLatest,
};

const loadFromCache = (): string[] | null => {
  const key = '@search/types';
  let cache: {
    value: string[],
    releaseTime: string,
  } | null = null;

  try {
    const buffer = Storage.get(key);
    if (buffer) {
      cache = JSON.parse(buffer);
    }
  } catch (error) {
    cache = null;
  }

  if (
    !cache
    || cache.releaseTime !== process.env.RELEASE_TIME
    || !Array.isArray(cache)
  ) {
    return null;
  }

  return cache.value;
};

const saveToCache = (list: string[]): void => {
  if (!Array.isArray(list)) {
    return;
  }
  const key = '@search/types';
  Storage.set(key, JSON.stringify({
    value: list,
    releaseTime: process.env.RELEASE_TIME,
  }));
};

export function* func() {
  const options = yield* call(() => loadFromCache());

  if (!options) {
    const { data, error } = yield* call(() => api.resource.content.filter.items());
    if (error || !data) {
      Alert.error(error?.message || 'Server error #13');
      yield put(actions.loadFiltersDone(true));
      yield cancel(); return;
    }
    yield* call(() => saveToCache(data));
    yield put(actions.setFilters({
      type: {
        options: data,
      },
    }));
    yield put(actions.loadFiltersDone());
    yield cancel(); return;
  }

  yield put(actions.setFilters({
    type: {
      options,
    },
  }));
  yield put(actions.loadFiltersDone());

  const { data, error } = yield* call(() => api.resource.content.filter.items());
  if (error || !data) {
    yield cancel(); return;
  }
  yield* call(() => saveToCache(data));
  yield put(actions.setFilters({
    type: {
      options: data,
    },
  }));
}
