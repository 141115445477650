import { createAction } from '@reduxjs/toolkit';

import { type NoteType } from 'app/entities';

export const loadById = createAction('SMARTNOTE/LOAD_BY_ID', (
  id: number | number[],
) => ({
  payload: {
    id,
  },
}));

export const setData = createAction('SMARTNOTE/SET_ITEM', (
  data: Record<string | number, NoteType> | NoteType[],
) => ({
  payload: {
    data,
  },
}));

export const create = createAction('SMARTNOTE/CREATE', (
  resourceType: 'collection' | 'material',
  resourceId: number,
  text: string,
  position: number,
) => ({
  payload: {
    resourceType,
    resourceId,
    text,
    position,
  },
}));

export const createDone = createAction('SMARTNOTE/CREATE_DONE', (
  success: boolean,
  data?: NoteType,
) => ({
  payload: {
    success,
    data,
  },
}));

export const update = createAction('SMARTNOTE/UPDATE', (
  data: Pick<NoteType, 'id' | 'text'> | Pick<NoteType, 'id' | 'text'>[],
) => ({
  payload: {
    data,
  },
}));

export const remove = createAction('SMARTNOTE/REMOVE', (
  id: number | number[],
) => ({
  payload: {
    id,
  },
}));
