import { useMemo } from 'react';
import {
  type StyleProp, type ViewStyle,
  useWindowDimensions,
} from 'react-native';

import { useResponsive } from 'hooks';
import { unit } from 'utils';

type PageStyleConfig = {
  noTopMargin: boolean,
};

export const useScreenStyle = (config: Partial<PageStyleConfig> = {}) => {
  const {
    noTopMargin,
  } = config;

  const dimensions = useWindowDimensions();
  const responsive = useResponsive();

  const page = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      alignItems: 'center',
      paddingTop: unit(32),
      paddingBottom: unit(24),
      paddingHorizontal: unit(16),
    };
    if (noTopMargin) {
      return result;
    }
    if (responsive.isMoreThen.mobileLarge && dimensions.height < 600) {
      result.paddingTop = dimensions.height * 0.05;
      return result;
    }
    if (responsive.isMoreThen.mobileLarge && dimensions.height < 700) {
      result.paddingTop = dimensions.height * 0.075;
      return result;
    }
    if (responsive.isMoreThen.mobileLarge && dimensions.height < 800) {
      result.paddingTop = dimensions.height * 0.13;
      return result;
    }
    if (responsive.isMoreThen.mobileLarge) {
      result.paddingTop = dimensions.height * 0.16;
      return result;
    }
    return result;
  }, [noTopMargin, dimensions.height, responsive.isMoreThen.mobileLarge]);

  const container = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      alignItems: 'stretch',
      flex: 1,
      width: '100%',
      maxWidth: unit(414),
    };
    return result;
  }, []);

  const form = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      flex: 1,
      marginTop: unit(24),
      alignItems: 'stretch',
    };
    if (responsive.isMoreThen.mobileLarge) {
      delete result.flex;
    }
    return result;
  }, [responsive.isMoreThen.mobileLarge]);

  const footer = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      marginTop: unit(24),
      justifyContent: 'flex-end',
    };
    if (responsive.isMoreThen.mobileLarge) {
      // footer.alignSelf = 1;
    }
    return result;
  }, [responsive.isMoreThen.mobileLarge]);

  return useMemo(() => ({
    container,
    page,
    form,
    footer,
  }), [page, container, form, footer]);
};
