import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

import { guard } from 'utils';
import { type CollectionType } from 'app/entities';

import * as userStore from 'store/nodes/user';
import * as commentStore from 'store/nodes/comment';

// Определите функцию для создания ключа кеша
const cacheKeyCreator = (state: DefaultRootState, resourceId: number | null | undefined, updateMeta?: { mode: 'add' | 'sub' }) => `${resourceId}-${updateMeta?.mode || 'none'}`;

const dataByIdSelector = createCachedSelector(
  (state: DefaultRootState, resourceId: number | null | undefined) => state,
  (state, resourceId: number | null | undefined, updateMeta?: { mode: 'add' | 'sub' }) => resourceId,
  (state, resourceId, updateMeta) => updateMeta,
  (state, resourceId, updateMeta): CollectionType | null => {
    if (!resourceId || !state.playlist.data || !state.playlist.data?.[resourceId]) {
      return null;
    }

    const playlist: CollectionType = {
      ...state.playlist.data[resourceId],
      ...state?.interaction?.playlist?.[resourceId],
      isMy: state.playlist.data[resourceId].user?.id === userStore.selectors.getIdByLogin('my')(state),
    };

    if (!updateMeta) {
      return playlist;
    }
    const commentsCount = (playlist.commentMeta?.commentsCount || 0) + (updateMeta.mode === 'add' ? 1 : -1);
    const userPhotos: string[] = [];
    if (commentsCount > 3 && playlist.commentMeta && Array.isArray(playlist.commentMeta?.userPhotos) && playlist.commentMeta.userPhotos.length === 3) {
      userPhotos.push(...playlist.commentMeta.userPhotos);
    } else {
      const commentItems = commentStore.selectors.items('playlist', resourceId)(state);
      commentItems.forEach((comment) => {
        if (userPhotos.length === 3 || !comment.user?.photo) {
          return;
        }
        const { photo } = comment.user;
        let url = '';
        if (guard.isImage(photo)) {
          url = photo.url;
        }
        if (typeof photo === 'string') {
          url = photo;
        }
        if (!url) {
          return;
        }
        if (userPhotos.length > 0 && userPhotos.includes(url)) {
          return;
        }
        userPhotos.push(url);
      });
    }
    return {
      ...playlist,
      commentMeta: {
        commentsCount,
        userPhotos,
      },
    };
  },
)(cacheKeyCreator);

export default (resourceId: number | null | undefined, updateMeta?: { mode: 'add' | 'sub' }) => (state: DefaultRootState) => {
  return dataByIdSelector(state, resourceId, updateMeta);
};
