import { Platform } from 'react-native';
import {
  takeEvery, put,
} from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';
import * as Linking from 'expo-linking';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.doOAuth.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.doOAuth>) {
  const { provider } = action.payload;

  const url = Linking.createURL('/welcome');
  const result = yield* call(() => api.resource.oauth.urls(url));

  if (result.error || !result.data) {
    yield put(actions.doOAuthDone(result.error));
  }

  if (!result?.data?.[provider]) {
    yield put(actions.doOAuthDone('unknown_error'));
  }

  if (Platform.OS === 'web' && typeof window !== 'undefined' && window?.location?.href && typeof document !== 'undefined') {
    window.location.replace(result?.data?.[provider] || '/');
  }
}
