import { type ListEmptyComponentPropsInterface } from 'react-native-dropdown-picker';

import { Text, View } from 'components/Themed';
import { StyleSheet } from 'react-native';
import { unit } from 'utils';
import SpinnerDotted from 'components/SpinnerDotted';

const ListEmptyComponent = (props: ListEmptyComponentPropsInterface) => {
  const {
    loading = false,
  } = props;
  return (
    <View style={styles.ListEmptyComponent}>
      {!loading && (
        <SpinnerDotted size={50} />
      )}
      {loading && (
        <Text size={13} lightColor="#696969" darkColor="#D1CFD7">No results</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  ListEmptyComponent: {
    minHeight: unit(60),
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ListEmptyComponent;
