import { memo } from 'react';

import { BottomSheet as BottomSheetOriginal, type BottomSheetProps } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

import { useColorScheme } from 'hooks';

import BottomSheetStyleLight from '../style/BottomSheetStyleLight';
import BottomSheetStyleDark from '../style/BottomSheetStyleDark';

const BottomSheet = (props: BottomSheetProps) => {
  const { children, ...otherProps } = props;
  const theme = useColorScheme();

  return (
    <>
      {theme === 'light' && (
        <BottomSheetStyleLight />
      )}
      {theme === 'dark' && (
        <BottomSheetStyleDark />
      )}
      <BottomSheetOriginal {...otherProps}>
        {children}
      </BottomSheetOriginal>
    </>
  );
};

export default memo(BottomSheet);
