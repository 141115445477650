import { memo, useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import Card from 'widgets/Card';
import AuthorBlock from './AuthorBlock';

interface ItemProps {
  eventType: 'created' | 'completed' | 'commented',
  eventTime?: string,
  resourceId: number,
  resourceType: 'material' | 'collection',
  userId: number,
  comment?: string,
}

const Item = (props: ItemProps) => {
  const {
    eventType,
    eventTime,
    resourceId,
    resourceType,
    userId,
    comment,
  } = props;

  const user = useSelector(userStore.selectors.getById(userId));

  const eventDescription = useMemo(() => {
    let result = '';
    if (eventType === 'created') {
      result = `${resourceType.substr(0, 1).toUpperCase()}${resourceType.substr(1)} added`;
    }
    if (eventType === 'completed') {
      result = `${resourceType.substr(0, 1).toUpperCase()}${resourceType.substr(1)} completed${comment ? `: ${comment}` : ''}`;
    }
    if (eventType === 'commented') {
      result = `New comment: ${comment}`;
    }
    return result;
  }, [eventType, resourceType]);

  return (
    <Card
      id={resourceId}
      type={resourceType}
      hasToCollection
      hasQueue
      hasLink
      hasTags
      tagLimit={6}
      descriptionLineLimit={8}
      AuthorBlockComponent={!!user && (
        <AuthorBlock
          data={user}
          eventType={eventType}
          eventTime={eventTime}
          eventDescription={eventDescription}
        />
      )}
      sx={{
        mx: 1.5,
        mb: 2.5,
      }}
    />
  );
};

export default memo(Item);
