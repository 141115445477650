import { type PropsWithChildren, type ReactElement, useCallback } from 'react';
import {
  type ViewStyle, type StyleProp,
  TouchableOpacity, StyleSheet,
} from 'react-native';

import { useThemeColor } from 'hooks';

import { useSelector, useDispatcher } from 'store/utils/redux/hooks';

import { unit } from 'utils';
import { View } from 'components/Themed';
import SpinnerDotted from 'components/SpinnerDotted';

import { useButton } from '../utils/context';
import type { ButtonProps } from '../types';

// todo: поменять name на LoadingIndicator

const getActiveOpacity = (type: string, isValid: boolean, inProcess: boolean, isLocked: boolean): number => {
  if (inProcess) {
    return 0.8;
  }
  if (isLocked) {
    return 0.5;
  }
  if (type === 'submit' && !isValid) {
    return 0.5;
  }
  return 0.8;
};

const getRegularOpacity = (type: string, isValid: boolean, inProcess: boolean, isLocked: boolean): number => {
  if (inProcess) {
    return 0.8;
  }
  if (isLocked) {
    return 0.5;
  }
  if (type === 'submit' && !isValid) {
    return 0.5;
  }
  return 1;
};

interface YButtonProps extends ButtonProps {
  style?: StyleProp<ViewStyle>
  lightColor?: string,
  darkColor?: string,
}

const Button = (props: PropsWithChildren<YButtonProps>): ReactElement => {
  const {
    children,
    type = 'button',
    color: oldColor,
    width,
    size = 50,
    radius = 'half',
    variant = 'contained',
    slimEgle = false,
    startIcon,
    endIcon,
    onPress,
    name,
    isLocked,
    context,
    inProcess = false,
    style,
    lightColor = '#497CFF',
    darkColor = '#497CFF',
  } = props;

  const button = useButton();

  const color = useThemeColor({ light: lightColor, dark: darkColor });

  const dispatch = useDispatcher();
  const processButtonName = useSelector((state) => state.common.processButtonName);

  const inProcessCommon = processButtonName === name;

  const handlePress = useCallback((event: any) => {
    let action: any | undefined = onPress;
    if (isLocked || button?.isLocked) {
      return;
    }

    if (type === 'submit' && button?.handleSubmit) {
      if (!button?.isValid) {
        return;
      }
      action = button?.handleSubmit;
    }
    if (type === 'reset' && button?.handleReset) {
      action = button?.handleReset;
    }
    if (action && name) {
      dispatch.common.setButtonProcess(name);
    }
    action?.(event, context);
  }, [onPress, button?.isValid, button?.isLocked, button?.handleSubmit, button?.handleReset, isLocked, type, name, context, dispatch.common]);

  const buttonStyles = getButtonStyles(oldColor || color, width, slimEgle, size, radius, variant);
  if (button) {
    buttonStyles.marginTop = unit(32);
  }

  const extraStyles = StyleSheet.flatten(style);

  return (
    <TouchableOpacity
      onPress={!inProcess ? handlePress : undefined}
      activeOpacity={getActiveOpacity(type, !!button?.isValid, inProcess, isLocked || button?.isLocked || false)}
      style={[
        buttonStyles,
        extraStyles,
        { opacity: getRegularOpacity(type, !!button?.isValid, inProcess, isLocked || button?.isLocked || false) },
      ]}
    >
      <View style={styles.processIcon}>{(inProcessCommon || inProcess) && <SpinnerDotted />}</View>
      <View
        style={[buttonStyles, {
          flexDirection: 'row',
          paddingHorizontal: 0,
          marginTop: 0,
          borderColor: 'transparent',
          opacity: inProcessCommon || inProcess ? 0 : 1,
        }]}
      >
        {Boolean(startIcon) && <View style={styles.startIcon}>{startIcon}</View>}
        {children}
        {Boolean(endIcon) && <View style={styles.endIcon}>{endIcon}</View>}
      </View>
    </TouchableOpacity>
  );
};

const getButtonStyles = (
  color?: string,
  width?: ButtonProps['width'],
  slimEgle?: boolean,
  size: ButtonProps['size'] = 50,
  radius: ButtonProps['radius'] = 'half',
  variant: ButtonProps['variant'] = 'contained',
): ViewStyle => {
  const sizeMap: Record<Exclude<ButtonProps['size'], undefined>, { height: number, paddingHorizontal: number, paddingBottom: number, borderRadius: number }> = {
    25: {
      height: 25,
      paddingHorizontal: variant === 'text' ? 0 : 15,
      paddingBottom: 0,
      borderRadius: 7,
    },
    32: {
      height: 32,
      paddingHorizontal: variant === 'text' ? 0 : 20,
      paddingBottom: 0,
      borderRadius: 11,
    },
    40: {
      height: 40,
      paddingHorizontal: variant === 'text' ? 0 : 22,
      paddingBottom: 0,
      borderRadius: 15,
    },
    50: {
      height: 50,
      paddingHorizontal: variant === 'text' ? 0 : 25,
      paddingBottom: 0,
      borderRadius: 15,
    },
    70: {
      height: 70,
      paddingHorizontal: variant === 'text' ? 0 : 30,
      paddingBottom: 0,
      borderRadius: 15,
    },
  };

  const { height, paddingBottom, borderRadius } = sizeMap[size];
  let { paddingHorizontal } = sizeMap[size];
  if (slimEgle && paddingHorizontal > 0) {
    paddingHorizontal = Math.ceil(paddingHorizontal / 2);
  }

  const radiusMap = {
    none: 0,
    half: borderRadius,
    full: height / 2,
  };

  const styles: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: unit(height),
    borderRadius: unit(radiusMap[radius]),
    paddingHorizontal: unit(paddingHorizontal),
    paddingBottom: unit(paddingBottom),
    overflow: 'hidden',
  };

  if (width === 'full') {
    styles.width = '100%';
  } else
  if (typeof width === 'string') {
    styles.width = width;
  } else
  if (typeof width === 'number') {
    styles.width = unit(width);
  }

  if (variant === 'contained') {
    styles.backgroundColor = color;
    styles.borderWidth = 1;
    styles.borderStyle = 'solid';
    styles.borderColor = color;
  }
  if (variant === 'outlined') {
    styles.borderWidth = 1;
    styles.borderStyle = 'solid';
    styles.borderColor = color;
  }

  return styles;
};

const styles = StyleSheet.create({
  startIcon: {
    paddingRight: unit(10),
  },
  endIcon: {
    paddingLeft: unit(10),
  },
  processIcon: {
    position: 'absolute',
  },
});

export default Button;
