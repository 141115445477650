import moment from 'moment-timezone';

import type {
  ResultLegacy,
  CollectionType,
} from 'app/entities';

import request from 'services/api/request';
import comment from 'services/api/comment/route';
import interaction from 'services/api/interaction/route';
import network from 'lib/network';

export default async (ids: number[]): Promise<ResultLegacy<CollectionType[]>> => {
  if (!ids || (Array.isArray(ids) && ids.length === 0)) {
    return {
      data: [],
      error: null,
    };
  }
  const idsFinal = Array.isArray(ids) ? ids : [ids];

  const [playlists, interactionMap, commentMap, pendingContentCount] = await Promise.all([
    Promise.all(idsFinal.map((id) => request.get<CollectionType>(`/user/playlists/${id}`))).then((results) => {
      let data: CollectionType[] = [];
      let error: Error | null = null;
      results.forEach((result, key) => {
        if (result.error && result.error.message === 'object_not_found') {
          data.push({
            id: idsFinal[key],
            internalUrl: '',
            contentImages: [],
            materialsMetadata: [],
            cover: null,
            createdAt: '',
            description: '',
            duration: '',
            isCompleted: false,
            isPrivate: false,
            isShown: false,
            level: '',
            tags: [],
            title: '',
            updatedAt: '',
            userId: 0,
            deletedAt: moment().toISOString(),
          });
          return;
        }
        if (result.error || !result.data) {
          error = result.error || new Error('Error server #15');
          data = [];
          return;
        }
        data.push(result.data);
      });
      return {
        data,
        error,
      };
    }),
    interaction.items('playlist', idsFinal),
    comment.meta('playlist', idsFinal),
    network.request<{ pendingContentCount: Record<number, number | null> }>('/user/playlists/pendingContentCount').query({ resourceIds: idsFinal }).get(),
  ]);

  if (playlists.error || !playlists.data || interactionMap.error || !interactionMap.data || commentMap.error || !commentMap.data) {
    return {
      data: null,
      error: playlists.error || interactionMap.error || commentMap.error,
    };
  }

  return {
    data: playlists.data.map((collection) => ({
      ...collection,
      ...interactionMap.data?.[collection?.id],
      pendingMaterialsCount: pendingContentCount.data?.pendingContentCount?.[collection?.id],
      commentMeta: commentMap?.data?.[collection?.id],
    })),
    error: null,
  };
};
