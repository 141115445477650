import {
  cancel, put, type SagaReturnType, takeEvery,
} from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';

import { actions } from '../slice';
import type { StateType } from '../../types';

export const config = {
  action: [
    actions.loadState.type,
  ],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadState>) {
  const { errors, data } = yield* call(() => (
    network.request<StateType>('/payment/subscription').get()
  ));

  if ((errors?.length || 0) > 0 || !data) {
    yield cancel(); return;
  }

  yield put(actions.loadStateDone({
    state: {
      status: data?.status || null,
      plan: `${data?.plan || 0}`,
      startDate: data?.startDate || null,
      expirationDate: data?.expirationDate || null,
      canceledAt: data?.canceledAt || null,
      cancelAt: data?.cancelAt || null,
    },
  }));
}
