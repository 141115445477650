import {
  useCallback,
  type ReactElement, memo,
} from 'react';
import {
  StyleSheet, TouchableOpacity,
} from 'react-native';

import type {
  CollectionType,
} from 'app/entities';
import { useThemeColor } from 'hooks';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import CollectionSingleCover from 'components/CollectionSingleCover';
import Icon from 'components/LegacyIcon';

const defaultProps = {
  isLast: false as boolean,
};

type ItemProps = {
  id: number,
  hasMaterial?: boolean,
  data: CollectionType,
  isLast?: boolean,
  onBodyPress?: (id: number) => void,
} & typeof defaultProps;

const Item = (props: ItemProps): ReactElement => {
  const {
    id,
    hasMaterial,
    data,
    isLast,
    onBodyPress,
  } = props;

  const borderBottomColor = useThemeColor({ light: '#d2d2d2', dark: '#303030' });

  const handleBodyPress = useCallback(() => {
    onBodyPress?.(id);
  }, [id, onBodyPress]);

  return (
    <View
      style={[
        styles.Item,
        { borderBottomColor },
        isLast && styles.lastItem,
      ]}
    >
      <TouchableOpacity
        style={[styles.body, hasMaterial && { opacity: 0.3 }]}
        onPress={onBodyPress ? handleBodyPress : undefined}
        activeOpacity={onBodyPress ? 0.8 : 1}
        disabled={hasMaterial}
      >
        <CollectionSingleCover data={data} iconSize={50} heightPicture={50} widthPicture={50} />
        <View style={styles.content}>
          <Text size={17} weight="medium" lightColor="#000000" darkColor="#ffffff">{data.title}</Text>
          <View style={styles.info}>
            {data.description && (
              <Text lightColor="#515151" darkColor="#E6E4EA" size={13}>{data.description}</Text>
            )}
          </View>
        </View>
      </TouchableOpacity>
      {hasMaterial && (
        <Icon style={styles.iconDone} name="Done" size={28} />
      )}
    </View>
  );
};

Item.defaultProps = defaultProps;

const styles = StyleSheet.create({
  Item: {
    flexDirection: 'row',
    paddingTop: unit(16),
    paddingBottom: unit(16),
    borderBottomWidth: 1,
  },
  body: {
    flexDirection: 'row',
    flex: 1,
  },
  iconDone: {
    opacity: 0.3,
    alignSelf: 'center',
    paddingRight: unit(16),
  },
  lastItem: {
    borderBottomWidth: 0,
    paddingBottom: 0,
  },
  image: {
    width: unit(50),
    height: unit(50),
    borderRadius: unit(5),
    // backgroundColor: '#232326',
    backgroundColor: '#7d563e',
  },
  content: {
    flex: 1,
    marginHorizontal: unit(10),
    justifyContent: 'center',
  },
  info: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginLeft: unit(6),
    width: unit(44),
    height: unit(44),
    borderRadius: unit(22),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#232326',
  },
});

export default memo(Item);
