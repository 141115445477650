import moment from 'moment-timezone';

import type { ResultLegacy, CommentType, CommentMessageType } from 'app/entities';
import request from 'services/api/request';

export default async (id: number, message: Omit<CommentMessageType, 'replyToId'>): Promise<ResultLegacy<CommentType>> => {
  const body: Record<string, any> = {
    text: message.text,
  };
  if (message.rate) {
    body.rate = message.rate;
  }

  const { data, error } = await request.put<CommentType>(`/comment/${id}`, { body });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: {
      ...data,
      createdAt: data.createdAt ? moment.utc(data.createdAt).toISOString() : null,
      updatedAt: data.updatedAt ? moment.utc(data.updatedAt).toISOString() : null,
      deletedAt: data.deletedAt ? moment.utc(data.deletedAt).toISOString() : null,
    },
    error: null,
  };
};
