import { memo } from 'react';

import NoteController from 'components/Card/controllers/NoteController';
import MaterialCardController from './controllers/MaterialController';
import CollectionCardController from './controllers/CollectionController';
import WireframeCard from './variants/WireframeCard';

import type { CardProps } from './types';

const defaultProps = {
  type: 'material' as 'material' | 'collection' | 'note' | 'wireframe' | 'RagMaterial',
};

/**
 * @param props
 * @constructor
 * @deprecated
 */
const Card = (props: CardProps & typeof defaultProps) => {
  const {
    style,
    radius,
    id,
    extraData,
    type,
    materialDefaultLayout,
    materialForceLayout,
    collectionDefaultLayout,
    collectionForceLayout,
    hasMarkByIKI,
    hasToolbar,
    hasAuthor,
    hasDescription,
    hasInfo,
    hasTags,
    hasComments,
    hasWebLink,
    hasFooterQueue,
    hasMenuQueue,
    hasMenuComplete,
    hasMenuToCollection,
    hasMenuShare,
    hasMenuDislike,
    hasMenuPlaySummary,
    hasMenuTLDR,
    hasMenuShowRelated,
    hasMenuRemove,
    descriptionLimit,
    tagsLimit,
    authorSize,
    titleSize,
    descriptionSize,
    AuthorComponent,
    ExtraComponent,
    OverlayComponent,
    onLayout,
    onOptionsPress,
  } = props;

  if (type === 'wireframe') {
    return (
      <WireframeCard
        style={style}
        radius={radius}
      />
    );
  }

  if (type === 'note') {
    return (
      <NoteController
        id={id}
        style={style}
        radius={radius}
        hasMenuRemove={hasMenuRemove}
        onLayout={onLayout}
        onOptionsPress={onOptionsPress}
      />
    );
  }

  if (type === 'material' || type === 'RagMaterial') {
    return (
      <MaterialCardController
        style={style}
        type={type}
        radius={radius}
        id={id}
        extraData={extraData}
        defaultLayout={materialDefaultLayout}
        forceLayout={materialForceLayout}
        hasMarkByIKI={hasMarkByIKI}
        hasToolbar={hasToolbar}
        hasAuthor={hasAuthor}
        hasDescription={hasDescription}
        hasInfo={hasInfo}
        hasTags={hasTags}
        hasComments={hasComments}
        hasWebLink={hasWebLink}
        hasFooterQueue={hasFooterQueue}
        hasMenuQueue={hasMenuQueue}
        hasMenuComplete={hasMenuComplete}
        hasMenuToCollection={hasMenuToCollection}
        hasMenuShare={hasMenuShare}
        hasMenuDislike={hasMenuDislike}
        hasMenuPlaySummary={hasMenuPlaySummary}
        hasMenuTLDR={hasMenuTLDR}
        hasMenuShowRelated={hasMenuShowRelated}
        hasMenuRemove={hasMenuRemove}
        descriptionLimit={descriptionLimit}
        tagsLimit={tagsLimit}
        authorSize={authorSize}
        titleSize={titleSize}
        descriptionSize={descriptionSize}
        AuthorComponent={AuthorComponent}
        ExtraComponent={ExtraComponent}
        OverlayComponent={OverlayComponent}
        onLayout={onLayout}
        onOptionsPress={onOptionsPress}
      />
    );
  }

  if (type === 'collection') {
    return (
      <CollectionCardController
        style={style}
        radius={radius}
        id={id}
        extraData={extraData}
        defaultLayout={collectionDefaultLayout}
        forceLayout={collectionForceLayout}
        hasMarkByIKI={hasMarkByIKI}
        hasToolbar={hasToolbar}
        hasAuthor={hasAuthor}
        hasDescription={hasDescription}
        hasInfo={hasInfo}
        hasTags={hasTags}
        hasComments={hasComments}
        hasWebLink={hasWebLink}
        hasFooterQueue={hasFooterQueue}
        hasMenuQueue={hasMenuQueue}
        hasMenuComplete={hasMenuComplete}
        hasMenuShare={hasMenuShare}
        hasMenuDislike={hasMenuDislike}
        hasMenuPlaySummary={hasMenuPlaySummary}
        hasMenuTLDR={hasMenuTLDR}
        hasMenuShowRelated={hasMenuShowRelated}
        hasMenuRemove={hasMenuRemove}
        descriptionLimit={descriptionLimit}
        tagsLimit={tagsLimit}
        authorSize={authorSize}
        titleSize={titleSize}
        descriptionSize={descriptionSize}
        AuthorComponent={AuthorComponent}
        ExtraComponent={ExtraComponent}
        OverlayComponent={OverlayComponent}
        onLayout={onLayout}
        onOptionsPress={onOptionsPress}
      />
    );
  }

  return null;
};

Card.defaultProps = defaultProps;

export default memo(Card);
