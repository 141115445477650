import { memo } from 'react';
import useHTMLLoader from 'screens/Content/Page/hooks/useHTMLLoader';
import { View } from 'components/Themed';
import Lottie from 'components/Lottie';
import { StyleSheet } from 'react-native';
import { unit } from 'utils';
import HtmlReader from 'components/HtmlReader';
import { useThemeColor } from 'hooks';
import { Box } from '@mui/joy';

type HtmlViewerProps = {
  content?: string,
}

const HtmlViewer = (props: HtmlViewerProps) => {
  const { content } = props;

  const isUrl = /^https?:\/\/[^\s]*/.test(content || '');

  const { html: loadedHtml, isLoading } = useHTMLLoader(isUrl ? content : undefined);

  const lottieColor = useThemeColor({ light: '#497CFF', dark: '#497CFF' });

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Lottie name="LoadingCircle" loop width={160} height={160} color={lottieColor} />
      </Box>
    );
  }

  const html = !isUrl ? content : loadedHtml;

  return (
    <HtmlReader html={html || 'No content'} />
  );
};

export default memo(HtmlViewer);
