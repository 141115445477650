import request from 'services/api/request';
import type { SuccessResult } from 'app/entities';

export default async (
  resourceId: number,
): Promise<SuccessResult> => {
  const { error } = await request.delete(`/content/${resourceId}/notes`);
  if (error) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: { success: true },
    error,
  };
};
