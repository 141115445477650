import { memo, useCallback, useMemo } from 'react';
import {
  type StyleProp, type ViewStyle, type GestureResponderEvent,
  StyleSheet, TouchableOpacity,
} from 'react-native';
import { MacScrollbar } from 'mac-scrollbar';

import { useNavigate } from 'navigation/hooks';
import { styleSheetToCss, unit } from 'utils';
import { useColorScheme, useScreenSize } from 'hooks';

import * as userStore from 'store/nodes/user';
import { useSelector } from 'store/utils/redux/hooks';

import { controller as modal } from 'components/Modal2';
import { Text, View } from 'components/Themed';
import Icon from 'components/LegacyIcon';
import TagsOld from 'components/TagsOld';
import Button from 'components/Button';
import ListLayoutSwitcher from 'components/ListLayoutSwitcher';

type TopBarProps = {
  style?: StyleProp<ViewStyle>,
}

const TopBar = (props: TopBarProps) => {
  const {
    style,
  } = props;

  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const colorScheme = useColorScheme();

  const topics = useSelector(userStore.selectors.topics);

  const handleTopicPress = useCallback((event: GestureResponderEvent, context: { tag: string }) => {
    if (!context.tag) {
      return;
    }
    navigate('Ask', { tags: [context.tag] });
  }, []);

  const handleAddTopic = useCallback(() => {
    modal.popup.skills.open();
  }, []);

  const handlePlus = useCallback(() => {
    modal.popup.skills.open();
  }, []);

  const topicsRender = useMemo(() => {
    const selectText = screenSize.is499 ? 'topics to personalize feed' : 'the topics of your interest to personalize your knowledge feed';

    if (topics?.length === 0) {
      return (
        <View style={styles.topicsEmpty}>
          <TouchableOpacity onPress={handleAddTopic}>
            <Text darkColor="#497CFF" lightColor="#497CFF" weight="semi" size={13}>Select</Text>
          </TouchableOpacity>
          <Text size={13} weight="medium">
            {' '}
            {selectText}
          </Text>
        </View>

      );
    }

    return (
      <TagsOld
        items={topics || []}
        style={styles.tags}
        size="normal"
        variant="contained"
        textSize={13}
        radius={13}
        lightColor="#d2d2d2"
        darkColor="#232326"
        lightTextColor="#000000"
        darkTextColor="#ffffff"
        onPress={handleTopicPress}
        EndComponent={(
          <Button
            type="button-icon"
            variant="contained"
            lightColor="#d2d2d2"
            darkColor="#232326"
            radius="full"
            width={28}
            height={28}
            onPress={handlePlus}
          >
            <Icon name="AddOutline28" size={20} />
          </Button>
        )}
      />
    );
  }, [topics, handleTopicPress, handlePlus, screenSize.is499]);

  return (
    <View style={[styles.wrapper, screenSize.is767 && styles.mobile, style]}>
      {!screenSize.is767 && (
        <View style={styles.titleBig}>
          <Icon name="MenuStaffOutline" size={28} style={styles.icon} />
          <Text size={22} weight="semi" darkColor="#ffffff" lightColor="#000000">New stuff</Text>
          <Text size={22} weight="medium" darkColor="#606060" lightColor="#606060"> based on your selection</Text>
        </View>
      )}
      {screenSize.is767 && (
        <View style={styles.titleSmall}>
          <Text size={22} weight="semi" darkColor="#ffffff" lightColor="#000000">New stuff</Text>
          <Text size={20} weight="medium" darkColor="#606060" lightColor="#606060">based on your selection</Text>
        </View>
      )}
      <View style={styles.bar}>
        {screenSize.is767 && (
          <MacScrollbar
            skin={colorScheme}
            style={styleSheetToCss(styles.scroll)}
          >
            {topicsRender}
          </MacScrollbar>
        )}
        {!screenSize.is767 && (
          <>
            {topicsRender}
            <ListLayoutSwitcher stateKey="home.list.mode" style={{ marginTop: 0, marginRight: unit(-4) }} />
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginTop: unit(16),
    marginBottom: unit(20),
  },
  empty: {
    height: 0,
    marginTop: unit(40),
  },
  mobile: {
    marginTop: unit(48),
  },
  icon: {
    marginRight: unit(4),
    marginLeft: unit(-3),
  },
  titleBig: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: unit(10),
  },
  titleSmall: {
    marginBottom: unit(10),
  },
  bar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tags: {
    flex: 1,
  },
  topicsEmpty: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topicSelectButton: {
    padding: 0,
  },
  scroll: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export default memo(TopBar);
