import { createReducer } from '@reduxjs/toolkit';

import type { CommonStore } from './types';

const initialState: CommonStore = {
  processButtonName: '',
};

export default createReducer(initialState, (builder) => builder
  .addDefaultCase((state, action) => {
    if (typeof action?.meta?.processButtonName === 'string') {
      state.processButtonName = action?.meta?.processButtonName;
    }
  }));
