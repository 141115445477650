import {
  takeEvery, put, cancel,
  type SagaReturnType,
} from 'redux-saga/effects';
import moment from 'moment-timezone';

import Alert from 'components/Alert';
import { controller as modal } from 'components/Modal2';

import * as api from 'services/api';

import { select, call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as contentStore from 'store/nodes/content';
import * as playlistStore from 'store/nodes/playlist';
import * as interactionStore from 'store/nodes/interaction';
import * as libraryStore from 'store/nodes/library';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.newSend.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.newSend>) {
  const {
    resource,
    resourceId,
    message,
    tempId,
  } = action.payload;
  const { text, rate } = message;
  const isReview = !!rate;

  const userProfile = yield* select(userStore.selectors.getMy);
  const reply = yield* select(selectors.reply);

  yield put(actions.newOptimistic(resource, resourceId, tempId, {
    id: tempId,
    text,
    replyToId: reply?.id,
    user: {
      id: userProfile?.id || 0,
      name: userProfile?.name || '',
      photo: userProfile?.photo,
    },
    createdAt: moment().toISOString(),
    updatedAt: moment().toISOString(),
  }));
  if (resource === 'content') {
    const contentOptimistic = yield* select(contentStore.selectors.dataById(resourceId, { mode: 'add' }));
    yield put(contentStore.actions.setItem(contentOptimistic));
  }
  if (resource === 'playlist') {
    const contentOptimistic = yield* select(playlistStore.selectors.dataById(resourceId, { mode: 'add' }));
    yield put(playlistStore.actions.setItem({ data: contentOptimistic }));
  }

  const response = yield* call(() => api.resource.comment.post(resource, resourceId, message));
  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error 11');
    yield put(actions.newRollback(resource, resourceId, tempId));
    yield put(actions.newSendDone());
    if (resource === 'content') {
      const contentRollback = yield* select(contentStore.selectors.dataById(resourceId, { mode: 'sub' }));
      yield put(contentStore.actions.setItem(contentRollback));
    }
    if (resource === 'playlist') {
      const contentRollback = yield* select(playlistStore.selectors.dataById(resourceId, { mode: 'sub' }));
      yield put(playlistStore.actions.setItem({ data: contentRollback }));
    }
    yield cancel(); return;
  }

  if (isReview) {
    yield put(interactionStore.actions.setValues(resource, resourceId, 'complete', {
      isCompleted: true,
    }));
    yield put(actions.loadSummaryById(resource, resourceId));
    yield* call(() => modal.info.materialCompleted.open());
    yield* call(() => modal.popup.review.delay(400).close());
    yield* call(() => modal.info.materialCompleted.delay(3400).close());
  }

  yield put(actions.newCommit(resource, resourceId, tempId, response.data));
  yield put(actions.newSendDone());

  if (isReview) {
    // yield put(libraryStore.actions.loadCollections());
  }
}
