import { createReducer } from '@reduxjs/toolkit';

import type { ProfileStore } from './types';
import * as actions from './actions';

const initialState: ProfileStore = {
  meta: {
    uuid: null,
    isConsistent: false,
    isLoading: false,
    error: null,
    update: {
      isLoading: false,
      result: {
        success: null,
        error: null,
      },
    },
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.updateData, (state, action) => {
    state.meta.update.isLoading = true;
    state.meta.update.result.success = null;
    state.meta.update.result.error = null;
  })
  .addCase(actions.updateDataDone, (state, action) => {
    state.meta.update.isLoading = false;
    state.meta.update.result.success = !action.payload?.error;
    state.meta.update.result.error = action.payload?.error ? action.payload.error.message : null;
  }));
