import connect from '../../utils/saga/connect';
import * as send from './sagas/send';
import * as copilotAnswer from './sagas/copilotAnswer';
import * as reloadAnswer from './sagas/reloadAnswer';
import * as saveAnswerToNote from './sagas/saveAnswerToNote';
import * as clear from './sagas/clear';

export default connect([
  send,
  copilotAnswer,
  reloadAnswer,
  saveAnswerToNote,
  clear,
]);
