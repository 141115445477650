import {
  type SagaReturnType,
  put, fork, takeEvery, take, delay,
} from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';

import Socket from 'lib/Socket';

import { call } from 'store/utils/saga/effects';
import * as appStore from 'store/nodes/app';
import * as authStore from 'store/nodes/auth';

import log from '../model/log';

import * as actions from '../actions';

const socket = new Socket();

const OPENED = 'OPENED';
const PING = 'PING';

const isOpened = (message: typeof OPENED | any): message is typeof OPENED => (
  typeof message === 'string' && message === 'OPENED'
);
const isPing = (message: typeof PING | any): message is typeof PING => (
  typeof message === 'string' && message === 'PING'
);

function createWebSocketChannel(ws: Socket) {
  return eventChannel((emit) => {
    const closeListener = ws.addListener('close', () => {
      emit(END);
    });
    const disconnectListener = ws.addListener('disconnect', () => {
      emit(END);
    });
    const openListener = ws.addListener('open', () => {
      emit(OPENED);
    });
    const pingListener = ws.addListener('ping', () => {
      emit(PING);
    });
    const messageListener = ws.addListener('message', (event: MessageEvent) => {
      emit(event.data);
    });
    return () => {
      closeListener.remove();
      disconnectListener.remove();
      openListener.remove();
      pingListener.remove();
      messageListener.remove();
    };
  });
}

let autoIncrement = 0;
function* initSocketConnection() {
  socket.open({ pingInMs: 15000 /* 15sec */ });

  autoIncrement += 1;
  const socketChannel = yield* call(() => createWebSocketChannel(socket));
  const channelKey = autoIncrement;
  log(`${channelKey}:: init channel`);
  try {
    while (true) {
      const event: string = yield take(socketChannel);
      if (isOpened(event)) {
        log(`${channelKey}:: message OPENED`);
        yield put(actions.opened());
        continue;
      }
      if (isPing(event)) {
        log(`${channelKey}:: message PING`);
        yield put(actions.sendPing({ message: 'ping' }));
        continue;
      }
      try {
        const { type, data: payload } = JSON.parse(event);
        log(`${channelKey}:: message ${type.toUpperCase()}`);
        const action = actions.map[`socket/${type}` as unknown as keyof typeof actions.map];
        if (action && typeof action === 'function') {
          yield put((action as CallableFunction)(payload));
        } else if (action && action in actions && typeof actions[action] === 'function') {
          yield put((actions[action] as CallableFunction)(payload));
        } else {
          yield put({ type: `socket/${type}`, payload });
        }
      } catch (error) {
        console.error((error as Error).message);
      }
    }
  } finally {
    log(`${channelKey}:: message CLOSED`);
    yield put(actions.closed());
    log(`${channelKey}:: channel closed`);
  }
}

export const config = {
  action: [
    actions.reconnect.type,
    appStore.actions.prepared.type,
    authStore.actions.doSignInDone.type,
    authStore.actions.logOut.type,
  ],
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<
    | typeof actions.reconnect
    | typeof appStore.actions.prepared
    | typeof authStore.actions.doSignInDone
    | typeof authStore.actions.logOut
  >,
) {
  log('request to close task');
  socket.close();
  yield delay(200);
  yield fork(initSocketConnection);
  log('task open');
}
