import request from 'services/api/request';
import type { SuccessResult } from 'app/entities';

export default async (): Promise<SuccessResult> => {
  const { error } = await request.delete<never>('/user/profile');
  return {
    data: { success: !error },
    error,
  };
};
