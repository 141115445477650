import {
  memo, useMemo,
} from 'react';

import type { Resource } from 'store/nodes/copilot/types';

import DefaultLayout from './layouts/DefaultLayout';
import type { ResourceType } from './types';

type BaseProps = {
  windowScroll?: boolean,
}

type MaterialProps = {
  variant: 'material',
  resourceId: number,
} & BaseProps;

type CollectionProps = {
  variant: 'collection',
  resourceId: number,
} & BaseProps;

type LibraryProps = {
  variant: 'library',
} & BaseProps;

type WebProps = {
  variant: 'web',
} & BaseProps;

type GlobalFulltextProps = {
  variant: 'global-fulltext',
} & BaseProps;

type LibraryFulltextProps = {
  variant: 'library-fulltext',
} & BaseProps

const Copilot = (props: MaterialProps | CollectionProps | LibraryProps | WebProps | GlobalFulltextProps | LibraryFulltextProps) => {
  const { variant, windowScroll } = props;

  const resourceId = useMemo(() => {
    if (variant === 'material' || variant === 'collection') {
      return props.resourceId;
    }
    return 0;
  }, [variant, props]);

  const includeItemsForResource = useMemo(() => {
    const map: Record<ResourceType, Resource[]> = {
      'material': [],
      'collection': [],
      'library': [],
      'web': [{ type: 'global-fulltext', id: null }, { type: 'library-fulltext', id: null }],
      'global-fulltext': [{ type: 'web', id: null }, { type: 'library-fulltext', id: null }],
      'library-fulltext': [{ type: 'web', id: null }, { type: 'global-fulltext', id: null }],
    } as const;
    return map[variant];
  }, [variant]);

  return (
    <DefaultLayout
      resourceType={variant}
      resourceId={resourceId}
      includeItemsForResource={includeItemsForResource}
      windowScroll={windowScroll}
    />
  );
};

export default memo(Copilot);
