import {
  memo, useCallback, useEffect, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet, TouchableOpacity, useWindowDimensions,
} from 'react-native';
import { type DefaultRootState } from 'react-redux';
import { MacScrollbar } from 'mac-scrollbar';

import { useColorScheme, useResponsive, useThemeColor } from 'hooks';
import { styleSheetToCss, unit } from 'utils';

import * as searchModalStore from 'store/nodes/searchModal';
import * as contentStore from 'store/nodes/content';

import FeedList from 'components/FeedList';
import { Text, View } from 'components/Themed';
import { TextInput } from 'components/Form';
import { useSelector, useDispatcher } from 'store/utils/redux/hooks';
import ButtonSwitcher from 'components/ButtonSwitcher';
import SlimContentItem from 'components/SlimContentItem';
import Icon from 'components/LegacyIcon';
import ConnectInput from 'components/ConnectInput';

import Header from './elements/Header';

const defaultProps = {
};

export type SearchProps = {
  hasSelector?: boolean,
  isSelectedItemSelector?: (id: number) => (state: DefaultRootState) => boolean,
  onPress?: (resourceId: number, resourceType: string) => void,
  onSelectPress?: (id: number) => void,
  onDeselectPress?: (id: number) => void,
} & typeof defaultProps;

const Search = (props: SearchProps) => {
  const {
    hasSelector,
    isSelectedItemSelector,
    onPress,
    onSelectPress,
    onDeselectPress,
  } = props;

  const dispatcher = useDispatcher();
  const responsive = useResponsive();
  const dimensions = useWindowDimensions();
  const colorScheme = useColorScheme();

  const list = useSelector(searchModalStore.selectors.list);
  const listMeta = useSelector(searchModalStore.selectors.listMeta);

  const addBackground = useThemeColor({ light: '#d2d2d2', dark: '#232326' });
  const removeBackground = useThemeColor({ light: '#db3327', dark: '#db3327' });

  useEffect(() => {
    dispatcher.searchModal.clear();
    return () => {
      dispatcher.searchModal.clear();
    };
  }, []);

  const handleNextPage = useCallback(() => {
    if (!listMeta.firstPage.isLoading && !listMeta.nextPage.isLoading) {
      dispatcher.searchModal.loadNextPage();
    }
  }, [listMeta]);

  const sequenceFinal = useMemo(() => {
    const wireframes = [
      { id: 'w-1', type: 'wireframe' },
      { id: 'w-2', type: 'wireframe' },
      { id: 'w-3', type: 'wireframe' },
      { id: 'w-4', type: 'wireframe' },
      { id: 'w-5', type: 'wireframe' },
      { id: 'w-6', type: 'wireframe' },
    ];
    if (listMeta.firstPage.isLoading) {
      return wireframes;
    }
    if (listMeta.nextPage.isLoading) {
      return [...list.sequence, ...wireframes];
    }
    return list.sequence;
  }, [list.sequence, listMeta.firstPage.isLoading, listMeta.nextPage.isLoading]);

  const componentStyles = useMemo(() => {
    let result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.Search),
      height: unit(dimensions.height - 80),
    };
    if (responsive.isMoreThen.mobile) {
      result = {
        ...result,
        ...StyleSheet.flatten(styles.desktop),
      };
    }
    return result;
  }, [responsive.isMoreThen.mobile, dimensions.height]);

  const renderItem = useCallback((args: { id: number }) => (
    <SlimContentItem
      id={args.id}
      selectorData={contentStore.selectors.dataById}
      selectorState={isSelectedItemSelector}
      onPress={onPress}
      ComponentTrue={!hasSelector ? undefined : (
        <TouchableOpacity
          style={[styles.button, { backgroundColor: removeBackground }]}
          onPress={() => { onDeselectPress?.(args.id); }}
          activeOpacity={0.8}
        >
          <Icon name="Minus24" size={24} />
        </TouchableOpacity>
      )}
      ComponentFalse={!hasSelector ? undefined : (
        <TouchableOpacity
          style={[styles.button, { backgroundColor: addBackground }]}
          onPress={() => { onSelectPress?.(args.id); }}
          activeOpacity={0.8}
        >
          <Icon name="Add" size={24} />
        </TouchableOpacity>
      )}
    />
  ), [onDeselectPress, onSelectPress, isSelectedItemSelector, addBackground, removeBackground, hasSelector]);

  return (
    <View
      style={componentStyles}
      lightColor="#e7e7e7"
      darkColor="#181818"
      pointerEvents="auto"
    >
      <View style={styles.toolbar}>
        <View style={styles.toolbarSearch}>
          <ConnectInput
            valueGetter="searchModal.filter.text"
            valueSetter="searchModal.setFilterText"
          >
            <TextInput style={styles.input} />
          </ConnectInput>
        </View>
        <MacScrollbar
          skin={colorScheme}
          style={styleSheetToCss(styles.toolbarTypes)}
        >
          <ConnectInput
            itemsGetter="searchModal.filter.type.options"
            valueGetter="searchModal.filter.type.value"
            valueSetter="searchModal.setFilterType"
          >
            <ButtonSwitcher variant="button" />
          </ConnectInput>
        </MacScrollbar>
      </View>
      {!listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && (
        <View style={[styles.panelInfo]}>
          <Text lightColor="#000000" darkColor="#ffffff" size={17}>Start typing</Text>
          <Text lightColor="#797979" darkColor="#B8B6BF" size={15}>to search</Text>
        </View>
      )}
      {listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && (
        <View style={[styles.panelInfo]}>
          <Text lightColor="#000000" darkColor="#ffffff" size={17}>No results</Text>
          <Text lightColor="#797979" darkColor="#B8B6BF" size={15}>Try to type something else</Text>
        </View>
      )}
      {(listMeta.firstPage.isLoading || listMeta.nextPage.isLoading || list.sequence.length > 0) && (
        <FeedList
          scrollable
          data={sequenceFinal}
          hasNextPage={list.paginationInfo.hasNext}
          onEndReached={handleNextPage}
          HeaderComponent={<Header />}
          ItemComponent={renderItem}
          paddingHorizontal={16}
          style={styles.panelResult}
        />
      )}
    </View>
  );
};

Search.defaultProps = defaultProps;

const styles = StyleSheet.create({
  Search: {
    width: '100%',
    maxWidth: unit(560),
    alignSelf: 'center',
    paddingHorizontal: unit(16),
    paddingTop: unit(18),
    paddingBottom: unit(32),
    borderTopLeftRadius: unit(40),
    borderTopRightRadius: unit(40),
  },
  desktop: {
    borderRadius: unit(40),
    marginTop: unit(40),
    marginBottom: unit(40),
  },
  toolbar: {
  },
  toolbarSearch: {
    flexDirection: 'row',
    marginBottom: unit(16),
  },
  input: {
    flex: 1,
  },
  toolbarTypes: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: unit(16),
  },
  toolbarTags: {
    marginTop: unit(-8),
    marginBottom: unit(16),
  },
  panelResult: {
    marginLeft: unit(-16),
    marginRight: unit(-16),
    flex: 1,
  },
  panelInfo: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  button: {
    marginLeft: unit(6),
    width: unit(44),
    height: unit(44),
    borderRadius: unit(22),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default memo(Search);
