import { memo } from 'react';
import { StyleSheet } from 'react-native';

import { View } from 'components/Themed';
import { unit } from 'utils';

type RegularLayoutProps = {
  radius?: number,
};

const RegularLayout = (props: RegularLayoutProps) => {
  const { radius } = props;
  return (
    <View style={[styles.Wireframe, !!radius && { borderRadius: radius }]} lightColor="#ffffff50" darkColor="#ffffff20" />
  );
};

const styles = StyleSheet.create({
  Wireframe: {
    width: '100%',
    aspectRatio: 5 / 6,
    borderRadius: unit(6),
  },
});

export default memo(RegularLayout);
