import {
  type SagaReturnType, takeEvery, put, take, cancel,
} from 'redux-saga/effects';

import * as socketStore from 'store/nodes/socket';
import * as collectionStore from 'store/nodes/playlist';
import * as userStore from 'store/nodes/user';
import { call, select } from 'store/utils/saga/effects';

import { getRoute, navigate } from 'navigation/methods';

import Alert from 'components/Alert';

export const config = {
  action: [
    socketStore.actions.collectionAccessProvided.type,
    socketStore.actions.collectionAccessChanged.type,
    socketStore.actions.collectionAccessRemoved.type,
  ],
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<
    | typeof socketStore.actions.collectionAccessProvided
    | typeof socketStore.actions.collectionAccessChanged
    | typeof socketStore.actions.collectionAccessRemoved
  >,
) {
  const { payload } = action;
  const { playlist_id: id } = payload;

  if (!id) {
    yield cancel(); return;
  }

  if (action.type === socketStore.actions.collectionAccessProvided.type) {
    yield put(collectionStore.actions.loadById({ id }));
    yield take(collectionStore.actions.loadByIdDone.type);
    yield put(userStore.actions.addedCollectionPermission(id));
    const collection = yield* select(collectionStore.selectors.dataById(id));
    if (collection) {
      Alert.info('CollectionProvided', {
        closeOnClick: true,
        autoClose: 10000,
        data: {
          resourceId: collection.id,
        },
      });
    }
    yield cancel(); return;
  }

  if (action.type === socketStore.actions.collectionAccessChanged.type) {
    const collection = yield* select(collectionStore.selectors.dataById(id));
    if (!collection) {
      yield cancel(); return;
    }
    Alert.info(`Your permission in "${collection.title}" collection has been changed`);
    yield put(collectionStore.actions.loadById({ id }));
    yield take(collectionStore.actions.loadByIdDone.type);
    yield cancel(); return;
  }

  if (action.type === socketStore.actions.collectionAccessRemoved.type) {
    const route = yield* call(() => getRoute<'Playlist'>());
    const collection = yield* select(collectionStore.selectors.dataById(id));
    if (!collection) {
      yield cancel(); return;
    }
    Alert.info(`Your access in "${collection.title}" collection has been deleted`);
    if (route.isScreen('Playlist') && Number(route.params.resourceId) === Number(id)) {
      navigate('Ask');
    }
    yield put(userStore.actions.removedCollectionPermission(id));
    yield cancel();
  }
}
