import { useStore } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import * as modal from 'widgets/modals/store';
import * as subscription from 'widgets/Subscription/store';

import * as common from './nodes/common';
import * as value from './nodes/value';
import * as app from './nodes/app';
import * as menu from './nodes/menu';
import * as message from './nodes/message';
import * as navigation from './nodes/navigation';
import * as auth from './nodes/auth';
import * as activity from './nodes/activity';
import * as profile from './nodes/profile';
import * as user from './nodes/user';
import * as author from './nodes/author';
import * as content from './nodes/content';
import * as myContent from './nodes/myContent';
import * as comment from './nodes/comment';
import * as interaction from './nodes/interaction';
import * as search from './nodes/search';
import * as searchModal from './nodes/searchModal';
import * as searchUser from './nodes/searchUser';
import * as playlist from './nodes/playlist';
import * as community from './nodes/community';
import * as library from './nodes/library';
import * as news from './nodes/news';
import * as smartNote from './nodes/smartNote';
import * as relation from './nodes/relation';
import * as ask from './nodes/ask';
import * as plus from './nodes/plus';
import * as socket from './nodes/socket';
import * as note from './nodes/note';
import * as copilot from './nodes/copilot';

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  function* sagas() {
    yield all([
      app.sagas(),
      menu.sagas(),
      message.sagas(),
      navigation.sagas(),
      auth.sagas(),
      activity.sagas(),
      profile.sagas(),
      user.sagas(),
      author.sagas(),
      content.sagas(),
      myContent.sagas(),
      comment.sagas(),
      interaction.sagas(),
      search.sagas(),
      searchModal.sagas(),
      searchUser.sagas(),
      playlist.sagas(),
      community.sagas(),
      library.sagas(),
      news.sagas(),
      note.sagas(),
      smartNote.sagas(),
      relation.sagas(),
      plus.sagas(),
      socket.sagas(),
      copilot.sagas(),
      subscription.sagas(),
      ask.sagas(),
    ]);
  }

  const store = ({
    ...configureStore({
      reducer: {
        value: value.reducer,
        app: app.reducer,
        menu: menu.reducer,
        message: message.reducer,
        auth: auth.reducer,
        activity: activity.reducer,
        common: common.reducer,
        profile: profile.reducer,
        user: user.reducer,
        author: author.reducer,
        content: content.reducer,
        myContent: myContent.reducer,
        comment: comment.reducer,
        search: search.reducer,
        searchModal: searchModal.reducer,
        searchUser: searchUser.reducer,
        interaction: interaction.reducer,
        playlist: playlist.reducer,
        community: community.reducer,
        library: library.reducer,
        news: news.reducer,
        smartNote: smartNote.reducer,
        relation: relation.reducer,
        // screenCollection: screenCollection.reducer,
        plus: plus.reducer,
        modal: modal.reducer,
        note: note.reducer,
        copilot: copilot.reducer,
        subscription: subscription.reducer,
        ask: ask.reducer,
      },
      middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
      devTools: process.env.NODE_ENV === 'development',
    }),
    saga: {
      run: sagaMiddleware.run,
    },
  });

  store.saga.run(sagas);
  store.dispatch({ type: 'STORE/READY' });

  return store;
};

const store = createStore();

export {
  useStore,
  store,
};
