import React, {
  type ReactNode, memo, useLayoutEffect, useRef, useMemo, useState, useCallback, useEffect,
} from 'react';
import { Box, type BoxProps, useTheme } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as copilotStore from 'store/nodes/copilot';

import { useScreenSize } from 'hooks';
import ButtonSwitcher from 'components/ButtonSwitcher';
import { unit } from 'utils';
import { useRoute } from 'navigation/hooks';

type PanelsBarProps = {
  contentMeta: {
    label: string,
    value: string,
  },
  content: ReactNode,
  panels: {
    label: string,
    value: string,
    component: ReactNode
  }[] | false,
} & BoxProps;

const PanelsBar = (props: PanelsBarProps) => {
  const {
    contentMeta,
    content,
    panels,
    ...rest
  } = props;
  const dispatcher = useDispatcher();
  const isShow = useSelector(copilotStore.selectors.rightBarIsShow);
  const copilotPanelWidth = useSelector(copilotStore.selectors.rightBarWidth);
  const [activePanelValue, setActivePanelValue] = useState<string | null>(null);

  const route = useRoute();

  const theme = useTheme();
  const screenSize = useScreenSize();

  const widgetRef = useRef<HTMLDivElement | null>(null);
  const copilotPanelRef = useRef<HTMLDivElement | null>(null);
  const floatingRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const widgetElement = widgetRef.current;
    const copilotPanelElement = copilotPanelRef.current;
    const floatingElement = floatingRef.current;
    if (!widgetElement || !copilotPanelElement || !floatingElement) {
      return undefined;
    }

    const handleResize = () => {
      const rect = copilotPanelElement.getBoundingClientRect();
      floatingElement.style.width = `${rect.width}px`;
      floatingElement.style.left = `${rect.left}px`;
      floatingElement.style.height = `${window.innerHeight - 80}px`;
      const newWidth = Math.ceil(widgetElement.clientWidth * 0.45);
      dispatcher.copilot.setWidth(newWidth);
    };

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserver.observe(copilotPanelElement);
    window.addEventListener('resize', handleResize);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const panelItems = useMemo(() => {
    if (panels === false) {
      return [];
    }
    const items = panels.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    if (screenSize.is767) {
      items.unshift(contentMeta);
    }
    return items;
  }, [panels, contentMeta, screenSize]);

  const handlePanelChange = useCallback((panelValue: string) => {
    setActivePanelValue(panelValue);
  }, []);

  useEffect(() => {
    if (panels === false) {
      return;
    }
    setActivePanelValue(panelItems?.[0].value);
  }, [panelItems]);

  const panelsRender = useMemo(() => {
    if (activePanelValue === contentMeta.value) {
      return (
        <Box mx={2} pt={2}>{content}</Box>
      );
    }
    if (panels === false) {
      return null;
    }
    return panels.find((panel) => panel.value === activePanelValue)?.component || null;
  }, [activePanelValue, contentMeta, content, panels]);

  return (
    <Box display="flex" ref={widgetRef} {...rest}>
      {!screenSize.is767 && (
        <Box
          width={panelsRender ? 'calc(100% - 600px)' : undefined}
          mr={(panelsRender && isShow) ? '2em' : undefined}
          flex={1}
          display="flex"
          flexDirection="column"
        >
          {content}
        </Box>
      )}
      <Box
        position="relative"
        width={(panelsRender && isShow) ? `${copilotPanelWidth || 0}px` : 0}
        sx={{
          opacity: 1,
          '@media (max-width: 767px)': {
            flex: 1,
          },
        }}
        ref={copilotPanelRef}
      >
        <Box
          position="fixed"
          overflow="hidden"
          ref={floatingRef}
          sx={{
            '@media (max-width: 767px)': {
              position: 'relative',
              overflow: 'unset',
              width: 'unset!important',
              height: 'unset!important',
              left: 'unset!important',
            },
          }}
        >
          <Box
            position="relative"
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            height="100%"
            // width={`${copilotPanelWidth || 0}px`}
            sx={{
              '@media (max-width: 767px)': {
                width: 'unset',
                height: 'unset',
              },
            }}
          >
            <Box
              bgcolor={theme.palette.background.level1}
              mx={2}
              py={0.5}
              px={0.5}
              borderRadius={24}
              sx={{
                '@media (max-width: 767px)': {
                  width: 'unset',
                  position: 'sticky',
                  top: route.isScreen('Ask') || route.isScreen('Library') ? '6.5rem' : '4.25rem',
                  zIndex: 10,
                },
              }}
            >
              <ButtonSwitcher
                textSize={17}
                size={screenSize.is767 ? 'sm' : 'md'}
                variant="button"
                items={panelItems}
                value={activePanelValue || ''}
                onChange={handlePanelChange}
              />
            </Box>
            {panelsRender}
          </Box>
          <Box
            position="absolute"
            width="1px"
            top={0}
            left={0}
            bottom={0}
            bgcolor={theme.palette.neutral.softHoverBg}
            sx={{
              '@media (max-width: 767px)': {
                display: 'none',
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PanelsBar);

// export default memo(PanelsBar, (prevProps, nextProps) => (
//   prevProps.contentMeta === nextProps.contentMeta
//   && prevProps.content === nextProps.content
//   && prevProps.panels === nextProps.panels
//   && JSON.stringify(prevProps.sx) === JSON.stringify(nextProps.sx)
//   && JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
// ));
