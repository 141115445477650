import connect from '../../utils/saga/connect';
import * as loadNextPage from './sagas/loadNextPage';
import * as loadSummaryById from './sagas/loadSummaryById';
import * as newSend from './sagas/newSend';
import * as editSend from './sagas/editSend';
import * as deleteSend from './sagas/deleteSend';

export default connect([
  loadNextPage,
  loadSummaryById,
  newSend,
  editSend,
  deleteSend,
]);
