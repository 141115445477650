import {
  takeEvery, put, cancel, delay,
} from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'Profile'>());
  if (!route.isScreen('Profile')) {
    yield cancel(); return;
  }

  const isAppPrepared = yield* select((state) => state.app.prepared);
  if (!isAppPrepared) {
    yield delay(500);
    yield put(actions.runPageHandler());
    yield cancel(); return;
  }

  const { login } = route.params;
  if (!!login && login !== 'my') {
    yield put(userStore.actions.loadProfile(login));
  }

  yield* call(() => track('Profile', { login }));
}
