import {
  type ReactElement,
  useCallback, useEffect, useState, memo,
} from 'react';
import {
  StyleSheet, useWindowDimensions,
} from 'react-native';

import type { ResourceVariant } from 'app/entities';

import { useResponsive } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as commentStore from 'store/nodes/comment';

import { unit } from 'utils';
import { View, Text } from 'components/Themed';
import Stars from 'components/Stars';
import { Button, TextInput } from 'components/Form';

export type ReviewFormProps = {
  resource?: ResourceVariant,
  resourceId?: number,
}

const ReviewForm = (props: ReviewFormProps): ReactElement => {
  const {
    resource,
    resourceId,
  } = props;

  const dispatcher = useDispatcher();
  const dimensions = useWindowDimensions();
  const responsive = useResponsive();

  const [text, setText] = useState<string>('');
  const [rate, setRate] = useState<number>(5);
  const isSending = useSelector(commentStore.selectors.isLoadingNew(resource, resourceId, 'review'));

  const handleSend = useCallback(() => {
    if (!resource || !resourceId) {
      return;
    }
    dispatcher.comment.newSend(resource, resourceId, { rate, text }, 'review');
  }, [rate, resource, resourceId, text]);

  useEffect(() => {
    setText('');
    setRate(5);
  }, []);

  return (
    <View
      style={[
        styles.ReviewForm,
        responsive.isMoreThen.mobile && styles.desktop,
      ]}
      lightColor="#e7e7e7"
      darkColor="#181818"
      pointerEvents="auto"
    >
      <View style={styles.title}>
        <Text size={22}>Add a review</Text>
      </View>
      <View style={styles.info}>
        <Text size={16} lightColor="#929292" darkColor="#77767E">Rate this course from 1 to 5</Text>
      </View>
      <View style={styles.rate}>
        <Stars size={40} value={rate} onChange={!isSending ? setRate : undefined} />
        <Text size={28} style={styles.rateValue}>{`${rate}/5`}</Text>
      </View>
      <View style={[styles.comment, { height: dimensions.height / 5 }]}>
        <Text size={13} lightColor="#797979" darkColor="#B8B6BF" style={{ marginBottom: unit(8) }}>Your comment</Text>
        <TextInput value={text} onChange={setText} multiline style={styles.input} disabled={isSending} />
      </View>
      <View
        style={[
          styles.send,
          responsive.isMoreThen.mobile && styles.sendDesktop,
        ]}
      >
        <Button variant="outlined" lightColor="#929292" darkColor="#77767E" onPress={handleSend} inProcess={isSending}>
          <Text size={17} lightColor="#000000" darkColor="#ffffff">Send review</Text>
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  ReviewForm: {
    width: '100%',
    maxWidth: unit(560),
    alignSelf: 'center',
    paddingHorizontal: unit(16),
    paddingVertical: unit(40),
    borderTopLeftRadius: unit(24),
    borderTopRightRadius: unit(24),
  },
  desktop: {
    borderRadius: unit(24),
  },
  title: {
  },
  info: {
    marginTop: unit(32),
  },
  rate: {
    marginTop: unit(8),
    flexDirection: 'row',
    alignItems: 'center',
  },
  rateValue: {
    marginLeft: unit(16),
  },
  comment: {
    marginTop: unit(32),
  },
  send: {
    marginTop: unit(32),
  },
  sendDesktop: {
    alignSelf: 'center',
  },
  input: {
    flex: 1,
  },
});

export default memo(ReviewForm);
