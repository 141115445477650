import { memo, useCallback } from 'react';
import { useColorScheme } from '@mui/joy';

import { StyleSheet } from 'react-native';

import Storage from 'lib/Storage';

import MenuButton from 'components/UIBar/elements/MenuButton';
import Icon from 'ui/Icon';

import { useColorScheme as useOldColorScheme } from 'hooks';

type ThemeSwitcherProps = {
  isSlim?: boolean
}

const ThemeSwitcher = (props: ThemeSwitcherProps) => {
  const { isSlim } = props;

  const colorScheme = useOldColorScheme();
  const { mode, setMode } = useColorScheme();
  const handleThemeChange = useCallback((newSchemeValue: 'light' | 'dark') => {
    Storage.set('theme.type', newSchemeValue);
    setMode(mode === 'dark' ? 'light' : 'dark');
  }, [mode]);

  return (
    <>
      {colorScheme === 'dark' && (
        <MenuButton
          style={styles.colorSchemeItem}
          onPress={() => { handleThemeChange('light'); }}
          text="White mode"
          isSlim={isSlim}
          isSelected={false}
          revertSlim
          iconRight={<Icon name="sun-bright" fw size="lg" weight="light" color="icon" />}
        />
      )}
      {colorScheme === 'light' && (
        <MenuButton
          style={styles.colorSchemeItem}
          onPress={() => { handleThemeChange('dark'); }}
          text="Dark mode"
          isSlim={isSlim}
          isSelected={false}
          revertSlim
          iconRight={<Icon name="moon" fw size="lg" weight="light" color="icon" />}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  colorSchemeItem: {
    justifyContent: 'space-between',
  },
});

export default memo(ThemeSwitcher);
