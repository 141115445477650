import {
  memo, useCallback,
  type ReactElement,
} from 'react';

import { useDispatcher } from 'store/utils/redux/hooks';
import type { ResourceVariant } from 'app/entities';

import MenuItem from 'components/MenuItem';
import Icon from 'components/LegacyIcon';
import { controller as modal } from 'components/Modal2';

export type OptionsProps = {
  resource?: ResourceVariant,
  resourceId?: number,
  commentId?: number,
  isMy?: boolean,
}

const Options = (props:OptionsProps): ReactElement => {
  const {
    resource,
    resourceId,
    commentId,
    isMy,
  } = props;
  const dispatcher = useDispatcher();

  const handleReply = useCallback(() => {
    if (!resource || !resourceId || !commentId) {
      return;
    }
    dispatcher.comment.replySetId(resource, resourceId, commentId);
    modal.menu.commentOptions.close();
  }, [resource, resourceId, commentId, dispatcher.comment]);

  const handleEdit = useCallback(() => {
    if (!resource || !resourceId || !commentId) {
      return;
    }
    dispatcher.comment.editStart(resource, resourceId, commentId);
    modal.menu.commentOptions.close();
  }, [resource, resourceId, commentId, dispatcher.comment]);

  const handleDelete = useCallback(() => {
    if (!resource || !resourceId || !commentId) {
      return;
    }
    dispatcher.comment.deleteSend(resource, resourceId, commentId);
    modal.menu.commentOptions.close();
  }, [resource, resourceId, commentId, dispatcher.comment]);

  return (
    <>
      <MenuItem
        icon={<Icon name="ChatOutline28" size={28} lightColor="#696969" darkColor="#D1CFD7" />}
        label="Reply"
        onPress={handleReply}
      />
      {isMy && (
        <MenuItem
          icon={<Icon name="EditOutline28" size={28} lightColor="#696969" darkColor="#D1CFD7" />}
          label="Edit"
          onPress={handleEdit}
        />
      )}
      {isMy && (
        <MenuItem
          icon={<Icon name="DeleteOutline28" size={28} lightColor="#696969" darkColor="#D1CFD7" />}
          label="Delete"
          onPress={handleDelete}
        />
      )}
    </>
  );
};

export default memo(Options);
