import React, {
  useCallback,
  type ReactElement,
} from 'react';
import { StyleSheet } from 'react-native';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';

import { controller as modal } from 'components/Modal2';

import {
  IconButton, Tooltip, Typography,
} from '@mui/joy';
import Icon from 'ui/Icon';
import EmptyPanel from 'screens/Profile/elements/EmptyPanel';
import Job from './elements/Job';

const defaultProps = {
  bigLabel: false as boolean,
};

type JobsProps = {
  userLogin?: string,
  bigLabel?: boolean,
} & typeof defaultProps;

const Jobs = (props: JobsProps): ReactElement | null => {
  const {
    userLogin,
    bigLabel,
  } = props;

  const data = useSelector(userStore.selectors.getByLogin(userLogin));

  const jobs = data?.jobExperience || [];

  const handleAddJob = useCallback(() => {
    modal.popup.jobAdd.open();
  }, []);

  const handleEdit = useCallback((id: number) => {
    modal.popup.jobAdd.open({
      id,
    });
  }, []);

  if (!userLogin || !data) {
    return null;
  }

  return (
    <View>
      <View style={styles.header}>
        <Typography fontSize={bigLabel ? 22 : 20} fontWeight="bolder">Experience</Typography>
        <View style={styles.flex1} />
        {data.isMy && (
          <Tooltip arrow variant="outlined" size="sm" title="Add job experience">
            <IconButton onClick={handleAddJob}>
              <Icon size="xl" name="plus" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </View>
      {data.isMy && (!Array.isArray(jobs) || !jobs.length) && (
        <EmptyPanel mt={2} sx={{ cursor: 'pointer' }} onClick={handleAddJob} text="Add your experience here" />
      )}
      {!data.isMy && (!Array.isArray(jobs) || !jobs.length) && (
        <EmptyPanel mt={2} text={`${data.name} did not specify experience`} />
      )}
      {Array.isArray(jobs) && jobs.map((job) => (
        <Job
          id={job.id}
          key={job.company}
          location={job.location}
          company={job.company}
          startDate={job.startDate}
          endDate={job.endDate}
          position={job.position}
          description={job.description}
          editable={data?.isMy}
          onEdit={handleEdit}
        />
      ))}
    </View>
  );
};

Jobs.defaultProps = defaultProps;

const styles = StyleSheet.create({
  empty: {
    borderRadius: unit(16),
    paddingVertical: unit(64),
    alignItems: 'center',
    marginTop: unit(16),
  },
  flex1: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addBtn: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Jobs;
