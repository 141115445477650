import { type DefaultRootState } from 'react-redux';

import type { UserType } from 'app/entities';

type ResultType<T> = T extends number ? UserType | null : UserType[];

export const getById = <T = number | number[]>(id?: T) => (state: DefaultRootState): ResultType<T> => {
  const ids = !Array.isArray(id) ? [id] : id;
  const result: UserType[] = [];
  ids.forEach((itemId) => {
    if (!itemId || !state.user.idMap[itemId]) {
      return;
    }
    const user = state.user.data[state.user.idMap[itemId]];
    if (!user) {
      return;
    }
    result.push(user);
  });
  if (!Array.isArray(id)) {
    return (result.length === 0 ? null : result[0]) as ResultType<T>;
  }
  return result as ResultType<T>;
};
