import React, { memo, useCallback } from 'react';
import { Box } from '@mui/joy';

import { type CollectionType, type MaterialType } from 'app/entities';
import HorizontalSwiper from 'widgets/HorizontalSwiper';

import CardSmall from 'components/CardSmall';
import dialog from 'components/Dialog';

import { useNavigate } from 'navigation/hooks';
import { dispatcher } from 'store/utils/redux/hooks';

import { guard } from 'utils';

import Delete from '../elements/Delete';

type ContentSwiperProps = {
  contentIds: number[],
  isEditable: boolean,
}

const ContentSwiper = (props: ContentSwiperProps) => {
  const { contentIds, isEditable } = props;

  const navigate = useNavigate();

  const handleMaterialOpen = useCallback((_: 'content' | 'playlist', resourceId: number, context: { data: MaterialType | CollectionType }) => {
    if (guard.isMaterial(context.data)) {
      navigate('Content', { resourceId, resourceType: (context.data?.type || '').toLowerCase() });
    }
  }, []);

  const handleDelete = useCallback((id: number) => {
    dialog.show(
      {
        title: 'Delete content',
        text: 'Are you sure want to delete this content?',
      },
      [{
        label: 'Cancel',
        variant: 'outlined',
        lightColor: '#929292',
        darkColor: '#77767E',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
        },
      }, {
        label: 'Delete',
        variant: 'contained',
        lightColor: '#db3327',
        darkColor: '#db3327',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
          dispatcher.content.remove(id);
        },
      }],
    );
  }, []);

  return (
    <HorizontalSwiper
      className="swiperProfileCollection"
      slidesPerView="auto"
      autoHeight={false}
    >
      {contentIds.map((contentId, index) => (
        <CardSmall
          key={contentId}
          id={contentId}
          type="content"
          toolbar={isEditable && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              flex={1}
              mr={2.1}
              mt={2.2}
            >
              <Delete id={contentId} onPress={handleDelete} />
            </Box>
          )}
          onPress={handleMaterialOpen}
        />
      ))}
    </HorizontalSwiper>

  );
};

export default memo(ContentSwiper);
