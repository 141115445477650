import {
  takeLatest, put, cancel,
} from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';
import * as Linking from 'expo-linking';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.doPasswordReset.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.doPasswordReset>) {
  const { email } = action.payload;

  const url = yield* call(() => Linking.createURL('/auth/password-reset'));
  const result = yield* call(() => api.resource.auth.passwordReset(email, url));
  if (result?.error) {
    yield put(actions.doPasswordResetDone(result?.error));
    yield cancel(); return;
  }

  yield put(actions.doPasswordResetDone());
}
