import {
  memo, type ReactElement, type Ref, useCallback,
} from 'react';
import { Box, type BoxProps } from '@mui/joy';
import {
  DragDropContext, Droppable,
  type DraggableProvidedDraggableProps,
  type DraggableProvidedDragHandleProps,
  type DropResult,
} from 'react-beautiful-dnd';
import { Virtuoso } from 'react-virtuoso';

import { type MaterialMetadataItemType } from 'app/entities';

import DraggableItem from './components/DraggableItem';
import HeightPreservingItem from './components/HeighPreservingItems';

type ItemArgs = {
  item: {
    id: number | string;
    type: string;
    isSmartListRecommendation: boolean;
  };
  ref: Ref<any>;
  isActive?: boolean;
  isDragged?: boolean;
  handleProps?: DraggableProvidedDragHandleProps | null;
  props: DraggableProvidedDraggableProps
}

export type RenderItemFunction = (args: ItemArgs, draggable: boolean) => ReactElement<HTMLElement>;

type DraggableVirtuosoProps = {
  items: MaterialMetadataItemType[];
  renderItem: RenderItemFunction;
  onSort: (oldIndex: number, newIndex: number) => void;
} & BoxProps;

window.addEventListener('error', (e) => {
  if (
    e.message
    === 'ResizeObserver loop completed with undelivered notifications.'
    || e.message === 'ResizeObserver loop limit exceeded'
  ) {
    e.stopImmediatePropagation();
  }
});

const SortableVirtuosoList = (props: DraggableVirtuosoProps) => {
  const {
    items,
    renderItem,
    onSort,
    ...rest
  } = props;

  const onDragEnd = useCallback((result: DropResult) => {
    if (result.reason === 'CANCEL') {
      return;
    }
    if (result.source.index === result.destination?.index) {
      return;
    }
    if (result.reason === 'DROP' && typeof result.destination?.index === 'number') {
      onSort(result.source.index, result.destination?.index);
    }
  }, [onSort]);

  return (
    <Box {...rest}>
      <style>
        {`
          .height-preserving-container:empty {
            min-height: calc(var(--child-height));
          }
        `}
      </style>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          mode="virtual"
          droppableId="droppable"
          direction="vertical"
          renderClone={(provided, snapshot, rubric) => {
            const item = items[rubric.source.index];
            return renderItem({
              item,
              ref: provided.innerRef,
              props: provided.draggableProps,
              handleProps: provided.dragHandleProps,
              isActive: true,
              isDragged: false,
            }, true);
          }}
        >
          {(droppableProvided) => {
            return (
              <Box style={{ overflow: 'hidden' }} p={1} ref={droppableProvided.innerRef}>
                <Virtuoso
                  data={items}
                  components={{
                    Item: HeightPreservingItem,
                  }}
                  useWindowScroll
                  itemContent={(index, item) => (
                    <DraggableItem item={item} index={index} draggableRenderItem={renderItem} />
                  )}
                />
              </Box>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default memo(SortableVirtuosoList);
