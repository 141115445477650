import { memo } from 'react';
import {
  Box, Button, ButtonGroup, type BoxProps, useTheme,
} from '@mui/joy';

import { useScreenSize, useThemeColor } from 'hooks';

import Icon from 'ui/Icon';

type HeadProps = {
  onAddMaterialPress?: () => void,
  onAddNotePress?: () => void,
} & BoxProps

const Head = (props: HeadProps) => {
  const {
    onAddMaterialPress,
    onAddNotePress,
    ...rest
  } = props;

  const theme = useTheme();
  const screenSize = useScreenSize();

  if (screenSize.is499) {
    return null;
  }

  return (
    <Box {...rest}>
      <ButtonGroup
        spacing={2}
        sx={{
          '--ButtonGroup-radius': '40px',
          '--variant-softColor': theme.palette.neutral.softBg,
          '--variant-softActiveColor': theme.palette.neutral.softBg,
          '--variant-softBg': theme.palette.text.primary,
          '--variant-softHoverBg': theme.palette.mode === 'dark' ? theme.palette.neutral['300'] : theme.palette.neutral['700'],
          '--variant-softActiveBg': theme.palette.mode === 'dark' ? theme.palette.neutral['400'] : theme.palette.neutral['600'],
          '& button': {
            fontWeight: 400,
          },
        }}
      >
        <Button
          variant="soft"
          startDecorator={
            <Icon name="plus" fw weight="regular" color="inherit" />
          }
          onClick={onAddMaterialPress}
        >
          Add knowledge
        </Button>
        <Button
          variant="soft"
          startDecorator={
            <Icon name="text" fw weight="regular" color="inherit" />
          }
          onClick={onAddNotePress}
        >
          Add text block
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default memo(Head);
