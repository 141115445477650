import { memo } from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet,
} from 'react-native';

import { useResponsive } from 'hooks';
import { unit } from 'utils';

import { View } from 'components/Themed';

type TopBarMobileProps = {
  style?: StyleProp<ViewStyle>,
}

const TopBarMobile = (props: TopBarMobileProps) => {
  const {
    style,
  } = props;

  const responsive = useResponsive();

  if (!responsive.is.mobileL) {
    return null;
  }

  return (
    <View style={[styles.wrapper, style]} />
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginTop: unit(48),
    marginBottom: unit(20),
  },
  icon: {
    marginRight: unit(6),
    marginLeft: unit(-2),
  },
});

export default memo(TopBarMobile);
