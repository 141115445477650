import { Dimensions } from 'react-native';
import {
  takeLatest, put, cancel, delay,
  type SagaReturnType,
} from 'redux-saga/effects';

import { controller as modal } from 'components/Modal2';
import Alert from 'components/Alert';

import * as api from 'services/api';

import { getOldResourceType, prepare } from 'utils';
import { call } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as userStore from 'store/nodes/user';

import * as modalStore from 'widgets/modals/store';

import network from 'lib/network';
import type { CollectionType } from 'app/entities';

import * as actions from '../actions';

export const config = {
  action: actions.addPlaylist.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.addPlaylist>) {
  const {
    title,
    description,
    cover,
    isPrivate,
    isSmartListEnabled,
    materialsMetadata,
  } = action.payload;

  if (!title) {
    Alert.error('"Title" is required');
    yield put(actions.addPlaylistDone());
    yield cancel(); return;
  }

  const preparedCover = prepare.newImage(cover);

  const { errors, data } = yield* call(() => (
    network.request<CollectionType>('share/user/playlists').body({
      title,
      description,
      cover: preparedCover,
      isPrivate,
      isSmartListEnabled,
      materialsMetadata: materialsMetadata.map((item) => ({
        ...item,
        type: getOldResourceType(item.type),
      })),
    }).post()
  ));

  // const collection = yield* call(() => api.resource.user.playlist.create({
  //   title,
  //   description,
  //   cover: preparedCover,
  //   isPrivate,
  //   isSmartListEnabled,
  //   materialsMetadata,
  // }));
  yield checkUnauthorized({ data, error: errors });
  if (errors || !data?.id) {
    Alert.error(errors?.[0]?.message || 'Unknown error #28');
    yield put(actions.addPlaylistDone(errors || 'unknown error'));
    yield cancel(); return;
  }
  yield delay(10);

  yield put(actions.addPlaylistDone());

  if (Dimensions.get('window').width <= 425) {
    modal.info.playlistCreated.open();
    modal.info.playlistCreated.delay(2400).close();
  } else {
    yield put(modalStore.actions.close('AddorEditCollection'));
    Alert.success('Collection created');
  }

  yield delay(2000);
  yield put(userStore.actions.loadProfile());
}
