import { DateTime } from 'luxon';
import { type Task } from 'redux-saga';
import {
  type SagaReturnType,
  takeEvery, cancel, put, fork, delay,
} from 'redux-saga/effects';

import log from '../model/log';

import * as actions from '../actions';

export function* tick() {
  while (true) {
    yield delay(1000);
    yield put(actions.tick());
  }
}

export const config = {
  action: [
    actions.opened.type,
    actions.closed.type,
    actions.close.type,
    actions.sendPing.type,
    actions.receivePong.type,
    actions.tick.type,
  ],
  method: takeEvery,
};

let tickTask: Task | null = null;
let pingTime: DateTime<true>;
let pongTime: DateTime<true>;

export function* func(
  action: SagaReturnType<
    | typeof actions.opened
    | typeof actions.closed
    | typeof actions.close
    | typeof actions.sendPing
    | typeof actions.receivePong
    | typeof actions.tick
  >,
) {
  if (action.type === actions.opened.type) {
    log('opened');
    tickTask = yield fork(tick);
  }

  if (action.type === actions.closed.type && tickTask) {
    log('closed');
    yield cancel(tickTask);
    tickTask = null;
  }

  if (action.type === actions.close.type) {
    log('closed by server');
    yield put(actions.reconnect());
  }

  if (action.type === actions.sendPing.type) {
    log('send ping');
    pingTime = DateTime.now();
  }

  if (action.type === actions.receivePong.type) {
    log('receive pong');
    pongTime = DateTime.now();
  }

  if (action.type === actions.tick.type && pingTime && pongTime && pongTime.diff(pingTime, ['milliseconds']).milliseconds > 0) {
    const secondsLost = DateTime.now().diff(pongTime, ['seconds']).seconds;
    if (secondsLost > 30) {
      log(`pong timeout (${secondsLost})`);
      yield put(actions.reconnect());
    }
  }
}
