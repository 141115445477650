import { memo } from 'react';
import { StyleSheet } from 'react-native';

import { Box, Typography } from '@mui/joy';
import { useResponsive } from 'hooks';
import { unit } from 'utils';

import TagsFilter from './TagsFilter';

const TopBarDesktop = () => {
  const responsive = useResponsive();

  if (!responsive.is.tabletL) {
    return null;
  }

  return (
    <Box mt={5} mb={3}>
      <Box display="flex" flexDirection="row" alignItems="center" ml={-0.5}>
        <Typography fontSize={18} fontWeight={500} ml={0.5}>Topics</Typography>
        {/* <Text size={20} weight="medium" style={{ marginLeft: unit(4) }}>Topics</Text> */}
      </Box>
      <Box mt={0.5}>
        <TagsFilter textSize={13} />
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginTop: unit(8),
    marginBottom: unit(24),
  },
  filterLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: unit(-3),
  },
  text: {
    marginTop: unit(4),
    marginLeft: unit(-3),
  },
  tags: {
    marginTop: unit(10),
  },
});

export default memo(TopBarDesktop);
