import { md5 } from 'js-md5';

const fileMd5 = async (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = () => {
    const arrayBuffer = reader.result as ArrayBuffer;
    const byteArray = new Uint8Array(arrayBuffer);

    const md5Hash = md5(byteArray);

    resolve(md5Hash);
  };

  reader.onerror = (error) => {
    reject(error);
  };

  reader.readAsArrayBuffer(file);
});

export default fileMd5;
