import React, {
  type ReactElement, type PropsWithChildren,
  useState, useMemo, cloneElement, forwardRef, memo, useRef, useCallback, useEffect,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions,
} from 'react-native';

import { useResponsive } from 'hooks';

import { View } from 'components/Themed';
import { unit } from 'utils';
import Icon from 'components/LegacyIcon';
import dialog from 'components/Dialog';

import { dispatcher } from 'store/utils/redux/hooks';
import { type Controller, useScrollView, isForwardedController } from '../helpers';

import Popup from './Popup';
import Menu from './Menu';

const defaultProps: Partial<ModalProps> = {
  layout: 'panel' as 'panel' | 'transparent' | 'infinity' | 'menu',
  height: 'auto' as 'auto' | string,
  propagateSwipe: false as boolean,
};

type ModalProps = {
  name?: string,
  height?: 'auto' | string,
  layout?: 'panel' | 'transparent' | 'infinity' | 'menu',
  propagateSwipe?: boolean,
  onContentSizeChange?: (event: { target: ScrollView, size: { w: number, h: number } }) => void
  isConfirmClosing?: boolean
};

const Modal = forwardRef<Controller, PropsWithChildren<ModalProps & typeof defaultProps>>((props, forwardedRef) => {
  const {
    name,
    height,
    layout,
    propagateSwipe,
    onContentSizeChange,
    children,
    isConfirmClosing,
  } = props;
  const scrollViewRef = useRef<any>();
  const dimensions = useWindowDimensions();
  const responsive = useResponsive();

  const [context, setContext] = useState<Record<string, any> | null>(null);

  const {
    handleTouchStart, handleTouchMove, handleTouchEnd, handleOnScroll,
  } = useScrollView();

  useEffect(() => {
    if (!isForwardedController(forwardedRef)) {
      return;
    }
    forwardedRef.current.name = name;
  }, [name]);

  const handleClose = useCallback(() => {
    if (!isForwardedController(forwardedRef)) {
      return;
    }
    if (forwardedRef.current.name) {
      dispatcher.modal.close(forwardedRef.current.name as any);
    }
    if (!isConfirmClosing) {
      forwardedRef.current?.close();
      return;
    }
    dialog.show(
      {
        title: 'Confirmation',
        text: 'Are you sure want to close this modal?',
      },
      [{
        label: 'Back',
        variant: 'outlined',
        lightColor: '#929292',
        darkColor: '#77767E',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
        },
      }, {
        label: 'Close',
        variant: 'contained',
        lightColor: '#db3327',
        darkColor: '#db3327',
        textLightColor: '#ffffff',
        textDarkColor: '#ffffff',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
          forwardedRef.current?.close();
        },
      }],
    );
  }, [isConfirmClosing]);

  const handleContentSizeChange = useCallback((size: any) => {
    if (scrollViewRef.current) {
      onContentSizeChange?.({ target: scrollViewRef.current, size });
    }
  }, [onContentSizeChange]);

  const panelStyles = useMemo(() => {
    if (height === 'auto') {
      return ({
        xs: {},
      });
    }
    const percent = parseFloat(height || 'auto') / 100;
    return ({
      xs: {
        height: dimensions.height * percent,
      },
    });
  }, [dimensions.height, height]);

  const infinityContainerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      height: dimensions.height,
      justifyContent: 'flex-end',
    };
    if (responsive.isMoreThen.mobile) {
      result.justifyContent = 'center';
    }
    return result;
  }, [responsive.isMoreThen.mobile, dimensions.height]);

  if (layout === 'menu') {
    return (
      <Menu.Panel ref={forwardedRef} onContext={setContext}>
        {!!context && cloneElement(children as ReactElement, context)}
      </Menu.Panel>
    );
  }

  // @todo метод по умолчанию
  if (layout === 'infinity') {
    return (
      <Popup.Panel
        ref={forwardedRef as any}
        propagateSwipe
        layout="transparent"
        onContext={setContext}
        isConfirmClosing={isConfirmClosing}
      >
        <View
          style={infinityContainerStyle}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <ScrollView
            ref={scrollViewRef}
            style={[
              responsive.isLessThen.mobileLarge && styles.xsScrollView,
              {
                height: dimensions.height,
                flexGrow: 0,
              },
            ]}
            contentContainerStyle={{
              minHeight: dimensions.height,
              justifyContent: responsive.isLessThen.mobileLarge ? 'flex-end' : 'center',
            }}
            onScroll={handleOnScroll}
            onContentSizeChange={handleContentSizeChange}
            scrollEventThrottle={16}
          >
            <TouchableOpacity style={styles.backdropCloser} onPress={handleClose} activeOpacity={1} />
            {!!context && cloneElement(children as ReactElement, {
              ...context,
              scrollRef: scrollViewRef.current,
            })}
          </ScrollView>
          {responsive.isMoreThen.mobileLarge && (
            <TouchableOpacity onPress={handleClose} style={styles.closeButton}>
              <Icon name="Close" size={32} lightColor="#000000" darkColor="#ffffff" />
            </TouchableOpacity>
          )}
        </View>
      </Popup.Panel>
    );
  }

  // @todo deprecated удалить после рефакторинга search
  return (
    <Popup.Panel
      ref={forwardedRef as any}
      propagateSwipe={propagateSwipe}
      layout={layout as 'panel' | 'transparent'}
      styles={panelStyles}
      onContext={setContext}
      isConfirmClosing={isConfirmClosing}
    >
      <TouchableOpacity style={styles.backdropCloser} onPress={handleClose} activeOpacity={1} />
      {!!context && cloneElement(children as ReactElement, context)}
    </Popup.Panel>
  );
});

Modal.defaultProps = defaultProps;

const styles = StyleSheet.create({
  backdropCloser: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  closeButton: {
    position: 'absolute',
    width: unit(32),
    height: unit(32),
    top: unit(32),
    right: unit(48),
  },
  xsScrollView: {
    marginHorizontal: unit(-10),
    paddingHorizontal: unit(10),
  },
});

export default memo(Modal);
