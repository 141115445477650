import { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { type MaterialMetadataItemType } from 'app/entities';
import { type RenderItemFunction } from 'components/SortableVirtuosoList';

type DraggableItemprops = {
  item: MaterialMetadataItemType,
  draggableRenderItem: RenderItemFunction
  index: number,
}

const DraggableItem = (props: DraggableItemprops) => {
  const { item, index, draggableRenderItem } = props;

  return (
    <Draggable draggableId={item.id.toString()} index={index} key={item.id}>
      {(provided) => (
        draggableRenderItem({
          item,
          ref: provided.innerRef,
          props: provided.draggableProps,
          handleProps: provided.dragHandleProps,
        }, true)
      )}
    </Draggable>
  );
};

export default memo(DraggableItem);
