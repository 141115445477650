import connect from '../../utils/saga/connect';
import * as addMaterial from './sagas/addMaterial';
import * as editMaterial from './sagas/editMaterial';
import * as addPlaylist from './sagas/addPlaylist';
import * as addSelectedId from './sagas/addSelectedId';

export default connect([
  addMaterial,
  editMaterial,
  addPlaylist,
  addSelectedId,
]);
