import { memo, type ReactElement, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import {
  Platform, StyleSheet,
} from 'react-native';
import PlatformView from 'components/PlatformView';
import Lottie from 'components/Lottie';

import { useThemeColor } from 'hooks';

const Preparing = (): ReactElement => {
  const backgroundColor = useThemeColor({ light: '#FFFFFF', dark: '#111111' });
  const classes = useStyles({
    backgroundColor,
  });

  useEffect(() => {
    if (Platform.OS !== 'web' || typeof document === 'undefined') {
      return undefined;
    }
    document.body.classList.add(classes.hidden);
    return () => {
      document.body.classList.remove(classes.hidden);
    };
  }, [classes]);

  return (
    <PlatformView webClassName={classes.Preparing} nativeStyle={styles.Preparing}>
      <Lottie width={160} name="Loading" loop />
    </PlatformView>
  );
};

const useStyles = createUseStyles<
  'Preparing' | 'hidden',
  {
    backgroundColor?: string
  }
>({
  Preparing: {
    position: 'fixed',
    display: 'flex',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    backgroundColor: (props) => props.backgroundColor,
  },
  hidden: {
    overflow: 'hidden',
  },
});

const styles = StyleSheet.create({
  Preparing: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    backgroundColor: '#111111',
  },
});

export default memo(Preparing);
