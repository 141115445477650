import { useMemo } from 'react';
import { unit } from 'utils';

export const usePlaceholder = (method: 'GlobalFullText' | 'GlobalTitle' | 'LibraryFullText' | 'LibraryTitle' | 'Web') => useMemo(() => {
  const map = {
    'GlobalFullText': 'Search full-text',
    'GlobalTitle': 'Search by title',
    'LibraryFullText': 'Search full-text in my library',
    'LibraryTitle': 'Search by title in my library',
    'Web': 'Search on the web',
  };
  if (!method) {
    return '';
  }
  return map[method];
}, [method]);

export const useFontSize = (size: 'small' | 'medium' | 'large') => useMemo(() => {
  const map = {
    'small': 16,
    'medium': 16,
    'large': 22,
  };
  return map[size];
}, [size]);

export const useBorderRadius = (size: 'small' | 'medium' | 'large') => useMemo(() => {
  const map = {
    'small': 36 / 2,
    'medium': 44 / 2,
    'large': 70 / 2,
  };
  return map[size];
}, [size]);

export const useButtonSize = (size: 'small' | 'medium' | 'large') => useMemo(() => {
  const map = {
    'small': 28,
    'medium': 32,
    'large': 62,
  };
  return map[size];
}, [size]);

export const useButtonHeight = (size: 'small' | 'medium' | 'large') => useMemo(() => {
  const map = {
    'small': 36,
    'medium': 40,
    'large': 70,
  };
  return map[size];
}, [size]);

export const usePadding = (size: 'small' | 'medium' | 'large', value?: string) => {
  const inputVerticalPaddings = useMemo(() => ({
    'small': {
      paddingTop: unit(7),
      paddingBottom: unit(8),
    },
    'medium': {
      paddingTop: unit(9),
      paddingBottom: unit(10),
    },
    'large': {
      paddingTop: unit(21),
      paddingBottom: unit(22),
    },
  })[size], [size]);

  const inputHorizontalPadding = useMemo(() => {
    const result = {
      'small': {
        paddingLeft: unit(12),
        paddingRight: unit(48),
      },
      'medium': {
        paddingLeft: unit(12),
        paddingRight: unit(48),
      },
      'large': {
        paddingLeft: unit(24),
        paddingRight: unit(48),
      },
    }[size];
    if (value) {
      result.paddingRight = unit(80);
    }
    return result;
  }, [size, value]);

  return useMemo(() => ({
    inputVerticalPaddings,
    inputHorizontalPadding,
  }), [inputVerticalPaddings, inputHorizontalPadding]);
};
