import { memo } from 'react';
import { useWindowDimensions } from 'react-native';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { View } from 'components/Themed';

type WebInfiniteScrollProps = {
  loading?: boolean,
  hasNextPage?: boolean,
  onLoadMore?: () => void,
};

const WebInfiniteScroll = (props: WebInfiniteScrollProps) => {
  const {
    loading,
    hasNextPage,
    onLoadMore,
  } = props;
  const dimensions = useWindowDimensions();

  const [infiniteRef] = useInfiniteScroll({
    loading: loading ?? false,
    hasNextPage: hasNextPage ?? true,
    onLoadMore: onLoadMore ?? (() => null),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: `0px 0px ${dimensions.height}px 0px`,
  });

  return (
    <View ref={infiniteRef} />
  );
};

export default memo(WebInfiniteScroll);
