import {
  type ReactElement, useCallback, useMemo, memo,
} from 'react';
import {
  StyleSheet,
  type StyleProp, type ViewStyle,
} from 'react-native';

import { unit } from 'utils';
import { View } from 'components/Themed';

import StarTouchable from './elements/StarTouchable';

const defaultProps = {
  size: 24,
  lightColorFill: '#000000',
  darkColorFill: '#ffffff',
  lightColorOutline: '#888888',
  darkColorOutline: '#9A99A2',
};

type IStartProps = {
  size?: number
  value: number
  lightColorFill?: string
  darkColorFill?: string
  lightColorOutline?: string
  darkColorOutline?: string
  style?: StyleProp<ViewStyle>
  onChange?: (value: number) => void,
} & typeof defaultProps;

const Stars = (props: IStartProps): ReactElement => {
  const {
    size,
    value,
    lightColorFill,
    darkColorFill,
    lightColorOutline,
    darkColorOutline,
    style,
    onChange,
  } = props;

  const handlePress = useCallback((context: { value: number; }) => {
    onChange?.(context?.value);
  }, [onChange]);

  const { containerStyle, overlayStyle, starStyle } = useMemo(() => {
    let finalValue = value;
    if (finalValue > 5) {
      finalValue = 5;
    }
    if (finalValue < 0) {
      finalValue = 0;
    }

    const percentValue = finalValue / 5;

    const padding = (size / 24);

    return {
      containerStyle: {
        width: unit((size + padding) * 5),
        height: unit(size),
        marginHorizontal: unit(-padding / 2),
      },
      overlayStyle: {
        width: `${percentValue * 100}%`,
      },
      starStyle: {
        marginHorizontal: unit(padding / 2),
      },
    };
  }, [size, value]);

  return (
    <View style={style}>
      <View style={[styles.container, containerStyle]}>
        <StarTouchable
          size={size}
          style={starStyle}
          lightColor={lightColorOutline}
          darkColor={darkColorOutline}
          onPress={onChange ? handlePress : undefined}
          context={{ value: 1 }}
        />
        <StarTouchable
          size={size}
          style={starStyle}
          lightColor={lightColorOutline}
          darkColor={darkColorOutline}
          onPress={onChange ? handlePress : undefined}
          context={{ value: 2 }}
        />
        <StarTouchable
          size={size}
          style={starStyle}
          lightColor={lightColorOutline}
          darkColor={darkColorOutline}
          onPress={onChange ? handlePress : undefined}
          context={{ value: 3 }}
        />
        <StarTouchable
          size={size}
          style={starStyle}
          lightColor={lightColorOutline}
          darkColor={darkColorOutline}
          onPress={onChange ? handlePress : undefined}
          context={{ value: 4 }}
        />
        <StarTouchable
          size={size}
          style={starStyle}
          lightColor={lightColorOutline}
          darkColor={darkColorOutline}
          onPress={onChange ? handlePress : undefined}
          context={{ value: 5 }}
        />
        <View style={[styles.overlay, overlayStyle]} pointerEvents="none">
          <StarTouchable type="fill" size={size} style={starStyle} lightColor={lightColorFill} darkColor={darkColorFill} />
          <StarTouchable type="fill" size={size} style={starStyle} lightColor={lightColorFill} darkColor={darkColorFill} />
          <StarTouchable type="fill" size={size} style={starStyle} lightColor={lightColorFill} darkColor={darkColorFill} />
          <StarTouchable type="fill" size={size} style={starStyle} lightColor={lightColorFill} darkColor={darkColorFill} />
          <StarTouchable type="fill" size={size} style={starStyle} lightColor={lightColorFill} darkColor={darkColorFill} />
        </View>
      </View>
    </View>
  );
};

Stars.defaultProps = defaultProps;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    flexDirection: 'row',
    top: 0,
    left: 0,
    height: '100%',
    overflow: 'hidden',
  },
});

export default memo(Stars);
