import { createSelector } from 'reselect';
import type { DefaultRootState } from 'react-redux';

const getFollow = (state: DefaultRootState) => state.menu.panels.follow;
const getUserData = (state: DefaultRootState) => state.user.data;
const getUserLogin = (state: DefaultRootState) => state.user.idMap;

export default createSelector(
  [getFollow, getUserLogin, getUserData],
  (follow, login, data) => (
    follow.map((id) => data[login[id]]).filter((item) => !!item)
  ),
);
