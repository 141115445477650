import React, {
  memo, type PropsWithChildren, Children, useEffect, useRef, useState, useCallback,
} from 'react';
import {
  Swiper, SwiperSlide, type SwiperProps, type SwiperRef, useSwiper,
} from 'swiper/react';
import { Virtual, Mousewheel, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';

import { Box, IconButton } from '@mui/joy';
import Icon from 'ui/Icon';

import useSwiperMouseWheel from './model/useSwiperMouseWheel';

const HorizontalSwiper = (props: PropsWithChildren<SwiperProps>) => {
  const { breakpoints, children } = props;

  const swiperRef = useRef<SwiperRef>(null);
  useSwiperMouseWheel(swiperRef);

  useEffect(() => {
    swiperRef.current?.swiper.updateSize();
  }, [breakpoints]);

  const handleSlideNext = useCallback(() => {
    swiperRef.current?.swiper.slideNext();
  }, []);

  const handleSlidePrev = useCallback(() => {
    swiperRef.current?.swiper.slidePrev();
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      position: 'relative',
    }}
    >
      <Swiper
        ref={swiperRef}
        modules={[Virtual, Mousewheel]}
        autoHeight
        spaceBetween={16}
        direction="horizontal"
        loop
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
        }}
        {...props}
      >
        {Children.map(children, (child, index) => (
          <SwiperSlide style={{ width: props.slidesPerView === 'auto' ? 'auto' : undefined }} virtualIndex={index}>
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
      <Box sx={{
        '@media (max-width: 767px)': {
          display: 'none',
        },
      }}
      >
        <IconButton
          variant="soft"
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: -18,
            zIndex: 10,
            boxShadow: (theme) => theme.shadow.sm,
            backgroundColor: (theme) => theme.palette.background.level2,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.background.level3,
            },
            '&:active': {
              backgroundColor: (theme) => theme.palette.background.level1,
            },
          }}
          onClick={handleSlidePrev}
        >
          <Icon name="chevron-left" color="inherit" />
        </IconButton>
        <IconButton
          variant="soft"
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: -18,
            zIndex: 10,
            boxShadow: (theme) => theme.shadow.sm,
            backgroundColor: (theme) => theme.palette.background.level2,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.background.level3,
            },
            '&:active': {
              backgroundColor: (theme) => theme.palette.background.level1,
            },
          }}
          onClick={handleSlideNext}
        >
          <Icon name="chevron-right" color="inherit" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(HorizontalSwiper);
