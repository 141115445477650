import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import type { MaterialType } from 'app/entities';

import * as commentStore from 'store/nodes/comment';
import { guard } from 'utils';

const selectorDataById = createCachedSelector(
  (state: DefaultRootState, resourceId: number) => state.content.data[resourceId],
  (state: DefaultRootState, resourceId: number) => state.interaction.content?.[resourceId],
  (content, interaction) => {
    if (!content) {
      return null;
    }
    return {
      ...content,
      ...interaction,
    };
  },
)(
  (_state_, resourceId) => resourceId,
);

const selectorDataByIdWithUpdateMeta = createCachedSelector(
  (state: DefaultRootState, resourceId: number) => state.content.data[resourceId],
  (state: DefaultRootState, resourceId: number) => state.interaction.content?.[resourceId],
  (state: DefaultRootState, resourceId: number) => commentStore.selectors.items('content', resourceId)(state),
  (_, __, updateMeta: { mode: 'add' | 'sub' }) => updateMeta,
  (content, interaction, commentItems, updateMeta) => {
    const result = {
      ...content,
      ...interaction,
    };
    const commentsCount = (result.commentMeta?.commentsCount || 0) + (updateMeta.mode === 'add' ? 1 : -1);
    const userPhotos: string[] = [];
    if (commentsCount > 3 && result.commentMeta && Array.isArray(result.commentMeta?.userPhotos) && result.commentMeta.userPhotos.length === 3) {
      userPhotos.push(...result.commentMeta.userPhotos);
    } else {
      (commentItems || []).forEach((comment) => {
        if (userPhotos.length === 3 || !comment.user?.photo) {
          return;
        }
        const { photo } = comment.user;
        let url = '';
        if (guard.isImage(photo)) {
          url = photo.url;
        }
        if (typeof photo === 'string') {
          url = photo;
        }
        if (!url) {
          return;
        }
        if (userPhotos.length > 0 && userPhotos.includes(url)) {
          return;
        }
        userPhotos.push(url);
      });
    }
    return {
      ...result,
      commentMeta: {
        commentsCount,
        userPhotos,
      },
    };
  },
)(
  (_state_, resourceId, updateMeta) => `${resourceId}-${updateMeta.mode}`,
);

export const dataById = (resourceId: number | null | undefined, updateMeta?: { mode: 'add' | 'sub' }) => (state: DefaultRootState): MaterialType | null => {
  if (!resourceId) {
    return null;
  }
  if (typeof updateMeta !== 'undefined') {
    return selectorDataByIdWithUpdateMeta(state, resourceId, updateMeta);
  }
  return selectorDataById(state, resourceId);
};
