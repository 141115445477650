import { memo, useCallback } from 'react';
import {
  Box, Button, Tooltip, Typography,
} from '@mui/joy';
import { useSelector } from 'react-redux';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as copilotStore from 'store/nodes/copilot';

import { useScreenSize } from 'hooks';

import Icon from 'ui/Icon';

import CopilotScopes from '../../../ui/CopilotScopes';

const TopBarDesktop = () => {
  const dispatcher = useDispatcher();
  const screenSize = useScreenSize();
  const hasItems = useSelector(copilotStore.selectors.hasItems([{ type: 'web' }, { type: 'library-fulltext' }, { type: 'global-fulltext' }]));
  const isAnswerTyping = useSelector(copilotStore.selectors.isAnswerTyping([{ type: 'web' }, { type: 'library-fulltext' }, { type: 'global-fulltext' }]));

  const handleClear = useCallback(() => {
    dispatcher.copilot.clear({
      resources: [
        { type: 'web' },
        { type: 'global-fulltext' },
        { type: 'library-fulltext' },
      ],
    });
  }, []);

  if (screenSize.is767) {
    return null;
  }

  return (
    <Box display="flex" flex={1} zIndex={10}>
      <Box display="flex" flexDirection="row" flex={1} alignItems="flex-end" mt={-2}>
        <Box display="flex" flex={1} flexDirection="column" ml={5} mt={2} gap={1}>
          <Typography fontSize={17}>Ask in:</Typography>
          <CopilotScopes />
        </Box>
        <Tooltip arrow variant="outlined" size="sm" title="Clear context">
          <Button
            size="sm"
            color="neutral"
            disabled={isAnswerTyping || !hasItems}
            variant="soft"
            onClick={handleClear}
            startDecorator={(
              <Icon name="message-plus" fw weight="regular" color={isAnswerTyping || !hasItems ? 'icon' : 'primary'} />
            )}
            sx={{
              mb: -0.75,
              height: '2rem',
              pl: '0.5rem',
              fontWeight: 400,
              '--Button-gap': '0.25rem',
            }}
          >
            New chat
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default memo(TopBarDesktop);
