import network from 'lib/network';
import convert from 'xml-js';
import { delay } from 'utils';

import extensionByContentType from './extensionByContentType';
import fileMd5 from './fileMd5';

type UploaderResultType = {
  url: string,
  fields: {
    key: string,
    'x-amz-algorithm': string,
    'x-amz-credential': string,
    'x-amz-date': string,
    'x-amz-security-token': string,
    policy: string,
    'x-amz-signature': string
  }
  message: string
};

type NormalizedInfoType = {
  Location: string,
  Bucket: string,
  Key: string,
  ETag: string,
};

type S3InfoType = {
  error: null,
  data: {
    hash: string,
    file: {
      name: string,
      extension: string,
    },
    info: NormalizedInfoType,
  },
} | {
  error: string,
  data: null,
} | {
  error: null,
  data: {
    hash: string,
    file: {
      name: string,
      extension: string,
    },
    info: null,
  },
}

const getNormalizeResult = (data: Record<string, any>): NormalizedInfoType => {
  if (!data || !data.elements || !data.elements[0] || !data.elements[0].elements) {
    throw new Error('Неверный формат объекта data');
  }
  const { elements } = data.elements[0];
  const result: Record<string, any> = {};
  elements.forEach((element: any) => {
    const { name } = element;
    const value = element.elements && element.elements[0] && element.elements[0].text;
    if (name && value !== undefined) {
      result[name] = value;
    }
  });
  return result as NormalizedInfoType;
};

const uploadFileToS3 = async (files: File[], fileUploadCallback?: (file: File, result?: { error: string | null, info: NormalizedInfoType | null }) => void): Promise<S3InfoType[]> => {
  const uploads = files.map(async (file) => {
    const extension = extensionByContentType(file.type, 'txt');
    const fileHash = await fileMd5(file);
    const uploader = await network.request<UploaderResultType>('/get-uploader').query({
      file_name: file.name,
      file_hash: fileHash,
      extension,
    }).get();

    if ((uploader.errors || [])?.length > 0 || !uploader.data) {
      fileUploadCallback?.(file, { error: 'Failed to upload file', info: null });
      return {
        data: null,
        error: 'Failed to upload file',
      };
    }

    if (uploader.data?.message?.toLowerCase?.() === 'uploaded') {
      fileUploadCallback?.(file, { error: null, info: null });
      return {
        data: {
          file: {
            name: file.name,
            extension,
          },
          hash: fileHash,
          info: null,
        },
        error: null,
      };
    }

    const formData = new FormData();
    Object.entries(uploader.data.fields).forEach(([key, data]) => {
      formData.append(key, data);
    });
    formData.append('success_action_status', '201');
    formData.append('file', file);

    try {
      const uploadResponse = await fetch(`${uploader.data.url}`, {
        method: 'POST',
        body: formData,
      });
      if (!uploadResponse.ok) {
        fileUploadCallback?.(file, { error: 'Failed to upload file', info: null });
        return {
          data: null,
          error: 'Failed to upload file',
        };
      }
      const xmlString = await uploadResponse.text();
      const info = getNormalizeResult(convert.xml2js(xmlString));
      fileUploadCallback?.(file, { error: null, info });

      return {
        data: {
          file: {
            name: file.name,
            extension,
          },
          hash: fileHash,
          info,
        },
        error: null,
      };
    } catch (error) {
      fileUploadCallback?.(file, { error: 'Failed to upload file', info: null });
      return {
        data: null,
        error: 'Failed to upload file',
      };
    }
  });

  return Promise.all(uploads);
};

export default uploadFileToS3;
