import {
  memo, useCallback, useMemo, useState,
} from 'react';
import {
  type GestureResponderEvent, type LayoutChangeEvent,
  type StyleProp, type ViewStyle,
} from 'react-native';

import { useResponsive } from 'hooks';

import { controller as modal } from 'components/Modal2';
import CardContextMenu from 'components/CardContextMenu';

import NoteCard from 'components/Card/variants/NoteCard';

type NoteControllerProps = {
  id?: number,
  style?: StyleProp<ViewStyle>
  radius?: number,
  hasMenuRemove?: boolean
  onLayout?: ((event: LayoutChangeEvent, context: { cardId: number }) => void) | undefined,
  onOptionsPress?: (event: GestureResponderEvent, context: { cardId: number }) => void,
}

const NoteController = (props: NoteControllerProps) => {
  const {
    style,
    radius,
    id,
    hasMenuRemove,
    onLayout,
    onOptionsPress,
  } = props;

  const responsive = useResponsive();

  const [isContextOpen, setContextOpen] = useState<boolean>(false);

  const contextMenuOptions = useMemo(() => ({
    hasRemove: hasMenuRemove,
  }), [
    hasMenuRemove,
  ]);

  const handleOptionsPress = useCallback((event: GestureResponderEvent) => {
    if (!id) {
      return;
    }
    if (onOptionsPress) {
      onOptionsPress(event, { cardId: id });
      return;
    }
    if (responsive.is.mobileL) {
      modal.menu.cardActions.open({
        callingTarget: event.currentTarget,
        resource: 'content',
        resourceId: id,
        options: contextMenuOptions,
      });
    } else {
      setContextOpen(true);
    }
  }, [id, onOptionsPress, responsive.is.mobileL, contextMenuOptions]);

  const handleOptionsClose = useCallback(() => {
    setContextOpen(false);
  }, []);

  return (
    <NoteCard
      style={style}
      radius={radius}
      id={id}
      OverlayComponent={(
        <>
          {!responsive.is.mobileL && id && (
            <CardContextMenu
              resourceType="note"
              resourceId={id}
              isOpen={isContextOpen}
              hasRemove={contextMenuOptions.hasRemove}
              onActionPress={handleOptionsClose}
              onOutsidePress={handleOptionsClose}
            />
          )}
        </>
      )}
      onLayout={onLayout}
      onOptionsPress={handleOptionsPress}
    />
  );
};

export default memo(NoteController);
