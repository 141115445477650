import type { ResultLegacy, TopicType } from 'app/entities';
import request from 'services/api/request';

export default async (query: string, directionId?: number | null, pageSize = 50, pageNum = 0): Promise<ResultLegacy<TopicType[]>> => {
  if (!query) {
    return {
      data: [],
      error: null,
    };
  }

  const params = {
    q: query,
    pageSize,
    pageNum,
  };

  if (params && directionId) {
    // @ts-ignore
    params.directionId = directionId;
  }

  const { data, error } = await request.get<TopicType[]>('/skills/search', { query: params });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
