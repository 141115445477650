import {
  type CSSProperties,
  memo, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet, TouchableOpacity, Platform,
} from 'react-native';
import Typography from '@mui/joy/Typography';

import { unit, guard } from 'utils';
import { useColorScheme, useThemeColor } from 'hooks';
import { useMaterialInfo } from 'hooks/material';

import { Text, View } from 'components/Themed';
import WebLink from 'components/WebLink';
import Picture from 'components/Picture';
import Info from 'components/Info';
import Tags from 'components/Tags';
import Icon from 'ui/Icon';

import AuthorBlock from '../../../elements/AuthorBlock';
import FooterBlock from '../../../elements/FooterBlock';
import HeaderToolbar from '../../../elements/HeaderToolbar';

import { useDescriptionCrop } from '../../../hooks';

import {
  useTagsCrop,
  useAuthor,
} from '../hooks';

import type { MaterialLayoutProps } from '../types';

const defaultProps = {
  hasToolbar: true,
  hasAuthor: true,
  hasDescription: true as MaterialLayoutProps['hasDescription'],
  hasInfo: true,
  hasTags: true,
  hasComments: true,
  hasFooterQueue: false,
};

const RegularLayout = (props: MaterialLayoutProps & typeof defaultProps) => {
  const {
    data,
    radius,
    hasMarkByIKI,
    hasToolbar,
    hasAuthor,
    hasDescription,
    hasInfo,
    hasTags,
    type,
    hasComments,
    hasWebLink,
    hasFooterQueue,
    descriptionLimit,
    tagsLimit,
    descriptionSize,
    AuthorComponent,
    ExtraComponent,
    OverlayComponent,
    isHoverMode,
    isTouchMode,
    onAuthorPress,
    onBodyPress,
    onOptionsPress,
    onSourcePress,
    onCommentsPress,
    onBookmarkPress,
  } = props;

  const theme = useColorScheme();
  const borderColor = useThemeColor({ dark: '#333333', light: '#ffffff' });

  const description = useDescriptionCrop(data?.description, descriptionLimit, ' ');
  const tags = useTagsCrop(data?.tags, tagsLimit);
  const info = useMaterialInfo(data, hasMarkByIKI);
  const authorData = useAuthor(data?.authors?.[0]);

  const panelStyle = useMemo((): StyleProp<ViewStyle> => [
    styles.panel,
    !!data?.image && styles.panelWithPicture,
    {
      borderColor,
      borderRadius: unit(6),
    },
  ], [borderColor]);

  const toolbarStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.toolbar),
      opacity: isHoverMode ? 1 : 0,
    };
    if (guard.isWebStyleProp(result)) {
      result.transition = 'opacity 300ms ease';
    }
    return result;
  }, [isHoverMode, isTouchMode]);

  const containerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> | CSSProperties = ({
      ...StyleSheet.flatten(styles.RegularLayout),
    });
    if (radius) {
      result.borderRadius = radius;
    }
    if (guard.isWebStyleProp(result) && theme === 'light') {
      result.boxShadow = '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.15)';
    }
    return result as StyleProp<ViewStyle>;
  }, [theme, radius]);

  const hasDescriptionIfEmpty = hasDescription === 'if empty'
    && (!!data?.description || !!data?.gptSummary || !!data?.summary)
    && !data?.image;

  if (!data) {
    return (
      <View style={styles.wireframe} lightColor="#ffffff50" darkColor="#ffffff20" />
    );
  }

  return (
    <TouchableOpacity
      style={containerStyle}
      onPress={onBodyPress}
      activeOpacity={guard.isLink(data?.originalUrl) ? 0.8 : 1}
    >
      {!!OverlayComponent && (
        <View pointerEvents="none" style={styles.overlay}>{OverlayComponent}</View>
      )}
      <WebLink href={data?.internalUrl} disabled={!hasWebLink}>
        {!!data?.image && (
          <Picture source={data?.image} size="large" hasBackground style={[styles.poster, { borderColor }]} radius={5}>
            {Platform.OS === 'web' && theme === 'light' && (
              <div style={{ flex: 1, background: 'linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.03) 100%)' }} />
            )}
            {Platform.OS === 'web' && theme === 'dark' && (
              <div style={{ flex: 1, background: 'rgba(255,255,255,0.05)' }} />
            )}
          </Picture>
        )}
        <View style={panelStyle} darkColor="transparent" lightColor="#ffffff">
          {hasAuthor && !!authorData && (
            <AuthorBlock onPress={onAuthorPress} Component={AuthorComponent} data={authorData} style={{ marginBottom: 11 }} />
          )}
          {data.private && !data.title && (
            <Icon size="sm" marginRight={1} weight="duotone" name="lock" />
          )}
          {!!data?.title && (
          <Typography fontSize={22} fontWeight="bold" style={{ marginBottom: unit(11) }}>
            {data.private && (
              <Icon size="sm" marginRight={1} weight="duotone" name="lock" />
            )}
            {data?.title?.trim?.()}
          </Typography>
          )}
          {hasDescription === true && (!!data?.description) && (
            <Typography color="neutral" fontSize={descriptionSize} mb={1.5}>{description}</Typography>
          )}
          {hasDescriptionIfEmpty && (
            <Typography color="neutral" mb={1.5} fontSize={16} display="-webkit-box" overflow="hidden" sx={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: 10 }}>
              {data?.description || data.gptSummary || data.summary}
            </Typography>
          )}
          {hasInfo && !!info && (
            <Info list={info} style={{ marginBottom: 10 }} fontSize={13} />
          )}
          {hasTags && tags?.length > 0 && (
            <Tags data={data} tagsLimit={tagsLimit} sx={{ marginBottom: unit(10) }} />
          )}
          {!!ExtraComponent && ExtraComponent}
          {(isTouchMode || hasComments || hasFooterQueue) && (
            <FooterBlock
              style={styles.footer}
              data={data}
              hasOptions={isTouchMode}
              hasQueue={hasFooterQueue}
              MainComponent={hasComments ? undefined : false}
              onOptionsPress={onOptionsPress}
              onCommentsPress={onCommentsPress}
              onBookmarkPress={onBookmarkPress}
            />
          )}
        </View>
      </WebLink>
      {hasToolbar && !!data && !isTouchMode && (
        <HeaderToolbar
          style={toolbarStyle}
          sourceName={data?.source?.name}
          align="right"
          hasOptions={type === 'material'}
          hasSource={guard.isLink(data?.originalUrl) && !isTouchMode}
          onOptionsPress={onOptionsPress}
          onSourcePress={onSourcePress}
        />
      )}
    </TouchableOpacity>
  );
};

RegularLayout.defaultProps = defaultProps;

const styles = StyleSheet.create({
  RegularLayout: {
    position: 'relative',
    zIndex: Platform.OS === 'web' ? 'unset' as unknown as number : 0,
    borderRadius: unit(6),
  },
  wireframe: {
    width: '100%',
    aspectRatio: 6 / 7,
    borderRadius: unit(6),
  },
  overlay: {
    zIndex: 10,
  },
  panel: {
    borderWidth: 1,
    borderRadius: unit(6),
    paddingHorizontal: unit(20),
    paddingTop: unit(20),
    paddingBottom: unit(10),
    zIndex: 1,
  },
  panelWithPicture: {
    borderTopWidth: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingTop: unit(22),
  },
  poster: {
    marginBottom: unit(-6),
    overflow: 'hidden',
    zIndex: 2,
  },
  title: {
    marginBottom: unit(11),
  },
  description: {
    marginBottom: unit(10),
  },
  footer: {
    marginTop: unit(-2),
    marginBottom: unit(10),
  },
  toolbar: {
    position: 'absolute',
    top: unit(12),
    right: unit(16),
    left: unit(16),
    zIndex: 2,
  },
});

export default memo(RegularLayout);
