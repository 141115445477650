import {
  type ReactElement,
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import {
  type LayoutChangeEvent, type NativeSyntheticEvent, type NativeScrollEvent,
  StyleSheet,
} from 'react-native';
import { useStore, useSelector } from 'react-redux';

import { useFocusEffect } from 'navigation/hooks';

import * as api from 'services/api';

import { unit } from 'utils';
import { useResponsive, useListData, useListLayout } from 'hooks';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';
import * as userStore from 'store/nodes/user';
import * as interactionStore from 'store/nodes/interaction';

import AdaptivePage from 'components/AdaptivePage';
import { Text, View } from 'components/Themed';
import Lottie from 'components/Lottie';
import List from 'components/List';
import Card from 'components/Card';
import { useScrollMethods } from 'components/Scroller';

import Watched from './elements/Watched';
import TopBar from './elements/TopBar';

const Feed = (): ReactElement => {
  const itemsRect = useRef<Record<number, LayoutChangeEvent['nativeEvent']['layout']>>({});

  const store = useStore();
  const responsive = useResponsive();
  const dispatcher = useDispatcher();
  const { setScrollValue, getScrollValue, scrollTo } = useScrollMethods();

  const { listLayout } = useListLayout('home.list.mode');

  const userProfile = useSelector(userStore.selectors.getMy);
  const list = useSelector(contentStore.selectors.list);
  const listMeta = useSelector(contentStore.selectors.listMeta);

  const listData = useListData(list.sequence, listMeta, 12);

  useEffect(() => {
    if (listMeta.firstPage.isLoading === true) {
      setScrollValue('Feed', 0);
      scrollTo(0);
    }
  }, [listMeta.firstPage.isLoading]);

  useFocusEffect(useCallback(() => {
    const { top } = getScrollValue('Feed');
    setTimeout(() => {
      // @todo реализовать совместимую со списками версию (для devices)
      scrollTo(top);
    }, 10);
  }, []));

  const handleRefresh = useCallback(() => {
    dispatcher.content.loadNextPage(true);
  }, []);

  const handleNextPage = useCallback(() => {
    if (!listMeta.isConsistent) {
      return;
    }
    dispatcher.content.loadNextPage();
  }, [listMeta.isConsistent]);

  const handleItemShow = useCallback(() => {
    if (!api.credentials.hasSession()) {
      return;
    }
    const { top, dimension } = getScrollValue('Feed');
    const state = store.getState();
    Object.entries(itemsRect.current).forEach(([id, rect]) => {
      const resourceId = Number(id);
      const data = contentStore.selectors.dataById(resourceId)(state);
      const isLoading = interactionStore.selectors.isLoading('shown', 'content', resourceId)(state);
      const isInScreen = rect.y + (top * -1) < dimension.window.height - rect.height;
      if (isInScreen && !isLoading && !data?.isShown) {
        dispatcher.interaction.shown('content', resourceId);
      }
    });
  }, [store, getScrollValue]);

  const handleScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    // @todo реализовать совместимую со списками версию (для devices)
    const { y } = event.nativeEvent.contentOffset;
    if (window.location.pathname.includes('/content/feed')) {
      setScrollValue('Feed', y);
    }
    handleItemShow();
  }, [handleItemShow]);

  const handleItemLayout = useCallback((event: LayoutChangeEvent, context: { cardId: number }) => {
    const { nativeEvent } = event;
    itemsRect.current[context.cardId] = {
      x: nativeEvent.layout.x || (nativeEvent.layout as any)?.left || 0,
      y: nativeEvent.layout.y || (nativeEvent.layout as any)?.top || 0,
      width: nativeEvent.layout.width,
      height: nativeEvent.layout.height,
    };
    handleItemShow();
  }, [handleItemShow]);

  const renderItem = useCallback((args: { item: any, i: number }) => {
    return (
      <Card
        id={args.item.id}
        type="material"
        style={styles.listItem}
        tagsLimit={10}
        hasInfo={false}
        hasWebLink
        hasDescription="if empty"
        hasFooterQueue
        hasMenuQueue
        hasMenuComplete
        hasMenuToCollection
        hasMenuShare
        hasMenuDislike
        hasMenuPlaySummary
        hasMenuTLDR
        hasMenuShowRelated
        onLayout={handleItemLayout}
        materialForceLayout={!responsive.is.tabletL && listLayout.isRow ? 'horizontal' : undefined}
      />
    );
  }, [handleItemLayout, responsive.is.tabletL, listLayout.isRow]);

  const pagePaddingHorizontalScheme = useMemo(() => ({
    320: 16,
  }), []);

  // const wireframes = new Array(10).fill(null).map((_, key) => ({ id: `w-${key + 1}`, type: 'wireframe' }));

  return (
    <AdaptivePage
      desktopHeaderLeftPanel="ask"
      desktopHeaderMiddlePanel={false}
      bodyMaxWidth={1280}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
    >
      <TopBar />
      {(!userProfile || userProfile?.isRegisterCompleted) && !listData.isEmpty && (
        <List
          type="masonry"
          componentStyle={styles.listComponent}
          contentContainerStyle={styles.listContentContainer}
          scrollEmitter="web-page"
          data={listData.sequence}
          keyExtractor={(item) => `${item.id}`}
          renderItem={renderItem}
          columnsScheme={listLayout.isRow ? '320:1' : '320:1;560:2;960:3'}
          // @ts-ignore
          showsVerticalScrollIndicator={false}
          hasNextPage={list.paginationInfo.hasNext}
          onScroll={handleScroll}
          onEndReached={handleNextPage}
          onRefresh={handleRefresh}
          ListFooterComponent={(
            <View style={{ height: unit(108) }} />
          )}
        />
      )}
      {!!userProfile && !userProfile?.isRegisterCompleted && !listData.isEmpty && (
        <View style={styles.preparing}>
          <Text size={16} weight="semi" lightColor="#000000" darkColor="#ffffff">Preparing</Text>
          <Text size={12} weight="regular" align="center" lightColor="#888888" darkColor="#9A99A2" style={styles.preparingText}>
            Please wait, we are preparing your feed. It doesn&apos;t take a lot of time.
          </Text>
          <View style={styles.preparingLoader}>
            <Lottie name="Loading" width={64} loop />
          </View>
        </View>
      )}
      {listData.isEmpty && (
        <View style={styles.empty}>
          <Text size={16} weight="semi" lightColor="#000000" darkColor="#ffffff">No results</Text>
          <Text size={12} weight="regular" align="center" lightColor="#888888" darkColor="#9A99A2" style={styles.emptyText}>
            Your feed is empty. Try to look later.
          </Text>
        </View>
      )}
      {!!userProfile && userProfile?.isRegisterCompleted && (
        <Watched />
      )}
    </AdaptivePage>
  );
};

const styles = StyleSheet.create({
  listComponent: {
    marginHorizontal: unit(-10),
  },
  listContentContainer: {
    alignSelf: 'stretch',
  },
  listItem: {
    marginHorizontal: unit(10),
    marginBottom: unit(20),
  },
  empty: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    maxWidth: unit(230),
    marginTop: unit(8),
  },
  preparing: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  preparingText: {
    maxWidth: unit(230),
    marginTop: unit(8),
  },
  preparingLoader: {
    marginTop: unit(-4),
  },
});

export default Feed;
