import {
  memo, useCallback, useMemo, type MouseEvent,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  Platform, StyleSheet,
} from 'react-native';
import { createUseStyles } from 'react-jss';
import Box from '@mui/joy/Box';

import { useThemeColor } from 'hooks';
import { styleSheetToCss, unit } from 'utils';
import { Text } from 'components/Themed';

type DescriptionBlockProps = {
  style?: StyleProp<ViewStyle>,
  text: string,
  ellipsizeMode?: 'tail' | 'clip';
  numberOfLines?: number,
};

const DescriptionBlock = (props: DescriptionBlockProps) => {
  const {
    style,
    ellipsizeMode,
    numberOfLines,
  } = props;

  const color = useThemeColor({ light: '#888888', dark: '#9A99A2' });
  const classes = useStyles({ descriptionColor: color || 'transparent' });

  const text = useMemo(() => {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    return props.text
      ?.replace(
        urlRegex,
        '<a href="$1" target="_blank" rel="noopener noreferrer">link</a>',
      )
      .replace(/\s+/g, ' ')
      .trim();
  }, [props.text]);

  const isHtml = useMemo(() => /<[A-z]+[^>]*>.*?<\/[A-z]+>/.test(text), [text]);

  const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).tagName === 'A') {
      event.stopPropagation();
    }
  }, []);

  const webStyles = useMemo(() => {
    if (Platform.OS !== 'web') {
      return undefined;
    }
    const result = styleSheetToCss(StyleSheet.flatten(style)) || {};
    if (ellipsizeMode === 'tail') {
      result.textOverflow = 'ellipsis';
    }
    if (ellipsizeMode === 'clip') {
      result.textOverflow = 'clip';
    }
    if (ellipsizeMode) {
      result.display = '-webkit-box';
      result.lineClamp = numberOfLines;
      result.WebkitLineClamp = numberOfLines;
      result.textOverflow = 'ellipsis';
      result.boxOrient = 'vertical';
      result.WebkitBoxOrient = 'vertical';
      result.overflow = 'hidden';
    }
    return result;
  }, [style, ellipsizeMode, numberOfLines]);

  if (Platform.OS === 'web' && isHtml) {
    return (
      <Box
        dangerouslySetInnerHTML={{ __html: text }}
        className={classes.description}
        style={webStyles}
        sx={{
          '& a': {
            fontSize: 'var(--Typography-fontSize, var(--joy-fontSize-md, 1rem))',
            lineHeight: 'var(--joy-lineHeight-md, 1.5)',
            color: 'inherit',
            '&:hover': {
              textDecoration: 'none',
            },
          },
        }}
        onClick={handleClick}
      />
    );
  }

  return (
    <Text
      size={15}
      lightColor="#888888"
      darkColor="#9A99A2"
      style={style}
      ellipsizeMode={ellipsizeMode}
      numberOfLines={numberOfLines}
    >
      {text}
    </Text>
  );
};

const useStyles = createUseStyles<'description', { descriptionColor: string }>({
  description: {
    '&': {
      lineHeight: unit(20),
      fontSize: unit(15),
      color: (props) => props.descriptionColor,
    },
    '& em': {
      fontStyle: 'normal',
      fontWeight: 600,
    },
  },
});

export default memo(DescriptionBlock);
