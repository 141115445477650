import connect from '../../utils/saga/connect';
import * as loadProfile from './sagas/loadProfile';
import * as subscription from './sagas/subscription';
import * as handleLibraryUpdate from './sagas/handleLibraryUpdate';

export default connect([
  loadProfile,
  subscription,
  handleLibraryUpdate,
]);
