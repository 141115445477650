import {
  takeLatest, put, cancel, take,
} from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import * as authStore from 'store/nodes/auth';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: actions.doSignUp.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.doSignUp>) {
  const { email, password } = action.payload;

  const result = yield* call(() => api.resource.auth.register(email, password));
  if (result?.error || !result?.data) {
    Alert.error(result?.error?.message || 'Server error #7');
    yield put(actions.doSignUpDone(result?.error));
    yield cancel(); return;
  }

  yield put(actions.doSignUpDone());

  yield put(actions.doSignIn(email, password));
}
