import {
  Card, Typography, Box, type BoxProps,
} from '@mui/joy';
import React, { memo } from 'react';
import { useThemeColor } from 'hooks';

type EmptyPanelProps = {
  text: string
} & BoxProps

const EmptyPanel = (props: EmptyPanelProps) => {
  const emptyBackground = useThemeColor({ dark: '#232323', light: '#efefef' });

  const { text, ...rest } = props;

  return (
    <Box {...rest}>
      <Card
        color="neutral"
        sx={{
          backgroundColor: emptyBackground,
          minHeight: 128,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: (theme) => theme.radius.xl,
        }}
      >
        <Typography fontSize={17} fontWeight="bolder">{text}</Typography>
      </Card>
    </Box>

  );
};

export default memo(EmptyPanel);
