import { memo } from 'react';
import { Box } from '@mui/joy';

import UpgradeToProLink from 'components/UpgradeToProLink';

type LimitProps = {
}

const Limit = (props: LimitProps) => (
  <Box component="div" sx={{ '& > p': { m: 0 } }}>
    <span>You&apos;ve run out of your limit for co-pilot requests.</span>
    {' '}
    <UpgradeToProLink />
  </Box>
);

export default memo(Limit);
