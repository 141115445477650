import {
  type SagaReturnType, takeEvery, put, cancel,
} from 'redux-saga/effects';
import { intersection } from 'lodash';

import { call } from 'store/utils/saga/effects';
import network from 'lib/network';

import { actions } from '../slice';

export const config = {
  action: actions.clear.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.clear>) {
  if (!action?.payload?.resourceType) {
    yield cancel(); return;
  }
  const { resourceType } = action.payload;
  const types = !Array.isArray(resourceType) ? [resourceType] : resourceType;

  if (intersection(['web', 'global-fulltext', 'library-fulltext'], types).length > 0) {
    yield* call(() => Promise.all([
      network.request<any>('/copilot/chat/type/library', { baseUrl: process.env.COPILOT_DOMAIN }).post(),
      network.request<any>('/copilot/chat/type/web', { baseUrl: process.env.COPILOT_DOMAIN }).post(),
      network.request<any>('/copilot/chat/type/fulltext', { baseUrl: process.env.COPILOT_DOMAIN }).post(),
    ]));
  }
}
