import { memo } from 'react';
import { Box, useTheme } from '@mui/joy';
import { MacScrollbar } from 'mac-scrollbar';

import Material from 'widgets/RelatedMaterials/components/Material';
import { useStorageString } from 'lib/storage2';

type NotesProps = {
  resourceIds: number[],
}

const RelatedMaterials = (props: NotesProps) => {
  const {
    resourceIds,
  } = props;

  const theme = useTheme();

  if (!resourceIds) {
    return null;
  }

  return (
    <MacScrollbar style={{ position: 'relative' }} skin={theme.palette.colorScheme}>
      <Box
        position="sticky"
        top={0}
        left={2}
        right={0}
        height={4}
        ml={1}
        zIndex={2}
        bgcolor={theme.palette.background.body}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        pt={2}
        px={3}
        pb={2}
        sx={{
          '@media (max-width: 767px)': {
            px: 0.5,
          },
        }}
      >
        {resourceIds.map((id) => (
          <Material key={id} materialId={id} />
        ))}
      </Box>
    </MacScrollbar>
  );
};

export default memo(RelatedMaterials);
