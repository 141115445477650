import {
  type SagaReturnType,
  put, cancel, takeLatest, delay,
} from 'redux-saga/effects';
import * as uuid from 'uuid';

import network from 'lib/network';
import { call } from 'store/utils/saga/effects';

import { actions } from '../slice';

export const config = {
  action: [actions.sendQuestion.type, actions.sendCommand.type],
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.sendQuestion | typeof actions.sendCommand>) {
  const {
    reloadId,
    resourceId = null,
    text,
    resourceType,
  } = action.payload;

  if (!text) {
    yield cancel(); return;
  }

  let requestId = uuid.v4();

  if (reloadId) {
    requestId = reloadId;
  } else {
    yield put(actions.addItem({
      requestId,
      resourceId,
      resourceType,
      label: action.type === actions.sendCommand.type ? (action.payload.label || null) : null,
      text,
      type: 'question',
    }));
    yield delay(10);
  }
  yield put(actions.answerStart({
    requestId,
    resourceId,
    resourceType,
  }));

  const copilot = {
    'material': {
      name: 'material',
      body: { request_id: requestId, content_id: resourceId, query: text },
    },
    'collection': {
      name: 'collection',
      body: { request_id: requestId, collection_id: resourceId, query: text },
    },
    'library': {
      name: 'library',
      body: { request_id: requestId, query: text },
    },
    'web': {
      name: 'web/new',
      body: { request_id: requestId, query: text },
    },
    'library-fulltext': {
      name: 'library',
      body: { request_id: requestId, query: text },
    },
    'global-fulltext': {
      name: 'fulltext/new',
      body: { request_id: requestId, query: text },
    },
  }[resourceType];

  if (!copilot) {
    yield put(actions.answerAddChunk({ requestId, text: 'Unknown copilot params' }));
    yield delay(10);
    yield put(actions.answerEnd({ requestId }));
    yield cancel(); return;
  }

  const { errors, data } = yield* call(() => (
    network.request<any>(
      `copilot/chat/${copilot.name}`,
      { baseUrl: process.env.COPILOT_DOMAIN },
    ).body(copilot.body).post()
  ));

  const [error] = errors || [];
  if (error && error.message === 'Please register to continue chatting') {
    yield put(actions.answerError({ requestId, type: 'unauthorized' }));
    yield cancel(); return;
  }

  if (Array.isArray(errors) && errors.length > 0) {
    yield put(actions.answerAddChunk({ requestId, text: errors[0]?.message || 'Unknown error' }));
    yield delay(10);
    yield put(actions.answerEnd({ requestId }));
  }
}
