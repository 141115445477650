import {
  type ReactElement,
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import {
  type StyleProp, type ViewStyle, type GestureResponderEvent,
  StyleSheet, TouchableOpacity,
} from 'react-native';
import moment from 'moment-timezone';

import { useThemeColor } from 'hooks';
import { unit } from 'utils';

import * as userProfile from 'store/nodes/user';

import { Text, View } from 'components/Themed';
import Icon from 'components/LegacyIcon';
import Picture from 'components/Picture';
import Stars from 'components/Stars';

interface CommentProps {
  id: number | string,
  preventReply?: boolean,
  style?: StyleProp<ViewStyle>,
  onOptions?: (event: GestureResponderEvent, data: { id: number | string, isMy: boolean }) => void,
  onTapReply?: (data: { id: number | string, parentId: number }) => void,
}

const Comment = (props: CommentProps): ReactElement => {
  const {
    style,
    id,
    preventReply = false,
    onOptions,
    onTapReply,
  } = props;
  const timer = useRef<any>();

  const replyBorderColor = useThemeColor({ light: '#497CFF', dark: '#497CFF' });

  const data = useSelector((state: DefaultRootState) => state.comment.data[id]);
  const isReplyLoaded = useSelector((state: DefaultRootState) => (data && data?.replyToId && state.comment.data[data.replyToId]));
  const userId = useSelector(userProfile.selectors.getIdByLogin('my'));
  const [tick, setTick] = useState<number>();

  const userName = data?.user?.name || 'Guest';
  const text = data?.text || '';

  const handleOptions = useCallback((event: any) => {
    const isMy = data?.user?.id === userId;
    onOptions?.(event, {
      id,
      isMy,
    });
  }, [data?.user?.id, id, onOptions, userId]);

  const handleTapReply = useCallback(() => {
    if (!data?.replyToId) {
      return;
    }
    onTapReply?.({
      id,
      parentId: data.replyToId,
    });
  }, [data?.replyToId, id, onTapReply]);

  useEffect(() => {
    setTick(Date.now());
    timer.current = setInterval(() => {
      setTick(Date.now());
    }, 5000);
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  const createdAt = useMemo(() => {
    if (!tick) {
      return moment().fromNow();
    }
    const diff = moment().diff(moment(data?.createdAt));
    if (diff <= 1000 * 60 * 3) {
      return moment(data?.createdAt).fromNow();
    }
    if (timer.current) {
      clearInterval(timer.current);
    }
    if (moment().isSame(moment(data?.createdAt), 'day')) {
      return moment(data?.createdAt).format('h:mm:ss');
    }
    if (moment().isSame(moment(data?.createdAt), 'month')) {
      return moment(data?.createdAt).format('Do h:mm');
    }
    return moment(data?.createdAt).format('MMMM Do h:mm');
  }, [tick, data?.createdAt]);

  const isEdited = useMemo(() => data?.createdAt !== data?.updatedAt, [data?.createdAt, data?.updatedAt]);

  const styleFinal = useMemo(() => {
    return {
      ...StyleSheet.flatten(styles.Comment),
      ...StyleSheet.flatten(style),
    };
  }, [style]);

  const renderWireframe = useMemo(() => (
    <>
      <View style={styles.body}>
        <View style={styles.userPhoto} lightColor="#d2d2d2" darkColor="#232326" />
        <View style={styles.wireframeHeader} lightColor="#d2d2d2" darkColor="#232326" />
      </View>
      <View style={styles.wireframeText} lightColor="#d2d2d2" darkColor="#232326" />
      <View style={styles.wireframeText2} lightColor="#d2d2d2" darkColor="#232326" />
    </>
  ), []);

  if (!data) {
    return (
      <View style={styleFinal}>
        {renderWireframe}
      </View>
    );
  }

  return (
    <View style={styleFinal}>
      {!!data?.replyToId && isReplyLoaded && !preventReply && (
        <TouchableOpacity style={[styles.replyTo, { borderLeftColor: replyBorderColor }]} onPress={handleTapReply} activeOpacity={1}>
          <Comment id={data.replyToId} preventReply />
        </TouchableOpacity>
      )}
      <View style={styles.body}>
        {!!data?.user?.photo && <Picture source={data?.user?.photo} size="small" style={styles.userPhoto} aspectRatio={1} />}
        <Text size={12} weight="medium">{userName}</Text>
        {!!createdAt && <Text size={12} lightColor="#929292" darkColor="#77767E" style={styles.createdAt}>{createdAt}</Text>}
        {isEdited && <Icon style={styles.editIcon} name="EditOutline28" size={14} lightColor="#929292" darkColor="#77767E" />}
        <View style={styles.separator} />
        {!!onOptions && (
          <TouchableOpacity onPress={handleOptions} style={styles.optionButton} activeOpacity={0.8}>
            <Icon name="Dots" size={32} />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.itemText}>
        <Text size={13}>{text}</Text>
      </View>
      {!!data?.rate && (
        <Stars style={styles.rate} size={12} value={Number(data.rate)} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  Comment: {
    alignSelf: 'stretch',
  },
  body: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: unit(40),
  },
  separator: {
    flex: 1,
  },
  editIcon: {
    paddingLeft: unit(8),
    marginBottom: unit(2),
  },
  replyTo: {
    borderLeftWidth: unit(4),
    paddingLeft: unit(8),
  },
  optionsBtn: {
  },
  userPhoto: {
    width: unit(20),
    height: unit(20),
    borderRadius: unit(20),
    marginRight: unit(8),
  },
  optionButton: {
    marginRight: unit(-5),
    alignItems: 'center',
    justifyContent: 'center',
    height: unit(40),
    width: unit(40),
  },
  itemText: {
    marginTop: unit(-4),
  },
  createdAt: {
    marginLeft: unit(8),
  },
  wireframeHeader: {
    height: unit(12),
    width: '40%',
  },
  wireframeText: {
    height: unit(14),
  },
  wireframeText2: {
    height: unit(14),
    width: '40%',
    marginTop: unit(12),
  },
  rate: {
    marginTop: unit(8),
  },
});

export default memo(Comment);
