import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import type {
  CommentType,
} from 'app/entities';
import type { CommentStore } from './types';
import * as actions from './actions';

const initialState: CommentStore = {
  data: {},
  list: {
    sequence: [],
    map: {},
    paginationInfo: {
      hasNext: false,
      nextCursor: null,
    },
  },
  input: {
    text: '',
    currentEditKey: null,
    currentReplyKey: null,
  },
  edit: {},
  delete: {},
  reply: {},
  meta: {
    loadingNewMap: {},
    loadingEditMap: {},
    loadingDeleteMap: {},
    parent: {
      resource: null,
      resourceId: null,
    },
    isFirstPageLoading: false,
    isFirstPageLoaded: false,
    isNextPageLoading: false,
    isNextPageLoaded: false,
  },
  summary: {
    data: {},
    meta: {
      loadingMap: {},
    },
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.addPage, (state, action) => {
    if (!action.payload.items) {
      return;
    }
    if (action.payload.reload) {
      state.list.sequence = [];
      state.list.map = {};
    }
    action.payload.items.forEach((item) => {
      state.data[item.id] = item;
      if (item.id in state.list.map) {
        return;
      }
      state.list.sequence.push({
        id: item.id,
        isShow: true,
      });
      state.list.map[item.id] = state.list.sequence.length - 1;
    });
    state.list.paginationInfo.nextCursor = action.payload.paginationInfo.nextCursor;
    state.list.paginationInfo.hasNext = action.payload.paginationInfo.hasNext;
  })
  .addCase(actions.setItem, (state, action) => {
    const { data } = action.payload;
    state.data[data.id] = data;
  })
  .addCase(actions.setText, (state, action) => {
    if (state.input.currentEditKey && state.edit[state.input.currentEditKey]) {
      state.edit[state.input.currentEditKey].text = action.payload.text;
    } else {
      state.input.text = action.payload.text;
    }
  })
  .addCase(actions.loadNextPage, (state, action) => {
    const { resource, resourceId, reload } = action.payload;
    state.meta.parent.resource = resource;
    state.meta.parent.resourceId = resourceId;
    if (reload) {
      state.meta.isFirstPageLoading = true;
    } else {
      state.meta.isNextPageLoading = true;
    }
  })
  .addCase(actions.loadNextPageDone, (state, action) => {
    const { reload } = action.payload;
    state.meta.isFirstPageLoading = false;
    state.meta.isNextPageLoading = false;
    if (reload) {
      state.meta.isFirstPageLoaded = true;
    } else {
      state.meta.isNextPageLoaded = true;
    }
  })
  .addCase(actions.replySetId, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    if (state.input.currentEditKey && state.edit[state.input.currentEditKey]) {
      delete state.edit[state.input.currentEditKey];
      state.input.currentEditKey = null;
    }
    if (state.input.currentReplyKey && state.edit[state.input.currentReplyKey]) {
      delete state.reply[state.input.currentReplyKey];
    }
    state.input.currentReplyKey = key;
    state.reply[key] = {
      id,
    };
  })
  .addCase(actions.replyClearId, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    state.input.currentReplyKey = null;
    delete state.reply[key];
  })
  .addCase(actions.newOptimistic, (state, action) => {
    const {
      resource, resourceId, tempId, data,
    } = action.payload;
    const key = `${resource}-${resourceId}-${tempId}`;
    state.meta.loadingNewMap[key] = true;
    let replyToId = null;
    if (state.input.currentReplyKey && state.reply[state.input.currentReplyKey]) {
      replyToId = state.reply[state.input.currentReplyKey].id;
      state.input.currentReplyKey = null;
    }
    state.data[tempId] = {
      ...data,
      replyToId,
      user: {
        id: data.user?.id || 0,
        login: data.user?.login,
        name: data.user?.name || 'Unknown',
        surname: data.user?.surname,
        photo: data.user?.photo || null,
        profession: data.user?.profession,
      },
    };
    state.list.sequence.unshift({
      id: tempId,
      isShow: true,
    });
    state.list.map = {};
    state.list.sequence.forEach((item, position) => {
      state.list.map[item.id] = position;
    });
    state.input.text = '';
  })
  .addCase(actions.newRollback, (state, action) => {
    const { resource, resourceId, tempId } = action.payload;
    const key = `${resource}-${resourceId}-${tempId}`;
    delete state.meta.loadingNewMap[key];
    if (typeof state.list.map[tempId] === 'undefined') {
      return;
    }
    state.list.sequence.splice(state.list.map[tempId], 1);
    delete state.list.map[tempId];
    delete state.data[tempId];
  })
  .addCase(actions.newCommit, (state, action) => {
    const {
      resource, resourceId, tempId, data,
    } = action.payload;
    const key = `${resource}-${resourceId}-${tempId}`;
    delete state.meta.loadingNewMap[key];
    if (typeof state.list.map[tempId] === 'undefined' || !state.data[tempId]) {
      return;
    }
    const updatedData: Omit<CommentType, 'id'> & { id: number | string } = {
      ...state.data[tempId],
      ...data,
      user: {
        ...state.data[tempId].user,
        ...data.user,
      } as CommentType['user'],
    };
    state.data[updatedData.id] = updatedData;
    state.list.map[updatedData.id] = state.list.map[tempId];
    state.list.sequence[state.list.map[tempId]].id = updatedData.id;
    delete state.data[tempId];
    delete state.list.map[tempId];
  })
  .addCase(actions.editStart, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    if (state.input.currentReplyKey && state.reply[state.input.currentReplyKey]) {
      delete state.reply[state.input.currentReplyKey];
      state.input.currentReplyKey = null;
    }
    if (state.input.currentEditKey && state.edit[state.input.currentEditKey]) {
      delete state.edit[state.input.currentEditKey];
    }
    state.input.currentEditKey = key;
    state.edit[key] = {
      id,
      text: state.data[action.payload.id]?.text,
    };
  })
  .addCase(actions.editCancel, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    state.input.currentEditKey = null;
    delete state.edit[key];
  })
  .addCase(actions.editOptimistic, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    state.meta.loadingEditMap[key] = true;
    const { text } = state.edit[key];
    if (!id || !text || !state.edit[key] || !state.data[id]) {
      return;
    }
    state.input.currentEditKey = null;
    state.edit[key] = {
      rollbackId: id,
      rollbackText: state.data[id].text,
    };
    state.data[id].text = text;
    state.data[id].updatedAt = moment().toISOString();
  })
  .addCase(actions.editRollback, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    delete state.meta.loadingEditMap[key];
    const { rollbackId, rollbackText } = state.edit[key];
    if (!rollbackId || !rollbackText || !state.data[rollbackId]) {
      return;
    }
    state.data[rollbackId].text = rollbackText;
    state.data[id].updatedAt = state.data[id].createdAt;
    delete state.edit[key];
  })
  .addCase(actions.editCommit, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    delete state.meta.loadingEditMap[key];
    delete state.edit[key];
  })
  .addCase(actions.deleteOptimistic, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    state.meta.loadingDeleteMap[key] = true;
    if (!state.data[id] || typeof state.list.map[id] === 'undefined') {
      return;
    }
    state.delete[key] = {
      rollbackId: id,
    };
    state.list.sequence[state.list.map[id]].isShow = false;
  })
  .addCase(actions.deleteRollback, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    delete state.meta.loadingDeleteMap[key];
    const { rollbackId } = state.delete[key];
    if (!rollbackId || !state.data[rollbackId] || typeof state.list.map[rollbackId] === 'undefined') {
      return;
    }
    state.list.sequence[state.list.map[rollbackId]].isShow = true;
    delete state.delete[key];
  })
  .addCase(actions.deleteCommit, (state, action) => {
    const { resource, resourceId, id } = action.payload;
    const key = `${resource}-${resourceId}-${id}`;
    delete state.meta.loadingDeleteMap[key];
    const { rollbackId } = state.delete[key];
    if (!rollbackId || !state.data[rollbackId] || typeof state.list.map[rollbackId] === 'undefined') {
      return;
    }
    state.list.sequence.splice(state.list.map[rollbackId], 1);
    delete state.list.map[rollbackId];
    delete state.data[id];
    delete state.delete[key];
    state.list.sequence.forEach((item, pos) => {
      state.list.map[item.id] = pos;
    });
  })
  .addCase(actions.loadSummaryById, (state, action) => {
    const { resource, resourceId } = action.payload;
    state.summary.meta.loadingMap[`${resource}-${resourceId}`] = true;
  })
  .addCase(actions.setSummaryData, (state, action) => {
    const { resource, resourceId, data } = action.payload;
    state.summary.data[`${resource}-${resourceId}`] = data;
  })
  .addCase(actions.loadSummaryByIdDone, (state, action) => {
    const { resource, resourceId } = action.payload;
    delete state.summary.meta.loadingMap[`${resource}-${resourceId}`];
  }));
