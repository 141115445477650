import {
  ArrayQueue, Websocket, WebsocketBuilder, WebsocketEvent,
} from 'websocket-ts';

import EventEmitter from 'lib/EventEmitter';
import credentials from 'services/api/credentials';
import { type EmitterSubscription } from 'lib/EventEmitter/types';

import getAppId from 'utils/getAppId';
import getSessionId from 'utils/getSessionId';

import log from 'store/nodes/socket/model/log';

type EventMap = {
  message: (event: MessageEvent) => void;
  open: () => void;
  close: () => void;
  disconnect: () => void;
  ping: () => void;
};

type OptionsType = {
  pingInMs?: number | undefined;
};

class Socket {
  private readonly emitter = new EventEmitter();

  private socket: Websocket | null = null;

  private reconnectDelayMs = 10;

  private pingInterval: number | null = null;

  private options: OptionsType = {};

  private handleSocketOpen = () => {
    log('socket.handleSocketOpen()');
    this.reconnectDelayMs = 10;
    this.startPingCircle();
    this.emitter.emit('open', {});
  };

  private handleSocketClose = (_: Websocket, event: CloseEvent) => {
    log('socket.handleSocketClose()');
    if (!this.socket) {
      return;
    }
    this.stopPingCircle();
    this.socket.removeEventListener(WebsocketEvent.open, this.handleSocketOpen);
    this.socket.removeEventListener(WebsocketEvent.close, this.handleSocketClose);
    if (event.reason === 'by user') {
      this.emitter.emit('close', {});
      this.socket = null;
    } else {
      this.emitter.emit('disconnect', {});
      this.socket = null;
    }
  };

  private startPingCircle = () => {
    if (!('pingInMs' in this.options) || typeof this.options.pingInMs === 'undefined') {
      return;
    }
    this.stopPingCircle();
    log('socket.startPingCircle()');
    const ping = () => {
      this.socket?.send(JSON.stringify({
        action: 'ping',
        message: 'ping',
        isAuthorized: !!credentials.sessionId(),
        userId: credentials.sessionId() || credentials.getAnonymousId(),
        appId: getAppId(),
        sessionId: getSessionId(),
      }));
      this.emitter.emit('ping', {});
    };
    setTimeout(ping, 10);
    this.pingInterval = setInterval(ping, this.options.pingInMs) as unknown as number;
  };

  private stopPingCircle = () => {
    log('socket.stopPingCircle()');
    if (this.pingInterval === null) {
      return;
    }
    clearInterval(this.pingInterval);
    this.pingInterval = null;
  };

  private handleMessage = (_: Websocket, event: MessageEvent) => {
    this.emitter.emit('message', event);
  };

  private reconnect() {
    setTimeout(() => {
      this.open(this.options);
    }, this.reconnectDelayMs);
    if (this.reconnectDelayMs * 10 <= 1000) {
      this.reconnectDelayMs *= 10;
    }
  }

  public open(options?: OptionsType) {
    log('socket.open()');
    this.options = { ...options };

    const accessToken = credentials.getAccess() || credentials.getAnonymousId();
    if (!accessToken) {
      return false;
    }

    const url = `${process.env.API_WEBSOCKET}?token=${accessToken}&app_id=${getAppId()}&session_id=${getSessionId()}`;

    this.socket = new WebsocketBuilder(url)
      .withBuffer(new ArrayQueue())
      .build();
    this.socket.addEventListener(WebsocketEvent.open, this.handleSocketOpen);
    this.socket.addEventListener(WebsocketEvent.close, this.handleSocketClose);
    this.socket.addEventListener(WebsocketEvent.message, this.handleMessage);
    return true;
  }

  public close() {
    log('socket.close()');
    if (!this.socket) {
      return;
    }
    this.socket.close(1000, 'by user');
  }

  public addListener<T extends keyof EventMap>(type: T, listener: EventMap[T]): EmitterSubscription {
    return this.emitter.addListener(type, listener);
  }
}

export default Socket;
