import {
  cancel, put, type SagaReturnType, takeEvery,
} from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import * as appStore from 'store/nodes/app';

import network from 'lib/network';
import Alert from 'components/Alert';
import { openInNewTab } from 'utils';

import { actions } from '../slice';

export const config = {
  action: [
    actions.openBillingPortal.type,
  ],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof appStore.actions.prepared | typeof actions.loadState>) {
  if (action.type === appStore.actions.prepared.type) {
    yield put(actions.loadState());
    yield cancel(); return;
  }

  const { errors, data } = yield* call(() => (
    network.request<{ billingPortalSessionUrl?: string }>('/payment/billing_portal').body({
      return_url: document.location.href,
    }).post()
  ));

  const { billingPortalSessionUrl } = data || {};

  if ((errors?.length || 0) > 0 || !billingPortalSessionUrl) {
    yield call(() => Alert.error(errors?.[0]?.message || 'Something was wrong. Aty again later.'));
    yield put(actions.openBillingPortalDone());
    yield cancel(); return;
  }

  yield call(() => openInNewTab(billingPortalSessionUrl));
  yield put(actions.openBillingPortalDone());
}
