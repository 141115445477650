import { useMemo } from 'react';

import type {
  CollectionType, MaterialType, NoteType, RagMaterialType,
} from 'app/entities';
import { guard } from 'utils';

export const useTagsCrop = (list?: string[], limit = 0) => useMemo(() => {
  if (!list || !Array.isArray(list) || list.length === 0) {
    return [];
  }
  let result = [...new Set(list)];
  if (limit && limit > 0) {
    result = result.slice(0, limit);
  }
  return result;
}, [list, limit]);

export const usePosterSources = (data?: CollectionType | MaterialType | NoteType | RagMaterialType | null) => useMemo(() => {
  if (guard.isCollection(data)) {
    if (data?.cover) {
      return [data?.cover];
    }
    if (!data?.cover) {
      return data?.contentImages || [];
    }
    return [];
  }
  if (guard.isMaterial(data)) {
    if (data.image) {
      return [data.image.url];
    }
    return [];
  }
  if (guard.isNote(data)) {
    return [];
  }
  return [];
}, [data]);

export const useInfo = (data?: CollectionType | null, hasMarkByIKI = false) => useMemo(() => {
  const result = [];
  if (hasMarkByIKI) {
    result.push('**by-iki**');
  }
  result.push(...[data?.level, data?.duration].filter((item) => !!item) as string[]);
  return result;
}, [data?.level, data?.duration]);

export const useComments = (data?: CollectionType | null) => useMemo(() => ({
  photos: data?.commentMeta?.userPhotos || [],
  count: data?.commentMeta?.commentsCount || 0,
}), [data?.commentMeta?.commentsCount, data?.commentMeta?.userPhotos]);

export const useAuthor = (data?: CollectionType | null) => useMemo(() => {
  return data?.user || null;
}, [data]);

export const useLayout = (
  data?: CollectionType | null,
  defaultLayout?: 'regular' | 'horizontal' | 'wireframe',
  forceLayout?: 'regular' | 'horizontal' | 'wireframe',
): 'regular' | 'horizontal' | 'wireframe' => useMemo(() => {
  if (forceLayout) {
    return forceLayout;
  }
  return defaultLayout || 'regular';
}, [data, defaultLayout, forceLayout]);
