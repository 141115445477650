import {
  type FC, type MouseEvent, memo, useCallback,
} from 'react';
import { Box, Typography } from '@mui/joy';
import { DateTime } from 'luxon';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import { ui } from 'utils';

import Divider from 'components/Divider';

import Panel from './ui/Panel';
import PlanHead from './ui/PlanHead';
import PlanInclude from './ui/PlanInclude';
import PlanFeatures from './ui/PlanFeatures';
import UpgradeButton from './ui/UpgradeButton';

import useGetButtonState from '../../model/useGetButtonState';
import * as selectors from '../../store/selectors';

import type { DirectionType, PlanDataType } from '../../types';

export type PlansProps = {
  directions: DirectionType[],
  features: string[],
  plans: PlanDataType[],
}

const Plans: FC<PlansProps> = (props) => {
  const {
    directions = [],
    features = [],
    plans = [],
  } = props;

  const dispatcher = useDispatcher();
  const currentPlan = useSelector(selectors.currentPlan);
  const isProcessing = useSelector(selectors.isProcessing);
  const getButtonState = useGetButtonState();

  const handleCancel = useCallback(async () => {
    const isConfigured = await ui.confirm(
      (
        <Box display="flex" flexDirection="column" maxWidth={500}>
          <Typography mt={0} fontWeight={400} textColor="text.secondary">
            Your subscription will be canceled, but you can continue to use the plan until the end of the billing period, which is until
            {currentPlan.expirationDate ? ` ${DateTime.fromISO(currentPlan.expirationDate).toLocaleString(DateTime.DATE_FULL, { locale: 'en' })}.` : '.'}
          </Typography>
          <Typography mt={2}>
            If you change your mind, you can renew your subscription.
          </Typography>
        </Box>
      ), {
        title: (
          <Typography fontSize={18}>
            {`Current plan: ${currentPlan.name}`}
          </Typography>
        ),
        cancelLabel: 'Close',
        confirmLabel: 'Cancel plan',
      },
    );
    if (!isConfigured) {
      return;
    }
    dispatcher.subscription.cancel();
  }, [currentPlan.name, currentPlan.expirationDate]);

  const handleUpgrade = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const { value: planId } = (event.target as HTMLButtonElement);
    if (!planId) {
      return;
    }
    dispatcher.subscription.subscribe({ planId });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="center"
        gap={1.5}
        sx={{
          '--Card-radius': '10px',
        }}
      >
        {plans.map((plan) => {
          const buttonState = getButtonState(
            currentPlan,
            plan.identity?.planId,
            directions,
          );
          return (
            <Panel key={plan.name} variant={plan.isSolid ? 'solid' : 'plain'}>
              <PlanHead
                name={plan.name}
                marker={(
                  <>
                    {buttonState === 'current' && (
                      <Typography component="span" textColor="primary.solidBg" ml={0.75} fontSize={14} fontWeight={500} lineHeight={1}>{plan.label.current}</Typography>
                    )}
                    {buttonState === 'cancelled' && currentPlan.expirationDate && (
                      <Typography component="span" textColor="danger.500" ml={0.75} fontSize={14} fontWeight={500} lineHeight={1}>
                        {'until '}
                        {DateTime.fromISO(currentPlan.expirationDate).toLocaleString(DateTime.DATE_FULL, { locale: 'en' })}
                      </Typography>
                    )}
                  </>
                )}
                description={plan.description}
                note={plan.note}
                costValue={plan.cost.value}
                costBy={plan.cost.by}
                costNote={plan.cost.note}
              />
              <Divider margin="1.5 0" />
              <PlanInclude
                list={plan.includes}
              />
              {buttonState === 'current' && plan.isCancellable && (
                <UpgradeButton loading={isProcessing} sx={{ mt: 4 }} value={plan.identity?.planId} onClick={handleCancel}>
                  {plan.label.cancel}
                </UpgradeButton>
              )}
              {buttonState === 'current' && !plan.isCancellable && (
                <UpgradeButton disabled sx={{ mt: 4 }}>
                  {plan.label.current}
                </UpgradeButton>
              )}
              {buttonState === 'cancelled' && (
                <UpgradeButton loading={isProcessing} sx={{ mt: 4 }} value={plan.identity?.planId} onClick={handleUpgrade}>
                  {plan.label.resume}
                </UpgradeButton>
              )}
              {buttonState === 'unavailable' && (
                <UpgradeButton disabled sx={{ mt: 4 }}>
                  {plan.label.unavailable}
                </UpgradeButton>
              )}
              {buttonState === 'incomplete' && (
                <UpgradeButton loading={isProcessing} sx={{ mt: 4 }} value={plan.identity?.planId} onClick={handleUpgrade}>
                  {plan.label.complete}
                </UpgradeButton>
              )}
              {buttonState === 'available' && (
                <UpgradeButton loading={isProcessing} sx={{ mt: 4 }} value={plan.identity?.planId} onClick={handleUpgrade}>
                  {plan.label.upgrade}
                </UpgradeButton>
              )}
            </Panel>
          );
        })}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="center"
        gap={1.5}
      >
        {plans.map((plan, key) => (
          <Panel
            key={plan.name}
            variant="flat"
            title={(
              <Typography component="h2" fontSize={34} fontWeight={400} lineHeight={1} textColor="text.primary" mt={12} mb={4}>
                {key === 0 ? 'Core features' : <span>&nbsp;</span>}
              </Typography>
            )}
          >
            <PlanFeatures
              titles={features}
              hasTitles={key === 0}
              values={plan.features}
            />
          </Panel>
        ))}
      </Box>
    </Box>
  );
};

export default memo(Plans);
