import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

type Result = {
  options: string[],
  value: 'all' | string,
}

const selector = createCachedSelector(
  (state: DefaultRootState) => state.searchModal.filter.type,
  (type) => {
    return {
      options: type.options,
      value: (type.value || 'all'),
    };
  },
)(
  (state, filterName, defaultValue) => `${filterName}-${defaultValue}`,
);

export default (state: DefaultRootState) => (
  selector(state) as Result
);
