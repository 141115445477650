import {
  type SagaReturnType,
  takeEvery, cancel, put,
} from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';

import * as noteStore from 'store/nodes/note';

import Alert from 'components/Alert';
import network from 'lib/network';
import dialog from 'components/Dialog';
import * as materialStore from 'store/nodes/content';
import { actions } from '../slice';

export const config = {
  action: actions.remove.type,
  method: takeEvery,
};

const confirm = (): Promise<boolean> => new Promise((resolve) => {
  dialog.show(
    {
      title: 'Confirmation',
      text: 'Are you sure want to delete\nthis note?',
    },
    [{
      label: 'Cancel',
      variant: 'outlined',
      lightColor: '#929292',
      darkColor: '#77767E',
      handle: (dialogId: string) => {
        dialog.hide(dialogId);
        resolve(false);
      },
    }, {
      label: 'Yes',
      variant: 'contained',
      lightColor: '#db3327',
      darkColor: '#db3327',
      textLightColor: '#ffffff',
      textDarkColor: '#ffffff',
      handle: (dialogId: string) => {
        dialog.hide(dialogId);
        resolve(true);
      },
    }],
  );
});

export function* func(action: SagaReturnType<typeof actions.remove>) {
  const {
    id,
    force,
  } = action.payload;

  let isConfirmed = true;

  if (!force) {
    isConfirmed = yield* call(() => confirm());
  }

  if (!id || !isConfirmed) {
    yield put(actions.removeDone({ id }));
    yield cancel(); return;
  }

  const relation = yield* select(noteStore.selectors.resourceByNoteId(id));

  let url = '';
  if (relation?.resourceType === 'material') {
    url = `/content/${relation.resourceId}/note/${id}`;
  }
  if (relation?.resourceType === 'collection') {
    url = `/playlists/${relation.resourceId}/note/${id}`;
  }

  if (!url) {
    yield cancel(); return;
  }
  const { data, errors } = yield* call(() => (
    network.request(url).delete()
  ));

  if (errors) {
    Alert.error('Error delete note');
    yield put(actions.removeDone({ id }));
    yield cancel(); return;
  }

  yield put(actions.removeItem({ id }));

  if (relation?.resourceId) {
    yield put(materialStore.actions.loadById(relation.resourceId));
  }
}
