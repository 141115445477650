import request from 'services/api/request';
import type { ResultLegacy } from 'app/entities';

type OAuthUrlsResult = {
  facebook: string,
  google: string,
  linkedin: string,
}

export default async (redirectUri: string): Promise<ResultLegacy<OAuthUrlsResult>> => {
  const { data, error } = await request.get<OAuthUrlsResult>('/oauth/urls', {
    query: {
      frontendRedirectUri: redirectUri,
    },
  });

  if (error || !data) {
    return {
      data: null,
      error: error || new Error('Unknown error #29'),
    };
  }

  return {
    data,
    error,
  };
};
