import connect from '../../utils/saga/connect';
import * as create from './sagas/create';
import * as update from './sagas/update';
import * as remove from './sagas/remove';
import * as loadById from './sagas/loadById';
import * as loadByResource from './sagas/loadByResource';

export default connect([
  create,
  update,
  remove,
  loadById,
  loadByResource,
]);
