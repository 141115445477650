import type { UserType, ResultLegacy } from 'app/entities';
import request from 'services/api/request';

type CreateResult = {
  id: number,
  contentId: number,
  rate: number,
  userId: number,
  text: string,
  user?: Pick<UserType,
    | 'id'
    | 'login'
    | 'name'
    | 'surname'
    | 'photo'
    | 'profession'
  >,
  updatedAt: string,
  createdAt: string,
}

export default async (contentId: number, rate: number, text: string): Promise<ResultLegacy<CreateResult>> => {
  const body: Record<string, number | string> = {
    rate,
    text,
  };
  const { data, error } = await request.post<CreateResult>(`/content/${contentId}/reviews`, { body });

  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
