import moment from 'moment-timezone';

import type { MaterialType } from 'app/entities';
import { useMemo } from 'react';

type ContentInfoElements = {
  type?: string,
  level?: string
  duration?: string
  publishedAt?: string
}

const prepareSourceName = (text: string) => {
  if (/^http.*/i.test(text)) {
    return text.replace(/^http.*?\/\/([^.]+\.[^/]+).*/, '$1');
  }
  if (/^pdf/i.test(text)) {
    return 'File';
  }
  return text;
};

const useInfo = (data?: MaterialType | ContentInfoElements | null, hasMarkByIKI = false) => useMemo(() => {
  if (!data) {
    return null;
  }
  const {
    type, level, duration, publishedAt,
  } = data;
  const result = [];
  if (hasMarkByIKI) {
    result.push('**by-iki**');
  }
  if ('source' in data && data.source?.name) {
    result.push(prepareSourceName(data.source?.name));
  }
  if (type) {
    result.push(type);
  }
  if (level) {
    result.push(level);
  }
  if (duration) {
    result.push(duration);
  }
  if (publishedAt) {
    result.push(moment(publishedAt).fromNow());
  }
  return result;
}, [data?.type, data?.level, data?.duration, data?.publishedAt]);

export default useInfo;
