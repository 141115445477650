import { useCallback, useEffect, useState } from 'react';
import { type ScaledSize, Dimensions } from 'react-native';

/**
 * @deprecated use useScreenSize instead
 */
const useBreakpoint = (): 'xs' | 'sm' | 'md' | 'lg' => {
  const [value, setValue] = useState<'xs' | 'sm' | 'md' | 'lg'>('sm');

  const handleDimensionChange = useCallback(({ window }: { window: ScaledSize }) => {
    let newValue: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
    if (window.width >= 425) {
      newValue = 'sm';
    }
    if (window.width >= 768) {
      newValue = 'md';
    }
    if (window.width >= 1024) {
      newValue = 'lg';
    }
    if (value !== newValue) {
      setValue(newValue);
    }
  }, [value]);

  useEffect(() => {
    const handler = Dimensions.addEventListener('change', handleDimensionChange);
    handleDimensionChange({ window: Dimensions.get('window') });
    return () => {
      handler.remove();
    };
  }, [handleDimensionChange]);

  return value;
};

export default useBreakpoint;
