import {
  type CSSProperties, type KeyboardEvent, type ChangeEvent, type ReactNode,
  memo, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { Box, useTheme } from '@mui/joy';
import { createUseStyles } from 'react-jss';
import TextareaAutosize from 'react-textarea-autosize';
import cn from 'classnames';

import { useThemeColor, useFontProps, useColorScheme } from 'hooks';

export type CopilotInputMethods = {
  cursor: {
    toStart: () => void,
    toEnd: () => void,
  },
};

export type CopilotInputProps = {
  size?: 'small' | 'regular' | 'large',
  focusBorderSize?: number | string,
  value?: string,
  autoFocus?: boolean,
  className?: string,
  onSubmit?: () => void,
  disabled?: boolean,
  multiline?: boolean,
  startAdornment?: ReactNode,
  endAdornment?: ReactNode,
  onChange?: (text: string) => void,
  onFocus?: () => void,
  onBlur?: () => void,
};

const CopilotInput = forwardRef<CopilotInputMethods, CopilotInputProps>((props, forwardedRef) => {
  const {
    size = 'regular',
    focusBorderSize = '0.470588235em',
    value,
    autoFocus,
    className,
    onSubmit,
    disabled,
    multiline = true,
    startAdornment,
    endAdornment,
    onChange,
    onFocus,
    onBlur,
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const textareaRef = useRef< HTMLTextAreaElement | null>(null);
  const [isFocus, setFocus] = useState<boolean>(false);

  const theme = useTheme();
  const colorScheme = useColorScheme();
  const backgroundColor = theme.palette.background.level1;
  const placeholderColor = useThemeColor({ dark: '#a9a9a9', light: '#5e5e5e' });
  const color = useThemeColor({ dark: '#ffffff', light: '#000000' });
  const fontProps = useFontProps<CSSProperties>({
    size: ({
      small: 15,
      regular: 17,
      large: 22,
    } as const)[size],
  });

  const handleFocus = useCallback(() => {
    onFocus?.();
    setFocus(true);
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    onBlur?.();
    setFocus(false);
  }, [onBlur]);

  const classes = useStyle({
    focusBorderSize,
    colorScheme,
    placeholderColor,
    color,
    size,
    fontFamily: fontProps.fontFamily,
    fontSize: fontProps.fontSize,
    lineHeight: fontProps.lineHeight,
    letterSpacing: fontProps.letterSpacing,
  });

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }, []);

  useImperativeHandle(forwardedRef, () => ({
    cursor: {
      toStart: () => {
        if (multiline) {
          textareaRef.current?.setSelectionRange(0, 0);
        } else {
          inputRef.current?.setSelectionRange(0, 0);
        }
      },
      toEnd: () => {
        if (multiline) {
          const length = textareaRef.current?.value.length ?? 0;
          textareaRef.current?.setSelectionRange(length, length);
        } else {
          const length = inputRef.current?.value.length ?? 0;
          inputRef.current?.setSelectionRange(length, length);
        }
      },
    },
  }), [multiline]);

  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if ((event.key || event.code) === 'Enter' && !event.ctrlKey) {
      event.preventDefault();
      onSubmit?.();
    }
    if ((event.key || event.code) === 'Enter' && (event.ctrlKey || event.metaKey || event.shiftKey)) {
      event.preventDefault();
      const textarea = event.target as HTMLTextAreaElement;
      const cursorPosition = textarea.selectionStart;
      const textBeforeCursor = textarea.value.slice(0, cursorPosition);
      const textAfterCursor = textarea.value.slice(cursorPosition);
      textarea.value = `${textBeforeCursor}\n${textAfterCursor}`;
      textarea.selectionStart = cursorPosition + 1;
      textarea.selectionEnd = cursorPosition + 1;
      window.dispatchEvent(new Event('resize'));
    }
  }, [onSubmit]);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange?.(event.target.value);
  }, [onChange]);

  return (
    <Box
      display="flex"
      position="relative"
      flex={1}
      bgcolor={backgroundColor}
      borderRadius="1.411764706em"
      className={cn(classes.container, { [classes.isFocus]: isFocus })}
      fontSize={fontProps.fontSize}
    >
      {startAdornment}
      {!multiline && (
        <input
          key="CopilotInput"
          ref={inputRef}
          className={cn(classes.input, className)}
          placeholder="Ask IKI co-pilot"
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      )}
      {multiline && (
        <TextareaAutosize
          key="CopilotTextarea"
          ref={textareaRef}
          className={cn(classes.input, className)}
          placeholder="Ask IKI co-pilot"
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          maxRows={6}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      )}
      {endAdornment}
    </Box>
  );
});

const useStyle = createUseStyles<
  | 'container'
  | 'isFocus'
  | 'input',
  {
    colorScheme?: 'light' | 'dark',
    focusBorderSize?: string | number,
    placeholderColor?: string,
    color?: string,
    fontFamily?: string,
    fontSize?: number | string,
    fontWeight?: string,
    lineHeight?: number | string,
    letterSpacing?: number | string,
    size?: 'small' | 'regular' | 'large'
  }
>({
  container: {
    transition: 'box-shadow 200ms ease',
  },
  isFocus: {
    boxShadow: (props) => `0 0 0 ${props.focusBorderSize} #497CFF`,
  },
  input: {
    '&': {
      display: 'flex',
      alignSelf: 'stretch',
      border: 0,
      flex: 1,
      outline: 'none',
      resize: 'none',
      padding: (props) => ({
        small: '0.6652em 1em',
        regular: '0.764705882em 1em',
        large: '0.764705882em 1em',
      } as const)[props.size || 'regular'],
      backgroundColor: 'transparent',
      boxShadow: '0 0 0 0 transparent',
      color: (props) => props.color,
      fontFamily: (props) => props.fontFamily,
      fontSize: (props) => props.fontSize,
      lineHeight: (props) => props.lineHeight,
      letterSpacing: (props) => props.letterSpacing,
    },
    '&::placeholder': {
      color: (props) => props.placeholderColor,
      fontFamily: (props) => props.fontFamily,
      fontSize: (props) => props.fontSize,
      fontWeight: 500,
      lineHeight: (props) => props.lineHeight,
      letterSpacing: (props) => props.letterSpacing,
    },
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
    },
  },
});

export default memo(CopilotInput);
