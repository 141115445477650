import {
  type CSSProperties, memo, type PropsWithChildren, type MutableRefObject,
} from 'react';
import { Card, Box, type BoxProps } from '@mui/joy';

type BodyProps = {
  style?: CSSProperties,
  hoverRef?: MutableRefObject<null>
  handleBodyPress?: () => void
} & BoxProps;

const Body = (props: PropsWithChildren<BodyProps>) => {
  const {
    children,
    style,
    hoverRef,
    handleBodyPress,
    sx,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        containerType: 'inline-size',
        ...sx,
      }}
      ref={hoverRef}
      {...rest}
    >
      <Card
        variant="soft"
        sx={{
          '&': {
            '--Card-radius': (theme) => theme.radius.xl,
            backgroundColor: 'unset',
            transition: '200ms box-shadow ease, 200ms background-color ease',
            cursor: 'pointer',
            gap: 1,
            '--card-hover-opacity': 0,
            '--nestedBg': (theme) => theme.palette.background.level1,
            '--nestedHoverBg': (theme) => theme.palette.background.level1,
            '--nestedActiveBg': (theme) => theme.palette.background.level2,
            '--variant-plainHoverBg': 'var(--nestedHoverBg)',
            '--variant-plainActiveBg': 'var(--nestedActiveBg)',
            '--variant-outlinedHoverBg': 'var(--nestedHoverBg)',
            '--variant-outlinedActiveBg': 'var(--nestedActiveBg)',
          },
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: (theme) => theme.palette.background.level1,
            '--card-hover-opacity': 1,
            '--nestedBg': (theme) => theme.palette.background.level2,
            '--nestedHoverBg': (theme) => theme.palette.background.level2,
            '--nestedActiveBg': (theme) => theme.palette.background.level3,
          },
          '@container (width <= 400px )': {
            '&': {
              gap: 1.5,
              backgroundColor: (theme) => theme.palette.background.level1,
              '--nestedBg': (theme) => theme.palette.background.level2,
              '--nestedHoverBg': (theme) => theme.palette.background.level2,
              '--nestedActiveBg': (theme) => theme.palette.background.level3,
            },
            '&:hover': {
              boxShadow: (theme) => theme.shadow.md,
            },
          },
        }}
        onClick={handleBodyPress}
      >
        {children}
      </Card>
    </Box>
  );
};

export default memo(Body);
