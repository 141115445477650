import request from 'services/api/request';
import type { SuccessResult } from 'app/entities';

export default async (
  email: string,
  frontendRedirectUri: string,
): Promise<SuccessResult> => {
  const { error } = await request.post('/auth/password-reset', {
    body: {
      email,
      frontendRedirectUri,
    },
  });
  if (error) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: {
      success: true,
    },
    error,
  };
};
