import {
  memo, useCallback, useState, useEffect, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet, useWindowDimensions,
} from 'react-native';

import { useResponsive } from 'hooks';

import { useSelector, useDispatcher } from 'store/utils/redux/hooks';
import * as searchUserStore from 'store/nodes/searchUser';
import * as userStore from 'store/nodes/user';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import { TextInput } from 'components/Form';
import FeedList from 'components/FeedList';
import SlimProfileItem from 'components/SlimProfileItem';
import Relations from 'components/Relations';

import Header from './elements/Header';

export type SearchUserProps = {
  onPress?: (login: string, id: number) => void,
}

const SearchUser = (props: SearchUserProps) => {
  const {
    onPress,
  } = props;

  const dimension = useWindowDimensions();
  const dispatcher = useDispatcher();
  const responsive = useResponsive();

  const userProfile = useSelector(userStore.selectors.getMy);
  const list = useSelector(searchUserStore.selectors.list);
  const listMeta = useSelector(searchUserStore.selectors.listMeta);

  const [text, setText] = useState<string>('');

  useEffect(() => {
    dispatcher.searchUser.loadNextPage(text, 'all', true);
  }, [text]);

  useEffect(() => {
    setText('');
    dispatcher.searchUser.clear();
  }, []);

  const sequenceFinal = useMemo(() => {
    const wireframes = [
      { id: 'w-1', type: 'wireframe' },
      { id: 'w-2', type: 'wireframe' },
      { id: 'w-3', type: 'wireframe' },
      { id: 'w-4', type: 'wireframe' },
      { id: 'w-5', type: 'wireframe' },
      { id: 'w-6', type: 'wireframe' },
    ];
    if (listMeta.firstPage.isLoading) {
      return wireframes;
    }
    if (listMeta.nextPage.isLoading) {
      return [...list.sequence, ...wireframes];
    }
    return list.sequence;
  }, [list.sequence, listMeta.firstPage.isLoading, listMeta.nextPage.isLoading]);

  const renderItem = useCallback(({ id }) => (
    <SlimProfileItem
      id={id}
      onPress={onPress}
    />
  ), [onPress]);

  const componentStyle = useMemo(() => {
    const { height } = dimension;
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.SearchUser),
      height,
    };
    if (responsive.isMoreThen.mobile) {
      result.height = height - 100;
      result.paddingBottom = unit(40);
      result.borderRadius = unit(24);
    }
    return result;
  }, [responsive.isMoreThen.mobile, dimension.height]);

  return (
    <View
      style={componentStyle}
      lightColor="#e7e7e7"
      darkColor="#181818"
      pointerEvents="auto"
    >
      <View style={styles.toolbar}>
        <View style={styles.toolbarSearch}>
          <TextInput placeholder="Search some person" style={styles.input} value={text} onChange={setText} />
        </View>
      </View>
      {text.length === 0 && !userProfile && (
        <View style={[styles.panelInfo]}>
          <Text lightColor="#000000" darkColor="#ffffff" size={17}>Start typing</Text>
          <Text lightColor="#797979" darkColor="#B8B6BF" size={15}>to search</Text>
        </View>
      )}
      {text.length === 0 && !!userProfile && (
        <Relations slim login={userProfile?.login} onPress={onPress} />
      )}
      {text.length > 0 && listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && (
        <View style={[styles.panelInfo]}>
          <Text lightColor="#000000" darkColor="#ffffff" size={17}>No results</Text>
          <Text lightColor="#797979" darkColor="#B8B6BF" size={15}>Try to type something else</Text>
        </View>
      )}
      {text.length > 0 && (listMeta.firstPage.isLoading || listMeta.nextPage.isLoading || list.sequence.length > 0) && (
        <FeedList
          scrollable
          data={sequenceFinal}
          hasNextPage={list.paginationInfo.hasNext}
          HeaderComponent={<Header />}
          ItemComponent={renderItem}
          paddingHorizontal={16}
          style={styles.panelResult}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  SearchUser: {
    width: '100%',
    maxWidth: unit(560),
    alignSelf: 'center',
    paddingHorizontal: unit(16),
    paddingTop: unit(40),
    paddingBottom: unit(16),
    borderTopLeftRadius: unit(24),
    borderTopRightRadius: unit(24),
    flexDirection: 'column',
  },
  desktop: {
    paddingBottom: unit(40),
    borderRadius: unit(24),
  },
  toolbar: {
  },
  toolbarSearch: {
    flexDirection: 'row',
    marginBottom: unit(16),
  },
  input: {
    flex: 1,
  },
  toolbarTypes: {
    marginHorizontal: unit(-16),
    marginBottom: unit(16),
  },
  panelResult: {
    marginLeft: unit(-16),
    marginRight: unit(-16),
    flex: 1,
  },
  panelInfo: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  button: {
    marginLeft: unit(6),
    width: unit(44),
    height: unit(44),
    borderRadius: unit(22),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default memo(SearchUser);
