import {
  memo, useCallback, useEffect, useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import moment from 'moment-timezone';
import _ from 'lodash';

import { dispatcher, useSelector } from 'store/utils/redux/hooks';
import * as plusStore from 'store/nodes/plus';
import * as userStore from 'store/nodes/user';

import SelectInput from 'components/SelectInput';
import { useAuthor } from 'components/Card/variants/MaterialCard/hooks';
import AuthorBlock from 'components/Card/elements/AuthorBlock';
import TextInput from 'components/TextInput';

import CollectionSelectInput from '../elements/CollectionSelectInput';
import Body from '../elements/Body';
import Text from '../elements/Text';
import AiSummary from '../elements/AISummary';

import { usePlusContext } from '../../context';

type MaterialLayoutProps = {
  onTagsChange?: (value: string[]) => void,
  onCollectionChange?: (value: number | null) => void,
};

const MaterialLayout = (props: MaterialLayoutProps) => {
  const { onTagsChange, onCollectionChange } = props;
  const { hasSummary, hasNewCollectionButton } = usePlusContext();
  const classes = useStyle();

  const userProfile = useSelector(userStore.selectors.getMy);
  const state = useSelector(plusStore.selectors.state);
  const { toCollection } = state.props || {};
  const material = useSelector(plusStore.selectors.materialResult);
  const [tags, setTags] = useState<string[]>(material?.tags || []);
  const [title, setTitle] = useState<string>(material?.title || '');
  const authorData = useAuthor(material?.authors);
  const [currentCollectionId, setCurrentCollectionId] = useState<number | null>(
    toCollection && 'id' in toCollection ? toCollection.id : null,
  );

  const publishedAgo = typeof material?.publishedAt === 'string' ? moment(material.publishedAt).fromNow() : null;

  const handleTagsChange = useCallback((value: string[]) => {
    setTags(value);
    onTagsChange?.(value);
  }, [material?.tags]);

  const handleTitleChange = useCallback((value: string) => {
    const trimReg = /\s+/g;
    setTitle(value.replace(trimReg, ' '));
  }, []);

  const handleCollectionChange = useCallback((value: number | null) => {
    setCurrentCollectionId(value !== null ? Number(value) : null);
    onCollectionChange?.(value);
  }, [onCollectionChange]);

  useEffect(() => {
    if (!currentCollectionId || !material || state.isOpen) {
      return;
    }
    const isInitialCollectionIsNotSelected = toCollection && 'id' in toCollection && toCollection.id === currentCollectionId;
    if (!isInitialCollectionIsNotSelected) {
      dispatcher.playlist.addMaterialItem({
        collectionId: currentCollectionId, data: material, itemType: 'material', options: { append: true },
      });
      return;
    }
    dispatcher.playlist.addMaterialItem(({
      collectionId: currentCollectionId, data: material, itemType: 'material', options: toCollection,
    }));
  }, [state.isOpen, currentCollectionId, material, toCollection]);

  useEffect(() => {
    if (!material || state.isOpen) {
      return;
    }
    const updatedMaterial = { ...material, tags, title };
    const isEqual = _.isEqual(material, updatedMaterial);
    if (!isEqual) {
      dispatcher.myContent.editMaterial(updatedMaterial);
    }
  }, [state.isOpen, tags, material, title]);

  if (!material) {
    return null;
  }

  return (
    <>
      <Body>
        <div className={classes.panel}>
          <div>
            <TextInput
              maxLines={4}
              autoHeight
              multiline
              value={title}
              onChangeText={handleTitleChange}
              style={{ width: '18.75em' }}
              fontSize={22}
              disabled={userProfile?.id !== material.creator?.id}
            />
            <div className={classes.duration}>
              <Text darkColor="#9A99A2" lightColor="#888888" size={14}>{material.source?.name}</Text>
              {material.duration && (
                <>
                  •
                  <Text darkColor="#9A99A2" lightColor="#888888" size={14}>{material.duration}</Text>
                </>
              )}
            </div>
          </div>
          {material.source?.image?.url && (
          <div className={classes.image}>
            <img src={material.source?.image?.url} width="100%" alt="TEST" />
          </div>
          )}
        </div>
        <div className={classes.panel}>
          {authorData && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style={{ marginBottom: '0.125em' }} darkColor="#B8B6BF" lightColor="#797979" size={12}>Author</Text>
            <AuthorBlock data={authorData} />
          </div>
          )}
          {publishedAgo && (
            <div>
              <Text style={{ marginBottom: '0.125em' }} darkColor="#B8B6BF" lightColor="#797979" size={12}>Statistic</Text>
              <Text size={16}>{publishedAgo}</Text>
            </div>
          )}
        </div>
        <div className={classes.tagsInput}>
          <Text style={{ marginBottom: '0.5em' }} darkColor="#B8B6BF" lightColor="#797979" size={12}>Tags</Text>
          <SelectInput
            isMulti
            optionsUrl="/skills/search?pageNum=0&pageSize=50&q=[TEXT]"
            value={tags}
            isDisabled={userProfile?.id !== material.creator?.id}
            onChange={handleTagsChange}
            colors={{
              outline: { light: '#FFFFFF', dark: '#000000' },
              controlBackground: { light: '#FFFFFF', dark: '#000000' },
              menuBackground: { light: '#FFFFFF', dark: '#000000' },
            }}
          />
        </div>
        <div className={classes.selectInput}>
          <Text style={{ marginBottom: '0.5em' }} darkColor="#B8B6BF" lightColor="#797979" size={12}>Add to collection</Text>
          <CollectionSelectInput hasNewCollectionButton={hasNewCollectionButton} value={currentCollectionId} onChange={handleCollectionChange} />
        </div>
      </Body>
      {hasSummary && !!material?.summary && (
        <Body>
          <AiSummary text={material.summary} />
        </Body>
      )}
    </>
  );
};

const useStyle = createUseStyles<
  'panel' | 'image' | 'selectInput' | 'tagsInput' | 'duration',
  {
    backgroundColorIcon?: string,
  }
>({
  panel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5em',
    marginBottom: '1em',
  },
  image: {
    display: 'flex',
    width: '6em',
    height: '3.5em',
    alignItems: 'center',
  },
  duration: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1em',
    gap: '0.325em',
  },
  selectInput: {
    marginBottom: '1.5em',
  },
  tagsInput: {
    marginBottom: '1.5em',
  },
});

export default memo(MaterialLayout);
