import {
  takeLatest, put, cancel, all,
  type SagaReturnType,
} from 'redux-saga/effects';

import * as api from 'services/api';
import type { ResourceVariant } from 'app/entities';

import { call, select } from 'store/utils/saga/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.loadNextPage.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.loadNextPage>) {
  const resource = action.payload.resource as ResourceVariant;
  const { resourceId, reload } = action.payload;
  const nextCursor = yield* select(selectors.selectLoadNextCursor(reload));

  const response = yield* call(() => api.resource.comment.page(
    resource,
    resourceId,
    {
      cursor: nextCursor,
      pageSize: 10,
    },
  ));

  if (response.error || !response.data) {
    yield put(actions.loadNextPageDone(reload));
    yield cancel(); return;
  }

  const rootIds = response.data.items.map((item) => Number(item.replyToId)).filter((item) => item && !Number.isNaN(item));
  const rootComments = yield* call(() => api.resource.comment.items(rootIds));
  if (rootComments.error || !rootComments.data) {
    yield put(actions.loadNextPageDone(reload));
    yield cancel(); return;
  }

  yield all(rootComments.data.map((comment) => put(actions.setItem(comment))));

  yield put(actions.addPage(action.payload.resource, resourceId, response.data.items, response.data.paginationInfo, reload));
  yield put(actions.loadNextPageDone(reload));
}
