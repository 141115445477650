import {
  type ForwardRefRenderFunction,
  memo, forwardRef, useCallback, useRef,
} from 'react';
import { StyleSheet } from 'react-native';
import LottieView from 'lottie-react-native';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';

import { type Controller } from '../types';
import Popup from '../elements/Info';

export interface Context {
  originalUrl: string,
  tags: string[],
  title?: string,
  type?: 'course' | 'article' | 'video' | 'source' | 'link' | string,
  description?: string,
  sectionId?: number,
  sourceId?: number,
  image?: string,
  level?: 'junior' | 'middle' | 'senior' | string,
  duration?: string,
}

const PlaylistCreated: ForwardRefRenderFunction<Controller, any> = (props, forwardedRef) => {
  const size = 200;
  const loop = false;

  const lottie = useRef<any>(null);

  const handleShow = useCallback(() => {
    if (lottie.current && 'play' in lottie.current) {
      lottie.current.play?.();
    }
  }, [lottie]);

  const handleHide = useCallback(() => {
    if (lottie.current && 'play' in lottie.current) {
      lottie.current.stop?.();
    }
  }, [lottie]);

  return (
    <Popup.Panel ref={forwardedRef} onShow={handleShow} onHide={handleHide} isBlocked>
      <View style={styles.container}>
        <LottieView
          ref={lottie}
          style={{
            width: unit(size),
          }}
          source={require('../../../../assets/lottie/material-added.json')}
          loop={loop}
        />
        <Text size={34}>Playlist Created</Text>
      </View>
    </Popup.Panel>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: unit(100),
  },
});

export default memo(forwardRef<Controller, any>(PlaylistCreated));
