import { memo, useMemo } from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet,
} from 'react-native';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';

type HighlightBlockProps = {
  style?: StyleProp<ViewStyle>,
  text?: string,
};

const HighlightBlock = (props: HighlightBlockProps) => {
  const {
    style,
    text,
  } = props;

  const textFinal = useMemo(() => {
    const max = 200;
    if (!text) {
      return null;
    }
    if (text.length < max) {
      return text;
    }
    const position = text.indexOf(' ', max);
    return `${text.substring(0, position).trim().replace(/[,.-]+$/, '')}...`;
  }, [text]);

  if (!textFinal) {
    return null;
  }

  return (
    <View style={[styles.Highlight, style]}>
      <View style={styles.line} darkColor="#fff8b5" lightColor="#f8bd00" />
      <Text font="Realist" size={20}>{textFinal}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  Highlight: {
    flexDirection: 'row',
    flex: 1,
  },
  line: {
    width: unit(5),
    borderRadius: unit(3),
    marginRight: unit(18),
  },
});

export default memo(HighlightBlock);
