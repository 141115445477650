import { memo, useMemo } from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet,
} from 'react-native';

import { Text, View } from 'components/Themed';

type NoteBlockProps = {
  style?: StyleProp<ViewStyle>,
  text?: string,
};

const NoteBlock = (props: NoteBlockProps) => {
  const {
    style,
    text,
  } = props;

  const textFinal = useMemo(() => {
    const max = 200;
    if (!text) {
      return null;
    }
    if (text.length < max) {
      return text;
    }
    const position = text.indexOf(' ', max);
    return `${text.substring(0, position).trim().replace(/[,.-]+$/, '')}...`;
  }, [text]);

  if (!textFinal) {
    return null;
  }

  return (
    <View style={[styles.Note, style]}>
      <Text font="Realist" size={20}>{textFinal}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  Note: {
    flexDirection: 'row',
    flex: 1,
  },
});

export default memo(NoteBlock);
