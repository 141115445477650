import { useCallback, type ReactElement } from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet, TouchableOpacity,
} from 'react-native';

import Icon from 'components/LegacyIcon';

type StarTouchableProps = {
  size?: number,
  type?: 'fill' | 'outline',
  lightColor?: string,
  darkColor?: string,
  context?: any,
  style?: StyleProp<ViewStyle>
  onPress?: (context: any) => void,
} & typeof defaultProps;

const defaultProps = {
  size: 24,
  type: 'outline',
  lightColor: '#000000',
  darkColor: '#ffffff',
};

const StarTouchable = (props:StarTouchableProps): ReactElement => {
  const {
    size,
    type,
    lightColor,
    darkColor,
    context = {},
    style,
    onPress,
  } = props;

  const handlePress = useCallback(() => {
    onPress?.(context);
  }, [onPress, context]);

  if (onPress) {
    return (
      <TouchableOpacity onPress={handlePress} style={style} activeOpacity={0.8}>
        <Icon name={type === 'outline' ? 'StarOutline' : 'Star'} size={size} style={styles.star} lightColor={lightColor} darkColor={darkColor} />
      </TouchableOpacity>
    );
  }
  return (
    <Icon name={type === 'outline' ? 'StarOutline' : 'Star'} size={size} style={[style, styles.star]} lightColor={lightColor} darkColor={darkColor} />
  );
};

StarTouchable.defaultProps = defaultProps;

const styles = StyleSheet.create({
  star: {
    flexShrink: 0,
  },
});

export default StarTouchable;
