import {
  type CSSProperties, type PropsWithChildren,
  memo, useCallback, useMemo,
} from 'react';
import { Platform } from 'react-native';

const defaultProps = {
  href: '/' as string | undefined,
  disabled: false,
};

type WebLinkProps = {
  className?: string,
  href?: string,
  disabled?: boolean,
} & typeof defaultProps;

const WebLink = (props: PropsWithChildren<WebLinkProps>) => {
  const {
    children,
    disabled,
    href,
  } = props;

  const handleClick = useCallback((event: { preventDefault: () => void; }) => {
    event.preventDefault();
  }, []);

  const styles = useMemo((): CSSProperties => ({
    textDecoration: 'none',
  }), []);

  if (disabled || Platform.OS !== 'web') {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <a href={href} style={styles} onClick={handleClick}>{children}</a>
  );
};

export default memo(WebLink);
