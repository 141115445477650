import { createAction } from '@reduxjs/toolkit';

import type { InteractionType, InteractionVariants, ResourceVariant } from 'app/entities';

export const updateInteraction = createAction('INTERACTION/UPDATE_INTERACTION', (
  type: InteractionVariants,
  resource: ResourceVariant,
  resourceId: number,
) => ({
  payload: {
    type,
    resource,
    resourceId,
  },
}));

export const updateInteractionDone = createAction('INTERACTION/UPDATE_INTERACTION_DONE', (
  type: InteractionVariants,
  resource: ResourceVariant,
  resourceId: number,
) => ({
  payload: {
    type,
    resource,
    resourceId,
  },
}));

export const setValues = createAction('INTERACTION/SET_VALUES', (
  resource: ResourceVariant,
  resourceId: number,
  type: InteractionVariants,
  values?: InteractionType,
) => ({
  payload: {
    resource,
    resourceId,
    type,
    values,
  },
}));

export const toggleLike = createAction('INTERACTION/TOGGLE_LIKE', (resource: ResourceVariant, resourceId: number) => ({
  payload: {
    type: 'like',
    resource,
    resourceId,
  },
}));

export const toggleDislike = createAction('INTERACTION/TOGGLE_DISLIKE', (resource: ResourceVariant, resourceId: number) => ({
  payload: {
    type: 'dislike',
    resource,
    resourceId,
  },
}));

export const toggleBookmark = createAction('INTERACTION/TOGGLE_BOOKMARK', (resource: ResourceVariant, resourceId: number) => ({
  payload: {
    type: 'bookmark',
    resource,
    resourceId,
  },
}));

export const view = createAction('INTERACTION/VIEW', (resource: ResourceVariant, resourceId: number) => ({
  payload: {
    type: 'view',
    resource,
    resourceId,
  },
}));

export const shown = createAction('INTERACTION/SHOWN', (resource: ResourceVariant, resourceId: number) => ({
  payload: {
    type: 'shown',
    resource,
    resourceId,
  },
}));
