import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/joy';

import { useRoute } from 'navigation/hooks';

import * as userStore from 'store/nodes/user';
import * as contentStore from 'store/nodes/content';
import * as copilotStore from 'store/nodes/copilot';
import { useDispatcher } from 'store/utils/redux/hooks';
import { store } from 'store';

import { controller as modal } from 'components/Modal2';
import { useSharing } from 'hooks';

import Icon from 'ui/Icon';

const ActionsBar = () => {
  const route = useRoute<'Content'>();
  const dispatcher = useDispatcher();

  const { resourceId } = route.params;

  const userProfile = useSelector(userStore.selectors.getMy);
  const data = useSelector(contentStore.selectors.dataById(resourceId));
  const sourceData = useSelector(contentStore.selectors.sourceDataById(resourceId));
  const contentSharing = useSharing(data);

  const handleEdit = useCallback(() => {
    if (!sourceData) {
      return;
    }
    dispatcher.modal.open('EditMaterial', { id: sourceData.id });
  }, [sourceData]);

  const handleInQueue = useCallback(() => {
    if (!data?.id) {
      return;
    }
    dispatcher.interaction.toggleBookmark('content', data?.id);
  }, [data?.id]);

  const handleToNewCollection = useCallback(() => {
    if (!data?.id) {
      return;
    }
    modal.popup.playlists.close();
    dispatcher.modal.open('AddorEditCollection', { create: { materialIds: [data.id] } });
  }, [data?.id]);

  const handleToExistsCollection = useCallback((collectionId: number) => {
    if (!collectionId || !data?.id) {
      return;
    }
    const state = store.getState();
    const material = state.content.data[data.id];
    if (!material) {
      return;
    }
    modal.popup.playlists.close(() => {
      dispatcher.playlist.addMaterialItem({ collectionId, itemType: 'material', data: material });
    });
  }, [data?.id]);

  const handleToCollection = useCallback(() => {
    if (!data?.id) {
      return;
    }
    modal.popup.playlists.open({
      addingContentId: data?.id,
      hasNewButton: true,
      onNewPress: handleToNewCollection,
      onItemPress: handleToExistsCollection,
    });
  }, [data?.id, handleToNewCollection, handleToExistsCollection]);

  const handleShare = useCallback(() => {
    contentSharing.share();
  }, [contentSharing]);

  return (
    <Box display="flex" flexDirection="row" alignSelf="stretch">
      <Box display="flex" justifyContent="center" gap={1.5} flexGrow={1}>
        {!!userProfile && data?.creator?.id === userProfile?.id && (
        <Tooltip arrow variant="outlined" size="sm" title="Edit material">
          <IconButton
            size="sm"
            color="neutral"
            variant="soft"
            onClick={handleEdit}
          >
            <Icon name="pen-to-square" fw weight="light" color="primary" />
          </IconButton>
        </Tooltip>
        )}
        <Tooltip arrow variant="outlined" size="sm" title="Share material">
          <IconButton
            size="sm"
            color="neutral"
            variant="soft"
            disabled={!data}
            onClick={handleShare}
          >
            <Icon name="link" fw weight="light" color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip disableInteractive arrow variant="outlined" size="sm" title="Add material to collection">
          <IconButton
            size="sm"
            color="neutral"
            variant="soft"
            disabled={!data}
            onClick={handleToCollection}
          >
            <Icon family="kit" name="sharp-light-bars-circle-plus" fw color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip disableInteractive arrow variant="outlined" size="sm" title={!data?.isBookmarked ? 'Add material to bookmarks' : 'Remove material from bookmarks'}>
          <IconButton
            size="sm"
            color="neutral"
            variant="soft"
            disabled={!data}
            onClick={handleInQueue}
          >
            <Icon name="bookmark" fw weight={!data?.isBookmarked ? 'light' : 'solid'} color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default memo(ActionsBar);
