import {
  type ForwardRefExoticComponent, type ForwardRefRenderFunction, type PropsWithoutRef, type RefAttributes, type MutableRefObject,
  forwardRef, useCallback, useRef,
} from 'react';

import {
  type Measure,
  type Controller,
  type ModalFC,
} from './types';

export type {
  Measure,
  Controller,
  ModalFC,
};

export function forwardController<P = {}>(render: ModalFC): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<Controller>> {
  return forwardRef(render as unknown as ForwardRefRenderFunction<Controller, P>);
}

export const useScrollView = () => {
  const scrollState = useRef<{ y: number }>({ y: 0 });
  const userState = useRef<{ swiping: boolean }>({ swiping: false });
  const accessState = useRef<{ prevent: boolean }>({ prevent: false });

  const handleOnScroll = useCallback((event) => {
    const { y } = event.nativeEvent.contentOffset;
    scrollState.current = { y };
    if (!userState.current.swiping) {
      accessState.current.prevent = (scrollState.current.y > 5);
    }
  }, []);

  const handleTouchStart = useCallback(() => {
    userState.current.swiping = true;
  }, []);

  const handleTouchMove = useCallback((event) => {
    if (accessState.current.prevent) {
      event.stopPropagation();
    }
  }, []);

  const handleTouchEnd = useCallback(() => {
    userState.current.swiping = false;
    accessState.current.prevent = (scrollState.current.y > 5);
  }, []);

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleOnScroll,
  };
};

export const isForwardedController = (ref: MutableRefObject<Controller> | any): ref is MutableRefObject<Controller> => {
  return (ref && 'current' in ref);
};
