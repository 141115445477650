import {
  useCallback, useEffect, useRef,
  type ReactElement, type PropsWithChildren,
} from 'react';
import {
  Formik,
  type FormikConfig, type FormikProps, type FormikErrors,
} from 'formik';
import clonedeep from 'lodash.clonedeep';
import set from 'lodash.set';

import { View } from 'components/Themed';

import { type ViewStyle } from 'react-native';
import { FormContext, useField, useButton } from './utils/context';
import Button from './controls/Button';
import TextInput from './inputs/TextInput';
import BooleanInput from './inputs/BooleanInput';
import PhotoInput from './inputs/PhotoInput';
import DateInput from './inputs/DateInput';
import DropdownInput from './inputs/DropdownInput';
import TagsInput from './inputs/TagsInput';

export {
  Button,
  TextInput,
  BooleanInput,
  PhotoInput,
  DateInput,
  DropdownInput,
  TagsInput,
  useField,
  useButton,
};

export {
  FormikProps,
};

interface FormProps<Values = Record<string, any>> extends ViewStyle, Omit<FormikConfig<Values>, 'initialValues' | 'validate' | 'innerRef'> {
  initialValues?: Values | any,
  values?: Values | any,
  onChange?: (values: Values) => void,
  isLocked?: boolean,
  validate?: (values: Values, updatedField?: string) => void | Record<string, any> | Promise<FormikErrors<Values>>
  innerRef?: {
    current: any,
  }
  style?: ViewStyle
}

interface Store {
  updated: {
    field: string,
    value: any,
  }
}

export const Form = <Values, >(props: PropsWithChildren<FormProps<Values>>): ReactElement => {
  const {
    children,
    initialValues = {},
    onSubmit,
    onReset,
    onChange,
    validationSchema,
    validate,
    validateOnChange,
    validateOnBlur,
    validateOnMount,
    initialErrors,
    initialStatus,
    initialTouched,
    isInitialValid,
    enableReinitialize,
    innerRef,
    component,
    render,
    isLocked,
    values: formValues,
    style,
  } = props;

  const storeRef = useRef<Partial<Store>>({});
  const formRef = useRef<any>(null);

  useEffect(() => {
    if (innerRef && 'current' in innerRef) {
      innerRef.current = formRef.current;
    }
  }, [innerRef]);

  useEffect(() => {
    const { current: form } = formRef;
    if (!form || !formValues) {
      return;
    }
    form.setValues(formValues);
  }, [formValues]);

  useEffect(() => {
    const { current: form } = formRef;
    if (!form) {
      return;
    }
    if (isInitialValid) {
      form.validateForm();
    }
  }, [isInitialValid]);

  const handleValidate = useCallback((values: any) => {
    if (!storeRef.current?.updated?.field && validate) {
      const result = validate?.(values) as Record<string, any>;
      Object.keys(result).forEach((key) => {
        result[key] = '';
      });
      return result;
    }
    return validate?.(values, storeRef.current?.updated?.field);
  }, [validate]);

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      initialStatus={initialStatus}
      initialTouched={initialTouched}
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={validationSchema}
      validate={handleValidate}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      validateOnMount={validateOnMount}
      enableReinitialize={enableReinitialize}
      innerRef={formRef}
      component={component}
      render={render}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        isValid,
        setFieldValue,
        errors,
        values,
        submitForm,
      }) => (
        <FormContext.Provider
          value={{
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isValid,
            setFieldValue: (field: string, value: any, shouldValidate?: boolean): void => {
              storeRef.current.updated = {
                field,
                value,
              };
              setFieldValue(field, value, shouldValidate);
              const newValue = clonedeep(values);
              set(newValue, field, value);
              onChange?.(newValue);
              // @todo fix it, need to call after update
              setTimeout(() => formRef?.current?.validateForm?.(), 10);
            },
            errors,
            values,
            isLocked,
            submitForm,
          }}
        >
          <View style={style}>{children}</View>
        </FormContext.Provider>
      )}
    </Formik>
  );
};
