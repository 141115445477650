import {
  memo, useCallback,
} from 'react';
import {
  type StyleProp, type ViewStyle, type GestureResponderEvent,
  StyleSheet, View,
} from 'react-native';

import { saveLastRoute, unit } from 'utils';
import { useRoute } from 'navigation/hooks';
import {
  isCommunityCollectionScreen,
  isCommunityPeersScreen,
} from 'navigation/guards';

import Icon from 'ui/Icon';
import { Text } from 'components/Themed';

import { dispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import { navigate } from 'navigation/methods';

import TutorialStep from 'components/TutorialStep';
import MenuButton from '../../elements/MenuButton';
import { Typography } from '@mui/joy';

type CommunityPanelProps = {
  style?: StyleProp<ViewStyle>,
  isSlim?: boolean,
}

const CommunityPanel = (props: CommunityPanelProps) => {
  const {
    style,
    isSlim,
  } = props;
  const userId = useSelector(userStore.selectors.getIdByLogin('my'));
  const route = useRoute<'CommunityPeers' | 'News'>();

  const handleCommunity = useCallback(() => {
    if (!userId) {
      saveLastRoute();
    }
    dispatcher.search.clear();
    navigate('CommunityCollection');
  }, [userId]);

  const handlePeers = useCallback(() => {
    if (!userId) {
      saveLastRoute();
    }
    dispatcher.search.clear();
    navigate('CommunityPeers');
  }, [userId]);

  return (
    <View style={style}>
      {!isSlim && (
        <View style={styles.header}>
          <Typography fontSize={16} fontWeight={400} textColor="text.secondary">Community</Typography>
        </View>
      )}
      <TutorialStep placement="left" stepIndex={3} title="Community" description="Check recent collections">
        <MenuButton
          href="/community/collections"
          onPress={handleCommunity}
          iconLeft={<Icon name="users" fw={24} size="lg" weight={isCommunityCollectionScreen(route) ? 'regular' : 'light'} color={isCommunityCollectionScreen(route) ? 'primary' : 'secondary'} />}
          text="Community"
          isSlim={isSlim}
          isSelected={isCommunityCollectionScreen(route)}
        />
      </TutorialStep>
      <TutorialStep placement="left" stepIndex={2} title="Peers feed" description="Keep an eye on what your peers save.">
        <MenuButton
          href="/community/peers"
          onPress={handlePeers}
          iconLeft={<Icon name="user-group" fw={24} size="lg" weight={isCommunityPeersScreen(route) ? 'regular' : 'light'} color={isCommunityPeersScreen(route) ? 'primary' : 'secondary'} />}
          text="Peers"
          isSlim={isSlim}
          isSelected={isCommunityPeersScreen(route)}
        />
      </TutorialStep>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: unit(4),
    paddingHorizontal: unit(20),
  },
});

export default memo(CommunityPanel);
