import connect from '../../../store/utils/saga/connect';
import * as openBillingPortal from './sagas/openBillingPortal';
import * as loadState from './sagas/loadState';
import * as loadPlans from './sagas/loadPlans';
import * as subscribe from './sagas/subscribe';
import * as subscriptionUpdate from './sagas/subscriptionUpdate';
import * as cancel from './sagas/cancel';
import * as trigger from './sagas/trigger';
import * as limits from './sagas/limits';

export default connect([
  openBillingPortal,
  loadState,
  loadPlans,
  subscribe,
  subscriptionUpdate,
  cancel,
  trigger,
  limits,
]);
