import {
  type SagaReturnType,
  takeEvery, cancel,
} from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import { request } from 'services/api';

import * as actions from '../actions';

export const config = {
  action: actions.remove.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.remove>) {
  const ids = !Array.isArray(action.payload.id) ? [action.payload.id] : action.payload.id;

  if (ids.length === 0) {
    yield cancel(); return;
  }

  yield* call(() => Promise.all(ids.map((id) => (
    request.delete(`/notes/${id}`)
  ))));
}
