import {
  type CSSProperties, type ReactElement, type PropsWithChildren,
  memo, useMemo,
} from 'react';
import {
  Platform,
  StyleSheet,
} from 'react-native';

import View from '../View';

import { type BlurViewProps } from './types';

const BlurView = (props: PropsWithChildren<BlurViewProps>): ReactElement => {
  const {
    children, factor, style, ...otherProps
  } = props;

  const styleFinal = useMemo(() => {
    const result = {
      ...StyleSheet.flatten(styles.BlurView),
      ...StyleSheet.flatten(style),
    };

    if (Platform.OS === 'web' && factor) {
      (result as CSSProperties).backdropFilter = `blur(${factor / 10}rem)`;
      (result as CSSProperties).WebkitBackdropFilter = `blur(${factor / 10}rem)`;
    }

    return result;
  }, [style, factor]);

  return (
    <View style={styleFinal} {...otherProps}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  BlurView: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default memo(BlurView);
