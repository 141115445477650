import {
  memo, useCallback, useEffect, useMemo,
} from 'react';

import {
  Card, CardContent, Skeleton, Typography,
} from '@mui/joy';

import { useSelector, useDispatcher } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';
import * as playlistStore from 'store/nodes/playlist';
import * as noteStore from 'store/nodes/note';

import { guard } from 'utils';
import TextExpand from 'ui/TextExpand';

import HtmlReader from 'components/HtmlReader';
import openLink from '../model/openLink';

type SourceCardProps = {
  type: 'Material' | 'Collection' | 'Note',
  id: number;
}

const SourceCard = (props: SourceCardProps) => {
  const {
    type,
    id,
  } = props;

  const dispatcher = useDispatcher();

  const data = useSelector((state) => {
    if (type === 'Material') {
      return contentStore.selectors.dataById(id)(state);
    }
    if (type === 'Collection') {
      return playlistStore.selectors.dataById(id)(state);
    }
    if (type === 'Note') {
      return noteStore.selectors.dataById(id)(state);
    }
    return null;
  });

  const handleClick = useCallback(() => {
    if (!data?.id) {
      return;
    }
    const resourceId = data.id;
    if (guard.isMaterial(data) && data?.type) {
      const resourceType = data.type.toLowerCase?.();
      openLink(`/content/${resourceType}/${resourceId}`, '_blank');
    }
    if (guard.isCollection(data)) {
      openLink(`/playlist/${resourceId}`, '_blank');
    }
  }, [data]);

  useEffect(() => {
    if (!id || data) {
      return;
    }
    if (type === 'Material') {
      dispatcher.content.loadById(id);
    }
    if (type === 'Collection') {
      dispatcher.playlist.loadById({ id });
    }
    if (type === 'Note') {
      dispatcher.note.loadById({ id });
    }
  }, [id, type, data]);

  const skeletonRender = useMemo(() => {
    if (data) {
      return null;
    }
    return (
      <>
        <TextExpand
          slotsProps={{
            typography: {
              level: 'title-sm',
            },
          }}
          lines={3}
        >
          <Skeleton loading>
            0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
          </Skeleton>
        </TextExpand>
        <TextExpand
          slotsProps={{
            typography: {
              level: 'body-xs',
              mt: 1,
            },
          }}
          lines={2}
        >
          <Skeleton loading>
            <Typography fontWeight={400} color="neutral">
              0 0 0 0 0
            </Typography>
          </Skeleton>
        </TextExpand>
      </>
    );
  }, [data]);

  const materialRender = useMemo(() => {
    if (!guard.isMaterial(data)) {
      return null;
    }
    return (
      <>
        <TextExpand
          slotsProps={{
            typography: {
              level: 'title-sm',
            },
          }}
          lines={3}
        >
          {data?.title}
        </TextExpand>
        <TextExpand
          slotsProps={{
            typography: {
              level: 'body-xs',
              mt: 1,
            },
          }}
          lines={2}
        >
          <Typography fontWeight={400} color="neutral">
            {data?.source?.name}
          </Typography>
        </TextExpand>
      </>
    );
  }, [data]);

  const collectionRender = useMemo(() => {
    if (!guard.isCollection(data)) {
      return null;
    }
    return (
      <>
        <TextExpand
          slotsProps={{
            typography: {
              level: 'title-sm',
            },
          }}
          lines={3}
        >
          {data?.title}
        </TextExpand>
        <TextExpand
          slotsProps={{
            typography: {
              level: 'body-xs',
              mt: 1,
            },
          }}
          lines={2}
        >
          <Typography fontWeight={400} color="neutral">
            {data?.user?.name}
          </Typography>
        </TextExpand>
      </>
    );
  }, [data]);

  const noteRender = useMemo(() => {
    if (!guard.isNote(data)) {
      return null;
    }
    return (
      <>
        {/* <HtmlReader html={data?.text} /> */}
        <Typography fontWeight={400} color="neutral">
          {data?.user?.name}
        </Typography>
      </>
    );
  }, [data]);

  return (
    <Card
      onClick={handleClick}
      size="sm"
      variant="outlined"
      sx={{
        width: 128,
        background: 'transparent',
        cursor: !data ? 'not-allowed' : 'pointer',
        transition: '300ms background-color ease',
        '&:hover': {
          backgroundColor: (theme) => `var(--source-card-softHoverBg, ${theme.palette.background.level1})`,
        },
        '&:active': {
          transition: 'none',
          backgroundColor: (theme) => `var(--source-card-softActiveBg, ${theme.palette.background.level2})`,
        },
        borderRadius: (theme) => theme.radius.md,
        '--joy-lineHeight-md': 1,
      }}
    >
      <CardContent sx={{ justifyContent: 'space-between' }}>
        {skeletonRender}
        {materialRender}
        {collectionRender}
        {noteRender}
      </CardContent>
    </Card>
  );
};

export default memo(SourceCard);
