import { memo } from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet,
} from 'react-native';

import { useResponsive } from 'hooks';
import { capitalize, unit } from 'utils';

import { Text, View } from 'components/Themed';
import Icon from 'components/LegacyIcon';
import { useRoute } from 'navigation/hooks';
import ListLayoutSwitcher from 'components/ListLayoutSwitcher';

type TopBarDesktopProps = {
  style?: StyleProp<ViewStyle>,
}

const TopBarDesktop = (props: TopBarDesktopProps) => {
  const {
    style,
  } = props;

  const route = useRoute<'News'>();
  const responsive = useResponsive();

  if (!route.params.topic || responsive.is.mobileL) {
    return null;
  }

  return (
    <View style={[styles.wrapper, style]}>
      <View style={styles.title}>
        <Icon name="MarkOutline" size={24} style={styles.icon} />
        <Text size={22} weight="semi" darkColor="#ffffff" lightColor="#000000">{capitalize(route.params.topic)}</Text>
      </View>
      <ListLayoutSwitcher stateKey="news.list.mode" style={{ marginTop: unit(2), marginRight: unit(-4) }} />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginTop: unit(16),
    marginBottom: unit(20),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: unit(6),
    marginLeft: unit(-2),
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default memo(TopBarDesktop);
