import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import { type CollectionType } from 'app/entities';

import * as userStore from 'store/nodes/user';

const selector = createCachedSelector(
  (state: DefaultRootState) => userStore.selectors.getCollections('my')(state),
  (_: DefaultRootState, materialId: number) => materialId,
  (collections, materialId) => {
    const result: CollectionType[] = [];
    (collections || []).forEach((collection) => {
      const isMaterialInCollection = Boolean(
        collection.materialsMetadata.find((item) => item.id === materialId && !item.isSmartListRecommendation),
      );
      if (!isMaterialInCollection) {
        return;
      }
      result.push(collection);
    });
    return result;
  },
)(
  (state, login) => login,
);

export default (materialId?: number) => (state: DefaultRootState) => selector(state, materialId || 0);
