import { memo, useCallback } from 'react';
import { Typography } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';

type UpgradeToProLinkProps = {
}

const UpgradeToProLink = (props: UpgradeToProLinkProps) => {
  const dispatcher = useDispatcher();

  const handleUpgradeClick = useCallback(() => {
    dispatcher.subscription.showPricing();
  }, []);

  return (
    <Typography
      display="unset"
      component="span"
      color="primary"
      fontWeight={500}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      onClick={handleUpgradeClick}
    >
      Upgrade to IKI Pro.
    </Typography>
  );
};

export default memo(UpgradeToProLink);
