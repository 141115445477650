import {
  type ReactElement,
} from 'react';
import {
  StyleSheet,
} from 'react-native';

import { View } from 'components/Themed';
import { unit } from 'utils';

const Header = (): ReactElement => (
  <View style={styles.Header} />
);

const styles = StyleSheet.create({
  Header: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#4E4E53',
    marginHorizontal: unit(16),
  },
});

export default Header;
