import { memo, useCallback } from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet,
} from 'react-native';
import { MacScrollbar } from 'mac-scrollbar';
import { useSelector } from 'react-redux';

import { styleSheetToCss, unit } from 'utils';
import { useColorScheme, useResponsive } from 'hooks';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as libraryStore from 'store/nodes/library';

import { View } from 'components/Themed';
import ButtonSwitcher from 'components/ButtonSwitcher';

import { type FontSizeVariant } from 'font';

type TagsFilterProps = {
  style?: StyleProp<ViewStyle>,
  textSize?: FontSizeVariant,
  flowItems?: boolean,
};

const TagsFilter = (props: TagsFilterProps) => {
  const {
    style,
    textSize,
    flowItems,
  } = props;

  const responsive = useResponsive();
  const dispatcher = useDispatcher();
  const colorScheme = useColorScheme();

  const panelTagItems = useSelector(libraryStore.selectors.panelTags);
  const enabledFilterIds = useSelector(libraryStore.selectors.enabledFilterIds);

  const handlePress = useCallback((value: string) => {
    dispatcher.library.toggleFilter(value);
  }, []);

  if (flowItems) {
    return (
      <View style={[styles.container, style]}>
        <View style={styles.filter}>
          <ButtonSwitcher
            style={styles.tags}
            type="check"
            variant="button"
            textSize={textSize}
            items={panelTagItems}
            itemLabel="label"
            itemValue="id"
            flowItems={flowItems}
            value={enabledFilterIds}
            size="sm"
            onChange={handlePress}
          />
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.container, style]}>
      <View style={styles.filter}>
        <MacScrollbar
          skin={colorScheme}
          style={styleSheetToCss([styles.scroll, responsive.is.mobileL && { marginHorizontal: unit(-16), paddingHorizontal: unit(16) }])}
        >
          <ButtonSwitcher
            style={styles.tags}
            type="check"
            variant="button"
            textSize={textSize}
            items={panelTagItems}
            itemLabel="label"
            itemValue="id"
            flowItems={flowItems}
            value={enabledFilterIds}
            size="sm"
            onChange={handlePress}
          />
        </MacScrollbar>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
  },
  filter: {},
  tags: {
  },
  scroll: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
  },
});

export default memo(TagsFilter);
