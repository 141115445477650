import { createReducer } from '@reduxjs/toolkit';
import md5 from 'blueimp-md5';

import { guard } from 'utils';

import type { ActivityStore } from './types';
import * as actions from './actions';

const initialState: ActivityStore = {
  list: {
    user: 'my',
    sequence: [],
    map: {},
    paginationInfo: {
      hasNext: false,
      nextCursor: null,
    },
    meta: {
      resultWithFilterHash: md5(JSON.stringify('{}')),
      isFiltersLoading: false,
      isFiltersLoaded: false,
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
  filter: {
    data: {},
    enabledMap: {},
    meta: {
      isLoading: false,
      isLoaded: false,
    },
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.clear, (state) => {
    state.list = {
      user: 'my',
      sequence: [],
      map: {},
      paginationInfo: {
        hasNext: false,
        nextCursor: null,
      },
      meta: {
        resultWithFilterHash: md5(JSON.stringify('{}')),
        isFiltersLoading: false,
        isFiltersLoaded: false,
        isFirstPageLoading: false,
        isFirstPageLoaded: false,
        isNextPageLoading: false,
        isNextPageLoaded: false,
      },
    };
  })
  .addCase(actions.loadList, (state, action) => {
    const { user } = action.payload;
    state.list.user = user || 'my';
    state.list.meta.isFirstPageLoading = true;
    state.list.paginationInfo.hasNext = false;
    state.list.paginationInfo.nextCursor = null;
  })
  .addCase(actions.loadNextPage, (state, action) => {
    if (action?.payload?.reload) {
      state.list.meta.isFirstPageLoading = true;
    } else {
      state.list.meta.isNextPageLoading = true;
    }
  })
  .addCase(actions.loadNextPageDone, (state, action) => {
    const { reload } = action.payload;
    state.list.meta.isFirstPageLoading = false;
    state.list.meta.isNextPageLoading = false;
    if (reload) {
      state.list.meta.isFirstPageLoaded = true;
    } else {
      state.list.meta.isNextPageLoaded = true;
    }
    state.list.meta.resultWithFilterHash = md5(JSON.stringify(state.filter.enabledMap));
  })
  .addCase(actions.addPage, (state, action) => {
    if (!action.payload.items) {
      return;
    }
    if (action.meta.reload) {
      state.list.sequence = [];
      state.list.map = {};
    }
    action.payload.items.forEach((item) => {
      if (item.id in state.list.map) {
        return;
      }
      let eventType: 'created' | 'completed' | 'commented' | 'unknown' = 'unknown';
      let resourceType: 'material' | 'collection' | 'unknown' = 'unknown';
      let resourceId = 0;
      let comment = null;
      if (guard.isActivityNewMaterial(item)) {
        eventType = 'created';
        resourceId = item.content.id;
        resourceType = 'material';
      }
      if (guard.isActivityNewCollection(item)) {
        eventType = 'created';
        resourceId = item.playlist.id;
        resourceType = 'collection';
      }
      if (guard.isActivityCompletedMaterial(item)) {
        eventType = 'completed';
        resourceId = item.content.id;
        resourceType = 'material';
        comment = item.comment.user?.id ? {
          userId: item.comment.user.id,
          text: item.comment.text || '',
        } : null;
      }
      if (guard.isActivityCompletedCollection(item)) {
        eventType = 'completed';
        resourceId = item.playlist.id;
        resourceType = 'collection';
        comment = item.comment.user?.id ? {
          userId: item.comment.user.id,
          text: item.comment.text || '',
        } : null;
      }
      if (guard.isActivityCommentMaterial(item)) {
        eventType = 'commented';
        resourceId = item.content.id;
        resourceType = 'material';
        comment = item.comment.user?.id ? {
          userId: item.comment.user.id,
          text: item.comment.text || '',
        } : null;
      }
      if (guard.isActivityCommentCollection(item)) {
        eventType = 'commented';
        resourceId = item.playlist.id;
        resourceType = 'collection';
        comment = item.comment.user?.id ? {
          userId: item.comment.user.id,
          text: item.comment.text || '',
        } : null;
      }
      if (!resourceId) {
        return;
      }
      state.list.sequence.push({
        eventId: item.id,
        eventType,
        eventTime: item.createdAt,
        resourceId,
        resourceType,
        userId: item.user.id,
        comment: comment || undefined,
      });
      state.list.map[item.id] = state.list.sequence.length - 1;
    });
    state.list.paginationInfo.nextCursor = action.payload.paginationInfo.nextCursor;
    state.list.paginationInfo.hasNext = action.payload.paginationInfo.hasNext;
  }));
