import {
  type CSSProperties,
  memo, type ReactNode, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet, Platform, type GestureResponderEvent,
} from 'react-native';

import { guard, unit } from 'utils';

import { View } from 'components/Themed';
import HeaderToolbar from 'components/Card/elements/HeaderToolbar';
import TextExpand from 'ui/TextExpand';

import { useColorScheme } from 'hooks';

import { type NoteType } from 'app/entities';

type RegularLayoutProps = {
  data?: NoteType,
  radius?: number,
  isHoverMode?: boolean,
  isTouchMode?: boolean,
  onOptionsPress?: (event: GestureResponderEvent) => void,
  OverlayComponent?: ReactNode
}

const RegularLayout = (props: RegularLayoutProps) => {
  const {
    data,
    radius,
    isHoverMode,
    isTouchMode,
    onOptionsPress,
    OverlayComponent,
  } = props;

  const theme = useColorScheme();

  const panelStyle = useMemo((): StyleProp<ViewStyle> => [
    styles.panel,
    {
      borderRadius: unit(6),
    },
  ], []);

  const containerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> | CSSProperties = ({
      ...StyleSheet.flatten(styles.NoteLayout),
    });
    if (radius) {
      result.borderRadius = radius;
    }
    if (guard.isWebStyleProp(result) && theme === 'light') {
      result.boxShadow = '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.15)';
    }
    return result as StyleProp<ViewStyle>;
  }, [theme, radius]);

  const toolbarStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.toolbar),
      opacity: isHoverMode ? 1 : 0,
    };
    if (guard.isWebStyleProp(result)) {
      result.transition = 'opacity 300ms ease';
    }
    return result;
  }, [isHoverMode, isTouchMode]);

  if (!data) {
    return (
      <View style={styles.wireframe} lightColor="#ffffff50" darkColor="#ffffff20" />
    );
  }

  return (
    <View
      style={containerStyle}
    >
      {!!OverlayComponent && (
        <View pointerEvents="none" style={styles.overlay}>{OverlayComponent}</View>
      )}
      <View style={panelStyle} darkColor="#202020" lightColor="#ffffff">
        <TextExpand
          lines={10}
          slotsProps={{
            typography: {
              parseHtml: true,
            },
          }}
        >
          {data.text}
        </TextExpand>
      </View>
      {!!data && (
        <HeaderToolbar
          style={toolbarStyle}
          align="right"
          hasOptions
          hasSource={false}
          onOptionsPress={onOptionsPress}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  NoteLayout: {
    position: 'relative',
    zIndex: Platform.OS === 'web' ? 'unset' as unknown as number : 0,
    borderRadius: unit(6),
  },
  wireframe: {
    width: '100%',
    aspectRatio: 6 / 7,
    borderRadius: unit(6),
  },
  overlay: {
    zIndex: 10,
  },
  expand: {
    flex: 1,
  },
  panel: {
    borderRadius: unit(6),
    paddingHorizontal: unit(20),
    paddingTop: unit(16),
    paddingBottom: unit(16),
  },
  note: {
    marginBottom: unit(11),
  },
  toolbar: {
    position: 'absolute',
    top: unit(10),
    right: unit(16),
    left: unit(16),
    zIndex: 2,
  },
});

export default memo(RegularLayout);
