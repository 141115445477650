import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type NoteType } from 'app/entities';

export type ResourceType = 'collection' | 'material' | 'note' | 'library' | 'web' | 'global-fulltext' | 'library-fulltext';

type RelationType = {
  resourceType: ResourceType,
  resourceId: number,
};

export type NoteStore = {
  data: Record<number, NoteType>,
  relation: { resourceType: ResourceType, resourceId: number, noteId: number }[],
  meta: {
    loadingMap: Record<number | string, boolean>
  }
}

const initialState: NoteStore = {
  data: {},
  relation: [],
  meta: {
    loadingMap: {},
  },
};

const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    add(state, action: PayloadAction<{ text: string, relation: RelationType }>) {
      const { relation } = action.payload;
      const key = `${relation.resourceType}-${relation.resourceId}`;
      state.meta.loadingMap[key] = true;
    },
    addDone(state, action: PayloadAction<{ relation: RelationType }>) {
      const { relation } = action.payload;
      const key = `${relation.resourceType}-${relation.resourceId}`;
      delete state.meta.loadingMap[key];
    },
    update(state, action: PayloadAction<{ id: number, text: string }>) {
      const { id } = action.payload;
      state.meta.loadingMap[id] = true;
    },
    remove(state, action: PayloadAction<{ id: number, force?: boolean }>) {
      const { id } = action.payload;
      state.meta.loadingMap[id] = true;
    },
    removeDone(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      if (!id) {
        return;
      }
      delete state.meta.loadingMap[id];
    },
    setItem(state, action: PayloadAction<
      { data: NoteType, relation?: RelationType } | { data: NoteType, relation?: RelationType }[]
    >) {
      const payload = Array.isArray(action.payload) ? action.payload : [action.payload];
      payload.forEach(({ data, relation }) => {
        if (!data) {
          return;
        }
        delete state.meta.loadingMap[data.id];
        state.data[data.id] = {
          ...data,
          type: 'note',
        };
        const noteRelationIndex = state.relation.findIndex((item) => (item.noteId === data.id));
        if (!relation && noteRelationIndex > -1) {
          state.relation.splice(noteRelationIndex, 1);
          return;
        }
        if (!relation) {
          return;
        }
        const { resourceType, resourceId } = relation;
        if (noteRelationIndex !== -1) {
          return;
        }
        state.relation.push({
          resourceType,
          resourceId: Number(resourceId),
          noteId: data.id,
        });
      });
    },
    removeItem(state, action: PayloadAction<
      { id: number } | { id: number }[]
    >) {
      const payload = Array.isArray(action.payload) ? action.payload : [action.payload];
      payload.forEach(({ id }) => {
        delete state.data[id];
        const noteRelationIndex = state.relation.findIndex((item) => (item.noteId === id));
        if (noteRelationIndex > -1) {
          state.relation.splice(noteRelationIndex, 1);
        }
      });
    },
    loadById(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      state.meta.loadingMap[id] = true;
    },
    loadByIdDone(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      delete state.meta.loadingMap[id];
    },
    loadByResource(state, action: PayloadAction<{ resourceType: ResourceType, resourceId: number }>) {
      const { resourceType, resourceId } = action.payload;
      const key = `${resourceType}-${resourceId}`;
      state.meta.loadingMap[key] = true;
    },
    loadByResourceDone(state, action: PayloadAction<{ resourceType: ResourceType, resourceId: number }>) {
      const { resourceType, resourceId } = action.payload;
      const key = `${resourceType}-${resourceId}`;
      delete state.meta.loadingMap[key];
    },
  },
});

export const {
  reducer,
  actions,
} = noteSlice;
