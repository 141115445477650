import {
  memo, useCallback, useEffect,
} from 'react';
import {
  type StyleProp, type TextStyle, type GestureResponderEvent,
  StyleSheet, TouchableOpacity,
} from 'react-native';
import { useSelector } from 'react-redux';

import type {
  CollectionType,
} from 'app/entities';

import { unit } from 'utils';
import * as collectionStore from 'store/nodes/playlist';
// import Icon from 'components/LegacyIcon';
import { View } from 'components/Themed';
import { Box, IconButton } from '@mui/joy';
import Icon from 'ui/Icon';
import * as playlistStore from 'store/nodes/playlist';
import { useDispatcher } from 'store/utils/redux/hooks';

type DeleteProps = {
  style?: StyleProp<TextStyle>,
  id: number,
  onPress: (context: CollectionType) => void,
}

const Delete = (props: DeleteProps) => {
  const {
    style,
    id,
    onPress,
  } = props;
  const dispatcher = useDispatcher();
  const data = useSelector(collectionStore.selectors.dataById(id));
  const userPermission = useSelector(playlistStore.selectors.userPermission(id, 'my'));

  useEffect(() => {
    dispatcher.playlist.loadById({ id });
  }, [id]);

  const handlePress = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    if (!data) {
      return;
    }
    onPress?.(data);
  }, [onPress, data]);

  if (!data || !userPermission.hasDelete) {
    return null;
  }

  return (
    <Box sx={{
      position: 'relative',
    }}
    >
      <IconButton
        onClick={handlePress}
        variant="soft"
        sx={{
          opacity: '0.7',
          borderRadius: (theme) => theme.radius.md,
        }}
      >
        <Icon name="trash" color="red" />
      </IconButton>
    </Box>
  );
};

export default memo(Delete);
