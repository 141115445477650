import { memo, useCallback } from 'react';
import {
  type StyleProp, type ViewStyle, type GestureResponderEvent,
  StyleSheet,
} from 'react-native';

import { unit } from 'utils';
import { useSearchFilters } from 'hooks';

import { Text, View } from 'components/Themed';

import MenuButton from '../../elements/MenuButton';

type AskFiltersPanelProps = {
  style?: StyleProp<ViewStyle>,
  isSlim?: boolean,
}

const AskFiltersPanel = (props: AskFiltersPanelProps) => {
  const {
    style,
    isSlim,
  } = props;
  const filters = useSearchFilters();

  const handlePress = useCallback((_event: GestureResponderEvent, context: { id: string }) => {
    const { method, change, toggle } = filters;
    const { id } = context;
    if (['GlobalTitle', 'GlobalFullText'].includes(method)) {
      change(id);
    }
    if (['LibraryTitle', 'LibraryFullText'].includes(method)) {
      toggle(id);
    }
  }, [filters.method, filters.toggle, filters.change]);

  return (
    <View style={style}>
      {!isSlim && (
        <Text style={styles.header} size={17} weight="semi" lightColor="#9d9d9d" darkColor="#9d9d9d">Filters</Text>
      )}
      {isSlim && (
        <Text style={styles.headerSlim} size={16} weight="medium" align="center" lightColor="#9d9d9d" darkColor="#9d9d9d">Filters</Text>
      )}
      {filters.items.map((item) => (
        <MenuButton
          key={item.id}
          isSlim={isSlim}
          context={item}
          onPress={handlePress}
          iconLeft={item.icon}
          text={item.label}
          isEnabled={filters.value.includes(item.id)}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    marginBottom: unit(4),
    paddingHorizontal: unit(20),
  },
  headerSlim: {
    marginBottom: unit(8),
  },
});

export default memo(AskFiltersPanel);
