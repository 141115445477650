import { createAction } from '@reduxjs/toolkit';

import type {
  PaginationType,
  UserType,
  RelationVariants,
} from 'app/entities';

export const clear = createAction('RELATION/CLEAR');

export const load = createAction('RELATION/LOAD', (
  login: string,
) => ({
  payload: {
    login,
  },
}));

export const loadDone = createAction('RELATION/LOAD_DONE');

export const loadNextPage = createAction('RELATION/LOAD_NEXT_PAGE', (
  listType: RelationVariants,
) => ({
  payload: {
    listType,
  },
}));

export const loadNextPageDone = createAction('RELATION/LOAD_NEXT_PAGE_DONE', (
  listType: RelationVariants,
) => ({
  payload: {
    listType,
  },
}));

export const addPage = createAction('RELATION/SET_PAGE', (
  listType: RelationVariants,
  items: UserType[],
  paginationInfo: PaginationType,
) => ({
  payload: {
    listType,
    items,
    paginationInfo,
  },
}));
