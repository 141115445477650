import { memo, useCallback, useRef } from 'react';
import {
  ButtonGroup, Card, CardActions,
} from '@mui/joy';
import Box from '@mui/joy/Box';
import { type EditorInputMethodsType } from 'ui/EditorInput';
import IconButton from '@mui/joy/IconButton';

import { useEditorState } from 'hooks';

import Icon from 'ui/Icon';
import EditorInput from 'ui/EditorInput';

type NewNoteProps = {
  onAdd?: (text: string) => void,
  sx?: {
    flex: number,
  },
};

const NewNote = (props: NewNoteProps) => {
  const {
    onAdd,
    sx,
  } = props;

  const editorRef = useRef<EditorInputMethodsType | null>(null);
  const { html, hasContent, onChange } = useEditorState();

  const handleAdd = useCallback(() => {
    if (!hasContent) {
      return;
    }
    onAdd?.(html);
    editorRef.current?.clear({ focus: true });
  }, [onAdd, hasContent, html]);

  return (
    <Card
      variant="soft"
      size="sm"
      sx={{
        mx: 3,
        borderRadius: 26,
        ...sx,
      }}
    >
      <CardActions sx={{ minHeight: 32, '--Card-padding': 0, alignItems: 'flex-end' }}>
        <Box display="flex" flexDirection="column" gap={1} ml={0.5} my={0.5} fontSize={16} flex={1} alignItems="stratch">
          <EditorInput ref={editorRef} onChange={onChange} placeholder="Add new note" />
        </Box>
        <ButtonGroup variant="soft" color="neutral" spacing={1} sx={{ '--ButtonGroup-radius': '50%', '--ButtonGroup-separatorSize': 0 }}>
          <IconButton onClick={handleAdd} sx={{ my: -0.25 }} disabled={!hasContent}>
            <Icon name="plus" color="inherit" />
          </IconButton>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default memo(NewNote);
