import {
  type ChangeEvent, memo, useCallback, type MouseEvent,
} from 'react';
import {
  Box, Button, Radio, RadioGroup, ToggleButtonGroup,
} from '@mui/joy';
import { type BoxProps } from '@mui/joy/Box';

import { useSearchMethod } from 'hooks';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as copilotStore from 'store/nodes/copilot';

const SearchMethods = (props: BoxProps) => {
  const { sx, ...rest } = props;

  const isAnswerTyping = useSelector(copilotStore.selectors.isAnswerTyping([{ type: 'web' }, { type: 'library-fulltext' }, { type: 'global-fulltext' }]));

  const {
    scope, type, setScope, setType,
  } = useSearchMethod();

  const userProfile = useSelector(userStore.selectors.getMy);

  const handleScopeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setScope(event.target.value as 'Web' | 'Library' | 'Global');
  }, [setScope]);

  const handleScopeMobileChange = useCallback((event: MouseEvent<HTMLElement>, value: 'Web' | 'Global' | 'Library' | null) => {
    if (!value) {
      return;
    }
    setScope(value);
  }, [setScope]);

  const handleMethodMobileChange = useCallback((event: MouseEvent<HTMLElement>, value: 'FullText' | 'Title' | null) => {
    if (!value) {
      return;
    }
    setType(value);
  }, [setType]);

  const handleMethodChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value as 'FullText' | 'Title');
  }, [setType]);

  const isLegalUser = userProfile?.type === 'legal';

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={6}
      sx={{
        '@media (max-width: 599px)': {
          gap: 0.75,
        },
        ...sx,
      }}
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignSelf="center"
        gap={2}
      >
        <ToggleButtonGroup
          defaultValue="Web"
          variant="soft"
          size="sm"
          sx={{
            '--Button-minHeight': '24px',
            '--joy-fontSize-sm': '12px',
            '--joy-fontWeight-lg': 400,
            '--ButtonGroup-radius': '40px',
            '@media (min-width: 600px)': {
              display: 'none',
            },
          }}
          onChange={handleScopeMobileChange}
          value={scope}
          orientation="horizontal"
        >
          <Button disabled={isAnswerTyping} value="Web">Web</Button>
          <Button disabled={!userProfile || isAnswerTyping} value="Library">Library</Button>
          <Button disabled={isAnswerTyping} value="Global">IKI</Button>
        </ToggleButtonGroup>
        <RadioGroup
          orientation="horizontal"
          defaultValue="Web"
          value={scope}
          sx={{
            '@media (max-width: 599px)': {
              display: 'none',
            },
          }}
          onChange={handleScopeChange}
        >
          <Radio
            disabled={isAnswerTyping}
            label="Web"
            color="neutral"
            value="Web"
          />
          <Radio
            disabled={!userProfile || isAnswerTyping}
            label="Library"
            color="neutral"
            value="Library"
          />
          <Radio
            disabled={isAnswerTyping}
            label="IKI"
            color="neutral"
            value="Global"
          />
        </RadioGroup>
      </Box>
      {scope !== 'Web' && (
        <>
          <ToggleButtonGroup
            defaultValue="FullText"
            size="sm"
            variant="soft"
            sx={{
              '--Button-minHeight': '24px',
              '--joy-fontSize-sm': '12px',
              '--joy-fontWeight-lg': 400,
              '--ButtonGroup-radius': '40px',
              '@media (min-width: 600px)': {
                display: 'none',
              },
            }}
            onChange={handleMethodMobileChange}
            value={type}
            orientation="horizontal"
          >
            <Button value="FullText">Full text</Button>
            <Button value="Title">By title</Button>
          </ToggleButtonGroup>
          <RadioGroup
            orientation="horizontal"
            value={type}
            onChange={handleMethodChange}
            sx={{
              ml: 3,
              '@media (max-width: 599px)': {
                display: 'none',
              },
            }}
          >
            <Radio
              disabled={isAnswerTyping}
              label="Full text"
              color="neutral"
              value="FullText"
            />
            {!isLegalUser && (
              <Radio
                disabled={isAnswerTyping}
                label="By title"
                color="neutral"
                value="Title"
              />
            )}
          </RadioGroup>
        </>
      )}
    </Box>
  );
};

export default memo(SearchMethods);
