import { memo, useCallback } from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet,
} from 'react-native';
import pluralize from 'pluralize';

import { unit } from 'utils';
import { useRecommendedFilters } from 'hooks';

import { Text, View } from 'components/Themed';

import MenuButton from '../../elements/MenuButton';

type IconName =
  | 'GridView'
  | 'Playlist28'
  | 'School28'
  | 'ArticleOutline28'
  | 'YoutubeOutline28'
  | 'SourceOutline28';

type StaffPicksFiltersPanelProps = {
  style?: StyleProp<ViewStyle>,
  isSlim?: boolean,
}

const StaffPicksFiltersPanel = (props: StaffPicksFiltersPanelProps) => {
  const {
    style,
    isSlim,
  } = props;
  const { items, selectedValue, handleChange } = useRecommendedFilters();

  const handlePress = useCallback((event: any, context: any) => {
    handleChange(context.data);
  }, [handleChange]);

  const getIconName = useCallback((label: string) => ({
    all: 'GridView',
    collection: 'Playlist28',
    course: 'School28',
    article: 'ArticleOutline28',
    video: 'YoutubeOutline28',
    source: 'SourceOutline28',
  }[label.toLowerCase()] || 'Source28') as IconName, []);

  return (
    <View style={style}>
      {!isSlim && <Text style={styles.header} size={17} weight="semi" lightColor="#9d9d9d" darkColor="#9d9d9d">Filters</Text>}
      {isSlim && <Text style={styles.headerSlim} size={16} weight="medium" align="center" lightColor="#9d9d9d" darkColor="#9d9d9d">Filters</Text>}
      {items.map((item) => (
        <MenuButton
          key={item}
          isSlim={isSlim}
          context={{ data: item }}
          onPress={handlePress}
          iconLeft={getIconName(item)}
          text={item.toLowerCase() === 'all' ? item : pluralize(item)}
          isEnabled={item?.toLowerCase() === selectedValue?.toLowerCase()}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    marginBottom: unit(4),
    paddingHorizontal: unit(20),
  },
  headerSlim: {
    marginBottom: unit(8),
  },
});

export default memo(StaffPicksFiltersPanel);
