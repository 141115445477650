import { type DefaultRootState } from 'react-redux';
import {
  select as selectOrigin,
  call as callOrigin,
} from 'redux-saga/effects';

export function* select<T>(fn: (state: DefaultRootState) => T) {
  const res: T = yield selectOrigin(fn);
  return res;
}

export function* call<T>(fn: () => T) {
  const res: Awaited<T> = yield callOrigin(fn);
  return res;
}
