import { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import { Text, View } from 'components/Themed';
import Button from 'components/Button';
import { useNavigate } from 'navigation/hooks';

import { useColorScheme } from 'hooks';
import { saveLastRoute, unit } from 'utils';
import { Avatar } from '@mui/joy';

const UserPanel = () => {
  const navigate = useNavigate();
  const colorScheme = useColorScheme();

  const userProfile = useSelector(userStore.selectors.getMy);

  const handleProfile = useCallback(() => {
    navigate('Profile', { login: 'my' });
  }, []);

  const handleLogIn = useCallback(() => {
    saveLastRoute();
    navigate('Auth/Start');
  }, []);

  if (!userProfile) {
    return null;
  }

  return (
    <View style={styles.UserPanel}>
      <Button
        type="button-icon"
        variant="text"
        lightColor="#d2d2d2"
        darkColor="#232326"
        radius="full"
        onPress={handleProfile}
      >
        <Avatar src={userProfile?.photo?.url} size="sm" />
        <Text size={17} weight="medium" style={styles.userName}>
          {`${userProfile?.name}${userProfile?.surname ? ` ${userProfile?.surname[0]}.` : ''}`}
        </Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  UserPanel: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginTop: unit(8),
    marginRight: unit(8),
  },
  signIn: {
    justifyContent: 'flex-end',
  },
  userName: {
    marginLeft: unit(8),
  },
  logo: {
    width: unit(30),
    height: unit(30),
    backgroundColor: 'transparent',
  },
});

export default memo(UserPanel);
