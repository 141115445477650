import qs from 'query-string';

type CursorResponse<T> = {
  items: T,
  nextUrl: string | null,
  nextCursor: string | null,
  hasNext: boolean,
};

type PrepareCallback = (response: { data: any[], nextUrl: string } & Record<string, any>) => { data: any[], nextUrl: string };

export default (prepare?: PrepareCallback) => <T>(response: T): CursorResponse<T> => {
  const responseFinal = typeof prepare === 'function' ? prepare(response as any) : response;
  const { data, nextUrl } = (responseFinal || {}) as any;
  const parsedUrl = qs.parseUrl((responseFinal as any)?.nextUrl || '');
  const nextCursor = parsedUrl?.query?.cursor as string || null;
  const hasNext = !!nextCursor;
  return ({
    items: data || [],
    nextUrl: nextUrl || null,
    nextCursor,
    hasNext,
  });
};
