import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useHasSession } from 'hooks';

import * as userStore from 'store/nodes/user';

import AdaptivePage from 'components/AdaptivePage';

import CopilotButton from 'screens/Collection/elements/CopilotButton';
import ActionsBar from './elements/ActionsBar';

import LayoutDefault from './layouts/LayoutDefault';

const Collection = () => {
  const hasSession = useHasSession();
  const userData = useSelector(userStore.selectors.getByLogin('my'));

  const pagePaddingHorizontalScheme = useMemo(() => ({
    320: 16,
  }), []);

  if (hasSession && !userData) {
    return null;
  }

  return (
    <AdaptivePage
      desktopHeaderLeftPanel={userData ? <ActionsBar /> : null}
      desktopHeaderMiddlePanel={userData ? <CopilotButton /> : null}
      bodyMaxWidth={1280}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
      scrollToStart
    >
      <LayoutDefault />
    </AdaptivePage>
  );
};

export default Collection;
