import {
  memo, useCallback, useEffect, useId, useMemo, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { Platform, View } from 'react-native';
import { store } from 'store';

import { unit } from 'utils';
import { useCheckMouseInsideRect } from 'hooks';
import { useDispatcher } from 'store/utils/redux/hooks';
import * as plusStore from 'store/nodes/plus';
import * as modalStore from 'widgets/modals/store';

import DropdownPanel from 'components/DropdownPanel';

import Header from './elements/Header';
import MaterialLayout from './layouts/MaterialLayout';
import MaterialsLayout from './layouts/MaterialsLayout';
import AddLayout from './layouts/AddLayout';
import ProcessingLayout from './layouts/ProcessingLayout';

import { PlusContextProvider } from '../context';

import type { PlusProps } from '../types';

const defaultProps = {
  variant: 'dropdown',
};

const Plus = (props: PlusProps & typeof defaultProps) => {
  const {
    style,
    variant,
    hasSummary,
    hasNewCollectionButton,
    onCloseQuery,
    onStepChange,
    onTagsChange,
    onCollectionChange,
  } = props;
  const dispatcher = useDispatcher();

  const componentId = useId();
  const checkMouseInsideRect = useCheckMouseInsideRect(variant === 'dropdown');

  const state = useSelector(plusStore.selectors.state);
  const layout = useSelector(plusStore.selectors.layout);

  const autoCloseTimerRef = useRef<any>(null);

  const collectionModalOpenState = useSelector(modalStore.selectors.state('CreateEditPlaylist'));

  useEffect(() => {
    onStepChange?.(layout);
  }, [layout]);

  useEffect(() => {
    if (variant !== 'panel') {
      return undefined;
    }
    dispatcher.plus.open({ regenerateProps: true });
    const unsubscribe = store.subscribe(() => {
      const { plus: plusState } = store.getState();
      if (plusState.isOpen === false) {
        onCloseQuery?.();
        dispatcher.plus.open({ regenerateProps: true });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [variant, onCloseQuery]);

  useEffect(() => {
    if (variant !== 'panel') {
      return undefined;
    }
    return () => {
      dispatcher.plus.close();
    };
  }, [variant]);

  const handleAutoClose = useCallback(() => {
    dispatcher.plus.close();
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (autoCloseTimerRef.current) {
      clearTimeout(autoCloseTimerRef.current);
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (!['materials', 'material'].includes(layout)) {
      return;
    }
    if (autoCloseTimerRef.current) {
      clearTimeout(autoCloseTimerRef.current);
    }
    autoCloseTimerRef.current = setTimeout(handleAutoClose, 8000);
  }, [layout, handleAutoClose]);

  useEffect(() => {
    if (variant !== 'dropdown') {
      return;
    }
    if (collectionModalOpenState.isOpen) {
      handleMouseEnter();
    }
    const rect = document
      .querySelector(`[data-component-id="${componentId}"] > [data-type="context-menu"]`)
      ?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    if (!checkMouseInsideRect(rect)) {
      handleMouseLeave();
    }
  }, [variant, handleMouseLeave, collectionModalOpenState.isOpen]);

  const handleOpen = useCallback(() => {
    dispatcher.plus.open();
  }, [variant]);

  const handleClose = useCallback(() => {
    dispatcher.plus.close();
  }, []);

  const handleEscapePress = useCallback(() => {
    if (!collectionModalOpenState.isOpen) {
      dispatcher.plus.close();
    }
  }, [collectionModalOpenState.isOpen]);

  const layouts = useMemo(() => (
    <PlusContextProvider value={{ variant, hasSummary, hasNewCollectionButton }}>
      {layout === 'add' && (
        <AddLayout />
      )}
      {layout === 'processing' && (
        <ProcessingLayout />
      )}
      {layout === 'material' && (
        <MaterialLayout onTagsChange={onTagsChange} onCollectionChange={onCollectionChange} />
      )}
      {layout === 'materials' && (
        <MaterialsLayout onCollectionChange={onCollectionChange} />
      )}
    </PlusContextProvider>
  ), [layout, hasSummary, hasNewCollectionButton, variant]);

  if (variant === 'panel') {
    return (
      <View style={style}>
        {layouts}
      </View>
    );
  }

  return (
    <DropdownPanel
      componentId={componentId}
      anchor="right"
      transparent
      onOpenQuery={handleOpen}
      isOpen={state.isOpen}
      panelStyle={[style, {
        display: 'flex',
        position: (Platform.OS === 'web' ? 'fixed' : 'absolute') as 'absolute',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: unit(4),
        width: unit(448),
        filter: 'drop-shadow(0 0 0.875em rgba(0,0,0,0.225))',
        transform: [
          { translateX: unit(-20) },
          { translateY: unit(15) },
        ],
      }]}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onEscape={handleEscapePress}
      button={(
        <View />
      )}
    >
      <Header variant={layout} onCrossPress={handleClose} />
      {layouts}
    </DropdownPanel>
  );
};

Plus.defaultProps = defaultProps;

export default memo(Plus);
