import {
  type ReactElement, useCallback,
} from 'react';
import Modal from 'react-native-modal';
import {
  type GestureResponderEvent,
  StyleSheet,
} from 'react-native';

import { useSelector } from 'store/utils/redux/hooks';
import { unit } from 'utils';

import { GradientView, Text, View } from 'components/Themed';
import Button from 'components/Button';

import controller from './classes/Controller';

export const Dialogs = (): ReactElement => {
  const dialogs = useSelector((state) => state.app.dialogs);

  const handlePress = useCallback((event: GestureResponderEvent, context: { dialogId: string, handleId: string }) => {
    const { dialogId, handleId } = context;
    const handler = controller.getHandler(dialogId, handleId);
    if (!handler) {
      return;
    }
    handler(dialogId);
  }, []);

  return (
    <>
      {dialogs.map((dialog) => (
        <Modal
          key={dialog.id}
          isVisible={dialog.isVisible}
          style={styles.Dialog}
          animationIn="bounceIn"
          animationOut="fadeOut"
          animationInTiming={200}
          animationOutTiming={200}
          swipeDirection={[]}
          backdropOpacity={0.3}
          backdropTransitionInTiming={1}
          backdropTransitionOutTiming={1}
        >
          <GradientView lightColors={['#f2f2f1', '#f2f2f1']} darkColors={['#222222', '#101010']} style={styles.container}>
            <Text size={15} style={styles.title} lightColor="#00000099" darkColor="#ffffff99">{dialog.body.title}</Text>
            {!!dialog.body.text && (
              <Text size={22} style={styles.text} lightColor="#000000" darkColor="#ffffff">{dialog.body.text}</Text>
            )}
            <View style={styles.buttons}>
              {Array.isArray(dialog.buttons) && dialog.buttons.map((button) => (
                <Button
                  key={button.label}
                  variant={button.variant || 'contained'}
                  lightColor={button.lightColor || '#497CFF'}
                  darkColor={button.darkColor || '#497CFF'}
                  onPress={handlePress}
                  context={{ dialogId: dialog.id, handleId: button.handleId }}
                  style={styles.button}
                >
                  <Text
                    size={15}
                    weight="medium"
                    lightColor={button.textLightColor || '#000000'}
                    darkColor={button.textDarkColor || '#ffffff'}
                  >
                    {button.label}
                  </Text>
                </Button>
              ))}
            </View>
          </GradientView>
        </Modal>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  Dialog: {
  },
  container: {
    paddingHorizontal: unit(36),
    paddingTop: unit(20),
    paddingBottom: unit(24),
    borderRadius: unit(25),
    alignSelf: 'center',
  },
  buttons: {
    flexDirection: 'row',
    paddingTop: unit(24),
    marginHorizontal: unit(-6),
    justifyContent: 'center',
  },
  title: {
    paddingTop: unit(4),
    paddingBottom: unit(8),
  },
  text: {
    paddingTop: unit(12),
    paddingBottom: unit(4),
  },
  button: {
    flex: 1,
    marginHorizontal: unit(6),
    maxWidth: unit(120),
  },
});

export default controller;
