import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

const cacheKeyCreator = (state: DefaultRootState, id: number) => `${id}`;

const resourceByNoteIdSelector = createCachedSelector(
  (state: DefaultRootState) => state.note.relation,
  (state: DefaultRootState, id: number) => id,
  (relation, id) => relation.find((relationItem) => relationItem.noteId === id),
)(
  cacheKeyCreator,
);

export default (id: number) => (state: DefaultRootState) => {
  return resourceByNoteIdSelector(state, id);
};
