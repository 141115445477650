import {
  type SagaReturnType,
  takeLatest, put, delay, take, cancel,
} from 'redux-saga/effects';

import { navigate } from 'navigation/methods';

import { call } from 'store/utils/saga/effects';
import * as authStore from 'store/nodes/auth';
import * as profileStore from 'store/nodes/profile';

import * as actions from '../actions';

export const config = {
  action: actions.step1.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.step1>) {
  const {
    data: {
      phone, name, photo, login,
    },
  } = action.payload;

  yield delay(10);
  yield put(profileStore.actions.updateData({
    phone, name, photo, login,
  }));

  const { payload: updateDataPayload } = yield take(profileStore.actions.updateDataDone.type);

  if (updateDataPayload.error) {
    yield put(actions.step1Done());
    yield cancel(); return;
  }

  yield put(authStore.actions.registrationComplete());
  const { payload: registrationCompletePayload } = yield take(authStore.actions.registrationCompleteDone.type);

  if (registrationCompletePayload.isError) {
    yield put(actions.step1Done());
    yield cancel(); return;
  }

  yield put(actions.step1Done());
  yield* call(() => navigate('Ask', { interaction: 'all' }));
  yield cancel();
}
