import request from 'services/api/request';
import type { ResultLegacy } from 'app/entities';

interface Options {
  isDebug: boolean,
}

export default async (options?: Options): Promise<ResultLegacy<string[]>> => {
  const params: Record<string, any> = {};
  if (options?.isDebug) {
    params.isDebug = 1;
  }

  const { data, error } = await request.get<string[]>('/content/filters', { query: params });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
