import Storage from './classes/Storage';
import useStorageSync from './hooks/useStorageSync';
import useStorageValue from './hooks/useStorageValue';

export {
  Storage,
  useStorageSync,
  useStorageValue,
};

export default Storage;
