import {
  takeEvery, put, cancel,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';

import { select, call } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as userStore from 'store/nodes/user';

import * as api from 'services/api';

import * as actions from '../actions';

export const config = {
  action: actions.remove.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.remove>) {
  const { payload } = action;
  if (!payload.id) {
    yield cancel(); return;
  }

  const ids = !Array.isArray(payload.id) ? [payload.id] : payload.id;

  const userProfile = yield* select(userStore.selectors.getMy);
  if (!userProfile) {
    yield put(actions.removeDone(ids));
    yield cancel(); return;
  }

  const oldContentIds = [...userProfile.contentIds];
  const newContentIds = userProfile.contentIds.filter((contentId) => !ids.includes(contentId));

  yield put(userStore.actions.setProfile({ ...userProfile, contentIds: newContentIds }));

  const response = yield* call(() => api.resource.user.content.remove(ids));
  yield checkUnauthorized(response);
  if (response.error || !response.data) {
    yield put(userStore.actions.setProfile({ ...userProfile, contentIds: oldContentIds }));
    yield put(actions.removeDone(ids));
    Alert.error(response.error?.message || 'Server error #32');
    yield cancel(); return;
  }

  yield put(actions.removeDone(ids));
}
