import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import type { StateType, PlanType } from '../types';

export type SubscriptionStore = {
  state: StateType,
  plans: Record<string, PlanType>,
  isPricingShow: boolean,
  isLoading: boolean,
  isProcessing: boolean,
  isOpeningPortal: boolean,
}

const initialState: SubscriptionStore = {
  state: {
    status: null,
    plan: '-1',
    startDate: null,
    expirationDate: null,
    canceledAt: null,
    cancelAt: null,
  },
  plans: {},
  isPricingShow: false,
  isLoading: false,
  isProcessing: false,
  isOpeningPortal: false,
};

const noteSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    reset: (state) => {
      const resetState = cloneDeep(initialState);
      state.state = resetState.state;
      state.plans = resetState.plans;
      state.isPricingShow = resetState.isPricingShow;
      state.isLoading = resetState.isLoading;
      state.isProcessing = resetState.isProcessing;
      state.isOpeningPortal = resetState.isOpeningPortal;
    },
    showPricing: (state) => {
      state.isPricingShow = true;
    },
    hidePricing: (state) => {
      state.isPricingShow = false;
    },
    loadState: (state) => {
      state.isLoading = true;
    },
    loadStateDone: (state, action: PayloadAction<{ state: StateType }>) => {
      state.state = action.payload.state;
      state.isLoading = false;
    },
    setState: (state, action: PayloadAction<{ state: StateType }>) => {
      state.state = action.payload.state;
    },
    loadPlans: () => undefined,
    loadPlansDone: (state, action: PayloadAction<{ plans: PlanType[] }>) => {
      action.payload.plans.forEach((item) => {
        state.plans[item.planId] = item;
      });
    },
    subscribe: (state, action: PayloadAction<{ planId: string }>) => {
      state.isProcessing = true;
    },
    subscribeDone: (state) => {
      state.isProcessing = false;
    },
    cancel: (state, action: PayloadAction) => {
      state.isProcessing = true;
    },
    cancelDone: (state) => {
      state.isProcessing = false;
    },
    openBillingPortal: (state) => {
      state.isOpeningPortal = true;
    },
    openBillingPortalDone: (state) => {
      state.isOpeningPortal = false;
    },
  },
});

export const {
  reducer,
  actions,
} = noteSlice;
