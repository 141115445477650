import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { Dimensions, type ScaledSize } from 'react-native';

type Breakpoint = 'mobile' | 'mobile-large' | 'tablet' | 'laptop' | 'desktop';

type ScreenSize = 'desktop' | 'laptopL' | 'laptop' | 'tabletL' | 'tabletM' | 'mobileL' | 'mobileM' | 'mobileS';

type Responsive = {
  breakpoint: Breakpoint,
  is: {
    desktop: boolean,
    laptopL: boolean,
    laptop: boolean,
    tabletL: boolean,
    tabletM: boolean,
    mobileL: boolean,
    mobileM: boolean,
  },
  isMoreThen: {
    laptop: boolean,
    tablet: boolean,
    mobileLarge: boolean,
    mobile: boolean,
  },
  isLessThen: {
    desktop: boolean,
    laptop: boolean,
    tablet: boolean,
    mobileLarge: boolean,
  },
};

const getBreakpoint = (width: number): Breakpoint => {
  if (width >= 1280) {
    return 'desktop';
  }
  if (width >= 1024) {
    return 'laptop';
  }
  if (width >= 768) {
    return 'tablet';
  }
  if (width >= 460) {
    return 'mobile-large';
  }
  return 'mobile';
};

const getScreenSize = (width: number): ScreenSize => {
  if (width <= 320) {
    return 'mobileS';
  }
  if (width <= 375) {
    return 'mobileM';
  }
  if (width <= 460) {
    return 'mobileL';
  }
  if (width <= 650) {
    return 'tabletM';
  }
  if (width <= 768) {
    return 'tabletL';
  }
  if (width <= 1024) {
    return 'laptop';
  }
  if (width <= 1280) {
    return 'laptopL';
  }
  return 'desktop';
};

/**
 * @deprecated use useScreenSize instead
 */
const useResponsive = (): Responsive => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(getBreakpoint(Dimensions.get('window').width));
  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize(Dimensions.get('window').width));

  const handleDimensionChange = useCallback(({ window }: { window: ScaledSize }) => {
    setBreakpoint(getBreakpoint(window.width));
    setScreenSize(getScreenSize(window.width));
  }, []);

  useEffect(() => {
    const handler = Dimensions.addEventListener('change', handleDimensionChange);
    return () => {
      handler.remove();
    };
  }, [handleDimensionChange]);

  return useMemo(() => ({
    breakpoint,
    is: {
      desktop: ['desktop', 'laptopL', 'laptop', 'tabletL', 'tabletM', 'mobileL', 'mobileM', 'mobileS'].includes(screenSize),
      laptopL: ['laptopL', 'laptop', 'tabletL', 'tabletM', 'mobileL', 'mobileM', 'mobileS'].includes(screenSize),
      laptop: ['laptop', 'tabletL', 'tabletM', 'mobileL', 'mobileM', 'mobileS'].includes(screenSize),
      tabletL: ['tabletL', 'tabletM', 'mobileL', 'mobileM', 'mobileS'].includes(screenSize),
      tabletM: ['tabletM', 'mobileL', 'mobileM', 'mobileS'].includes(screenSize),
      mobileL: ['mobileL', 'mobileM', 'mobileS'].includes(screenSize),
      mobileM: ['mobileM', 'mobileS'].includes(screenSize),
      mobileS: ['mobileS'].includes(screenSize),
    },
    isMoreThen: {
      laptop: ['desktop'].includes(breakpoint),
      tablet: ['desktop', 'laptop'].includes(breakpoint),
      mobileLarge: ['desktop', 'laptop', 'tablet'].includes(breakpoint),
      mobile: ['desktop', 'laptop', 'tablet', 'mobile-large'].includes(breakpoint),
    },
    isLessThen: {
      desktop: ['laptop', 'tablet', 'mobile-large', 'mobile'].includes(breakpoint),
      laptop: ['tablet', 'mobile-large', 'mobile'].includes(breakpoint),
      tablet: ['mobile-large', 'mobile'].includes(breakpoint),
      mobileLarge: ['mobile'].includes(breakpoint),
    },
  }), [breakpoint, screenSize]);
};

export default useResponsive;
