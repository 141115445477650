import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';

export default () => {
  const [result, setResult] = useState(Platform.OS !== 'web');

  const check = useCallback(() => {
    const isSupports = Boolean(
      'ontouchstart' in window
      || window.navigator.maxTouchPoints > 0
      || ('msMaxTouchPoints' in window.navigator && (window.navigator as any).msMaxTouchPoints > 0)
      || ('DocumentTouch' in window && typeof (window as any).DocumentTouch !== 'undefined'),
    );
    const isMobile = window.innerWidth <= 425;
    setResult(isMobile && isSupports);
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return undefined;
    }
    window.addEventListener('resize', check);
    check();
    return () => {
      window.removeEventListener('resize', check);
    };
  }, []);

  return result;
};
