import React, {
  type ReactElement,
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import {
  type LayoutChangeEvent,
  StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';

import { useFocusEffect } from 'navigation/hooks';

import {
  useListData, useListLayout, useResponsive,
} from 'hooks';
import { unit } from 'utils';

import { store } from 'store';
import { useDispatcher } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';
import * as newsStore from 'store/nodes/news';
import * as interactionStore from 'store/nodes/interaction';

import { Text, View } from 'components/Themed';
import AdaptivePage from 'components/AdaptivePage';
import List from 'components/List';
import Card from 'components/Card';
import { useScrollMethods } from 'components/Scroller';

import TopBarDesktop from './elements/TopBarDesktop';
import TopBarMobile from './elements/TopBarMobile';

const Feed = (): ReactElement => {
  const itemsRect = useRef<Record<number, LayoutChangeEvent['nativeEvent']['layout']>>({});

  const dispatcher = useDispatcher();
  const responsive = useResponsive();
  const { setScrollValue, getScrollValue, scrollTo } = useScrollMethods();

  const { listLayout } = useListLayout('news.list.mode');

  const list = useSelector(newsStore.selectors.list);
  const listMeta = useSelector(newsStore.selectors.listMeta);

  const listData = useListData(list.sequence, listMeta, 12);

  useEffect(() => {
    if (listMeta.firstPage.isLoading === true) {
      setScrollValue('News', 0);
      scrollTo(0);
    }
  }, [listMeta.firstPage.isLoading]);

  useFocusEffect(useCallback(() => {
    const { top } = getScrollValue('News');
    setTimeout(() => {
      // @todo реализовать совместимую со списками версию (для devices)
      scrollTo(top);
    }, 10);
  }, []));

  const handleRefresh = useCallback(() => {
    dispatcher.news.loadNextPage(true);
  }, []);

  const handleNextPage = useCallback(() => {
    if (!listMeta.isConsistent) {
      return;
    }
    dispatcher.news.loadNextPage();
  }, [listMeta.isConsistent]);

  const handleItemShow = useCallback(() => {
    const { top, dimension } = getScrollValue('News');
    const state = store.getState();
    Object.entries(itemsRect.current).forEach(([id, rect]) => {
      const resourceId = Number(id);
      const data = contentStore.selectors.dataById(resourceId)(state);
      const isLoading = interactionStore.selectors.isLoading('shown', 'content', resourceId)(state);
      const isInScreen = rect.y + (top * -1) < dimension.window.height - rect.height;
      if (isInScreen && !isLoading && !data?.isShown) {
        dispatcher.interaction.shown('content', resourceId);
      }
    });
  }, [store, getScrollValue]);

  const handleScroll = useCallback((event: { nativeEvent: { contentOffset: { y: any; }; }; }) => {
    // @todo реализовать совместимую со списками версию (для devices)
    const { y } = event.nativeEvent.contentOffset;
    if (window.location.pathname.includes('/content/search')) {
      setScrollValue('News', y);
    }
    handleItemShow();
  }, [handleItemShow]);

  const handleItemLayout = useCallback((event: LayoutChangeEvent, context: { cardId: number }) => {
    const { nativeEvent } = event;
    itemsRect.current[context.cardId] = {
      x: nativeEvent.layout.x || (nativeEvent.layout as any)?.left || 0,
      y: nativeEvent.layout.y || (nativeEvent.layout as any)?.top || 0,
      width: nativeEvent.layout.width,
      height: nativeEvent.layout.height,
    };
    handleItemShow();
  }, []);

  const renderItem = useCallback((args: any) => {
    return (
      <Card
        id={args.item.id}
        type={args.item.type}
        style={styles.listItem}
        hasDescription={false}
        hasWebLink
        hasFooterQueue
        hasMenuQueue
        hasMenuComplete
        hasMenuToCollection
        hasMenuShare
        hasMenuPlaySummary
        hasMenuTLDR
        hasMenuShowRelated
        tagsLimit={10}
        onLayout={handleItemLayout}
        materialForceLayout={!responsive.is.tabletL && listLayout.isRow ? 'horizontal' : undefined}
      />
    );
  }, [handleItemLayout, responsive.is.tabletL, listLayout.isRow]);

  const pagePaddingHorizontalScheme = useMemo(() => ({
    320: 16,
  }), []);

  return (
    <AdaptivePage
      desktopHeaderLeftPanel="ask"
      desktopHeaderMiddlePanel={false}
      bodyMaxWidth={1280}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
    >
      <TopBarDesktop />
      <TopBarMobile />
      {!listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && (
        <View style={styles.preparing}>
          <Text lightColor="#000000" darkColor="#ffffff" size={17}>Start typing</Text>
          <Text lightColor="#797979" darkColor="#B8B6BF" size={15}>to search</Text>
        </View>
      )}
      {listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && (
        <View style={styles.empty}>
          <Text lightColor="#000000" darkColor="#ffffff" size={17}>No results</Text>
          <Text lightColor="#797979" darkColor="#B8B6BF" size={15}>Try to type something else</Text>
        </View>
      )}
      {(listMeta.firstPage.isLoading || listMeta.nextPage.isLoading || list.sequence.length > 0) && (
        <List
          type="masonry"
          componentStyle={styles.listComponent}
          contentContainerStyle={styles.listContentContainer}
          scrollEmitter="web-page"
          data={listData.sequence}
          keyExtractor={(item) => `${item.id}`}
          renderItem={renderItem}
          columnsScheme={listLayout.isRow ? '320:1' : '320:1;560:2;960:3'}
          // @ts-ignore
          showsVerticalScrollIndicator={false}
          hasNextPage={list.paginationInfo.hasNext}
          onRefresh={handleRefresh}
          onScroll={handleScroll}
          onEndReached={handleNextPage}
          ListFooterComponent={(
            <View style={{ height: unit(108) }} />
          )}
        />
      )}
    </AdaptivePage>
  );
};

const styles = StyleSheet.create({
  listComponent: {
    marginHorizontal: unit(-10),
  },
  listContentContainer: {
    alignSelf: 'stretch',
  },
  listItem: {
    marginHorizontal: unit(10),
    marginBottom: unit(20),
  },
  empty: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    maxWidth: unit(230),
    marginTop: unit(8),
  },
  bigSearchTags: {
    maxWidth: unit(1280),
    marginTop: unit(12),
  },
  bigSearchTagsMobile: {
    marginTop: unit(44),
  },
  preparing: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  preparingText: {
    maxWidth: unit(230),
    marginTop: unit(8),
  },
  preparingLoader: {
    marginTop: unit(-4),
  },
});

export default Feed;
