import {
  type SetStateAction,
  useCallback, useMemo, useState,
} from 'react';
import {
  Box, Button, FormControl, FormHelperText, FormLabel, Input, Typography,
} from '@mui/joy';

import { useNavigate } from 'navigation/hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import DualPanels from 'widgets/DualPanels';
import { useOnInputEnter } from 'hooks';

import videoBackground from './assets/videoBackground.mp4';

type FormValues = {
  email: string | null,
  password: string | null,
}

const validate = (values: FormValues): { isValidPassword: boolean, isValidEmail: boolean, errors: Partial<FormValues> } => {
  let isValidPassword = true;
  let isValidEmail = true;
  const errors: Partial<FormValues> = {};
  if (!values?.email || !/.+@.+\..+/.test(values?.email)) {
    isValidEmail = false;
    if (values?.email !== null) {
      errors.email = 'not valid';
    }
  }
  if (!values?.password || values?.password?.length < 4) {
    isValidPassword = false;
    if (values?.password !== null) {
      errors.password = 'too short';
    }
  }
  return {
    isValidPassword,
    isValidEmail,
    errors,
  };
};

const AuthSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatcher();

  const { email: emailInStore } = useSelector((state) => state.auth.signIn.data);

  const [email, setEmail] = useState<string | null>(emailInStore || null);
  const [password, setPassword] = useState<string | null>(null);

  const handleChangeEmail = useCallback((event: { target: { value: SetStateAction<string | null>; }; }) => {
    setEmail(event.target.value);
  }, []);

  const handleChangePassword = useCallback((event: { target: { value: SetStateAction<string | null>; }; }) => {
    setPassword(event.target.value);
  }, []);

  const { isLoading } = useSelector((state) => state.auth.signIn);

  const validation = useMemo(() => validate({ email, password }), [email, password]);

  const handleSubmit = useCallback(() => {
    if (!email || !password || !validation.isValidPassword || !validation.isValidEmail) {
      return;
    }
    dispatch.auth.doSignIn(email, password);
  }, [email, password, validation.isValidEmail, validation.isValidPassword]);

  const { onKeyDown } = useOnInputEnter(useCallback(() => {
    handleSubmit();
  }, [handleSubmit]));

  const handleRegister = useCallback(() => {
    navigate('Auth/SignUp');
  }, []);

  const handleForgot = useCallback(() => {
    if (!email) {
      return;
    }
    navigate('Auth/PasswordReset', { email });
  }, [email]);

  return (
    <DualPanels
      leftPanel={(
        <>
          <Box alignSelf="center" mb={2}>
            <Typography fontSize={32} fontWeight={500}>Welcome back!</Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <FormControl size="md" inputMode="email">
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <FormLabel sx={{ color: (theme) => theme.palette.neutral.plainColor }}>Email *</FormLabel>
                {!validation.isValidEmail && (
                  <FormHelperText sx={{ color: '#DB3327' }}>{validation.errors.email}</FormHelperText>
                )}
              </Box>
              <Input
                placeholder="Enter your email"
                variant="soft"
                required
                value={email || ''}
                name="email"
                onChange={handleChangeEmail}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) => theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <FormControl size="md" inputMode="email">
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <FormLabel sx={{ color: (theme) => theme.palette.neutral.plainColor }}>Password *</FormLabel>
                {!validation.isValidPassword && (
                  <FormHelperText sx={{ color: '#DB3327' }}>{validation.errors.password}</FormHelperText>
                )}
              </Box>
              <Input
                placeholder="Enter your password"
                variant="soft"
                required
                type="password"
                value={password || ''}
                name="password"
                onChange={handleChangePassword}
                size="lg"
                onKeyDown={onKeyDown}
                sx={{
                  '--_Input-focusedHighlight': (theme) => theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <Typography
              onClick={validation.isValidEmail ? handleForgot : undefined}
              color={validation.isValidEmail ? 'primary' : 'neutral'}
              sx={{ cursor: validation.isValidEmail ? 'pointer' : 'default' }}
              fontSize={13}
            >
              Forgot your password?
            </Typography>
          </Box>
          <Button
            color="neutral"
            variant="soft"
            size="lg"
            sx={{ mt: 4 }}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={!validation?.isValidEmail && !validation.isValidPassword}
          >
            Sign In
          </Button>
          <Button
            variant="plain"
            color="neutral"
            size="lg"
            sx={{ mt: 2 }}
            onClick={handleRegister}
            disabled={isLoading}
          >
            Register
          </Button>
        </>
    )}
      rightPanelBackGround={videoBackground}
    />

  );
};
export default AuthSignIn;
