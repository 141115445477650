import { type CSSProperties } from 'react';
import {
  type StyleProp, type TextStyle, type ViewStyle,
  Platform,
} from 'react-native';

import type {
  AuthorType,
  ImageType,
  MaterialType,
  CollectionType,
  RagMaterialType,
  NoteType,
  UserType,
  SearchItemType,
  ActivityNewContentType,
  ActivityNewPlaylistType,
  ActivityCompleteContentType,
  ActivityCompletePlaylistType,
  ActivityCommentContentType,
  ActivityCommentPlaylistType,
  ActivityNewJobType,
  SourceMaterialType,
  NoteKey,
  ResourceKey,
  CollectionPermissionType,
  PoorMaterialType,
  PoorCollectionType,
} from 'app/entities';

export const isHTMLDivElement = (element: HTMLDivElement | any): element is HTMLDivElement => {
  return Platform.OS === 'web' && element instanceof HTMLDivElement;
};

export const isHTMLInputElement = (element: HTMLInputElement | any): element is HTMLInputElement => {
  return Platform.OS === 'web' && element instanceof HTMLInputElement;
};

export const isHTMLElement = (element: HTMLElement | any): element is HTMLElement => {
  return Platform.OS === 'web' && element instanceof HTMLElement;
};

export const isWebStyleProp = (data: CSSProperties | StyleProp<ViewStyle> | StyleProp<TextStyle> | any): data is CSSProperties => (
  Platform.OS === 'web'
);

export const isPoorMaterial = (data: PoorMaterialType | any): data is PoorMaterialType => (
  !!data
  && typeof data?.id === 'number'
  && data?.id > 0
  && typeof data?.resourceType === 'string'
  && data?.resourceType === 'CONTENT'
);

export const isPoorCollection = (data: PoorCollectionType | any): data is PoorCollectionType => (
  !!data
  && typeof data?.id === 'number'
  && data?.id > 0
  && typeof data?.resourceType === 'string'
  && data?.resourceType === 'PLAYLIST'
);

export const isMaterial = (data: MaterialType | any): data is MaterialType => (
  !!data
  && 'type' in data
  && 'authors' in data
  && 'image' in data
  && !('materialsMetadata' in data)
);

export const isSourceMaterial = (data: SourceMaterialType | any): data is SourceMaterialType => (
  !!data
  && 'type' in data
  && 'isSaved' in data
  && 'originalUrl' in data
  && !('materialsMetadata' in data)
);

export const isCollection = (data: CollectionType | any): data is CollectionType => (
  !!data
  && 'cover' in data
  && 'materialsMetadata' in data
  && 'contentImages' in data
);

export const isRagMaterial = (data: RagMaterialType | any): data is RagMaterialType => (
  !!data
  && (
    ('__typename' in data && data.__typename === 'RagMaterial')
    || ('typename' in data && data.typename === 'RagMaterial')
  )
);

export const isSearchItem = (data: SearchItemType | any): data is SearchItemType => (
  !!data
  && 'element' in data
  && 'searchMeta' in data
  && (isMaterial(data.element) || isCollection(data.element))
);

export const isNote = (data: NoteType | any): data is NoteType => (
  (data?.type?.toLowerCase() === 'note')
  || (!Number.isNaN(data?.id) && typeof data?.text === 'string' && isUser(data?.user))
);

export const isImage = (source: ImageType | any): source is ImageType => (
  source !== null && typeof source === 'object' && 'id' in source && 'url' in source
);

export const isAuthor = (data: AuthorType | any): data is AuthorType => {
  return 'id' in data && 'name' in data && 'surname' in data && 'owner' in data && 'originalUrl' in data;
};

export const isUser = (data: UserType | any): data is UserType => {
  return !!data && !Array.isArray(data) && 'id' in data && 'login' in data && 'name' in data && 'surname' in data;
};

export const isUserArray = (data: UserType[] | any): data is UserType[] => {
  return !!data && Array.isArray(data) && isUser(data?.[0]);
};

export const isActivityNewMaterial = (data: ActivityNewContentType | any): data is ActivityNewContentType => {
  return data?.type === 'NEW_MATERIAL';
};

export const isActivityNewCollection = (data: ActivityNewPlaylistType | any): data is ActivityNewPlaylistType => {
  return data?.type === 'NEW_PLAYLIST';
};

export const isActivityCompletedMaterial = (data: ActivityCompleteContentType | any): data is ActivityCompleteContentType => {
  return data?.type === 'COMPLETED' && 'content' in data && !!data.content;
};

export const isActivityCompletedCollection = (data: ActivityCompletePlaylistType | any): data is ActivityCompletePlaylistType => {
  return data?.type === 'COMPLETED' && 'playlist' in data && !!data.playlist;
};

export const isActivityCommentMaterial = (data: ActivityCommentContentType | any): data is ActivityCommentContentType => {
  return data?.type === 'NEW_COMMENT' && 'content' in data && !!data.content;
};

export const isActivityCommentCollection = (data: ActivityCommentPlaylistType | any): data is ActivityCommentPlaylistType => {
  return data?.type === 'NEW_COMMENT' && 'playlist' in data && !!data.playlist;
};

export const isActivityNewJob = (data: ActivityNewJobType | any): data is ActivityNewJobType => {
  return data?.type === 'NEW_JOB' && 'job' in data && !!data.job;
};

export const isLink = (text: string | any): text is string => {
  return typeof text === 'string' && /^https?:\/\/.+\..+/.test(text);
};

export const isResourceKey = (key: ResourceKey | any): key is ResourceKey => (
  Object.keys(key).length === 2
  && typeof key?.resourceType === 'string'
  && typeof key?.resourceId === 'number'
);

export const isNoteKey = (key: NoteKey | any): key is NoteKey => (
  Object.keys(key).length === 1
  && typeof key?.noteId === 'number'
);

export const isCollectionPermissionType = (arr: CollectionPermissionType[]): arr is CollectionPermissionType[] => {
  return arr.every((item) => typeof item === 'object' && item !== null
    && 'user' in item && 'action' in item && 'permission' in item);
};
