import { createAction } from '@reduxjs/toolkit';
import * as uuid from 'uuid';

import type {
  PaginationType,
  CommentType,
  CommentMessageType,
  CommentSummaryType,
  ResourceVariant,
} from 'app/entities';

import { prepareActionDone } from 'store/utils/saga/actions';

export const clear = createAction('COMMENT/CLEAR');

export const loadNextPage = createAction('COMMENT/LOAD_PAGE', (
  resource: ResourceVariant,
  resourceId: number,
  reload = false,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    reload,
  },
}));

export const loadNextPageDone = createAction('COMMENT/LOAD_PAGE_DONE', (
  reload: boolean,
) => ({
  payload: {
    reload,
  },
}));

export const addPage = createAction('COMMENT/SET_PAGE', (
  resource: ResourceVariant,
  resourceId: number,
  items: CommentType[],
  paginationInfo: PaginationType,
  reload = false,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    items,
    paginationInfo,
    reload,
  },
}));

export const setItem = createAction('COMMENT/SET_ITEM', (
  data: CommentType,
) => ({
  payload: {
    data,
  },
}));

export const setText = createAction('COMMENT/SET_TEXT', (
  text: string,
) => ({
  payload: {
    text,
  },
}));

export const editStart = createAction('COMMENT/EDIT_START', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const editCancel = createAction('COMMENT/EDIT_CANCEL', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const editSend = createAction('COMMENT/EDIT_SEND', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
  text: string,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
    text,
  },
}));

export const editSendDone = createAction('COMMENT/EDIT_SEND_DONE');

export const editOptimistic = createAction('COMMENT/EDIT_OPTIMISTIC', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const editRollback = createAction('COMMENT/EDIT_ROLLBACK', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const editCommit = createAction('COMMENT/EDIT_COMMIT', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const newSend = createAction('COMMENT/NEW_SEND', (
  resource: ResourceVariant,
  resourceId: number,
  message: CommentMessageType,
  tempId?: string,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    message,
    tempId: tempId || uuid.v4(),
  },
}));

export const newSendDone = createAction('COMMENT/NEW_SEND_DONE', prepareActionDone);

export const newOptimistic = createAction('COMMENT/NEW_OPTIMISTIC', (
  resource: ResourceVariant,
  resourceId: number,
  tempId: number | string,
  data: Omit<CommentType, 'id'> & { id: number | string },
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    tempId,
    data,
  },
}));

export const newRollback = createAction('COMMENT/NEW_ROLLBACK', (
  resource: ResourceVariant,
  resourceId: number,
  tempId: number | string,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    tempId,
  },
}));

export const newCommit = createAction('COMMENT/NEW_COMMIT', (
  resource: ResourceVariant,
  resourceId: number,
  tempId: number | string,
  data: Partial<CommentType>,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    tempId,
    data,
  },
}));

export const deleteSend = createAction('COMMENT/DELETE_SEND', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const deleteSendDone = createAction('COMMENT/DELETE_SEND_DONE', prepareActionDone);

export const deleteOptimistic = createAction('COMMENT/DELETE_OPTIMISTIC', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const deleteRollback = createAction('COMMENT/DELETE_ROLLBACK', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const deleteCommit = createAction('COMMENT/DELETE_COMMIT', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const replySetId = createAction('COMMENT/REPLY_SET_ID', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const replyClearId = createAction('COMMENT/REPLY_CLEAR_ID', (
  resource: ResourceVariant,
  resourceId: number,
  id: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    id: Number(id),
  },
}));

export const loadSummaryById = createAction('REVIEW/LOAD_SUMMARY_BY_ID', (
  resource: ResourceVariant,
  resourceId: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
  },
}));

export const loadSummaryByIdDone = createAction('REVIEW/LOAD_SUMMARY_BY_ID_DONE', (
  resource: ResourceVariant,
  resourceId: number,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
  },
}));

export const setSummaryData = createAction('REVIEW/SET_SUMMARY_DATA', (
  resource: ResourceVariant,
  resourceId: number,
  data: CommentSummaryType,
) => ({
  payload: {
    resource,
    resourceId: Number(resourceId),
    data,
  },
}));
