import { createAction } from '@reduxjs/toolkit';
import camelcaseKeys from 'camelcase-keys';
import * as uuid from 'uuid';
import type {
  CollectionType, MaterialMetadataItemType, MaterialType, PoorCollectionType, PoorMaterialType, RagMaterialType,
} from 'app/entities';

import {
  subscriptionPaymentIntentCreated,
  subscriptionPaymentIntentSucceeded,
  customerSubscriptionUpdated,
  customerSubscriptionDeleted,
} from 'widgets/Subscription/store/socketActions';

export const map = {
  'socket/close': 'close',
  'socket/ping_pong': 'receivePong',
  'socket/ask_search_result': 'askSearchResult',
  'socket/message_warning': 'messageWarning',
  'socket/message_error': 'messageError',
  'socket/message_info': 'messageInfo',
  [subscriptionPaymentIntentCreated.type]: subscriptionPaymentIntentCreated,
  [subscriptionPaymentIntentSucceeded.type]: subscriptionPaymentIntentSucceeded,
  [customerSubscriptionUpdated.type]: customerSubscriptionUpdated,
  [customerSubscriptionDeleted.type]: customerSubscriptionDeleted,
} as const;

export const close = createAction('socket/close', (
  payload: {
    message: 'close connection',
  },
) => ({
  payload,
}));

export const sendPing = createAction('socket/sendPing', (
  payload: {
    message: 'ping',
  },
) => ({
  payload,
}));

export const receivePong = createAction('socket/receivePong', (
  payload: {
    message: 'pong',
  },
) => ({
  payload,
}));

export const reconnect = createAction('socket/reconnect');

export const opened = createAction('socket/opened');

export const closed = createAction('socket/closed');

export const tick = createAction('socket/tick');

export const summaryPrepared = createAction('socket/summary_prepared', (
  payload: {
    request_id: string,
    material_id: number,
    summary: string,
  },
) => ({
  payload,
}));

export const collectionUpdated = createAction('socket/collection_updated', (
  payload: {
    collection_id: number,
  },
) => ({
  payload,
}));

export const copilotAnswer = createAction('socket/copilot_answer', (
  payload: {
    type: string,
    request_id: string,
    chunk: string,
  },
) => ({
  payload,
}));

export const collectionAccessChanged = createAction('socket/collectionAccessChanged', (
  payload: {
    playlist_id: number
  },
) => ({
  payload,
}));

export const collectionAccessRemoved = createAction('socket/collectionAccessRemoved', (
  payload: {
      playlist_id: number
  },
) => ({
  payload,
}));

export const collectionAccessProvided = createAction('socket/collectionAccessProvided', (
  payload: {
    playlist_id: number
  },
) => ({
  payload,
}));

export const collectionMaterialsMetadataUpdated = createAction('socket/collectionMaterialsMetadataUpdated', (
  payload: {
      playlist_id: number
      materials_metadata: MaterialMetadataItemType[]
  },
) => ({
  payload,
}));

export const materialIndexed = createAction('socket/material_indexed', (
  payload: {
    material_id: number,
    request_id: string,
  },
) => ({
  payload,
}));

export const askSearchResult = createAction('socket/ask_search_result', (
  payload: {
    data: (MaterialType | PoorMaterialType | CollectionType | PoorCollectionType | RagMaterialType)[],
    metadata: {
      replace: boolean,
      isMaterialsExists: boolean,
      metadataHighlights: {
        highlights: string[]
      }[]
    },
  },
) => ({
  payload: camelcaseKeys<typeof payload>(payload, { deep: true }),
}));

export const askSearchClear = createAction('socket/ask_search_clear', (
  payload: {
    message: string,
  },
) => ({
  payload: camelcaseKeys<typeof payload>(payload, { deep: true }),
}));

export const messageWarning = createAction('socket/messageWarning', (
  payload: {
    message: string,
    context: Record<string, string>
  },
) => ({
  payload: {
    id: uuid.v4(),
    ...camelcaseKeys<typeof payload>(payload, { deep: true }),
  },
}));

export const messageError = createAction('socket/messageError', (
  payload: {
    message: string,
    context: Record<string, string>
  },
) => ({
  payload: {
    id: uuid.v4(),
    ...camelcaseKeys<typeof payload>(payload, { deep: true }),
  },
}));

export const messageInfo = createAction('socket/messageInfo', (
  payload: {
    message: string,
    context: Record<string, string>
  },
) => ({
  payload: {
    id: uuid.v4(),
    ...camelcaseKeys<typeof payload>(payload, { deep: true }),
  },
}));
