import { type DefaultRootState, useSelector } from 'react-redux';

import * as contentStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import * as noteStore from 'store/nodes/note';
import * as searchStore from 'store/nodes/search';

import type { CardType, LegacyCardType } from 'widgets/Card/types';

const useData = (
  resourceType: CardType | LegacyCardType,
  resourceId: number,
) => {
  return useSelector((state: DefaultRootState) => {
    if (resourceType === 'Material' || resourceType === 'material') {
      return contentStore.selectors.dataById(resourceId)(state);
    }
    if (resourceType === 'Collection' || resourceType === 'collection') {
      return collectionStore.selectors.dataById(resourceId)(state);
    }
    if (resourceType === 'Note' || resourceType === 'note') {
      return noteStore.selectors.dataById(resourceId)(state);
    }
    if (resourceType === 'RagMaterial') {
      return searchStore.selectors.dataById(resourceId)(state);
    }
    return null;
  });
};

export default useData;
