import { useMemo } from 'react';
import { type MaterialType } from 'app/entities';

const useMaterialType = (data?: MaterialType | null) => useMemo(() => {
  if (!data) {
    return null;
  }
  const type = data?.type?.toLowerCase?.();
  if (type === 'video' && /\/\/(youtube.com|vimeo.com)/i.test(data?.originalUrl || '')) {
    return 'video' as const;
  }
  if (type === 'pdf' && /\.pdf(\??.*)/i.test(data?.originalUrl || '')) {
    return 'pdf' as const;
  }
  if (type === 'link' && /\.(png|jpe?g|gif|webp|tiff?)(\??.*)/i.test(data?.originalUrl || '')) {
    return 'image' as const;
  }
  return type;
}, [data?.type]);

export default useMaterialType;
