import { type ReactElement } from 'react';

import dialog from 'components/Dialog';

type OptionsType = {
  title: ReactElement | string;
  cancelLabel?: string;
  confirmLabel?: string;
};

export const confirm = (body: ReactElement | string, options?: OptionsType): Promise<boolean> => new Promise((resolve) => {
  dialog.show(
    {
      text: body,
      title: options?.title || 'Confirmation',
    },
    [{
      label: options?.cancelLabel || 'Cancel',
      variant: 'outlined',
      lightColor: '#888888',
      darkColor: '#9A99A2',
      handle: (dialogId: string) => {
        dialog.hide(dialogId);
        resolve(false);
      },
    }, {
      label: options?.confirmLabel || 'Confirm',
      variant: 'contained',
      lightColor: '#db3327',
      darkColor: '#db3327',
      textLightColor: '#ffffff',
      textDarkColor: '#ffffff',
      handle: (dialogId: string) => {
        dialog.hide(dialogId);
        resolve(true);
      },
    }],
  );
});
