import {
  type ReactElement, useCallback, useMemo, memo,
} from 'react';
import {
  type StyleProp, type ViewStyle, type GestureResponderEvent,
  StyleSheet, TouchableOpacity,
} from 'react-native';
import pluralize from 'pluralize';

import { unit } from 'utils';

import { Text, View } from 'components/Themed';
import Icon from 'components/LegacyIcon';
import Picture from 'components/Picture';

import { useThemeColor } from 'hooks';

const defaultProps = {
  count: 0,
};

type CommentsBlockProps = {
  style?: StyleProp<ViewStyle>,
  photos: string[],
  count?: number,
  EndComponent?: ReactElement,
  onPress?: (event: GestureResponderEvent) => void,
}& typeof defaultProps;

const CommentsBlock = (props: CommentsBlockProps): ReactElement => {
  const {
    style,
    photos,
    count,
    EndComponent,
    onPress,
  } = props;

  const borderColor = useThemeColor({ light: '#000000', dark: '#ffffff' });

  const handlePress = useCallback((event: GestureResponderEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onPress?.(event);
  }, [onPress]);

  const isEmpty = useMemo(() => (!Array.isArray(photos) || photos.length === 0), [photos]);

  const renderBody = useMemo(() => (
    <>
      {isEmpty && (
        <Icon name="ChatOutline28" size={24} style={styles.icon} lightColor="#929292" darkColor="#77767E" />
      )}
      {!isEmpty && (
        <View style={styles.photos}>
          {photos.map((uri, key) => (
            <Picture
              key={key}
              source={uri}
              size="small"
              style={[styles.photo, { borderColor, zIndex: photos.length - key }]}
            />
          ))}
        </View>
      )}
      {count === 0 && (
        <Text style={styles.text} size={12} lightColor="#888888" darkColor="#9A99A2">Post a comment</Text>
      )}
      {count > 0 && (
        <Text style={styles.text} size={12} lightColor="#888888" darkColor="#9A99A2">{`Posted ${pluralize('comment', count, true)}`}</Text>
      )}
      {!!EndComponent && EndComponent}
    </>
  ), [EndComponent, count, photos, borderColor, isEmpty]);

  return (
    <>
      {!onPress && (
        <View style={[style, styles.Comments]}>
          {renderBody}
        </View>
      )}
      {!!onPress && (
        <TouchableOpacity onPress={handlePress} style={[style, styles.Comments]} activeOpacity={0.8}>
          {renderBody}
        </TouchableOpacity>
      )}
    </>
  );
};

CommentsBlock.defaultProps = defaultProps;

const styles = StyleSheet.create({
  Comments: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  photos: {
    flexDirection: 'row',
    marginRight: unit(8),
  },
  photo: {
    width: unit(20),
    height: unit(20),
    borderRadius: unit(10),
    marginRight: unit(-8),
    borderWidth: 1,
  },
  text: {
    marginLeft: unit(8),
    flex: 1,
  },
  icon: {
    marginHorizontal: unit(-4),
    marginVertical: unit(-4),
  },
});

export default memo(CommentsBlock);
