export default {
  'Index': '/',
  'Ask': '/ask',
  'AskCopilot': '/askCopilot',
  'Feed': '/content/feed',
  'News': '/content/news',
  'Content': '/content/:resourceType/:resourceId',
  'CommunityPeers': '/community/peers',
  'CommunityCollection': '/community/collection',
  'Library': '/library/:interaction',
  'Auth/Start': '/welcome',
  'Auth/SignIn': '/auth/sign-in',
  'Auth/SignUp': '/auth/sign-up',
  'Auth/PasswordReset': '/auth/password-reset',
  'Auth/Step1': '/auth/step-1',
  'Profile': '/profile/:login',
  'Author': '/author/:id',
  'Playlist': '/playlist/:resourceId',
  'Comments': '/:section/:id/comments/',
  'NotFound': '*',
};
