import { useMemo } from 'react';

type MetaType = {
  firstPage: {
    isLoading: boolean,
    isLoaded: boolean,
  }
  nextPage: {
    isLoading: boolean,
  }
};

export default (sourceSequence: object[], meta: MetaType, countOfWireframes = 6) => {
  const sequence = useMemo(() => {
    const wireframes = new Array(countOfWireframes).fill(null).map((_, key) => ({ id: `w-${key + 1}`, type: 'material' }));

    if (meta.firstPage.isLoading) {
      return wireframes;
    }

    if (meta.nextPage.isLoading) {
      return [...sourceSequence, ...wireframes];
    }

    return sourceSequence;
  }, [sourceSequence, meta.firstPage.isLoading, meta.nextPage.isLoading]);

  const isEmpty = useMemo(() => {
    const { isLoaded, isLoading } = meta.firstPage;
    return isLoaded && !isLoading && sequence.length === 0;
  }, [meta.firstPage, sequence.length]);

  return useMemo(() => ({
    sequence,
    isEmpty,
  }), [sequence, isEmpty]);
};
