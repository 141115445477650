import { createAction } from '@reduxjs/toolkit';

import type { UserType } from 'app/entities';

import { type PaginationInfo } from './types';

export const clear = createAction('SEARCH_USER/CLEAR');

export const loadNextPage = createAction('SEARCH_USER/LOAD_NEXT_PAGE', (
  text: string,
  type: string | null,
  reload = false,
) => ({
  payload: {
    text,
    type,
  },
  meta: {
    reload,
  },
}));

export const loadNextPageDone = createAction('SEARCH_USER/LOAD_NEXT_PAGE_DONE', (
  reload = false,
) => ({
  payload: {
    reload,
  },
}));

export const addPage = createAction('SEARCH_USER/SET_PAGE', (
  items: UserType[],
  paginationInfo: PaginationInfo,
  reload = false,
) => ({
  payload: {
    items,
    paginationInfo,
  },
  meta: {
    reload,
  },
}));
