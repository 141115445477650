import type { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

const getResult = (state: DefaultRootState) => state.plus.result;

const selector = createSelector(
  [getResult],
  (result) => {
    if (!result?.data || result?.type !== 'material') {
      return null;
    }
    return result.data;
  },
);

export default selector;
