import { memo, useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { useRoute } from 'navigation/hooks';
import { useSharing } from 'hooks';
import { unit } from 'utils';

import * as contentStore from 'store/nodes/content';

import Icon from 'components/LegacyIcon';
import { View } from 'components/Themed';
import OpenMaterialSource from 'components/OpenMaterialSource';

const MobileHeader = () => {
  const route = useRoute<'Content'>();

  const { resourceId } = route.params;

  const data = useSelector(contentStore.selectors.dataById(resourceId));
  const contentSharing = useSharing(data);

  const handleShare = useCallback(() => {
    contentSharing.share();
  }, [contentSharing]);

  return (
    <View style={styles.MobileHeader}>
      {contentSharing.isSupport && (
        <TouchableOpacity style={styles.button} onPress={handleShare} activeOpacity={0.8}>
          <Icon name="ShareOutline28" size={28} />
        </TouchableOpacity>
      )}
      {data?.originalUrl && (
        <OpenMaterialSource to={data?.originalUrl} type={data?.type?.toLowerCase()} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  MobileHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
  },
  button: {
    width: unit(42),
    height: unit(42),
    marginVertical: unit(-6),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default memo(MobileHeader);
