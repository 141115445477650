import type { NoteType, MapResult } from 'app/entities';
import request from 'services/api/request';

export default async (
  resourceIds: number | number[],
): Promise<MapResult<NoteType[]>> => {
  if (!resourceIds || (Array.isArray(resourceIds) && resourceIds.length === 0)) {
    return {
      data: [],
      error: null,
    };
  }
  const contentIds = Array.isArray(resourceIds) ? resourceIds : [resourceIds];

  const { error, data } = await request.get<Record<number, NoteType>>('/user/notes', {
    query: { contentIds: contentIds.join(',') },
  });

  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  const result: Record<number, NoteType[]> = {};
  Object.entries(data).forEach(([id, note]) => {
    result[Number(id)] = [note].filter((item) => !!item);
  });

  return {
    data: result,
    error,
  };
};
