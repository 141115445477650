import {
  type PropsWithChildren, type ReactElement,
  memo, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StatusBar, StyleSheet, useWindowDimensions,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { useThemeColor, useColorScheme } from 'hooks';

import View from './View';

const defaultProps = {
  useSafe: true,
  lightColor: '#E5E5E5',
  darkColor: '#000000',
};

type PageProps = {
  useSafe?: boolean,
  componentsProps?: {
    safe?: {
      mode?: 'padding' | 'margin',
      style?: StyleProp<ViewStyle>,
    }
    wrapper?: {
      style?: StyleProp<ViewStyle>,
    }
  },
  fullScreen?: boolean,
  lightColor?: string;
  darkColor?: string;
  style?: StyleProp<ViewStyle>,
}

/**
 * @param props
 * @constructor
 * @deprecated use AdaptivePage instead
 */
const Page = (props: PropsWithChildren<PageProps>): ReactElement => {
  const {
    componentsProps,
    fullScreen,
    lightColor,
    darkColor,
    style,
    useSafe,
    children,
  } = props;

  const dimensions = useWindowDimensions();
  const theme = useColorScheme();
  let backgroundColor: string | undefined = useThemeColor({ light: lightColor, dark: darkColor });
  if (backgroundColor === 'transparent') {
    backgroundColor = undefined;
  }

  const wrapperStyles = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(style || {}),
    };
    if (fullScreen) {
      result.minHeight = dimensions.height;
    }
    return result;
  }, [dimensions.height, fullScreen, style]);

  // Будет удалено после рефакторинга
  if (!componentsProps) {
    if (useSafe) {
      return (
        <SafeAreaView mode="padding" style={styles.flex1}>
          <View style={[styles.flex1, style]}>
            <StatusBar
              backgroundColor={backgroundColor}
              barStyle={`${theme}-content`}
            />
            {children}
          </View>
        </SafeAreaView>
      );
    }

    return (
      <View style={[styles.flex1, style]}>
        <StatusBar
          backgroundColor={backgroundColor}
          barStyle={`${theme}-content`}
        />
        {children}
      </View>
    );
  }

  if (useSafe) {
    return (
      <>
        <StatusBar
          backgroundColor={backgroundColor}
          barStyle={`${theme}-content`}
        />
        <SafeAreaView mode={componentsProps?.safe?.mode || 'padding'} style={componentsProps?.safe?.style}>
          <View style={[componentsProps?.wrapper?.style, wrapperStyles]}>
            {children}
          </View>
        </SafeAreaView>
      </>
    );
  }

  return (
    <>
      <StatusBar
        backgroundColor={backgroundColor}
        barStyle={`${theme}-content`}
      />
      <View style={[componentsProps?.wrapper?.style, wrapperStyles]}>
        {children}
      </View>
    </>
  );
};

Page.defaultProps = defaultProps;

const styles = StyleSheet.create({
  flex1: {
    flex: 1,
  },
});

export default memo(Page);
