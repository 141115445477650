import {
  takeEvery, put, cancel, delay,
} from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'CommunityCollection'>());
  if (!route.isScreen('CommunityCollection')) {
    yield cancel(); return;
  }

  const isAppPrepared = yield* select((state) => state.app.prepared);
  if (!isAppPrepared) {
    yield delay(500);
    yield put(actions.runPageHandler());
    yield cancel(); return;
  }

  yield* call(() => track('CommunityCollection'));
}
