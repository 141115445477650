import JoyAvatar, { type AvatarProps as JoyAvatarProps } from '@mui/joy/Avatar';
import { thumbnail as makeThumbnail } from 'utils';
import { useMemo } from 'react';

type AvatarProps = JoyAvatarProps & {
  thumbnail?: 'small' | 'medium' | 'large' | 'original',
};

const Avatar = (props: AvatarProps) => {
  const {
    thumbnail,
    src,
    ...rest
  } = props;

  const srcFinal = useMemo(() => {
    if (!thumbnail) {
      return src;
    }
    return makeThumbnail(src, thumbnail)?.url;
  }, [src]);

  return (
    <JoyAvatar src={srcFinal} {...rest} />
  );
};

export default Avatar;
