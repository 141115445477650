import type { ResultLegacy, MaterialType } from 'app/entities';
import request from 'services/api/request';
import comment from 'services/api/comment/route';
import interaction from 'services/api/interaction/route';

export default async (): Promise<ResultLegacy<MaterialType>> => {
  const { data, error } = await request.get<MaterialType>('/content/watching');

  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  const [commentsMap, interactionsMap] = await Promise.all([
    comment.meta('content', [data.id]),
    interaction.items('content', [data.id]),
  ]);

  if (commentsMap.error || !commentsMap.data || interactionsMap.error || !interactionsMap.data) {
    return {
      data: null,
      error: commentsMap.error || interactionsMap.error,
    };
  }

  const result = {
    ...data,
    ...interactionsMap?.data?.[data?.id],
    commentMeta: commentsMap?.data?.[data?.id],
  };

  return {
    data: result,
    error,
  };
};
