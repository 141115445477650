import {
  useMemo,
} from 'react';

import { useRoute } from 'navigation/hooks';

import AdaptivePage from 'components/AdaptivePage';

import Collections from './elements/Collections';
import PeersFeed from './elements/PeersFeed';

const Community = () => {
  const route = useRoute<'CommunityPeers' | 'CommunityCollection'>();

  const pagePaddingHorizontalScheme = useMemo(() => ({
    320: 16,
  }), []);

  return (
    <AdaptivePage
      desktopHeaderLeftPanel="ask"
      desktopHeaderMiddlePanel={false}
      bodyMaxWidth={1280}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
    >
      {route.name === 'CommunityCollection' && (
        <Collections />
      )}
      {route.name === 'CommunityPeers' && (
        <PeersFeed />
      )}
    </AdaptivePage>
  );
};

export default Community;
