import {
  takeEvery, put, cancel, delay,
} from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as activityStore from 'store/nodes/activity';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'CommunityPeers'>());
  if (!route.isScreen('CommunityPeers')) {
    yield cancel(); return;
  }

  const isAppPrepared = yield* select((state) => state.app.prepared);
  if (!isAppPrepared) {
    yield delay(500);
    yield put(actions.runPageHandler());
    yield cancel(); return;
  }

  const userProfile = yield* select(userStore.selectors.getMy);

  let byUser = userProfile ? 'my' : 'promo';
  if (route.params?.user) {
    byUser = route.params.user;
  }
  yield put(activityStore.actions.loadList(byUser));

  yield* call(() => track('CommunityPeers'));
}
