import { memo, useCallback } from 'react';
import { type StyleProp, type ViewStyle, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { unit } from 'utils';
import { useDispatcher } from 'store/utils/redux/hooks';
import * as searchStore from 'store/nodes/search';

import { TextInput } from 'components/Form';
import { View } from 'components/Themed';
import Icon from 'ui/Icon';
import Button from 'components/Button';
import { useTheme } from '@mui/joy';
import { isLibraryScreen } from 'navigation/guards';
import { useRoute } from 'navigation/hooks';
import {
  useFontSize, useBorderRadius, useButtonSize, usePadding, usePlaceholder,
} from './hooks';

const defaultProps = {
  size: 'medium',
};

type SearchInputProps = {
  style?: StyleProp<ViewStyle>,
  size?: 'small' | 'medium' | 'large',
  autoFocus?: boolean,
} & typeof defaultProps;

const SearchInput = (props: SearchInputProps) => {
  const {
    style,
    size,
    autoFocus,
  } = props;
  const dispatcher = useDispatcher();

  const route = useRoute<'Library'>();

  const theme = useTheme();

  const value = useSelector(searchStore.selectors.filterText);
  const method = useSelector(searchStore.selectors.method);
  const isProcessed = route.params.interaction === 'search' && route.params.text === value;

  const borderRadius = useBorderRadius(size);
  const { inputVerticalPaddings, inputHorizontalPadding } = usePadding(size, value);
  const buttonSize = useButtonSize(size);
  const placeholder = usePlaceholder(method);
  const fontSize = useFontSize(size);

  const handleChange = useCallback((text: string) => {
    dispatcher.search.setFilterText(text, false, false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!value || isProcessed) {
      return;
    }
    dispatcher.search.setFilterText(value, true, true);
  }, [value, isProcessed]);

  return (
    <View style={[styles.wrapper, style]}>
      <TextInput
        autoFocus={autoFocus}
        placeholder={isLibraryScreen(route) ? 'Search by title in my library' : placeholder}
        style={styles.search}
        inputFont={{
          size: fontSize as any,
        }}
        radius={borderRadius}
        pickerStyle={[
          inputVerticalPaddings,
          inputHorizontalPadding,
          {
            backgroundColor: theme.palette.background.level1,
          },
        ]}
        onChange={handleChange}
        value={value}
        onEnter={handleSubmit}
      />
      <View style={styles.actions}>
        {!!value && !isProcessed && (
          <Button
            width={buttonSize}
            height={buttonSize}
            radius="full"
            type="button-icon"
            variant="text"
            style={styles.submitAction}
            lightColor="#e5e5e5"
            darkColor="#000000"
            onPress={handleSubmit}
          >
            <Icon
              name="up"
              fw
              weight="light"
              color="var(--joy-palette-neutral-plainColor)"
              sx={{
                fontSize,
              }}
            />
          </Button>
        )}
      </View>
    </View>
  );
};

SearchInput.defaultProps = defaultProps;

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    flexDirection: 'row',
    width: '80%',
  },
  search: {
    flex: 1,
  },
  actions: {
    position: 'absolute',
    flexDirection: 'row',
    right: unit(4),
    top: unit(4),
    gap: unit(4),
  },
  submitAction: {
    position: 'relative',
  },
  optionsAction: {
    position: 'relative',
    marginRight: unit(4),
  },
});

export default memo(SearchInput);
