import request from 'services/api/request';
import type { SuccessResult } from 'app/entities';

export default async (
  highlightId: number,
): Promise<SuccessResult> => {
  const { error } = await request.delete(`/highlight/${highlightId}`);
  if (error) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: { success: true },
    error,
  };
};
