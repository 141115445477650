import { createAction } from '@reduxjs/toolkit';

import type { CommunityItemVariant } from 'app/entities';

export const loadCollections = createAction('COMMUNITY/LOAD_COLLECTIONS');

export const loadCollectionDone = createAction('COMMUNITY/LOAD_COLLECTIONS_DONE');

export const setCollection = createAction('COMMUNITY/SET_COLLECTION', (
  data: CommunityItemVariant | CommunityItemVariant[],
) => ({
  payload: {
    data,
  },
}));

export const setPanelName = createAction('COMMUNITY/SET_PANEL_NAME', (
  name: string,
) => ({
  payload: {
    name,
  },
}));

export const logOut = createAction('COMMUNITY/LOGOUT');
