import {
  takeEvery, put, cancel,
  type SagaReturnType,
} from 'redux-saga/effects';

import { navigate } from 'navigation/methods';
import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.doCheckEmail.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.doCheckEmail>) {
  const { email } = action.payload;

  const result = yield* call(() => api.resource.auth.checkCredentials(email));
  if (result.error || !result.data) {
    yield put(actions.doCheckEmailDone());
    yield cancel(); return;
  }

  if (result?.data?.isRegisteredUser) {
    yield* call(() => navigate('Auth/SignIn'));
  } else {
    yield* call(() => navigate('Auth/SignUp'));
  }
  yield put(actions.doCheckEmailDone());
}
