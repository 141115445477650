import {
  takeEvery, put, cancel, delay,
} from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as newsStore from 'store/nodes/news';

import * as actions from '../actions';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'News'>());
  if (!route.isScreen('News')) {
    yield cancel(); return;
  }

  const isAppPrepared = yield* select((state) => state.app.prepared);
  if (!isAppPrepared) {
    yield delay(500);
    yield put(actions.runPageHandler());
    yield cancel(); return;
  }

  const currentTopic = yield* select(newsStore.selectors.filterTopic);

  if (currentTopic !== route.params.topic) {
    yield put(newsStore.actions.loadNextPage(true));
  }
  if (route.params.topic) {
    yield put(newsStore.actions.setTopic(route.params.topic));
  }

  yield* call(() => track('News'));
}
