import {
  type CSSProperties,
  memo, useCallback, useMemo, useState,
} from 'react';
import {
  type StyleProp, type ViewStyle, type LayoutChangeEvent,
  StyleSheet, TouchableOpacity, Platform,
} from 'react-native';

import { useColorScheme, useThemeColor } from 'hooks';
import { guard, unit } from 'utils';
import { useMaterialInfo } from 'hooks/material';

import { Text, View } from 'components/Themed';
import WebLink from 'components/WebLink';
import Picture from 'components/Picture';
import Info from 'components/Info';
import Tags from 'components/Tags';
import Icon from 'ui/Icon';

import {
  useAuthor, useTagsCrop,
} from '../hooks';

import type { MaterialLayoutProps } from '../types';

import DescriptionBlock from '../../../elements/DescriptionBlock';
import FooterBlock from '../../../elements/FooterBlock';
import AuthorBlock from '../../../elements/AuthorBlock';
import HeaderToolbar from '../../../elements/HeaderToolbar';

import { useElementSize } from '../../../hooks';

const defaultProps = {
  hasToolbar: true,
  hasAuthor: true,
  hasDescription: true,
  hasInfo: true,
  hasTags: true,
  hasComments: true,
  hasFooterQueue: false,
};

const HorizontalLayout = (props: MaterialLayoutProps) => {
  const {
    data,
    radius,
    type,
    hasMarkByIKI,
    hasToolbar,
    hasAuthor,
    hasDescription,
    hasInfo,
    hasTags,
    hasComments,
    hasWebLink,
    hasFooterQueue,
    tagsLimit,
    AuthorComponent,
    ExtraComponent,
    OverlayComponent,
    isHoverMode,
    isTouchMode,
    onAuthorPress,
    onBodyPress,
    onOptionsPress,
    onSourcePress,
    onCommentsPress,
    onBookmarkPress,
  } = props;

  const [bodyHeight, setBodyHeight] = useState<number>(0);
  const theme = useColorScheme();
  const backgroundColor = useThemeColor({ light: '#ffffff', dark: 'transparent' });
  const borderColor = useThemeColor({ light: '#ffffff', dark: '#303030' });
  const { elementSize, handleElementLayout } = useElementSize();

  const tags = useTagsCrop(data?.tags, tagsLimit);
  const info = useMaterialInfo(data, hasMarkByIKI);
  const authorData = useAuthor(data?.authors?.[0]);

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    setBodyHeight(event.nativeEvent.layout.height);
  }, []);

  const toolbarStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.toolbar),
      opacity: isHoverMode ? 1 : 0,
    };
    if (isTouchMode) {
      result.opacity = 1;
    }
    if (Platform.OS === 'web') {
      (result as CSSProperties).transition = 'opacity 300ms ease';
    }
    return result;
  }, [isHoverMode, isTouchMode]);

  const panelStyle = useMemo((): StyleProp<ViewStyle> => ({
    'xl': styles.panelXL,
    'l': styles.panelL,
    'm': styles.panelM,
  })[elementSize], [elementSize]);

  const bodyStyle = useMemo(() => ({
    'xl': styles.bodyXL,
    'l': styles.bodyL,
    'm': styles.bodyM,
  })[elementSize] as StyleProp<ViewStyle>, [elementSize]);

  const containerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> | CSSProperties = ({
      ...StyleSheet.flatten(styles.HorizontalLayout),
    });
    if (radius) {
      result.borderRadius = radius;
    }
    if (guard.isWebStyleProp(result) && theme === 'light') {
      result.boxShadow = '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.15)';
    }
    return result as StyleProp<ViewStyle>;
  }, [theme, radius]);

  const renderPoster = useMemo(() => {
    if (!data?.image) {
      return null;
    }
    const width = {
      'xl': 200,
      'l': 150,
      'm': 100,
    }[elementSize];
    return (
      <Picture
        source={data?.image}
        size="medium"
        width={width}
        hasBackground
        radius={!radius ? 5 : false}
        radiusRight={!radius ? false : radius}
        radiusLeft={!radius ? false : 0}
        aspectRatio="none"
        style={[styles.poster, { minHeight: unit(80), maxHeight: bodyHeight + 2 }]}
      >
        {Platform.OS === 'web' && theme === 'light' && (
          <div style={{ flex: 1, background: 'rgba(0,0,0,0.03)' }} />
        )}
        {Platform.OS === 'web' && theme === 'dark' && (
          <div style={{ flex: 1, background: 'rgba(255,255,255,0.1)' }} />
        )}
      </Picture>
    );
  }, [elementSize, theme, data?.image, bodyHeight, radius]);

  const titleRender = useMemo(() => {
    if (!data?.title) {
      return null;
    }

    const style = { marginBottom: unit(10), flex: elementSize === 'm' ? 1 : undefined };
    const dynamicProps = {
      'xl': { size: 20 },
      'l': { size: 17 },
      'm': { size: 16, ellipsizeMode: 'tail' as 'head' | 'middle' | 'clip' | 'tail' | undefined, numberOfLines: 2 },
    }[elementSize];
    return (
      <Text weight="medium" lightColor="#000000" darkColor="#ffffff" style={style} {...dynamicProps}>
        {data.private && (
          <Icon size="sm" marginRight={1} weight="duotone" name="lock" />
        )}
        {data?.title?.trim?.()}
      </Text>
    );
  }, [elementSize, data?.title]);

  const descriptionRender = useMemo(() => {
    if (elementSize === 'm' || !hasDescription || !data?.description) {
      return null;
    }
    return (
      <DescriptionBlock style={{ marginBottom: unit(10), marginRight: unit(32) }} ellipsizeMode="tail" numberOfLines={5} text={data?.description} />
    );
  }, [elementSize, hasDescription, data?.description]);

  const infoRender = useMemo(() => {
    if (!hasInfo || !info) {
      return null;
    }
    if (elementSize === 'm') {
      return <Info list={info} style={{ marginBottom: 0 }} fontSize={12} />;
    }
    if (elementSize === 'l') {
      return <Info list={info} style={{ marginBottom: 10 }} fontSize={13} />;
    }
    return <Info list={info} style={{ marginBottom: 10 }} fontSize={15} />;
  }, [elementSize, hasInfo, info]);

  if (!data) {
    return (
      <View
        onLayout={handleElementLayout}
        style={[containerStyle, { minHeight: unit(['m'].includes(elementSize) ? 100 : 250) }]}
        lightColor="#ffffff50"
        darkColor="#ffffff20"
      />
    );
  }

  return (
    <TouchableOpacity
      style={[containerStyle, { backgroundColor, borderColor, borderWidth: 1 }]}
      onPress={onBodyPress}
      activeOpacity={guard.isLink(data?.originalUrl) ? 0.8 : 1}
      onLayout={handleElementLayout}
    >
      {!!OverlayComponent && (
        <View pointerEvents="none" style={styles.overlay}>{OverlayComponent}</View>
      )}
      <WebLink href={data?.internalUrl} disabled={!hasWebLink}>
        <View style={panelStyle}>
          <View onLayout={handleLayout} style={bodyStyle}>
            {['xl', 'l'].includes(elementSize) && hasAuthor && !!authorData && (
              <AuthorBlock onPress={onAuthorPress} Component={AuthorComponent} data={authorData} style={{ marginBottom: 12 }} />
            )}
            {titleRender}
            {descriptionRender}
            {infoRender}
            {!!ExtraComponent && ExtraComponent}
            {['xl', 'l'].includes(elementSize) && !!hasTags && tags.length > 0 && (
              <Tags data={data} tagsLimit={tagsLimit} sx={{ marginBottom: unit(10) }} />
            )}
            {['xl', 'l'].includes(elementSize) && (isTouchMode || hasComments || hasFooterQueue) && (
              <>
                {hasComments === false && hasFooterQueue && (
                  <View style={{ flex: 1 }} />
                )}
                <FooterBlock
                  style={[styles.footer, hasComments === false && hasFooterQueue && { alignSelf: 'flex-end', marginTop: unit(-40) }]}
                  data={data}
                  hasOptions={isTouchMode}
                  hasQueue={hasFooterQueue}
                  MainComponent={hasComments ? undefined : false}
                  onOptionsPress={onOptionsPress}
                  onCommentsPress={onCommentsPress}
                  onBookmarkPress={onBookmarkPress}
                />
              </>
            )}
          </View>
          {renderPoster}
        </View>
      </WebLink>
      {hasToolbar && !!data && !isTouchMode && (
        <HeaderToolbar
          style={toolbarStyle}
          sourceName={data?.source?.name}
          align="right"
          hasOptions={type === 'material'}
          hasSource={guard.isLink(data?.originalUrl) && !isTouchMode}
          onOptionsPress={onOptionsPress}
          onSourcePress={onSourcePress}
        />
      )}
    </TouchableOpacity>
  );
};

HorizontalLayout.defaultProps = defaultProps;

const styles = StyleSheet.create({
  HorizontalLayout: {
    position: 'relative',
    flexDirection: 'column',
    borderRadius: unit(6),
    zIndex: Platform.OS === 'web' ? 'unset' as unknown as number : 0,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10,
  },
  poster: {
    alignSelf: 'stretch',
    marginTop: -1,
    marginRight: -1,
    marginBottom: -1,
  },
  info: {
    marginBottom: unit(10),
  },
  footer: {
    marginBottom: unit(10),
  },
  toolbar: {
    position: 'absolute',
    top: unit(10),
    right: unit(16),
    left: unit(16),
    zIndex: 2,
  },
  panelXL: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  panelL: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  panelM: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    height: unit(92),
  },
  bodyXL: {
    flex: 1,
    paddingHorizontal: unit(20),
    paddingTop: unit(18),
    paddingBottom: unit(8),
    alignSelf: 'stretch',
  },
  bodyL: {
    flex: 1,
    paddingHorizontal: unit(16),
    paddingTop: unit(12),
    paddingBottom: unit(2),
    alignSelf: 'stretch',
  },
  bodyM: {
    flex: 1,
    paddingHorizontal: unit(16),
    paddingTop: unit(12),
    paddingBottom: unit(12),
    alignSelf: 'stretch',
  },
});

export default memo(HorizontalLayout);
