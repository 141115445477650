import { useEffect, useMemo, useRef } from 'react';

const useResizeDetect = (callback?: (target: HTMLDivElement) => void) => {
  const sizeMarkerRef = useRef(null);

  useEffect(() => {
    const { current } = sizeMarkerRef;
    if (!current || !callback) {
      return undefined;
    }
    const resizeObserver = new ResizeObserver(() => {
      callback(current);
    });
    resizeObserver.observe(current);
    return () => {
      resizeObserver.disconnect();
      resizeObserver.unobserve(current);
    };
  }, [callback]);

  return useMemo(() => (
    <div
      ref={sizeMarkerRef}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        width: '100%',
        height: 0,
      }}
    />
  ), []);
};

export default useResizeDetect;
