import React, { memo, useCallback } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';

import * as contentStore from 'store/nodes/content';

import { useRoute } from 'navigation/hooks';

import {
  Box, Button, IconButton, Tooltip, Typography,
} from '@mui/joy';
import Icon from 'ui/Icon';

const SourceButton = () => {
  const route = useRoute<'Content'>();
  const { resourceId } = route.params;

  const data = useSelector(contentStore.selectors.dataById(resourceId));

  const handleOpen = useCallback(() => {
    if (!data?.originalUrl) {
      return;
    }
    if (Platform.OS === 'web' && !!document) {
      const link = document.createElement('a');
      link.setAttribute('href', data?.originalUrl);
      link.setAttribute('target', '_blank');
      link.click();
    }
  }, [data?.originalUrl]);

  return (
    <Box mr={3}>
      <Tooltip arrow variant="outlined" size="sm" title="Open source link">
        <Button
          size="sm"
          color="neutral"
          variant="soft"
          onClick={handleOpen}
          endDecorator={
            <Icon name="arrow-up-right" fw weight="regular" color="icon" />
          }
        >
          <Typography color="neutral" fontSize={16} fontWeight={400}>Open source</Typography>
        </Button>
      </Tooltip>
    </Box>
  );
};

export default memo(SourceButton);
