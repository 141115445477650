import {
  type SagaReturnType,
  takeEvery, cancel, put, delay,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
import type { NoteType } from 'app/entities';

import { saveLastRoute } from 'utils';
import * as api from 'services/api';
import { navigate } from 'navigation/methods';
import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';
import network from 'lib/network';
import * as materialStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import { actions } from '../slice';
import * as utils from '../utils';

export const config = {
  action: actions.add.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.add>) {
  const {
    text, relation,
  } = action.payload;
  const hasSession = yield* call(() => api.credentials.hasSession());
  if (!hasSession) {
    yield* call(() => saveLastRoute());
    yield* call(() => navigate('Auth/Start'));
    yield cancel(); return;
  }

  let url = '';
  if (relation?.resourceType === 'material') {
    url = `/content/${relation.resourceId}/note`;
  }
  if (relation?.resourceType === 'collection') {
    url = `/playlists/${relation.resourceId}/note`;
  }

  if (!url) {
    yield put(actions.addDone({ relation }));
    yield cancel(); return;
  }

  const { data, errors } = yield* call(() => (
    network.request <NoteType>(url).body({ text }).post()
  ));

  if (!data || errors) {
    Alert.error('Error creating note');
    yield put(actions.addDone({ relation }));
    yield cancel(); return;
  }
  const preparedData = yield* utils.prepareNote({ ...data, createdAt: moment().toISOString() });
  const item = {
    data: preparedData,
    ...(relation && {
      relation: {
        resourceType: relation.resourceType,
        resourceId: relation.resourceId,
      },
    }),
  };

  yield put(actions.setItem(item));
  yield put(actions.addDone({ relation }));
  yield delay(100);
  if (relation?.resourceType === 'material') {
    yield put(materialStore.actions.loadById(relation.resourceId));
  }
  if (relation?.resourceType === 'collection') {
    yield put(collectionStore.actions.loadById({ id: relation.resourceId }));
  }
}
