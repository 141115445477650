import {
  memo, useCallback,
  type ReactElement,
} from 'react';
import {
  StyleSheet, TouchableOpacity, Platform,
} from 'react-native';

import { Text, View } from 'components/Themed';
import { unit } from 'utils';

interface ItemProps {
  icon: ReactElement,
  label: string,
  onPress: () => void,
}

const MenuItem = (props: ItemProps): ReactElement => {
  const {
    icon,
    label,
    onPress,
  } = props;

  const handlePress = useCallback(() => {
    onPress?.();
  }, [onPress]);

  return (
    <TouchableOpacity style={styles.Item} onPress={handlePress} activeOpacity={0.8}>
      <View style={styles.icon}>{icon}</View>
      <Text style={styles[`${Platform.OS}Text` as 'webText' | 'iosText' | 'androidText']} size={16}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  Item: {
    flexDirection: 'row',
    paddingVertical: unit(12),
    alignItems: 'center',
  },
  icon: {
    marginRight: unit(10),
  },
  webText: {
    marginBottom: unit(2),
  },
  iosText: {
  },
  androidText: {
  },
});

export default memo(MenuItem);
