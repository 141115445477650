import { useCallback, useMemo } from 'react';
import * as Linking from 'expo-linking';
import Clipboard from '@react-native-clipboard/clipboard';
import { track } from '@amplitude/analytics-react-native';

import { useResourceInfo, useSharing } from 'hooks';

import type {
  MaterialType, CollectionType, NoteType, RagMaterialType,
} from 'app/entities';
import { useDispatcher } from 'store/utils/redux/hooks';

import { controller as modal } from 'components/Modal2';
import Alert from 'components/Alert';
import { store } from 'store';

import { guard } from 'utils';

/**
 * @todo перенести в /widgets/Card/model
 * @param data
 */
export default (data: MaterialType | CollectionType | NoteType | RagMaterialType | null) => {
  const dispatcher = useDispatcher();

  const handleAddRagMaterialLink = useCallback((event) => {
    event.stopPropagation();
    if (!guard.isRagMaterial(data)) {
      return;
    }
    dispatcher.plus.parseText({ value: data?.originalUrl, privacy: false });
  }, [data]);

  const resourceInfo = useResourceInfo(data);
  const sharing = useSharing(data);

  const handleQueue = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    if (!resourceInfo) {
      return;
    }
    dispatcher.interaction.toggleBookmark(resourceInfo.legacy.type, resourceInfo.id);
  }, [resourceInfo]);

  const handleComplete = useCallback(() => {
    if (!resourceInfo) {
      return;
    }
    modal.popup.review.delay(400).open({ resource: resourceInfo.legacy.type, resourceId: resourceInfo.id });
  }, [resourceInfo]);

  const handleToNewCollection = useCallback(() => {
    if (!resourceInfo || resourceInfo.type !== 'material') {
      return;
    }
    dispatcher.modal.open('AddorEditCollection', { create: { materialIds: [resourceInfo.id] } });
  }, [resourceInfo]);

  const handleToExistsCollection = useCallback((collectionId: number) => {
    if (!collectionId || !resourceInfo || resourceInfo.type !== 'material') {
      return;
    }
    const state = store.getState();
    const material = state.content.data[resourceInfo.id];
    if (!material) {
      return;
    }

    dispatcher.playlist.addMaterialItem({
      collectionId, data: material, itemType: 'material', options: { append: true },
    });
  }, [resourceInfo]);

  const handleEdit = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    if (!resourceInfo) {
      return;
    }
    if (resourceInfo.type === 'material') {
      dispatcher.modal.open('EditMaterial', { id: resourceInfo.id });
    }
    if (resourceInfo.type === 'collection') {
      dispatcher.modal.open('AddorEditCollection', { edit: { id: resourceInfo?.id } });
    }
  }, [resourceInfo]);

  const handleToCollection = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    if (!resourceInfo) {
      return;
    }
    dispatcher.modal.open('AddToCollection', { id: resourceInfo.id });
  }, [handleToNewCollection, handleToExistsCollection, resourceInfo]);

  const handleShare = useCallback(() => {
    sharing.share();
  }, [sharing]);

  const handleLink = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    if (guard.isMaterial(data) && data.originalUrl) {
      Clipboard.setString(data.originalUrl);
    } else {
      const internalUrl = !!data && 'internalUrl' in data ? data.internalUrl : null;
      if (!internalUrl) {
        return;
      }
      const link = Linking.createURL(internalUrl);
      Clipboard.setString(link);
    }
    Alert.success('Link was copied');
  }, [data]);

  const handlePlaySummary = useCallback(() => {
    modal.popup.promo.open();
    track('PROMO:Card/ContextAction(Play audio summary)');
  }, []);
  const handleTLDR = useCallback(() => {
    modal.popup.promo.open();
    track('PROMO:Card/ContextAction(TLDR with GPT4)');
  }, []);
  const handleShowRelated = useCallback(() => {
    modal.popup.promo.open();
    track('PROMO:Card/ContextAction(Show related)');
  }, []);

  const handleRemove = useCallback((event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    if (!resourceInfo) {
      return;
    }
    dispatcher.library.remove(resourceInfo.type, resourceInfo.id);
  }, [resourceInfo]);

  return useMemo(() => ({
    handleQueue,
    handleComplete,
    handleToCollection,
    handleShare,
    handleLink,
    handlePlaySummary,
    handleTLDR,
    handleShowRelated,
    handleRemove,
    handleEdit,
    handleAddRagMaterialLink,
  }), [
    handleQueue,
    handleComplete,
    handleToCollection,
    handleShare,
    handleLink,
    handlePlaySummary,
    handleTLDR,
    handleShowRelated,
    handleRemove,
    handleEdit,
    handleAddRagMaterialLink,
  ]);
};
