import {
  useCallback, useEffect, useMemo,
} from 'react';
import { Box, Typography } from '@mui/joy';
import { Virtuoso } from 'react-virtuoso';

import { useFocusEffect } from 'navigation/hooks';

import {
  useListData, useListLayout, useSearchMethod,
} from 'hooks';

import RightBar from 'screens/Ask/elements/RightBar';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as searchStore from 'store/nodes/search';

import AdaptivePage from 'components/AdaptivePage';
import { useScrollMethods } from 'components/Scroller';
import Button from 'components/Button';

import Copilot from 'widgets/Copilot';
import PanelsBar from 'widgets/PanelsBar';
import Card from 'widgets/Card';

import TopBarDesktop from './elements/TopBarDesktop';
import TopBarMobile from './elements/TopBarMobile';

const Regular = () => {
  const dispatcher = useDispatcher();

  const { setScrollValue, getScrollValue, scrollTo } = useScrollMethods();
  const { scope: searchMethodScope, type: searchMethodType } = useSearchMethod();

  const list = useSelector(searchStore.selectors.list);
  const listMeta = useSelector(searchStore.selectors.listMeta);
  const listStatus = useSelector(searchStore.selectors.listStatus);

  const { listLayout } = useListLayout('search.list.mode', 'row');

  const listData = useListData(list.sequence, listMeta, 12);

  useEffect(() => {
    if (listMeta.firstPage.isLoading === true) {
      setScrollValue('Ask', 0);
      scrollTo(0);
    }
  }, [listMeta.firstPage.isLoading]);

  useFocusEffect(useCallback(() => {
    const { top } = getScrollValue('Ask');
    setTimeout(() => {
      // @todo реализовать совместимую со списками версию (для devices)
      scrollTo(top);
    }, 10);
  }, []));

  const handlePlusOpen = useCallback(() => {
    dispatcher.plus.open({ toCollection: { append: true } });
  }, []);

  const handleRefresh = useCallback(() => {
    dispatcher.search.loadNextPage({ reload: true });
  }, []);

  const handleNextPage = useCallback(() => {
    if (!listMeta.isConsistent) {
      return;
    }
    dispatcher.search.loadNextPage();
  }, [listMeta.isConsistent]);

  const renderItem = useCallback((args: any) => {
    const [highlight] = args?.item?.searchMeta?.highlights || [];
    const extraData: Record<string, string> = {};
    if (highlight) {
      extraData.description = highlight;
    }

    return (
      <Card
        id={args.item.id}
        type={args.item.type}
        descriptionLineLimit={4}
        hasDescription
        hasAuthor
        hasSummary
        hasLink
        hasTags
        hasToCollection
        hasIndexed
        hasRemove={searchMethodScope === 'Library'}
        hasQueue={searchMethodScope === 'Global'}
        sx={{
          mx: 1,
          mb: 2,
        }}
      />
    );
  }, [listLayout.isRow, searchMethodScope]);

  const pagePaddingHorizontalScheme = useMemo(() => ({
    320: 16,
  }), []);

  const isStartPage = !listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0;
  const isNoResults = listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && listStatus === 'no-results';
  const isNothingToSearch = listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && listStatus === 'nothing-to-search';
  const isHasResult = (listMeta.firstPage.isLoading || listMeta.nextPage.isLoading || list.sequence.length > 0);

  return (
    <AdaptivePage
      desktopHeaderLeftPanel={(
        <>
          <TopBarDesktop />
          <TopBarMobile />
        </>
      )}
      desktopHeaderRightPanel={<RightBar />}
      bodyMaxWidth={1280}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
    >
      <Box sx={{ '@media (max-width: 767px)': { mt: 5 } }}>
        <PanelsBar
          contentMeta={{
            label: 'Ask',
            value: 'ask',
          }}
          content={(
            <Box position="relative">
              <>
                {searchMethodScope === 'Web' && <Copilot variant="web" />}
                {searchMethodScope === 'Global' && <Copilot variant="global-fulltext" />}
                {searchMethodScope === 'Library' && <Copilot variant="library-fulltext" />}
              </>
            </Box>
          )}
          panels={[
            {
              label: 'Result',
              value: 'copilot',
              component: (
                <>
                  {isStartPage && (
                    <Box display="flex" flexDirection="column" flex={1} justifyContent="center" alignItems="center">
                      <Typography fontSize={17}>Start typing</Typography>
                      <Typography color="neutral" fontSize={15}>to search</Typography>
                    </Box>
                  )}
                  {isNoResults && (
                    <Box display="flex" flexDirection="column" flex={1} justifyContent="center" alignItems="center">
                      <Typography fontSize={17}>No results</Typography>
                      <Typography color="neutral" fontSize={15}>Try to type something else</Typography>
                    </Box>
                  )}
                  {isNothingToSearch && (
                    <Box display="flex" flexDirection="column" flex={1} justifyContent="center" alignItems="center">
                      <Typography fontSize={17}>Nothing to search</Typography>
                      <Typography color="neutral" fontSize={15} display="flex" flexDirection="row">
                        <Button type="button-icon" variant="text" onPress={handlePlusOpen}>
                          <Typography color="primary" fontSize={15}>Add</Typography>
                        </Button>
                        &nbsp;some content to your library
                      </Typography>
                    </Box>
                  )}
                  {isHasResult && (
                  <Box sx={{
                    ml: 1, mt: 1, mb: 1, mr: -0.4, height: '100%',
                  }}
                  >
                    <Virtuoso
                      data={listData.sequence || []}
                      totalCount={listData.sequence?.length}
                      itemContent={(_, item) => (
                        renderItem({ item })
                      )}
                    />
                  </Box>
                  )}
                </>
              ),
            },
          ]}
          sx={{
            '@media (max-width: 767px)': {
              mx: -2,
            },
          }}
        />
      </Box>
    </AdaptivePage>
  );
};

export default Regular;
