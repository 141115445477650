import type { SwiperRef } from 'swiper/swiper-react';
import { useCallback, useEffect } from 'react';

const useSwiperMouseWheel = (target?: { current?: SwiperRef | null }) => {
  const mouseWheelHandler = useCallback((event: WheelEvent) => {
    event.preventDefault();
    const direction = event.deltaY > 0 ? 'next' : 'prev';
    if (!target?.current || !target.current.swiper) {
      return;
    }
    const { swiper } = target.current;
    if (direction === 'next') {
      swiper.slideNext();
    } else {
      swiper.slidePrev();
    }
  }, []);

  const enableMouseWheel = useCallback(() => {
    target?.current?.swiper?.el?.addEventListener('wheel', mouseWheelHandler, { passive: false });
  }, []);

  const disableMouseWheel = useCallback(() => {
    target?.current?.swiper?.el?.removeEventListener('wheel', mouseWheelHandler);
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.altKey) {
      enableMouseWheel();
    }
  }, [enableMouseWheel]);

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (!event.altKey) {
      disableMouseWheel();
    }
  }, [disableMouseWheel]);

  useEffect(() => {
    disableMouseWheel();
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      disableMouseWheel();
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [disableMouseWheel, disableMouseWheel]);
};

export default useSwiperMouseWheel;
