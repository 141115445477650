import type { ScreenParams } from 'navigation/types';

export const isProfileScreen = (
  route: { name: 'Profile', params: ScreenParams['Profile'], isScreen: (name: 'Profile') => true } | any,
): route is { name: 'Profile', params: ScreenParams['Profile'], isScreen: (name: 'Profile') => true } => {
  return route.name === 'Profile';
};

export const isNewsScreen = (
  route: { name: 'News', params: ScreenParams['News'], isScreen: (name: 'News') => true } | any,
): route is { name: 'News', params: ScreenParams['News'], isScreen: (name: 'News') => true } => {
  return route.name === 'News';
};

export const isAskScreen = (
  route: { name: 'Ask', params: ScreenParams['Ask'], isScreen: (name: 'Ask') => true } | any,
): route is { name: 'Ask', params: ScreenParams['Ask'], isScreen: (name: 'Ask') => true } => {
  return route.name === 'Ask';
};

export const isIndexScreen = (
  route: { name: 'Index', params: ScreenParams['Index'], isScreen: (name: 'Index') => true } | any,
): route is { name: 'Index', params: ScreenParams['Index'], isScreen: (name: 'Index') => true } => {
  return route.name === 'Index';
};

export const isStaffPicksScreen = (
  route: { name: 'Feed', params: ScreenParams['Feed'], isScreen: (name: 'Feed') => true } | any,
): route is { name: 'Feed', params: ScreenParams['Feed'], isScreen: (name: 'Feed') => true } => {
  return route.name === 'Feed';
};

export const isCommunityPeersScreen = (
  route: { name: 'CommunityPeers', params: ScreenParams['CommunityPeers'], isScreen: (name: 'CommunityPeers') => true } | any,
): route is { name: 'CommunityPeers', params: ScreenParams['CommunityPeers'], isScreen: (name: 'CommunityPeers') => true } => {
  return route.name === 'CommunityPeers' && !route.params.user;
};

export const isCommunityPeersByUserScreen = (
  route: { name: 'CommunityPeers', params: ScreenParams['CommunityPeers'], isScreen: (name: 'CommunityPeers') => true } | any,
): route is { name: 'CommunityPeers', params: ScreenParams['CommunityPeers'], isScreen: (name: 'CommunityPeers') => true } => {
  return route.name === 'CommunityPeers' && !!route.params.user;
};

export const isCommunityCollectionScreen = (
  route: { name: 'CommunityCollection', params: ScreenParams['CommunityCollection'], isScreen: (name: 'CommunityCollection') => true } | any,
): route is { name: 'CommunityCollection', params: ScreenParams['CommunityCollection'], isScreen: (name: 'CommunityCollection') => true } => {
  return route.name === 'CommunityCollection';
};

export const isLibraryScreen = (
  route: { name: 'Library', params: ScreenParams['Library'], isScreen: (name: 'Library') => true } | any,
): route is { name: 'Library', params: ScreenParams['Library'], isScreen: (name: 'Library') => true } => {
  return route.name === 'Library';
};

export const isAuthorScreen = (
  route: { name: 'Author', params: ScreenParams['Author'], isScreen: (name: 'Author') => true } | any,
): route is { name: 'Author', params: ScreenParams['Author'], isScreen: (name: 'Author') => true } => {
  return route.name === 'Author';
};

export const isAuthStartScreen = (
  route: { name: 'Auth/Start', params: ScreenParams['Auth/Start'], isScreen: (name: 'Auth/Start') => true } | any,
): route is { name: 'Auth/Start', params: ScreenParams['Auth/Start'], isScreen: (name: 'Auth/Start') => true } => {
  return route.name === 'Auth/Start';
};

export const isAuthSignInScreen = (
  route: { name: 'Auth/SignIn', params: ScreenParams['Auth/SignIn'], isScreen: (name: 'Auth/SignIn') => true } | any,
): route is { name: 'Auth/SignIn', params: ScreenParams['Auth/SignIn'], isScreen: (name: 'Auth/SignIn') => true } => {
  return route.name === 'Auth/SignIn';
};

export const isAuthSignUpScreen = (
  route: { name: 'Auth/SignUp', params: ScreenParams['Auth/SignUp'], isScreen: (name: 'Auth/SignUp') => true } | any,
): route is { name: 'Auth/SignUp', params: ScreenParams['Auth/SignUp'], isScreen: (name: 'Auth/SignUp') => true } => {
  return route.name === 'Auth/SignUp';
};

export const isAuthPasswordResetScreen = (
  route: { name: 'Auth/PasswordReset', params: ScreenParams['Auth/PasswordReset'], isScreen: (name: 'Auth/PasswordReset') => true } | any,
): route is { name: 'Auth/PasswordReset', params: ScreenParams['Auth/PasswordReset'], isScreen: (name: 'Auth/PasswordReset') => true } => {
  return route.name === 'Auth/PasswordReset';
};

export const isAuthStep1Screen = (
  route: { name: 'Auth/Step1', params: ScreenParams['Auth/Step1'], isScreen: (name: 'Auth/Step1') => true } | any,
): route is { name: 'Auth/Step1', params: ScreenParams['Auth/Step1'], isScreen: (name: 'Auth/Step1') => true } => {
  return route.name === 'Auth/Step1';
};

export const isMaterialScreen = (
  route: { name: 'Content', params: ScreenParams['Content'], isScreen: (name: 'Content') => true } | any,
): route is { name: 'Content', params: ScreenParams['Content'], isScreen: (name: 'Content') => true } => {
  return route.name === 'Content';
};

export const isCollectionScreen = (
  route: { name: 'Playlist', params: ScreenParams['Playlist'], isScreen: (name: 'Playlist') => true } | any,
): route is { name: 'Playlist', params: ScreenParams['Playlist'], isScreen: (name: 'Playlist') => true } => {
  return route.name === 'Playlist';
};

export const isCommentsScreen = (
  route: { name: 'Comments', params: ScreenParams['Comments'], isScreen: (name: 'Comments') => true } | any,
): route is { name: 'Comments', params: ScreenParams['Comments'], isScreen: (name: 'Comments') => true } => {
  return route.name === 'Comments';
};

export const isNotFoundScreen = (
  route: { name: 'NotFound', params: ScreenParams['NotFound'], isScreen: (name: 'NotFound') => true } | any,
): route is { name: 'NotFound', params: ScreenParams['NotFound'], isScreen: (name: 'NotFound') => true } => {
  return route.name === 'NotFound';
};
