import type {
  ResultLegacy,
  CollectionType,
} from 'app/entities';

import request from 'services/api/request';

type CoverType = {
  data: string,
  extension: string,
  deleted: false
} | {
  data: null,
  extension: null,
  deleted: true
} | undefined

export default async (
  data: Partial<Omit<CollectionType, 'cover'>> & { cover?: CoverType | null },
): Promise<ResultLegacy<CollectionType>> => {
  const body: typeof data = {
    title: data.title,
    description: data.description,
    isPrivate: data.isPrivate,
    isSmartListEnabled: data.isSmartListEnabled,
    materialsMetadata: data.materialsMetadata,
  };
  if ('cover' in data) {
    body.cover = data.cover;
  }

  return request.post<CollectionType>('/user/playlists', { body });
};
