import type { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

const getIsOpen = (state: DefaultRootState) => state.plus.isOpen;
const getProps = (state: DefaultRootState) => state.plus.props;

const selector = createSelector(
  [getIsOpen, getProps],
  (isOpen, props) => ({
    isOpen,
    props,
  }),
);

export default selector;
