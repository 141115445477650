import request from 'services/api/request';
import type {
  RemarkType,
  PaginationResults,
  CursorType,
} from 'app/entities';

export default async (
  resourceIds: number | number[],
  pageSize?: number,
  cursor?: string,
): Promise<PaginationResults<RemarkType>> => {
  if (!resourceIds || (Array.isArray(resourceIds) && resourceIds.length === 0)) {
    return {
      data: {},
      error: null,
    };
  }
  const idsFinal = Array.isArray(resourceIds) ? resourceIds : [resourceIds];

  const results = await Promise.all(idsFinal.map((resourceId) => (
    request.get<CursorType<RemarkType>>(
      `/content/${resourceId}/remarks`,
      { query: { pageSize, cursor } },
    )
  )));

  const data: PaginationResults<RemarkType>['data'] = {};
  let error: Error | null = null;
  results.forEach((result, key) => {
    if (error) {
      return;
    }
    if (result.error) {
      error = result.error;
    }
    data[idsFinal[key]] = {
      items: result.data?.items || [],
      paginationInfo: {
        nextCursor: result.data?.nextUrl || null,
        hasNext: !!result.data?.nextUrl,
      },
    };
  });

  if (error) {
    return {
      data: {},
      error,
    };
  }

  return {
    data,
    error,
  };
};
