import hexRgb from 'hex-rgb';

export default (color = '#ffffff') => {
  const colorRGB = hexRgb(color);
  const red = colorRGB.red / 255;
  const green = colorRGB.green / 255;
  const blue = colorRGB.blue / 255;
  const { alpha } = colorRGB;

  return {
    'v': '5.5.5',
    'fr': 25,
    'ip': 0,
    'op': 79,
    'w': 300,
    'h': 150,
    'nm': 'Loading-2',
    'ddd': 0,
    'assets': [],
    'layers': [
      {
        'ddd': 0,
        'ind': 1,
        'ty': 4,
        'nm': 'icon 2',
        'sr': 1,
        'ks': {
          'o': {
            'a': 0,
            'k': 100,
            'ix': 11,
          },
          'r': {
            'a': 1,
            'k': [
              {
                'i': {
                  'x': [
                    0.667,
                  ],
                  'y': [
                    1,
                  ],
                },
                'o': {
                  'x': [
                    0.333,
                  ],
                  'y': [
                    0,
                  ],
                },
                't': 39,
                's': [
                  -90,
                ],
              },
              {
                't': 79,
                's': [
                  270,
                ],
              },
            ],
            'ix': 10,
          },
          'p': {
            'a': 0,
            'k': [
              150,
              75,
              0,
            ],
            'ix': 2,
          },
          'a': {
            'a': 0,
            'k': [
              53,
              53,
              0,
            ],
            'ix': 1,
          },
          's': {
            'a': 0,
            'k': [
              100,
              100,
              100,
            ],
            'ix': 6,
          },
        },
        'ao': 0,
        'shapes': [
          {
            'ty': 'gr',
            'it': [
              {
                'ind': 0,
                'ty': 'sh',
                'ix': 1,
                'ks': {
                  'a': 0,
                  'k': {
                    'i': [
                      [
                        0,
                        -15.464,
                      ],
                      [
                        15.464,
                        0,
                      ],
                      [
                        0,
                        15.464,
                      ],
                      [
                        -15.464,
                        0,
                      ],
                    ],
                    'o': [
                      [
                        0,
                        15.464,
                      ],
                      [
                        -15.464,
                        0,
                      ],
                      [
                        0,
                        -15.464,
                      ],
                      [
                        15.464,
                        0,
                      ],
                    ],
                    'v': [
                      [
                        28,
                        0,
                      ],
                      [
                        0,
                        28,
                      ],
                      [
                        -28,
                        0,
                      ],
                      [
                        0,
                        -28,
                      ],
                    ],
                    'c': true,
                  },
                  'ix': 2,
                },
                'nm': 'Path 1',
                'mn': 'ADBE Vector Shape - Group',
                'hd': false,
              },
              {
                'ty': 'st',
                'c': {
                  'a': 0,
                  'k': [
                    red,
                    green,
                    blue,
                    alpha,
                  ],
                  'ix': 3,
                },
                'o': {
                  'a': 0,
                  'k': 100,
                  'ix': 4,
                },
                'w': {
                  'a': 0,
                  'k': 10,
                  'ix': 5,
                },
                'lc': 2,
                'lj': 1,
                'ml': 10,
                'bm': 0,
                'nm': 'Stroke 1',
                'mn': 'ADBE Vector Graphic - Stroke',
                'hd': false,
              },
              {
                'ty': 'tr',
                'p': {
                  'a': 0,
                  'k': [
                    53,
                    53,
                  ],
                  'ix': 2,
                },
                'a': {
                  'a': 0,
                  'k': [
                    0,
                    0,
                  ],
                  'ix': 1,
                },
                's': {
                  'a': 0,
                  'k': [
                    100,
                    100,
                  ],
                  'ix': 3,
                },
                'r': {
                  'a': 0,
                  'k': 0,
                  'ix': 6,
                },
                'o': {
                  'a': 0,
                  'k': 100,
                  'ix': 7,
                },
                'sk': {
                  'a': 0,
                  'k': 0,
                  'ix': 4,
                },
                'sa': {
                  'a': 0,
                  'k': 0,
                  'ix': 5,
                },
                'nm': 'Transform',
              },
            ],
            'nm': 'Group 1',
            'np': 2,
            'cix': 2,
            'bm': 0,
            'ix': 1,
            'mn': 'ADBE Vector Group',
            'hd': false,
          },
          {
            'ty': 'tm',
            's': {
              'a': 1,
              'k': [
                {
                  'i': {
                    'x': [
                      0.667,
                    ],
                    'y': [
                      1,
                    ],
                  },
                  'o': {
                    'x': [
                      0.333,
                    ],
                    'y': [
                      0,
                    ],
                  },
                  't': 51,
                  's': [
                    0,
                  ],
                },
                {
                  't': 79,
                  's': [
                    100,
                  ],
                },
              ],
              'ix': 1,
            },
            'e': {
              'a': 1,
              'k': [
                {
                  'i': {
                    'x': [
                      0.667,
                    ],
                    'y': [
                      1,
                    ],
                  },
                  'o': {
                    'x': [
                      0.333,
                    ],
                    'y': [
                      0,
                    ],
                  },
                  't': 39,
                  's': [
                    0,
                  ],
                },
                {
                  't': 64,
                  's': [
                    100,
                  ],
                },
              ],
              'ix': 2,
            },
            'o': {
              'a': 0,
              'k': 0,
              'ix': 3,
            },
            'm': 1,
            'ix': 2,
            'nm': 'Trim Paths 1',
            'mn': 'ADBE Vector Filter - Trim',
            'hd': false,
          },
        ],
        'ip': 39,
        'op': 79,
        'st': 39,
        'bm': 0,
      },
      {
        'ddd': 0,
        'ind': 2,
        'ty': 4,
        'nm': 'icon',
        'sr': 1,
        'ks': {
          'o': {
            'a': 0,
            'k': 100,
            'ix': 11,
          },
          'r': {
            'a': 1,
            'k': [
              {
                'i': {
                  'x': [
                    0.667,
                  ],
                  'y': [
                    1,
                  ],
                },
                'o': {
                  'x': [
                    0.333,
                  ],
                  'y': [
                    0,
                  ],
                },
                't': 0,
                's': [
                  -90,
                ],
              },
              {
                't': 40,
                's': [
                  270,
                ],
              },
            ],
            'ix': 10,
          },
          'p': {
            'a': 0,
            'k': [
              150,
              75,
              0,
            ],
            'ix': 2,
          },
          'a': {
            'a': 0,
            'k': [
              53,
              53,
              0,
            ],
            'ix': 1,
          },
          's': {
            'a': 0,
            'k': [
              100,
              100,
              100,
            ],
            'ix': 6,
          },
        },
        'ao': 0,
        'shapes': [
          {
            'ty': 'gr',
            'it': [
              {
                'ind': 0,
                'ty': 'sh',
                'ix': 1,
                'ks': {
                  'a': 0,
                  'k': {
                    'i': [
                      [
                        0,
                        -15.464,
                      ],
                      [
                        15.464,
                        0,
                      ],
                      [
                        0,
                        15.464,
                      ],
                      [
                        -15.464,
                        0,
                      ],
                    ],
                    'o': [
                      [
                        0,
                        15.464,
                      ],
                      [
                        -15.464,
                        0,
                      ],
                      [
                        0,
                        -15.464,
                      ],
                      [
                        15.464,
                        0,
                      ],
                    ],
                    'v': [
                      [
                        28,
                        0,
                      ],
                      [
                        0,
                        28,
                      ],
                      [
                        -28,
                        0,
                      ],
                      [
                        0,
                        -28,
                      ],
                    ],
                    'c': true,
                  },
                  'ix': 2,
                },
                'nm': 'Path 1',
                'mn': 'ADBE Vector Shape - Group',
                'hd': false,
              },
              {
                'ty': 'st',
                'c': {
                  'a': 0,
                  'k': [
                    red,
                    green,
                    blue,
                    alpha,
                  ],
                  'ix': 3,
                },
                'o': {
                  'a': 0,
                  'k': 100,
                  'ix': 4,
                },
                'w': {
                  'a': 0,
                  'k': 10,
                  'ix': 5,
                },
                'lc': 2,
                'lj': 1,
                'ml': 10,
                'bm': 0,
                'nm': 'Stroke 1',
                'mn': 'ADBE Vector Graphic - Stroke',
                'hd': false,
              },
              {
                'ty': 'tr',
                'p': {
                  'a': 0,
                  'k': [
                    53,
                    53,
                  ],
                  'ix': 2,
                },
                'a': {
                  'a': 0,
                  'k': [
                    0,
                    0,
                  ],
                  'ix': 1,
                },
                's': {
                  'a': 0,
                  'k': [
                    100,
                    100,
                  ],
                  'ix': 3,
                },
                'r': {
                  'a': 0,
                  'k': 0,
                  'ix': 6,
                },
                'o': {
                  'a': 0,
                  'k': 100,
                  'ix': 7,
                },
                'sk': {
                  'a': 0,
                  'k': 0,
                  'ix': 4,
                },
                'sa': {
                  'a': 0,
                  'k': 0,
                  'ix': 5,
                },
                'nm': 'Transform',
              },
            ],
            'nm': 'Group 1',
            'np': 2,
            'cix': 2,
            'bm': 0,
            'ix': 1,
            'mn': 'ADBE Vector Group',
            'hd': false,
          },
          {
            'ty': 'tm',
            's': {
              'a': 1,
              'k': [
                {
                  'i': {
                    'x': [
                      0.667,
                    ],
                    'y': [
                      1,
                    ],
                  },
                  'o': {
                    'x': [
                      0.333,
                    ],
                    'y': [
                      0,
                    ],
                  },
                  't': 12,
                  's': [
                    0,
                  ],
                },
                {
                  't': 40,
                  's': [
                    100,
                  ],
                },
              ],
              'ix': 1,
            },
            'e': {
              'a': 1,
              'k': [
                {
                  'i': {
                    'x': [
                      0.667,
                    ],
                    'y': [
                      1,
                    ],
                  },
                  'o': {
                    'x': [
                      0.333,
                    ],
                    'y': [
                      0,
                    ],
                  },
                  't': 0,
                  's': [
                    0,
                  ],
                },
                {
                  't': 25,
                  's': [
                    100,
                  ],
                },
              ],
              'ix': 2,
            },
            'o': {
              'a': 0,
              'k': 0,
              'ix': 3,
            },
            'm': 1,
            'ix': 2,
            'nm': 'Trim Paths 1',
            'mn': 'ADBE Vector Filter - Trim',
            'hd': false,
          },
        ],
        'ip': 0,
        'op': 40,
        'st': 0,
        'bm': 0,
      },
    ],
    'markers': [],
  };
};
