import type { ResultLegacy, MaterialType, SourceMaterialType } from 'app/entities';
import request from 'services/api/request';
import { prepare } from 'utils';
import comment from 'services/api/comment/route';
import interaction from 'services/api/interaction/route';

export default async (content: SourceMaterialType): Promise<ResultLegacy<MaterialType>> => {
  const body: Record<string, any> = {
    description: content?.description,
    duration: content?.duration,
    image: content?.image,
    authorUrls: content?.authorUrls,
    originalUrl: content?.originalUrl,
    sectionId: content?.sectionId,
    sourceId: content?.sourceId,
    private: content?.private,
    tags: content?.tags,
    title: content?.title,
    type: content?.type ? (content?.type || '').toLocaleUpperCase() : undefined,
    level: content?.level ? (content?.level || '').toLocaleUpperCase() : undefined,
    publishedAt: content?.publishedAt,
  };

  const { data, error } = await request.post<any>('/content', { body });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  const [commentsMap, interactions] = await Promise.all([
    comment.meta('content', data.id),
    interaction.items('content', data.id),
  ]);

  if (commentsMap.error || !commentsMap.data || interactions.error || !interactions.data) {
    return {
      data: null,
      error: commentsMap.error || interactions.error,
    };
  }

  const result = {
    ...data,
    ...interactions.data[data?.id],
    commentMeta: commentsMap?.data?.[data?.id],
  };

  return {
    data: prepare.material(result),
    error,
  };
};
