import { memo, type MouseEvent } from 'react';

import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/joy';

import * as collectionStore from 'store/nodes/playlist';

import CollectionSingleCover from 'components/CollectionSingleCover';

type ItemProps = {
  id: number,
  onPress: (event: MouseEvent<HTMLButtonElement>) => void,
}

const CollectionBadge = (props: ItemProps) => {
  const { id, onPress } = props;

  const collection = useSelector(collectionStore.selectors.dataById(id));

  if (!collection) {
    return null;
  }

  return (
    <Button
      color="neutral"
      variant="soft"
      data-id={id}
      onClick={onPress}
      sx={{
        gap: 0.5, p: 0.5, minHeight: 0, borderRadius: 'var(--joy-radius-sm)',
      }}
    >
      <CollectionSingleCover data={collection} iconSize={24} widthPicture={24} heightPicture={24} />
      <Typography fontWeight={400}>{collection.title}</Typography>
    </Button>
  );
};

export default memo(CollectionBadge);
