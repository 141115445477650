const map = {
  collection: 'PLAYLIST',
  material: 'CONTENT',
  note: 'NOTE',
  'new-note': 'NOTE',
};

const getOldResourceType = (type: keyof typeof map) => map[type];

export default getOldResourceType;
