import {
  type SagaReturnType,
  debounce, put, cancel,
} from 'redux-saga/effects';

import * as actions from '../actions';

export const config = {
  action: [actions.setFilterText.type],
  method: debounce.bind(null, 500),
};

export function* func(action: SagaReturnType<typeof actions.setFilterText>) {
  const { payload } = action;
  if (!payload.triggerReload) {
    yield cancel(); return;
  }

  yield put(actions.loadNextPage(true));
}
