import {
  memo, Fragment, useCallback, useEffect, type MouseEvent,
} from 'react';
import {
  Box, type BoxProps, Button, type ButtonProps, useTheme,
} from '@mui/joy';

import { type StyleProp, type ViewStyle } from 'react-native';
import { isEqual } from 'lodash';

import type { FontSizeVariant } from 'font';

import ItemTab from './elements/ItemTab';

type DataObject = { label: string, value: string };

const isDataString = (data: string | any): data is string => {
  return !!data && typeof data === 'string';
};

const defaultProps = {
  type: 'switch' as 'switch' | 'check',
  variant: 'tab' as 'button' | 'tab',
  textSize: 15,
  items: [] as (string | DataObject | Record<string, any>)[],
  itemLabel: 'label',
  itemValue: 'value',
  value: '' as string | string[],
  itemSizeByContent: false,
  changeDelay: 0,
};

type ButtonSwitcherProps = {
  style?: StyleProp<ViewStyle>,
  tabStyle?: StyleProp<ViewStyle>,
  type?: 'switch' | 'check',
  variant?: 'button' | 'tab',
  textSize?: FontSizeVariant,
  items?: (string | DataObject | Record<string, any>)[] | undefined,
  itemLabel?: string,
  itemValue?: string,
  value?: string | string[],
  itemSizeByContent?: boolean,
  flowItems?: boolean,
  changeDelay?: number,
  onChange?: (value: string) => void,
  size?: ButtonProps['size'],
} & typeof defaultProps;

const ButtonSwitcher = (props: ButtonSwitcherProps & Omit<BoxProps, 'onChange'>) => {
  const {
    tabStyle,
    type,
    variant,
    items,
    itemLabel,
    itemValue,
    value,
    flowItems,
    changeDelay,
    onChange,
    itemSizeByContent,
    size,
    ...rest
  } = props;

  const theme = useTheme();

  useEffect(() => {
    if (type === 'check' && !!value) {
      return;
    }
    if (isDataString(items[0]) && !value) {
      onChange?.(items[0]);
      return;
    }
    if (!isDataString(items[0]) && !value) {
      onChange?.((items[0] as any)?.[itemValue]);
    }
  }, [onChange, type, items, value]);

  const handleChange = useCallback((event: MouseEvent<HTMLButtonElement> | any, context?: { id: string } | any) => {
    const id = context?.id || event.currentTarget.getAttribute('data-id');
    if (!id) {
      return;
    }
    if (changeDelay) {
      setTimeout(() => {
        onChange?.(id);
      }, changeDelay);
    } else {
      onChange?.(id);
    }
  }, [onChange, changeDelay]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: variant === 'button' ? 1 : undefined,
        height: variant === 'tab' ? 28 : undefined,
        flexWrap: flowItems ? 'wrap' : undefined,
      }}
      {...rest}
    >
      {items.map((item: any, key) => {
        let id = '';
        let text = '';
        if (isDataString(item)) {
          id = item;
          text = item;
        }
        if (!isDataString(item)) {
          id = item?.[itemValue];
          text = item?.[itemLabel];
        }
        const isFirst = key === 0;
        const isLast = key === items.length - 1;
        const isSelected = typeof value === 'string'
          ? id.toLowerCase() === (value || '').toLowerCase()
          : value.includes(id.toLowerCase());

        if (variant === 'tab') {
          return (
            <ItemTab
              key={id}
              id={id}
              text={text}
              sizeByContent={itemSizeByContent}
              isFirst={isFirst}
              isLast={isLast}
              isSelected={isSelected}
              onPress={handleChange}
              style={tabStyle}
            />
          );
        }

        if (variant === 'button') {
          return (
            <Button
              key={id}
              data-id={id}
              variant={isSelected ? 'solid' : 'soft'}
              size={size}
              sx={{
                fontWeight: 400,
                color: isSelected ? theme.palette.neutral.softBg : theme.palette.text.primary,
                backgroundColor: isSelected ? theme.palette.text.primary : theme.palette.neutral.softBg,
                '&:hover': {
                  backgroundColor: isSelected ? theme.palette.text.primary : theme.palette.neutral.softHoverBg,
                },
              }}
              onClick={handleChange}
            >
              {text}
            </Button>
          );
        }
        return <Fragment key={id} />;
      })}
    </Box>
  );
};

ButtonSwitcher.defaultProps = defaultProps;

export default memo(ButtonSwitcher, (prevProps, nextProps) => {
  const {
    onChange: prevOnChange,
    ...prevPropsObjects
  } = prevProps;
  const {
    onChange: nextOnChange,
    ...nextPropsObjects
  } = nextProps;
  return prevOnChange === nextOnChange && isEqual(prevPropsObjects, nextPropsObjects);
});
