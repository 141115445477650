import { createAction } from '@reduxjs/toolkit';

import type {
  PaginationType,
  ActivityType,
} from 'app/entities';

export const clear = createAction('ACTIVITY/CLEAR');

export const loadList = createAction('ACTIVITY/LOAD_LIST', (user: 'my' | 'promo' | string = 'my') => ({
  payload: {
    user,
  },
}));

export const loadNextPage = createAction('ACTIVITY/LOAD_PAGE');

export const loadNextPageDone = createAction('ACTIVITY/LOAD_PAGE_DONE', (
  reload = false,
  hasError = false,
) => ({
  payload: {
    reload,
    hasError,
  },
}));

export const addPage = createAction('ACTIVITY/SET_PAGE', (
  items: ActivityType[],
  paginationInfo: PaginationType,
  reload = false,
) => ({
  payload: {
    items,
    paginationInfo,
  },
  meta: {
    reload,
  },
}));

export const setItem = createAction('ACTIVITY/SET_ITEM', (
  data: ActivityType | ActivityType[],
) => ({
  payload: {
    data,
  },
}));
