import {
  memo, useCallback, useMemo, useRef, useState,
} from 'react';
import { Box } from '@mui/joy';
import { useRoute } from 'navigation/hooks';

import { useResizeDetect } from 'hooks';
import ScrollBox from 'ui/ScrollBox';

import { useSelector } from 'store/utils/redux/hooks';
import type { ResourceType, Resource } from 'store/nodes/copilot/types';
import * as copilotStore from 'store/nodes/copilot';

import useMethods from '../model/useMethods';
import scrollToEnd from '../model/scrollToEnd';

import CopilotInputConnector from '../elements/CopilotInputConnector';
import HotCommands from '../elements/HotCommands';
import ChatItems from '../elements/ChatItems';
import Summary from '../elements/Summary';
import RelatedMaterials from '../elements/RelatedMaterials';

type BodyProps = {
  resourceType: ResourceType,
  resourceId?: number | null,
  includeItemsForResource?: Resource[],
  windowScroll?: boolean,
}

const Body = (props: BodyProps) => {
  const {
    resourceType,
    resourceId,
    includeItemsForResource,
    windowScroll,
  } = props;

  const route = useRoute();
  const bodyRef = useRef<HTMLDivElement>(null);

  const resources = useMemo(() => [{ type: resourceType, id: resourceId || null }, ...(includeItemsForResource || [])], [resourceType, resourceId, includeItemsForResource]);
  const isAnswerTyping = useSelector(copilotStore.selectors.isAnswerTyping(resources));

  const {
    sendQuestion,
    sendCommand,
    stop,
    answerReload,
    answerSaveToNote,
  } = useMethods(resourceType, resourceId);

  const [inputWidth, setInputWidth] = useState<number | null>(null);

  const scrollBodyToEnd = useCallback(() => {
    setTimeout(() => {
      scrollToEnd(bodyRef.current, { windowScroll });
    }, 10);
  }, [windowScroll]);

  const ResizeDetector = useResizeDetect(useCallback((target: HTMLDivElement) => {
    if (!target) {
      return;
    }
    const { width } = target.getBoundingClientRect();
    setInputWidth(width);
  }, []));

  return (
    <ScrollBox ref={bodyRef}>
      <Box
        position="sticky"
        top={0}
        left={2}
        right={0}
        height={4}
        ml={1}
        zIndex={2}
        bgcolor={(theme) => theme.palette.background.body}
      />
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        borderRadius={0}
        bgcolor="transparent"
        gap="2em"
        sx={{
          overflowX: 'hidden',
          mt: 2,
          mr: 1,
          mb: 12,
          ml: 3,
          '@media (max-width: 767px)': {
            ml: 2,
            mb: route.isScreen('Ask') || route.isScreen('Library') ? '11rem' : undefined,
          },
        }}
      >
        {ResizeDetector}
        <Summary
          resourceType={resourceType}
          resourceId={resourceId}
        />
        <RelatedMaterials
          resourceType={resourceType}
          resourceId={resourceId}
        />
        <ChatItems
          resources={resources}
          onMount={scrollBodyToEnd}
          onUnauthorizedMount={scrollBodyToEnd}
          onQuestionMount={scrollBodyToEnd}
          onAnswerMount={scrollBodyToEnd}
          onAnswerReload={answerReload}
          onAnswerSave={answerSaveToNote}
          onAnswerAppend={scrollBodyToEnd}
          onAnswerDone={scrollBodyToEnd}
        />
        <HotCommands
          onSendCommand={sendCommand}
          isAnswerTyping={isAnswerTyping}
        />
        <Box
          position="fixed"
          bottom="1.5em"
          display="flex"
          flexDirection="row"
          zIndex={1}
          sx={{
            width: inputWidth,
            '@media (max-width: 767px)': {
              bottom: route.isScreen('Ask') || route.isScreen('Library') ? '6rem' : '1rem',
            },
          }}
        >
          <CopilotInputConnector
            isAnswerTyping={isAnswerTyping}
            onSubmit={sendQuestion}
            onStop={stop}
          />
        </Box>
      </Box>
    </ScrollBox>
  );
};

export default memo(Body);
