import { useCallback, useMemo } from 'react';
import { useStorageString } from 'lib/storage2';

export default (key: string, defaultValue = 'grid') => {
  const [value = defaultValue, setValue] = useStorageString<'grid' | 'row'>(key);

  const handleListToGrid = useCallback(() => {
    setValue('grid');
  }, []);

  const handleListToRow = useCallback(() => {
    setValue('row');
  }, []);

  const handleToggle = useCallback(() => {
    if (value === 'grid') {
      setValue('row');
    }
    if (value === 'row') {
      setValue('grid');
    }
  }, [value]);

  return useMemo(() => ({
    listLayout: {
      value,
      isGrid: value === 'grid',
      isRow: value === 'row',
    },
    setListLayout: setValue,
    toggleListLayout: handleToggle,
    listToGrid: handleListToGrid,
    listToRow: handleListToRow,
  }), [value, setValue, handleToggle]);
};
