import request from 'services/api/request';
import type { ResourceVariant, ResultLegacy, CommentSummaryType } from 'app/entities';

export default async (
  resource: ResourceVariant,
  resourceId: number,
): Promise<ResultLegacy<CommentSummaryType>> => {
  if (!resourceId) {
    return {
      data: null,
      error: new Error('Resource not provided'),
    };
  }

  const { data, error } = await request.get<CommentSummaryType>(`/${resource}/${resourceId}/comments/summary-rate`);
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
