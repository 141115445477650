import request from 'services/api/request';
import type { ResourceVariant, ResultLegacy } from 'app/entities';

type RemoveResult = {
  userId: 1,
  resourceId: number,
  resourceType: string,
  isViewed: boolean,
  isLiked: boolean,
  isDisliked: boolean,
  isBookmarked: boolean,
  isCompleted: boolean,
  isShown: boolean,
};

export default async (
  resource: ResourceVariant,
  resourceId: number,
  type: 'view' | 'like' | 'dislike' | 'bookmark',
): Promise<ResultLegacy<RemoveResult>> => {
  const { data, error } = await request.delete<RemoveResult>(`/user/interaction/${type}/${resource}/${resourceId}`);
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
