import { useCallback, useEffect, useRef } from 'react';
import { Platform } from 'react-native';

const useCheckMouseInsideRect = (listening = true) => {
  const positionRef = useRef<{ x: number, y: number } | null>(null);

  const handleMouseMove = useCallback((event: MouseEvent) => {
    positionRef.current = {
      x: event.clientX,
      y: event.clientY,
    };
  }, []);

  useEffect(() => {
    if (!listening || Platform.OS !== 'web') {
      return undefined;
    }
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [listening, handleMouseMove]);

  return useCallback((rect: DOMRect) => {
    const { current } = positionRef;
    if (current === null) {
      return false;
    }
    const { x, y } = current;
    return y > rect.top && y < rect.top + rect.height && x > rect.left && x < rect.left + rect.width;
  }, []);
};

export default useCheckMouseInsideRect;
