import {
  memo, type PropsWithChildren, useCallback, useEffect,
} from 'react';
import Tooltip from '@mui/joy/Tooltip';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import * as valueStore from 'store/nodes/value';
import { IconButton } from '@mui/joy';
import Icon from 'ui/Icon';
import Typography from '@mui/joy/Typography';

type TutorialStepProps = {
  stepIndex: number,
  title: string,
  description: string,
  isLast?: boolean,
  placement?: 'bottom' | 'left' | 'right' | 'top'
}

const TutorialStep = (props: PropsWithChildren<TutorialStepProps>) => {
  const {
    isLast,
    stepIndex,
    title,
    description,
    placement,
    children,
  } = props;

  const dispatcher = useDispatcher();
  const isAppPrepared = useSelector((state) => state.app.prepared);
  const currentStepIndex = useSelector(valueStore.selectors.get<number | 'complete'>('tutorialCurrentStep'));
  const lastStepIndex = useSelector(valueStore.selectors.get<number | 'complete'>('tutorialLastIndex'));

  useEffect(() => {
    const isComplete = window?.localStorage?.getItem('tutorialStatus') === 'complete';
    if (isComplete) {
      return;
    }
    if (stepIndex === 1) {
      dispatcher.value.set('tutorialCurrentStep', 1);
    }
    if (isLast) {
      dispatcher.value.set('tutorialLastIndex', stepIndex);
    }
  }, []);

  const handleNext = useCallback(() => {
    if (!currentStepIndex || currentStepIndex === 'complete') {
      return;
    }
    dispatcher.value.set('tutorialCurrentStep', currentStepIndex + 1);
  }, [currentStepIndex]);

  const handleClose = useCallback(() => {
    dispatcher.value.set('tutorialCurrentStep', 'complete');
    window?.localStorage?.setItem('tutorialStatus', 'complete');
  }, []);

  if (!isAppPrepared) {
    return null;
  }

  return (
    <Tooltip
      open={stepIndex === currentStepIndex}
      arrow
      sx={{ maxWidth: 272 }}
      placement={placement}
      variant="soft"
      title={(
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" pl={1.5}>
            <Typography fontSize={12} fontWeight={600}>{`Step ${stepIndex} of ${lastStepIndex}`}</Typography>
            <IconButton size="sm" onClick={handleClose}><Icon name="xmark" /></IconButton>
          </Box>
          <Typography position="relative" px={1.5} fontWeight={600}>
            {title}
          </Typography>
          <Typography position="relative" px={1.5} fontSize={13}>
            {description}
          </Typography>
          <Box mb={1} mt={1} px={0.5} display="flex" justifyContent="flex-end" gap={1}>
            {!isLast && (
              <Button variant="solid" size="sm" onClick={handleNext}>Next</Button>
            )}
            {isLast && (
              <Button variant="solid" size="sm" onClick={handleClose}>Finish</Button>
            )}
          </Box>
        </>
      )}
    >
      <Box>
        {children}
      </Box>
    </Tooltip>
  );
};

export default memo(TutorialStep);
