import {
  type PropsWithChildren,
  memo,
} from 'react';
import {
  type LinearGradientProps,
  LinearGradient,
} from 'expo-linear-gradient';

import { useThemeGradient } from 'hooks';

type GradientViewProps = Omit<LinearGradientProps, 'colors'> & {
  lightColors?: string[];
  darkColors?: string[];
}

const GradientView = (props: PropsWithChildren<GradientViewProps>) => {
  const {
    style,
    lightColors,
    darkColors,
    ...otherProps
  } = props;

  const color = useThemeGradient({ light: lightColors, dark: darkColors });

  return (
    <LinearGradient
      colors={color as ['#000000', '#ffffff']}
      style={style}
      {...otherProps}
    />
  );
};

export default memo(GradientView);
