import { type ReactElement, useEffect, useRef } from 'react';
import LottieView from 'lottie-react-native';

import { unit } from 'utils';

const defaultProps = {
  size: 100,
  loop: true,
};

type SpinnerDottedProps = {
  size?: number,
  loop?: boolean,
} & typeof defaultProps;

const SpinnerDotted = (props: SpinnerDottedProps): ReactElement => {
  const {
    size,
    loop,
  } = props;

  const lottie = useRef<any>(null);

  useEffect(() => {
    if (lottie.current && 'play' in lottie.current) {
      lottie.current.play?.();
    }
  }, [lottie]);

  return (
    <LottieView
      ref={lottie}
      style={{
        width: unit(size),
      }}
      source={require('../../assets/lottie/63274-loading-animation_iki.json')}
      loop={loop}
    />
  );
};

SpinnerDotted.defaultProps = defaultProps;

export default SpinnerDotted;
