import moment from 'moment-timezone';

import type {
  CommentType,
  CommentMessageType,
  ResourceVariant,
  ResultLegacy,
} from 'app/entities';
import request from 'services/api/request';

export default async (resource: ResourceVariant | string, resourceId: number, message: CommentMessageType): Promise<ResultLegacy<CommentType>> => {
  const body: Record<string, any> = {
    text: message.text,
  };
  if (message.rate) {
    body.rate = message.rate;
  }

  const url = !message.replyToId
    ? `/${resource}/${resourceId}/comments`
    : `/${resource}/${resourceId}/comments/${message.replyToId}`;

  const { data, error } = await request.post<CommentType>(url, { body });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: {
      ...data,
      createdAt: data.createdAt ? moment.utc(data.createdAt).toISOString() : null,
      updatedAt: data.updatedAt ? moment.utc(data.updatedAt).toISOString() : null,
      deletedAt: data.deletedAt ? moment.utc(data.deletedAt).toISOString() : null,
    },
    error: null,
  };
};
