import { extendTheme } from '@mui/joy/styles';

declare module '@mui/joy/styles' {
  interface ThemeScales {
    letterSpacing: {
      sm: string;
      md: string;
      lg: string;
    };
  }
}

export const MUiThemeExtend = extendTheme({
  fontFamily: {
    display: '-apple-system, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif',
    body: '-apple-system, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif',
  },
  components: {
    JoyIconButton: {
      styleOverrides: {
        root: {
          '--IconButton-radius': '50%',
          transition: 'background-color 200ms ease',
          '&:active': {
            transition: 'unset',
          },
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: {
          '--Button-radius': '1.375em',
          transition: 'background-color 200ms ease, border-color 200ms ease',
          '&:active': {
            transition: 'unset',
          },
        },
      },
    },
    JoyChip: {
      styleOverrides: {
        action: {
          transition: 'background-color 200ms ease',
          '&:active': {
            transition: 'unset',
          },
        },
      },
    },
    JoyRadio: {
      styleOverrides: {
        icon: {
          width: 'calc(var(--Radio-size) / 1.666)',
          height: 'calc(var(--Radio-size) / 1.666)',
        },
        radio: {
          '--variant-borderWidth': '2px',
          '&.Mui-checked': {
            borderColor: 'var(--joy-palette-text-icon)',
          },
        },
        label: {
          fontSize: 'var(--search-methods-label-size)',
        },
      },
    },
    JoyCheckbox: {
      styleOverrides: {
        checkbox: {
          '--variant-borderWidth': '2px',
        },
        label: {
          fontSize: 'var(--search-methods-label-size)',
        },
      },
    },
  },
  typography: {
    'body-lg': {
      color: 'var(--joy-palette-text-primary, var(--joy-palette-neutral-800, #171A1C))',
    },
    'body-md': {
      color: 'var(--joy-palette-text-primary, var(--joy-palette-neutral-800, #171A1C))',
    },
    'body-sm': {
      color: 'var(--joy-palette-text-primary, var(--joy-palette-neutral-800, #171A1C))',
    },
    'body-xs': {
      color: 'var(--joy-palette-text-primary, var(--joy-palette-neutral-800, #171A1C))',
    },
  },
  colorSchemes: {
    dark: {
      shadowRing: '0 0 #000',
      shadowChannel: '18 18 18',
      shadowOpacity: '0.6',
      palette: {
        primary: {
          50: '#eff3ff',
          100: '#e0eafd',
          200: '#c4cff6',
          300: '#89aae8',
          400: '#4686ff',
          500: '#2468e7',
          600: '#19449f',
          700: '#14357a',
          800: '#0a1a3b',
          900: '#050c1e',
        },
        neutral: {
          50: '#fbfbfb',
          100: '#f1f1f1',
          200: '#dddddd',
          300: '#cdcdcd',
          400: '#9f9f9f',
          500: '#636363',
          600: '#555555',
          700: '#383838',
          800: '#282828',
          900: '#1A1A1A',
        },
        background: {
          body: '#1A1A1A',
        },
      },
    },
    light: {
      palette: {
        primary: {
          50: '#eff3ff',
          100: '#e0eafd',
          200: '#c4cff6',
          300: '#89aae8',
          400: '#4686ff',
          500: '#2468e7',
          600: '#19449f',
          700: '#14357a',
          800: '#0a1a3b',
          900: '#050c1e',
        },
        neutral: {
          50: '#fbfbfa',
          100: '#f1f1f0',
          200: '#dddddb',
          300: '#cdcdcc',
          400: '#9f9f9e',
          500: '#636362',
          600: '#555554',
          700: '#323232',
          800: '#181817',
          900: '#0b0b0b',
        },
        background: {
          body: '#ffffff',
        },
      },
    },
  },
});
