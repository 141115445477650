import {
  debounce,
  type SagaReturnType, delay, cancel, put,
} from 'redux-saga/effects';

import type {
  CollectionType,
  MaterialMetadataItemType,
} from 'app/entities';
import { prepare, guard } from 'utils';

import { call, select } from 'store/utils/saga/effects';
import * as selectors from 'store/nodes/playlist/selectors';

import Alert from 'components/Alert';

import network from 'lib/network';
import { actions } from '../slice';

export const config = {
  action: [
    actions.updateInBackground.type,
    actions.toggleSmartList.type,
    actions.sortItems.type,
  ],
  method: debounce.bind(null, 200),
};

function* optimisticUpdateLocal(id: number, data?: SagaReturnType<typeof actions.updateInBackground>['payload']['data']) {
  const collection = yield* select(selectors.dataById(id));
  if (!collection || !data) {
    return {};
  }

  let { cover } = collection;
  if (guard.isImage(data.cover) || data.cover === null) {
    cover = data.cover;
  }

  yield put(actions.setItem({
    data: {
      id,
      title: typeof data?.title !== 'undefined' ? data.title : collection.title,
      description: typeof data?.description !== 'undefined' ? data.description : collection.description,
      cover,
      materialsMetadata: typeof data?.materialsMetadata !== 'undefined' ? data.materialsMetadata : collection.materialsMetadata,
      isPrivate: typeof data?.isPrivate !== 'undefined' ? data.isPrivate : collection.isPrivate,
    },
  }));

  return function* () {
    yield put(actions.setItem({
      data: {
        id,
        title: collection.title,
        description: collection.description,
        cover: collection.cover,
        materialsMetadata: collection.materialsMetadata,
        isPrivate: collection.isPrivate,
      },
    }));
  };
}

export function* func(
  action: SagaReturnType<
    | typeof actions.updateInBackground
    | typeof actions.toggleSmartList
    | typeof actions.sortItems
  >,
) {
  let collectionId: number | null = null;

  if (action.type === actions.updateInBackground.type) {
    collectionId = action.payload.id;
  }
  if (action.type === actions.toggleSmartList.type) {
    collectionId = action.payload.id;
  }
  if (action.type === actions.sortItems.type) {
    collectionId = action.payload.id;
  }

  let rollback;
  if (action.type === actions.updateInBackground.type && action.payload.data) {
    rollback = yield* optimisticUpdateLocal(action.payload.id, action.payload.data);
  }

  if (!collectionId) {
    yield put(actions.updateInBackgroundDone({ success: false }));
    yield cancel(); return;
  }

  yield delay(10);
  const collectionData = yield* select(selectors.dataById(collectionId));
  if (!collectionData) {
    yield put(actions.updateInBackgroundDone({ success: false }));
    yield cancel(); return;
  }

  const preparedCover = prepare.newImage(collectionData?.cover);

  // const updateResponse = yield* call(() => (
  //   request.post<CollectionType>(`/user/playlists/${collectionId}`, {
  //     body: {
  //       title: data.title,
  //       description: data.description,
  //       cover: preparedCover,
  //       materialsMetadata: data.materialsMetadata,
  //       isSmartListEnabled: data.isSmartListEnabled,
  //       isPrivate: data.isPrivate,
  //     },
  //   })
  // ));
  const elementType = {
    material: 'CONTENT',
    note: 'NOTE',
  } as const;

  const { errors, data } = yield* call(() => (
    network.request<CollectionType>(`share/user/playlists/${collectionId}`).body({
      title: collectionData.title,
      description: collectionData.description,
      cover: preparedCover,
      materialsMetadata: collectionData.materialsMetadata?.map((element) => ({
        ...element,
        type: elementType[element.type as keyof typeof elementType],
      })) || [],
      isSmartListEnabled: collectionData.isSmartListEnabled,
      isPrivate: collectionData.isPrivate,
    }).post()
  ));

  if ((errors || !data) && typeof rollback === 'function') {
    Alert.error(errors ? errors[0]?.message : 'Server error #34');
    yield rollback();
  }

  yield delay(1000);

  const collectionNewData = yield* call(() => (
    network.request<CollectionType>(`share/user/playlists/${collectionId}`).get()
  ));

  // const collectionNewData = yield* call(() => (
  //   request.get<CollectionType>(`/user/playlists/${collectionId}`)
  // ));

  const newElementType = {
    CONTENT: 'material',
    NOTE: 'note',
  };

  if (collectionNewData.data) {
    const [prevState, nextState] = [collectionData.materialsMetadata, collectionNewData.data.materialsMetadata];
    const prevStateMap = new Map(prevState.map((item) => [`${item.id}-${item.type}`, item]));
    const nextStateMap = new Map(nextState.map((item) => [`${item.id}-${newElementType[item.type as keyof typeof newElementType]}`, item]));

    const addedElements = nextState.reduce((acc, item, index) => {
      const itemKey = `${item.id}-${newElementType[item.type as keyof typeof newElementType]}`;
      if (!prevStateMap.has(itemKey)) {
        const previousItem = nextState[index - 1] || null;
        acc.push({
          item,
          after: previousItem,
        });
      }
      return acc;
    }, [] as { item: MaterialMetadataItemType, after: MaterialMetadataItemType }[]);

    const removedElements = prevState.filter((item) => !nextStateMap.has(`${item.id}-${item.type}`));

    if (addedElements.length > 0 || removedElements.length > 0) {
      yield put(actions.updateInBackgroundDone({
        success: true, id: collectionId, data: collectionNewData.data, updates: { addedElements, removedElements },
      }));
    } else {
      yield put(actions.updateInBackgroundDone({ success: true, id: collectionId, data: collectionNewData.data }));
    }
  }
}
