import { createReducer } from '@reduxjs/toolkit';

import type { MyContentStore } from './types';
import * as actions from './actions';

const initialState: MyContentStore = {
  createPlaylist: {
    selectedIds: [],
  },
  addMaterial: {
    lastCreatedId: null,
    data: null,
  },
  meta: {
    isAddMaterialParsing: false,
    isAdding: false,
    isPlaylistCreating: false,
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.addMaterial, (state, action) => {
    state.meta.isAdding = true;
  })
  .addCase(actions.addMaterialDone, (state, action) => {
    state.meta.isAdding = false;
  })
  .addCase(actions.addMaterialSetData, (state, action) => {
    if (!state.addMaterial.data) {
      state.addMaterial.data = {};
    }
    state.addMaterial.data = {
      ...state.addMaterial.data,
      ...action.payload.data,
    };
  })
  .addCase(actions.addMaterialClearData, (state, action) => {
    state.addMaterial.data = null;
  })
  .addCase(actions.addMaterialSetLastCreatedId, (state, action) => {
    state.addMaterial.lastCreatedId = action.payload.id;
  })
  .addCase(actions.addPlaylist, (state, action) => {
    state.meta.isPlaylistCreating = true;
  })
  .addCase(actions.addPlaylistDone, (state, action) => {
    state.meta.isPlaylistCreating = false;
  })
  .addCase(actions.createPlaylistAddSelectedId, (state, action) => {
    const ids = Array.isArray(action.payload.ids) ? action.payload.ids : [action.payload.ids];
    ids.forEach((id) => {
      const index = state.createPlaylist.selectedIds.indexOf(id);
      if (index > -1) {
        return;
      }
      state.createPlaylist.selectedIds.push(id);
    });
  })
  .addCase(actions.createPlaylistToggleSelectedId, (state, action) => {
    const ids = Array.isArray(action.payload.ids) ? action.payload.ids : [action.payload.ids];
    ids.forEach((id) => {
      const index = state.createPlaylist.selectedIds.indexOf(id);
      if (index > -1) {
        state.createPlaylist.selectedIds.splice(index, 1);
        return;
      }
      state.createPlaylist.selectedIds.push(id);
    });
  })
  .addCase(actions.createPlaylistRemoveSelectedId, (state, action) => {
    const ids = Array.isArray(action.payload.ids) ? action.payload.ids : [action.payload.ids];
    ids.forEach((id) => {
      const index = state.createPlaylist.selectedIds.indexOf(id);
      if (index === -1) {
        return;
      }
      state.createPlaylist.selectedIds.splice(index, 1);
    });
  })
  .addCase(actions.createPlaylistResortContent, (state, action) => {
    state.createPlaylist.selectedIds = action.payload.ids;
  })
  .addCase(actions.createPlaylistClearSelectedIds, (state, action) => {
    state.createPlaylist.selectedIds = [];
  }));
