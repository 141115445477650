import { memo, type ReactNode, useCallback } from 'react';
import { Box, CardContent, Typography } from '@mui/joy';
import Icon from 'ui/Icon';
import {
  type MaterialType, type ImageType, type AuthorType, type UserType,
} from 'app/entities';

import CompositePoster from 'components/CompositePoster';

import useAuthor from '../model/useAuthor';

import SourceInfo from '../ui/SourceInfo';
import DescriptionBlock from '../ui/DescriptionBlock';
import Summary from '../ui/Summary';
import AuthorBlock from '../ui/AuthorBlock';

type MaterialProps = {
  data?: MaterialType,
  posterSources?: ImageType[] | string[],
  handleAuthorPress?: (value: UserType | AuthorType | null) => void
  hasDescription?: boolean,
  hasSummary?: boolean,
  hasAuthor?: boolean,
  descriptionLineLimit?: number,
  AuthorBlockComponent?: ReactNode,
}

const Material = (props: MaterialProps) => {
  const {
    data,
    posterSources,
    handleAuthorPress,
    hasDescription,
    hasSummary,
    hasAuthor,
    descriptionLineLimit,
    AuthorBlockComponent,
  } = props;

  const authorData = useAuthor(data?.authors?.[0]);

  const handlePress = useCallback(() => {
    if (!authorData) {
      return;
    }
    handleAuthorPress?.(authorData);
  }, [authorData]);

  return (
    <>
      <Box sx={{
        '@container (width > 400px )': {
          display: 'none',
        },
      }}
      >
        {posterSources && posterSources.length > 0 && (
          <CompositePoster sources={posterSources} radius={8} aspectRatio={4 / 3} />
        )}
      </Box>
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            flex={1}
            sx={{
              '@container (width < 400px )': {
                gap: 1.5,
              },
            }}
          >
            <SourceInfo
              sx={{
                '@container (width > 400px )': {
                  marginRight: '40%',
                },
              }}
              data={data}
            />
            {data?.title && (
              <Typography
                fontSize={17}
                sx={{
                  wordBreak: 'break-word',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                fontWeight={600}
              >
                {data?.private && (
                  <Icon size="sm" marginRight={1} weight="duotone" name="lock" />
                )}
                {data?.title?.trim?.()}
              </Typography>
            )}
            {hasDescription && data?.description && (
              <DescriptionBlock ellipsizeMode="tail" numberOfLines={descriptionLineLimit || 20} text={data.description} />
            )}
            {hasSummary && data?.gptSummary && (
              <Summary text={data?.gptSummary} />
            )}
            {AuthorBlockComponent}
            {!AuthorBlockComponent && hasAuthor && !!authorData && (
              <AuthorBlock onPress={handlePress} createData={data?.publishedAt} data={authorData} />
            )}
          </Box>
          {posterSources && posterSources.length > 0 && (
            <Box sx={{
              ml: 1,
              '@container (width < 400px )': {
                display: 'none',
              },
            }}
            >
              <CompositePoster width={120} sources={posterSources} radius={8} aspectRatio={16 / 9} />
            </Box>
          )}
        </Box>
      </CardContent>
    </>
  );
};

export default memo(Material);
