import {
  type ReactElement,
  memo, useEffect, useMemo, useRef,
} from 'react';
import LottieView from 'lottie-react-native';
import { type StyleProp, type ViewStyle } from 'react-native';

import { unit } from 'utils';

import copied from './assets/copied.json';
import inQueue from './assets/in-queue.json';
import loading from './assets/loading.json';
import loadingDotted from './assets/loading-dotted.json';
import materialAdded from './assets/material-added.json';
import skillCompleted from './assets/skill-completed.json';
import loadingCircle from './assets/loading-circle';
import typing from './assets/typing.json';

const Sources = {
  Copied: copied,
  InQueue: inQueue,
  Loading: loading,
  LoadingDotted: loadingDotted,
  LoadingCircle: loadingCircle,
  Typing: typing,
  MaterialAdded: materialAdded,
  SkillCompleted: skillCompleted,
};

type LoadingProps = {
  width?: number,
  height?: number,
  name: keyof typeof Sources,
  loop?: boolean,
  color?: string,
}& typeof defaultProps;

const defaultProps = {
  width: 80,
  loop: false,
  color: '#ffffff',
};

const Loading = (props: LoadingProps): ReactElement => {
  const {
    width,
    height,
    name,
    loop,
    color,
  } = props;

  const lottie = useRef<LottieView | null>(null);

  useEffect(() => {
    lottie.current?.play();
  }, [lottie]);

  const styles = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      width: unit(width),
    };
    if (height) {
      result.height = unit(height);
    }
    return result;
  }, [width, height]);

  const source = useMemo(() => (
    typeof Sources?.[name] === 'function' ? (Sources?.[name] as CallableFunction)?.(color) : Sources[name]
  ), [color]);

  return (
    <LottieView
      ref={lottie}
      style={styles}
      source={source}
      loop={loop}
    />
  );
};

Loading.defaultProps = defaultProps;

export default memo(Loading);
