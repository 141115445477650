import {
  takeEvery, put, cancel, delay, take,
} from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as materialStore from 'store/nodes/content';
import * as commentStore from 'store/nodes/comment';
import * as noteStore from 'store/nodes/note';
import * as interactionStore from 'store/nodes/interaction';

import * as actions from '../actions';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'Content'>());
  if (!route.isScreen('Content')) {
    yield cancel(); return;
  }

  const isAppPrepared = yield* select((state) => state.app.prepared);
  if (!isAppPrepared) {
    yield delay(500);
    yield put(actions.runPageHandler());
    yield cancel(); return;
  }

  const { resourceType, resourceId } = route.params;
  if (!resourceId) {
    yield cancel(); return;
  }

  // const user = yield* select(userStore.selectors.getMy);
  // if (!user && typeof localStorage !== 'undefined') {
  //   localStorage.setItem('last-route', `material/${resourceId}`);
  // }

  yield put(materialStore.actions.loadById(resourceId));
  yield put(commentStore.actions.loadNextPage('content', resourceId, true));
  yield put(commentStore.actions.loadSummaryById('content', resourceId));
  yield put(interactionStore.actions.view('content', resourceId));
  yield put(noteStore.actions.loadByResource({ resourceType: 'material', resourceId }));
  yield put(materialStore.actions.loadRelated(resourceId));
  yield* call(() => track('Content', {
    type: resourceType,
    id: resourceId,
  }));
}
