import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

import { type NoteKey, type ResourceKey } from 'app/entities';

const isResourceKey = (key: any): key is ResourceKey => key && typeof key.resourceType === 'string' && typeof key.resourceId === 'number';
const isNoteKey = (key: any): key is NoteKey => key && typeof key.noteId === 'number';

const cacheKeyCreator = (state: DefaultRootState, key: ResourceKey | NoteKey) => {
  if (isResourceKey(key)) {
    return `resource-${key.resourceType}-${key.resourceId}`;
  } if (isNoteKey(key)) {
    return `note-${key.noteId}`;
  }
  return 'invalid-key';
};

const isLoadingSelector = createCachedSelector(
  (state: DefaultRootState) => state.note.meta.loadingMap,
  (state: DefaultRootState, key: ResourceKey | NoteKey) => key,
  (loadingMap, key) => {
    let finalKey: string | number | null = null;
    if (isResourceKey(key)) {
      finalKey = `${key.resourceType}-${key.resourceId}`;
    } else if (isNoteKey(key)) {
      finalKey = key.noteId;
    }

    if (!finalKey) {
      return false;
    }

    return !!loadingMap[finalKey];
  },
)(
  cacheKeyCreator,
);

export default (key: ResourceKey | NoteKey) => (state: DefaultRootState) => {
  return isLoadingSelector(state, key);
};
