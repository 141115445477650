import { createReducer } from '@reduxjs/toolkit';
import * as uuid from 'uuid';

import { type PlusStore } from './types';

import * as actions from './actions';

const initialState: PlusStore = {
  isOpen: false,
  layout: 'add',
  request: {
    id: null,
    status: null,
    upload: undefined,
  },
  result: null,
  props: null,
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.close, (state) => {
    state.request.id = null;
    state.request.status = null;
    state.isOpen = false;
  })
  .addCase(actions.open, (state, action) => {
    const { props } = action.payload;
    state.props = props?.regenerateProps ? state.props : (props || null);
    state.isOpen = true;
    state.layout = 'add';
    state.result = null;
  })
  .addCase(actions.setProps, (state, action) => {
    const { props } = action.payload;
    state.props = props || null;
  })
  .addCase(actions.setUploadingInfo, (state, action) => {
    const { data } = action.payload;
    if (state.request.upload && data.count) {
      state.request.upload.count = data.count;
    }
    if (state.request.upload && data.percent) {
      state.request.upload.percent = data.percent;
    }
  })
  .addCase(actions.parseText, (state) => {
    state.request.id = uuid.v4();
    state.request.status = 'pending';
    state.request.upload = {
      type: 'text',
      count: 0,
      percent: 0,
    };
    state.layout = 'processing';
  })
  .addCase(actions.parseFiles, (state, action) => {
    state.request.id = uuid.v4();
    state.request.status = 'pending';
    let type = 'file';
    if (action.payload?.data.files.length > 1) {
      type = 'files';
    }
    state.request.upload = {
      type,
      count: action.payload?.data.files.length,
      percent: 0,
    };
    state.layout = 'processing';
  })
  .addCase(actions.setMaterialResult, (state, action) => {
    const { type, data } = action.payload;
    state.result = {
      type,
      data,
    };
    state.layout = 'material';
    state.request.status = 'done';
    if (state.request.upload) {
      state.request.upload.type = 'material';
    }
  })
  .addCase(actions.setMaterialsResult, (state, action) => {
    const { type, data } = action.payload;
    state.result = {
      type,
      data,
    };
    state.layout = 'materials';
    state.request.status = 'done';
    if (state.request.upload) {
      state.request.upload.type = 'links';
    }
  })
  .addCase(actions.setNoteResult, (state, action) => {
    const { type, data } = action.payload;
    state.result = {
      type,
      data,
    };
    state.request.status = 'done';
    if (state.request.upload) {
      state.request.upload.type = 'note';
    }
  })
  .addCase(actions.setErrorResult, (state, action) => {
    const { type, data } = action.payload;
    state.result = {
      type,
      data,
    };
    state.request.status = 'done';
  })
  .addCase(actions.setSummary, (state, action) => {
    const { value } = action.payload;
    if (state.result?.data && state.result?.type === 'material') {
      state.result.data.summary = value;
    }
  }));
