import connect from '../../utils/saga/connect';
import * as parseData from './sagas/parseData';
import * as addMaterialsToCollection from './sagas/addMaterialsToCollection';
import * as summaryPrepared from './sagas/summaryPrepared';

export default connect([
  parseData,
  addMaterialsToCollection,
  summaryPrepared,
]);
