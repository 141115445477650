import {
  type CSSProperties,
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  type StyleProp, type ViewStyle, type GestureResponderEvent,
  StyleSheet, Platform,
} from 'react-native';

import { unit } from 'utils';
import { useThemeColor } from 'hooks';

import { Text, View } from 'components/Themed';
import Icon from 'components/LegacyIcon';
import Button from 'components/Button';

const defaultProps = {
  align: 'left',
};

type HeaderToolbarProps = {
  style?: StyleProp<ViewStyle>
  sourceName?: string,
  align?: 'left' | 'right',
  hasSource?: boolean,
  hasOptions?: boolean,
  isBookmarked?: boolean,
  onSourcePress?: (event: GestureResponderEvent) => void,
  onOptionsPress?: (event: GestureResponderEvent) => void,
} & typeof defaultProps;

const HeaderToolbar = (props: HeaderToolbarProps) => {
  const {
    style,
    sourceName,
    align,
    hasSource,
    hasOptions,
    isBookmarked,
    onSourcePress,
    onOptionsPress,
  } = props;

  const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);
  const [actionsWidth, setActionsWidth] = useState<number | undefined>(undefined);
  const [sourceMaxWidth, setSourceMaxWidth] = useState<number | undefined>(undefined);

  const boxShadowColor = useThemeColor({ dark: '#00000044', light: '#00000044' });

  const webBoxShadow = useMemo(() => {
    const result: StyleProp<ViewStyle> = {};
    if (Platform.OS === 'web') {
      (result as CSSProperties).boxShadow = `0 0 2px ${boxShadowColor}`;
    }
    return result;
  }, [boxShadowColor]);

  const sourceButtonStyles = useMemo(() => ([
    styles.sourceButton,
    webBoxShadow,
    { maxWidth: unit(sourceMaxWidth) },
  ]), [boxShadowColor, sourceMaxWidth]);

  useEffect(() => {
    if (!containerWidth || !actionsWidth) {
      return;
    }
    setSourceMaxWidth(containerWidth - actionsWidth - 8);
  }, [containerWidth, actionsWidth]);

  const handleContainerLayout = useCallback(({ nativeEvent }: any) => {
    const { width } = nativeEvent.layout;
    setContainerWidth(width);
  }, []);

  const handleActionsLayout = useCallback(({ nativeEvent }: any) => {
    const { width } = nativeEvent.layout;
    setActionsWidth(width);
  }, []);

  return (
    <View style={[styles.ToolbarBlock, style]} onLayout={handleContainerLayout}>
      {align === 'right' && (
        <View style={styles.spacer} />
      )}
      {!!hasSource && (
        <Button
          type="button"
          variant="contained"
          height={34}
          radius="full"
          lightColor="#ffffff"
          darkColor="#ffffff"
          onPress={onSourcePress}
          style={sourceButtonStyles}
        >
          <Icon name="Source28" size={28} lightColor="#000000" darkColor="#000000" style={styles.sourceIcon} />
          <Text font="SansEpsilion" size={14} lightColor="#000000" darkColor="#000000" ellipsizeMode="tail" numberOfLines={1} style={styles.sourceText}>
            {sourceName || 'Source'}
          </Text>
        </Button>
      )}
      {!!hasOptions && (
        <View style={styles.actions} onLayout={handleActionsLayout}>
          {/* <Button */}
          {/*   type="button-icon" */}
          {/*   variant="contained" */}
          {/*   width={35} */}
          {/*   height={34} */}
          {/*   radius="full" */}
          {/*   lightColor="#000000" */}
          {/*   darkColor="#ffffff" */}
          {/*   onPress={onBookmarkPress} */}
          {/*   style={[styles.button, webBoxShadow]} */}
          {/* > */}
          {/*   {isBookmarked && <Icon name="BookmarkFill28" size={24} lightColor="#ffffff" darkColor="#000000" />} */}
          {/*   {!isBookmarked && <Icon name="AddOutline24" size={28} lightColor="#ffffff" darkColor="#000000" />} */}
          {/* </Button> */}
          <Button
            type="button-icon"
            variant="contained"
            width={35}
            height={34}
            radius="full"
            lightColor="#ffffff"
            darkColor="#ffffff"
            onPress={onOptionsPress}
            style={[styles.button, webBoxShadow]}
          >
            <Icon name="Dots" size={28} lightColor="#000000" darkColor="#000000" />
          </Button>
        </View>
      )}
    </View>
  );
};

HeaderToolbar.defaultProps = defaultProps;

const styles = StyleSheet.create({
  ToolbarBlock: {
    flexDirection: 'row',
  },
  sourceIcon: {
    marginHorizontal: unit(-8),
  },
  sourceText: {
    marginLeft: unit(8),
    lineHeight: unit(14),
  },
  iconButton: {
  },
  sourceButton: {
    marginRight: unit(8),
  },
  button: {
    marginLeft: unit(8),
  },
  actions: {
    flexDirection: 'row',
  },
  spacer: {
    flex: 1,
  },
});

export default memo(HeaderToolbar);
