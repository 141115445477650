import {
  type SagaReturnType,
  put, cancel, takeLatest, take, fork, delay,
} from 'redux-saga/effects';
import { channel, END } from 'redux-saga';

import {
  guard, uploadFilesToS3,
} from 'utils';
import network from 'lib/network';
import { call, select } from 'store/utils/saga/effects';
import * as materialStore from 'store/nodes/content';

import Alert from 'components/Alert';
import { track } from '@amplitude/analytics-react-native';

import * as noteStore from 'store/nodes/note';
import * as libraryStore from 'store/nodes/library';
import * as modalStore from 'widgets/modals/store';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: [actions.parseText.type, actions.parseFiles.type],
  method: takeLatest,
};

type TextType = {
  text: string,
  plain_text: string,
  private: boolean,
}

type BulkType = {
  bulk_content_file: {
    data: string,
    extension: string,
  },
  private: boolean,
}

type FileType = {
  s3_file_info: {
    file_name: string,
    file_hash: string,
    extension: string,
  }[],
  private: boolean,
}

export function* func(action: SagaReturnType<typeof actions.parseText | typeof actions.parseFiles>) {
  let body: TextType | BulkType | FileType | null = null;
  if (action.type === actions.parseText.type) {
    const {
      data: { value: html, privacy },
    } = action.payload;
    const text = html.replace(/<[^>]*>/g, '');
    body = { text: html, plain_text: text, private: privacy };
  }

  if (action.type === actions.parseFiles.type) {
    const processChannel = channel();

    function* watchProcessChannel() {
      while (true) {
        const actionFromChannel: { payload: { percent: number } } | END = yield take(processChannel);
        if (actionFromChannel === END) {
          break;
        }
        const { percent } = (actionFromChannel as { payload: { percent: number } }).payload;
        yield put(actions.setUploadingInfo({
          percent,
        }));
      }
    }
    yield fork(watchProcessChannel);

    const {
      data: { files, privacy },
    } = action.payload;
    let processedCount = 0;
    const uploadedResults = yield* call(() => uploadFilesToS3(files, () => {
      processedCount += 1;
      processChannel.put({ type: 's3/uploaded', payload: { percent: (processedCount / files.length) * 100 } });
    }));
    processChannel.close();

    const successUploaded = uploadedResults.filter((item) => !item.error);
    body = successUploaded.length > 0 ? {
      s3_file_info: successUploaded.map(({ data }) => ({
        file_name: data?.file?.name || '',
        file_hash: data?.hash || '',
        extension: data?.file?.extension || '',
      })),
      private: privacy,
    } : null;
  }

  if (!body) {
    yield put(actions.setErrorResult('Error input data'));
    yield put(actions.parseDone());
    yield put(actions.close());
    Alert.error('Unknown error');
    yield cancel(); return;
  }

  const requestInfo = yield* select(selectors.requestInfo);
  const { data, errors } = yield* call(() => (
    network.request<any>('/content/upload').body({ request_id: requestInfo.id, ...body }).post()
  ));

  if (['Usage_limits_pins'].includes(data?.errors?.type)) {
    yield put(actions.parseDone());
    yield put(actions.close());
    yield cancel(); return;
  }

  if (!data || data.errors || errors) {
    yield put(actions.setErrorResult('Unknown error'));
    yield put(actions.parseDone());
    yield put(actions.close());
    Alert.error(data?.errors?.message || 'Unknown error');
    yield cancel(); return;
  }

  if (guard.isNote(data)) {
    yield put(actions.setNoteResult(data));
    yield put(libraryStore.actions.prependToSequence('note', data.id));
    yield put(noteStore.actions.setItem({ data: yield noteStore.utils.prepareNote(data) }));
    Alert.success('Note added to library');
    yield put(actions.parseDone());
    yield put(actions.close());
    yield put(modalStore.actions.close('PlusBottomSheet'));
    yield* call(() => track('PLUS:AddNote'));
    yield cancel(); return;
  }

  const isSingleMaterial = guard.isMaterial(data);

  if (isSingleMaterial) {
    yield put(actions.setMaterialResult(data));
    yield put(materialStore.actions.loadById(data.id));
    yield put(libraryStore.actions.prependToSequence('material', data.id));
    Alert.success('Knowledge added');
    yield* call(() => track('PLUS:AddOneMaterial'));
  } else {
    yield put(actions.setMaterialsResult(data));
    yield* call(() => track('PLUS:AddManyMaterials'));
  }

  yield put(actions.parseDone());
}
