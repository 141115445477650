import {
  type KeyboardEvent, type SyntheticEvent, type HTMLAttributes,
  memo, useCallback, useState, useMemo, useEffect,
} from 'react';
import {
  Autocomplete, AutocompleteOption, ListItemContent, ListItemDecorator, Typography, Box,
} from '@mui/joy';

import { type UserType } from 'app/entities';
import { guard, userFullName } from 'utils';

import Avatar from 'ui/Avatar';
import Icon from 'ui/Icon';

import PermissionSelect from 'widgets/CollectionShare/components/PermissionSelect';
import useUsers from '../hooks/useUsers';

type UserSelectProps = {
  onEnter?: () => void,
  onUserChange?: (value: number | string) => void,
  onPermissionsChange?: (value: string) => void
  reset?: boolean,
}

const UserSelect = (props: UserSelectProps) => {
  const {
    onEnter, onUserChange, onPermissionsChange, reset,
  } = props;

  const [invitePermissions, setInvitePermissions] = useState('');
  const [value, setValue] = useState<UserType | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');

  const users = useUsers();

  useEffect(() => {
    if (reset) {
      setInvitePermissions('');
      setValue(undefined);
      setInputValue('');
    }
  }, [reset]);

  const handleChange = useCallback((
    event: SyntheticEvent | null,
    text: string | null,
    reason: 'input' | 'reset' | 'clear' | 'enter' | string,
  ) => {
    if (reason === 'reset' && !!event) {
      const userId = Number((event.currentTarget as HTMLLIElement).value);
      const user = users.find((item) => item.id === userId) || undefined;
      if (user) {
        setValue(user);
        setInputValue(userFullName(user));
        onUserChange?.(user.id);
      }
      return;
    }
    if (reason === 'input' && text !== null) {
      setInputValue(text);
      onUserChange?.(text);
      setValue(undefined);
    }
    if (reason === 'clear') {
      setInputValue('');
      setValue(undefined);
    }
    if (reason === 'enter') {
      onEnter?.();
    }
  }, [value, users, onEnter, onUserChange]);

  const handlePermissionsChange = useCallback((newValue: string) => {
    setInvitePermissions(newValue ?? '');
    onPermissionsChange?.(newValue ?? '');
  }, [onPermissionsChange]);

  const handleInputKeyUp = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'NumpadEnter'].includes(event.code)) {
      handleChange(null, null, 'enter');
    }
  }, [handleChange]);

  const renderOption = useCallback((optionProps: Omit<HTMLAttributes<HTMLLIElement>, 'color'>, option: UserType) => (
    <AutocompleteOption value={option.id} {...optionProps}>
      <ListItemDecorator>
        <Avatar src={option?.photo?.url} thumbnail="small">
          {userFullName(option).slice(0, 1)}
        </Avatar>
      </ListItemDecorator>
      <ListItemContent sx={{ ml: 0.75 }}>
        <Typography fontSize={15} fontWeight={500}>
          {userFullName(option)}
        </Typography>
      </ListItemContent>
    </AutocompleteOption>
  ), []);

  const getOptionKey = useCallback((option: string | UserType) => (
    guard.isUser(option) ? option.id : ''
  ), []);

  const getOptionLabel = useCallback((option: string | UserType) => (
    guard.isUser(option) ? userFullName(option) : ''
  ), []);

  const startDecoratorRender = useMemo(() => {
    if (/.+@.+\..+/.test(inputValue)) {
      return (
        <Avatar src={value?.photo?.url} thumbnail="small" sx={{ '--Avatar-size': '24px' }}>
          <Box mt="1px">
            <Icon name="paper-plane" size="xl" weight="solid" fw />
          </Box>
        </Avatar>
      );
    }
    if (guard.isUser(value)) {
      return (
        <Avatar src={value?.photo?.url} thumbnail="small" sx={{ '--Avatar-size': '24px' }}>
          {userFullName(value).slice(0, 1)}
        </Avatar>
      );
    }
    return undefined;
  }, [inputValue, value]);

  return (
    <Autocomplete
      placeholder="Email, name"
      sx={{ flex: 1 }}
      required
      onInputChange={handleChange}
      inputValue={inputValue}
      value={value}
      // onChange={handleChange}
      clearOnEscape
      freeSolo={/.+@.+\..+/.test(inputValue)}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      options={users}
      loading={!users}
      loadingText="Loading user list"
      disableClearable
      clearOnBlur={false}
      renderOption={renderOption}
      onKeyUp={handleInputKeyUp}
      startDecorator={startDecoratorRender}
      endDecorator={(
        <PermissionSelect
          placeholder="Select role"
          disabled={!value && !/.+@.+\..+/.test(inputValue)}
          value={invitePermissions}
          onChange={(_, newValue) => handlePermissionsChange(newValue)}
        />
      )}
      slotProps={{
        popupIndicator: {
          sx: {
            display: 'none',
          },
        },
      }}
    />
  );
};

export default memo(UserSelect);
