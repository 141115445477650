import {
  type SagaReturnType,
  put, takeLatest, cancel,
} from 'redux-saga/effects';

import network from 'lib/network';
import { call, select } from 'store/utils/saga/effects';
import Alert from 'components/Alert';

import * as selectors from '../selectors';
import { actions } from '../slice';

export const config = {
  action: [
    actions.cancel.type,
  ],
  method: takeLatest,
};

export function* func(action: SagaReturnType<
  | typeof actions.cancel
>) {
  const currentPlan = yield* select(selectors.currentPlan);
  if (!currentPlan?.id || ['-1', '0'].includes(currentPlan.id)) {
    yield put(actions.cancelDone());
    yield cancel(); return;
  }

  const { errors, data } = yield* call(() => (
    network.request<{ unsubscribed: boolean }>('/payment/unsubscribe').post()
  ));

  if ((errors?.length || 0) > 0 || !data?.unsubscribed) {
    yield* call(() => Alert.error('Error cancelling subscription'));
    yield put(actions.cancelDone());
    yield cancel();
  }

  yield* call(() => Alert.success('Your subscription cancelled'));
  yield put(actions.cancelDone());
}
