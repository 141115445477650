import {
  takeEvery, put, cancel, delay,
} from 'redux-saga/effects';

import { navigate, getRoute } from 'navigation/methods';

import * as api from 'services/api';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.route.type,
  method: takeEvery,
};

// @todo переработка и соединение с handleLocationChange
export function* func() {
  const route = yield* call(() => getRoute());

  const isIndex = route?.name?.includes?.('Index');
  const isWelcome = route?.name?.includes?.('Start');
  const isAuth = route?.name?.includes?.('Auth');
  const isSignIn = route?.name?.includes?.('Auth/SignIn');
  const isSignUp = route?.name?.includes?.('Auth/SignUp');
  const isPasswordReset = route?.name?.includes?.('Auth/PasswordReset');
  const isContentFeed = route?.name?.includes?.('Feed');
  const isPlaylist = route?.name?.includes?.('Playlist');
  const isContent = route?.name?.includes?.('Content');
  const hasSession = api.credentials.hasSession();

  if (isPlaylist || isContent) {
    yield put(actions.routed());
    yield cancel(); return;
  }

  if (!hasSession && (isSignIn || isSignUp || isPasswordReset || isWelcome)) {
    yield put(actions.routed());
    yield cancel(); return;
  }

  if (!hasSession) {
    yield* call(() => navigate('Auth/Start', { replace: true }));
    yield delay(10);
    yield put(actions.routed());
    yield cancel(); return;
  }

  const userProfile = yield* select(userStore.selectors.getByLogin('my'));
  if (!userProfile) {
    yield* call(() => navigate('Auth/Start', { replace: true }));
    yield delay(10);
    yield put(actions.routed());
    yield cancel(); return;
  }

  if (!userProfile.name) {
    yield* call(() => navigate('Auth/Step1', { replace: true }));
    yield delay(10);
    yield put(actions.routed());
    yield cancel(); return;
  }

  if (isIndex || isWelcome || isAuth) {
    yield* call(() => navigate('Auth/Start', { replace: true }));
    yield delay(10);
    yield put(actions.routed());
    yield cancel(); return;
  }

  yield put(actions.routed());
}
