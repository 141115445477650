const scrollToEnd = (body?: HTMLDivElement | null, options?: { byGap?: number, windowScroll?: boolean }) => {
  const {
    byGap,
    windowScroll = false,
  } = options || {};
  const isMobileMode = window.innerWidth <= 767;
  if (!body) {
    return;
  }
  const innerHeight = (isMobileMode || windowScroll) ? window.innerHeight : body.clientHeight;
  const scrollHeight = (isMobileMode || windowScroll) ? document.body.scrollHeight : body.scrollHeight;
  const scrollTop = (isMobileMode || windowScroll) ? window.scrollY : body.scrollTop;
  if (typeof byGap !== 'undefined' && !(scrollTop + innerHeight + byGap > scrollHeight)) {
    return;
  }
  ((isMobileMode || windowScroll) ? window : body).scrollTo({
    top: scrollHeight,
    behavior: 'instant',
  });
};

export default scrollToEnd;
