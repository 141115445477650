import { memo, type MouseEvent, useCallback } from 'react';
import {
  Box, Button,
  DialogTitle, List, ListDivider, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Modal, ModalClose, ModalDialog, Typography,
} from '@mui/joy';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as modalStore from 'widgets/modals/store';
import * as userStore from 'store/nodes/user';
import Icon from 'ui/Icon';
import CollectionSingleCover from 'components/CollectionSingleCover';
import * as contentStore from 'store/nodes/content';

const AddToCollection = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('AddToCollection'));
  const collections = useSelector(userStore.selectors.getCollections('my'));
  const material = useSelector(contentStore.selectors.dataById(params?.id));

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('AddToCollection');
  }, []);

  const handleToNewCollection = useCallback(() => {
    if (!params?.id) {
      return;
    }
    dispatcher.modal.open('AddorEditCollection', { create: { materialIds: [params?.id] } });
  }, [params?.id]);

  const handleItemPress = useCallback((event: MouseEvent<HTMLDivElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    if (!id || !material) {
      return;
    }

    dispatcher.playlist.addMaterialItem({
      collectionId: Number(id), data: material, itemType: 'material', options: { append: true },
    });
  }, [params?.id, material]);

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        size="lg"
        sx={{
          width: 380,
          '--ModalClose-radius': '1.125em',
          '--variant-plainHoverBg': (theme) => theme.palette.background.level2,
          '--variant-plainActiveBg': (theme) => theme.palette.background.level3,
        }}
      >
        <ModalClose
          onClick={handleModalClose}
          variant="soft"
          size="lg"
          sx={{
            '--ModalClose-radius': '1.125em',
          }}
        />
        <DialogTitle sx={{ mt: -1 }}>Add material to collection</DialogTitle>
        {collections.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              height: 128,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography fontSize={17}>No available collections</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography fontSize={15}>You can</Typography>
              <Button variant="plain" onClick={handleToNewCollection}>create new collection</Button>
            </Box>
          </Box>
        )}
        {collections.length > 0 && (
          <List
            slotProps={{
              root: {},
            }}
            size="md"
            sx={{
              overflow: 'auto',
              mx: 'calc(-1 * var(--Card-padding))',
              px: 'var(--Card-padding)',
            }}
          >
            <ListItem>
              <ListItemButton
                onClick={handleToNewCollection}
                sx={{ gap: 1 }}
              >
                <Icon width={36} height={36} display="flex" alignItems="center" justifyContent="center" size="lg" name="plus" />
                <ListItemContent>Create new collection</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListDivider />
            {collections.map((playlist, key) => (
              <>
                <ListItem key={key}>
                  <ListItemButton
                    disabled={playlist.materialsMetadata.some((item) => item.id === params?.id)}
                    data-id={playlist.id}
                    onClick={handleItemPress}
                    sx={{ gap: 1 }}
                  >
                    <ListItemDecorator
                      component="div"
                      sx={{ flexShrink: 1, mr: 0 }}
                    >
                      <CollectionSingleCover data={playlist} iconSize={36} heightPicture={36} widthPicture={36} />
                    </ListItemDecorator>
                    <ListItemContent
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {playlist.title}
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
                {key < collections.length - 1 && <ListDivider />}
              </>
            ))}
          </List>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default memo(AddToCollection);
