const types: Record<string, any> = {
  'image/gif': 'gif',
  'image/jpeg': 'jpg',
  'image/pjpeg': 'jpg',
  'image/png': 'png',
  'image/svg+xml': 'svg',
  'image/tiff': 'tiff',
  'image/webp': 'webp',
  'text/csv': 'csv',
  'text/plain': 'txt',
  'application/pdf': 'pdf',
};

type ContentType = keyof typeof types;
type FileExtension = typeof types[ContentType];

export default (contentType: string, defaultValue?: string): FileExtension | typeof defaultValue => types[contentType] || defaultValue;
