import type { CommentItemMetaType, ResourceVariant, MapResult } from 'app/entities';
import request from 'services/api/request';

export default async (
  resource: ResourceVariant,
  ids: number | number[],
): Promise<MapResult<CommentItemMetaType>> => {
  if (!ids || (Array.isArray(ids) && ids.length === 0)) {
    return {
      data: {},
      error: null,
    };
  }
  const idsFinal = Array.isArray(ids) ? ids : [ids];

  const query: Record<string, any> = {
    resourceIds: idsFinal.join(','),
  };

  const { data, error } = await request.get<Record<number, CommentItemMetaType>>(`/${resource}/comments/meta`, { query });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
