import subscription from 'data/subscription.json';

import { useSelector } from 'store/utils/redux/hooks';
import * as subscriptionStore from '../store';

const useBarLabels = () => {
  const { bar } = subscription;
  const currentPlan = useSelector(subscriptionStore.selectors.currentPlan);

  const { label: stateLabel = 'Checking' } = bar.state.find((item) => {
    if (!currentPlan.id || !currentPlan.isLoaded) {
      return item.ids.includes('-1');
    }
    return item.ids.includes(currentPlan.id);
  }) || {};

  return {
    stateLabel,
    portalLabel: bar.portal,
  };
};

export default useBarLabels;
