import { type ReactElement, memo } from 'react';
import {
  Platform,
} from 'react-native';
import type {
  StyleProp, ViewStyle,
} from 'react-native';

import { useThemeColor } from 'hooks';
import { unit } from 'utils';

import { View } from 'components/Themed';

import * as Map from './lib/Map';
import type { IComponent, PlatformOSType } from './lib/types';

import AccessTime from './assets/AccessTime.svg';
import ActivityAdded28 from './assets/ActivityAdded28.svg';
import ActivityCompleted28 from './assets/ActivityCompleted28.svg';
import Add from './assets/Add.svg';
import AddOutline28 from './assets/AddOutline28.svg';
import AddOutline24 from './assets/AddOutline24.svg';
import ArrowUpCircle from './assets/ArrowUpCircle.svg';
import Remove from './assets/Remove.svg';
import AttachFile from './assets/AttachFile.svg';
import BookmarkBorder from './assets/BookmarkBorder.svg';
import BookmarkOutline28 from './assets/BookmarkOutline28.svg';
import BookmarkFill28 from './assets/BookmarkFill28.svg';
import Charging28 from './assets/Charging28.svg';
import Chat from './assets/Chat.svg';
import ChatOutline28Icon from './assets/ChatOutline28.svg';
import ChatBubbleOutlineIcon from './assets/ChatBubbleOutline.svg';
import ChevronDownIcon from './assets/ChevronDown.svg';
import ChevronLeftIcon from './assets/ChevronLeft.svg';
import ChevronRightIcon from './assets/ChevronRight.svg';
import ChevronUpIcon from './assets/ChevronUp.svg';
import CopyLinkIcon from './assets/CopyLink.svg';
import DeleteFill28 from './assets/DeleteFill28.svg';
import DeleteOutline28Icon from './assets/DeleteOutline28.svg';
import DoneIcon from './assets/Done.svg';
import DoneAllIcon from './assets/DoneAll.svg';
import DoneFill28Icon from './assets/DoneFill28.svg';
import DoneAllFill28Icon from './assets/DoneAllFill28.svg';
import DotsIcon from './assets/Dots.svg';
import DotsFill28Icon from './assets/DotsFill28.svg';
import Drag24 from './assets/Drag24.svg';
import Drag16 from './assets/Drag16.svg';
import EditOutline28Icon from './assets/EditOutline28.svg';
import ErrorOutlineIcon from './assets/ErrorOutline.svg';
import CheckboxOn from './assets/CheckboxOn.svg';
import CheckboxOff from './assets/CheckboxOff.svg';
import ExtensionOutline32 from './assets/ExtensionOutline32.svg';
import FileUploadIcon from './assets/FileUpload.svg';
import FilterOutline from './assets/FilterOutline.svg';
import GridViewIcon from './assets/GridView.svg';
import HideOutline28Icon from './assets/HideOutline28.svg';
import IconCircle from './assets/IconCircle.svg';
import IKIOutline68 from './assets/IKIOutline68.svg';
import HamburgerIcon from './assets/Hamburger.svg';
import InsertEmoticonIcon from './assets/InsertEmoticon.svg';
import IosShareIcon from './assets/IosShare.svg';
import LibraryIcon from './assets/Library.svg';
import LinkedinIcon from './assets/Linkedin.svg';
import LinkInclineOutline28Icon from './assets/LinkInclineOutline28.svg';
import LogoOutline from './assets/LogoOutline.svg';
import MarkerByIKI from './assets/MarkerByIKI.svg';
import MarkerByIKIGray from './assets/MarkerByIKIGray.svg';
import ListLayoutGrid28 from './assets/ListLayoutGrid28.svg';
import ListLayoutRow28 from './assets/ListLayoutRow28.svg';
import MarkOutline from './assets/MarkOutline.svg';
import MenuAddedOutline from './assets/MenuAddedOutline.svg';
import MenuBookmarkOutline from './assets/MenuBookmarkOutline.svg';
import MenuCommunityOutline from './assets/MenuCommunityOutline.svg';
import MenuCompletedOutline from './assets/MenuCompletedOutline.svg';
import MenuHomeOutline from './assets/MenuHomeOutline.svg';
import MenuLibraryOutline from './assets/MenuLibraryOutline.svg';
import MenuPeopleOutline from './assets/MenuPeopleOutline.svg';
import MenuPlusOutline from './assets/MenuPlusOutline.svg';
import MenuSearchOutline from './assets/MenuSearchOutline.svg';
import MenuStaffOutline from './assets/MenuStaffOutline.svg';
import MicIcon from './assets/Mic.svg';
import Minus24Icon from './assets/Minus24.svg';
import NotifyOutline28Icon from './assets/NotifyOutline28.svg';
import PeopleOutline28Icon from './assets/PeopleOutline28.svg';
import PersonOutline28Icon from './assets/PersonOutline28.svg';
import PictureOutline28Icon from './assets/PictureOutline28.svg';
import Playlist28Icon from './assets/Playlist28.svg';
import PlaylistPlayIcon from './assets/PlaylistPlay.svg';
import SchoolIcon from './assets/School.svg';
import School28Icon from './assets/School28.svg';
import SchoolThinIcon from './assets/SchoolThin.svg';
import SearchIcon from './assets/Search.svg';
import ShareOutline28Icon from './assets/ShareOutline28.svg';
import ShowOutline28Icon from './assets/ShowOutline28.svg';
import SmallDot from './assets/SmallDot.svg';
import SmartListGray from './assets/SmartListGray.svg';
import SmartListColor from './assets/SmartListColor.svg';
import Source28 from './assets/Source28.svg';
import StarIcon from './assets/Star.svg';
import StarOutlineIcon from './assets/StarOutline.svg';
import ThumbDownIcon from './assets/ThumbDown.svg';
import ThumbUpIcon from './assets/ThumbUp.svg';
import ThumbUpFilledIcon from './assets/ThumbUpFilled.svg';
import TuneIcon from './assets/Tune.svg';
import TurnedInNotIcon from './assets/TurnedInNot.svg';
import VideoCamIcon from './assets/VideoCam.svg';
import FacebookIcon from './assets/Facebook.svg';
import GoogleIcon from './assets/Google.svg';
import ContentIcon from './assets/Content.svg';
import VacancyIcon from './assets/Vacancy.svg';
import VisibilityOffIcon from './assets/VisibilityOff.svg';
import PeopleIcon from './assets/People.svg';
import ProfileIcon from './assets/Profile.svg';
import SettingsOutlineIcon from './assets/SettingsOutline.svg';
import InstagramOutline28 from './assets/InstagramOutline28.svg';
import ArticleOutline28 from './assets/ArticleOutline28.svg';
import LinkOutline28 from './assets/LinkOutline28.svg';
import HighlightOutline28 from './assets/HighlightOutline28.svg';
import SourceOutline28 from './assets/SourceOutline28.svg';
import YoutubeOutline28 from './assets/YoutubeOutline28.svg';
import LockFill from './assets/LockFill.svg';
import Add2 from './assets/Add2.svg';
import Gpt from './assets/Gpt.svg';
import Note from './assets/Note.svg';
import Gpt2 from './assets/Gpt2.svg';
import Play from './assets/Play.svg';
import RefreshCCW from './assets/RefreshCCW.svg';
import Eye from './assets/Eye.svg';
import Camera from './assets/Camera.svg';
import Close from './assets/Close.svg';
import Enhance from './assets/Enhance.svg';
import Dollar from './assets/Dollar.svg';
import FilePlus from './assets/FilePlus.svg';
import Organizing from './assets/Organizing.svg';
import UserPlus from './assets/UserPlus.svg';
import Zoom from './assets/Zoom.svg';
import ZoomIn from './assets/ZoomIn.svg';
import IkiCoPilot from './assets/IkiCoPilot.svg';
import LightTheme from './assets/LightTheme.svg';
import DarkTheme from './assets/DarkTheme.svg';
import EnterUrl from './assets/EnterUrl.svg';
import FilePDF from './assets/FilePDF.svg';
import Image from './assets/Image.svg';
import FileBlank from './assets/FileBlank.svg';

const mapItems = {
  AccessTime: new Map.IconSVG(AccessTime, 'stroke'),
  ActivityAdded28: new Map.IconSVG(ActivityAdded28, 'fill'),
  ActivityCompleted28: new Map.IconSVG(ActivityCompleted28, 'fill'),
  Add: new Map.IconSVG(Add, 'fill'),
  AddOutline28: new Map.IconSVG(AddOutline28, 'fill'),
  AddOutline24: new Map.IconSVG(AddOutline24, 'fill'),
  ArrowUpCircle: new Map.IconSVG(ArrowUpCircle, 'stroke'),
  Remove: new Map.IconSVG(Remove, 'stroke'),
  AttachFile: new Map.IconSVG(AttachFile, 'fill'),
  BookmarkBorder: new Map.IconSVG(BookmarkBorder, 'stroke'),
  BookmarkOutline28: new Map.IconSVG(BookmarkOutline28, 'fill'),
  BookmarkFill28: new Map.IconSVG(BookmarkFill28, 'fill'),
  Charging28: new Map.IconSVG(Charging28, 'stroke'),
  Chat: new Map.IconSVG(Chat, 'fill'),
  ChatOutline28: new Map.IconSVG(ChatOutline28Icon, 'stroke'),
  ChatBubbleOutline: new Map.IconSVG(ChatBubbleOutlineIcon, 'stroke'),
  ChevronDown: new Map.IconSVG(ChevronDownIcon, 'fill'),
  ChevronLeft: new Map.IconSVG(ChevronLeftIcon, 'fill'),
  ChevronRight: new Map.IconSVG(ChevronRightIcon, 'fill'),
  ChevronUp: new Map.IconSVG(ChevronUpIcon, 'fill'),
  CopyLink: new Map.IconSVG(CopyLinkIcon, 'stroke'),
  DeleteFill28: new Map.IconSVG(DeleteFill28, 'fill'),
  DeleteOutline28: new Map.IconSVG(DeleteOutline28Icon, 'fill'),
  Done: new Map.IconSVG(DoneIcon, 'fill'),
  DoneAll: new Map.IconSVG(DoneAllIcon, 'stroke'),
  DoneFill28: new Map.IconSVG(DoneFill28Icon, 'fill'),
  DoneAllFill28: new Map.IconSVG(DoneAllFill28Icon, 'fill'),
  Dots: new Map.IconSVG(DotsIcon, 'fill'),
  DotsFill28: new Map.IconSVG(DotsFill28Icon, 'fill'),
  Drag24: new Map.IconSVG(Drag24, 'fill'),
  Drag16: new Map.IconSVG(Drag16, 'fill'),
  EditOutline28: new Map.IconSVG(EditOutline28Icon, 'fill'),
  ErrorOutline: new Map.IconSVG(ErrorOutlineIcon, 'fill'),
  CheckboxOn: new Map.IconSVG(CheckboxOn, 'fill'),
  CheckboxOff: new Map.IconSVG(CheckboxOff, 'fill'),
  ExtensionOutline32: new Map.IconSVG(ExtensionOutline32, 'fill'),
  FileUpload: new Map.IconSVG(FileUploadIcon, 'stroke'),
  FilterOutline: new Map.IconSVG(FilterOutline, 'fill'),
  GridView: new Map.IconSVG(GridViewIcon, 'fill'),
  HideOutline28: new Map.IconSVG(HideOutline28Icon, 'fill'),
  IconCircle: new Map.IconSVG(IconCircle, 'fill'),
  IKIOutline68: new Map.IconSVG(IKIOutline68, 'fill'),
  Hamburger: new Map.IconSVG(HamburgerIcon, 'fill'),
  PictureOutline28: new Map.IconSVG(PictureOutline28Icon, 'fill'),
  InsertEmoticon: new Map.IconSVG(InsertEmoticonIcon, 'fill'),
  IosShare: new Map.IconSVG(IosShareIcon, 'stroke'),
  Library: new Map.IconSVG(LibraryIcon, 'fill'),
  LinkInclineOutline28: new Map.IconSVG(LinkInclineOutline28Icon, 'fill'),
  LogoOutline: new Map.IconSVG(LogoOutline, 'fill'),
  MarkerByIKI: new Map.IconSVG(MarkerByIKI, 'fill'),
  MarkerByIKIGray: new Map.IconSVG(MarkerByIKIGray, 'fill'),
  ListLayoutGrid28: new Map.IconSVG(ListLayoutGrid28, 'fill'),
  ListLayoutRow28: new Map.IconSVG(ListLayoutRow28, 'fill'),
  MarkOutline: new Map.IconSVG(MarkOutline, 'fill'),
  MenuAddedOutline: new Map.IconSVG(MenuAddedOutline, 'fill'),
  MenuBookmarkOutline: new Map.IconSVG(MenuBookmarkOutline, 'fill'),
  MenuCommunityOutline: new Map.IconSVG(MenuCommunityOutline, 'fill'),
  MenuCompletedOutline: new Map.IconSVG(MenuCompletedOutline, 'fill'),
  MenuHomeOutline: new Map.IconSVG(MenuHomeOutline, 'fill'),
  MenuLibraryOutline: new Map.IconSVG(MenuLibraryOutline, 'fill'),
  MenuPeopleOutline: new Map.IconSVG(MenuPeopleOutline, 'fill'),
  MenuPlusOutline: new Map.IconSVG(MenuPlusOutline, 'fill'),
  MenuSearchOutline: new Map.IconSVG(MenuSearchOutline, 'fill'),
  MenuStaffOutline: new Map.IconSVG(MenuStaffOutline, 'fill'),
  Mic: new Map.IconSVG(MicIcon, 'stroke'),
  Minus24: new Map.IconSVG(Minus24Icon, 'fill'),
  NotifyOutline28: new Map.IconSVG(NotifyOutline28Icon, 'fill'),
  PeopleOutline28: new Map.IconSVG(PeopleOutline28Icon, 'fill'),
  PersonOutline28: new Map.IconSVG(PersonOutline28Icon, 'fill'),
  Playlist28: new Map.IconSVG(Playlist28Icon, 'fill'),
  PlaylistPlay: new Map.IconSVG(PlaylistPlayIcon, 'stroke'),
  School: new Map.IconSVG(SchoolIcon, 'stroke'),
  School28: new Map.IconSVG(School28Icon, 'stroke'),
  SchoolThin: new Map.IconSVG(SchoolThinIcon, 'stroke'),
  Search: new Map.IconSVG(SearchIcon, 'fill'),
  ShareOutline28: new Map.IconSVG(ShareOutline28Icon, 'fill'),
  ShowOutline28: new Map.IconSVG(ShowOutline28Icon, 'fill'),
  SmallDot: new Map.IconSVG(SmallDot, 'fill'),
  SmartListGray: new Map.IconSVG(SmartListGray, 'fill'),
  SmartListColor: new Map.IconSVG(SmartListColor, 'fill'),
  Source28: new Map.IconSVG(Source28, 'fill'),
  Star: new Map.IconSVG(StarIcon, 'fill'),
  StarOutline: new Map.IconSVG(StarOutlineIcon, 'fill'),
  ThumbDown: new Map.IconSVG(ThumbDownIcon, 'stroke'),
  ThumbUp: new Map.IconSVG(ThumbUpIcon, 'stroke'),
  ThumbUpFilled: new Map.IconSVG(ThumbUpFilledIcon, 'fill'),
  Tune: new Map.IconSVG(TuneIcon, 'fill'),
  TurnedInNot: new Map.IconSVG(TurnedInNotIcon, 'stroke'),
  VideoCam: new Map.IconSVG(VideoCamIcon, 'fill'),
  Facebook: new Map.IconSVG(FacebookIcon, 'fill'),
  Linkedin: new Map.IconSVG(LinkedinIcon, 'fill'),
  Google: new Map.IconSVG(GoogleIcon, 'fill'),
  Content: new Map.IconSVG(ContentIcon, 'fill'),
  Vacancy: new Map.IconSVG(VacancyIcon, 'stroke'),
  VisibilityOff: new Map.IconSVG(VisibilityOffIcon, 'stroke'),
  People: new Map.IconSVG(PeopleIcon, 'stroke'),
  Profile: new Map.IconSVG(ProfileIcon, 'stroke'),
  SettingsOutline: new Map.IconSVG(SettingsOutlineIcon, 'fill'),
  InstagramOutline28: new Map.IconSVG(InstagramOutline28, 'fill'),
  ArticleOutline28: new Map.IconSVG(ArticleOutline28, 'fill'),
  LinkOutline28: new Map.IconSVG(LinkOutline28, 'fill'),
  HighlightOutline28: new Map.IconSVG(HighlightOutline28, 'fill'),
  SourceOutline28: new Map.IconSVG(SourceOutline28, 'fill'),
  YoutubeOutline28: new Map.IconSVG(YoutubeOutline28, 'fill'),
  LockFill: new Map.IconSVG(LockFill, 'fill'),
  Add2: new Map.IconSVG(Add2, 'fill'),
  Gpt: new Map.IconSVG(Gpt, 'fill'),
  Gpt2: new Map.IconSVG(Gpt2, 'fill'),
  Note: new Map.IconSVG(Note, 'fill'),
  Play: new Map.IconSVG(Play, 'fill'),
  RefreshCCW: new Map.IconSVG(RefreshCCW, 'fill'),
  Eye: new Map.IconSVG(Eye, 'fill'),
  Camera: new Map.IconSVG(Camera, 'fill'),
  Close: new Map.IconSVG(Close, 'fill'),
  Enhance: new Map.IconSVG(Enhance, 'fill'),
  Dollar: new Map.IconSVG(Dollar, 'fill'),
  FilePlus: new Map.IconSVG(FilePlus, 'fill'),
  Organizing: new Map.IconSVG(Organizing, 'fill'),
  UserPlus: new Map.IconSVG(UserPlus, 'fill'),
  Zoom: new Map.IconSVG(Zoom, 'fill'),
  ZoomIn: new Map.IconSVG(ZoomIn, 'fill'),
  IkiCoPilot: new Map.IconSVG(IkiCoPilot, 'fill'),
  LightTheme: new Map.IconSVG(LightTheme, 'fill'),
  DarkTheme: new Map.IconSVG(DarkTheme, 'fill'),
  EnterUrl: new Map.IconSVG(EnterUrl, 'stroke'),
  FilePDF: new Map.IconSVG(FilePDF, 'fill'),
  Image: new Map.IconSVG(Image, 'fill'),
  FileBlank: new Map.IconSVG(FileBlank, 'fill'),
};
const map = new Map.Items(mapItems);

export type IconNames = keyof typeof mapItems;

const getComponentObject = (
  data: Map.PlatformGroup | Map.IconSVG,
  platform?: PlatformOSType,
): IComponent => {
  if (data instanceof Map.PlatformGroup && typeof platform !== 'undefined') {
    return getComponentObject(data[platform]);
  }

  if (data instanceof Map.IconSVG) {
    return data;
  }

  return mapItems.ErrorOutline;
};

const defaultProps = {
  platform: Platform.OS,
  lightColor: '#000000',
  darkColor: '#ffffff',
};

type IconProps = {
  name: IconNames,
  size?: number | string,
  lightColor?: string,
  darkColor?: string,
  platform?: PlatformOSType,
  style?: StyleProp<ViewStyle>,
} & typeof defaultProps;

/**
 * @param props
 * @constructor
 * @deprecated
 */
const LegacyIcon = (props: IconProps): ReactElement | null => {
  const {
    name,
    size,
    lightColor,
    darkColor,
    platform,
    style = undefined,
  } = props;

  const componentObject = getComponentObject(
    map.getByName(name),
    platform as PlatformOSType,
  );

  const color = useThemeColor({ light: lightColor, dark: darkColor });

  if (componentObject instanceof Map.IconSVG) {
    const { source: IconComponent, colorProperty, scale } = componentObject;
    const properties = {
      width: typeof size === 'string' ? size : unit((size || 24) * scale) || undefined,
      height: typeof size === 'string' ? size : unit((size || 24) * scale) || undefined,
      [colorProperty]: color,
    };

    return typeof style !== 'undefined'
      ? <View style={style}><IconComponent {...properties} /></View>
      : <IconComponent {...properties} />;
  }

  return null;
};

LegacyIcon.defaultProps = defaultProps;

export default memo(LegacyIcon);
