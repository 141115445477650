import {
  memo, useCallback, useMemo, useState,
} from 'react';
import {
  type GestureResponderEvent,
  Platform,
} from 'react-native';

import type { AuthorType, UserType } from 'app/entities';
import { guard } from 'utils';

import { useNavigate } from 'navigation/hooks';
import { useResponsive } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';
import * as searchStore from 'store/nodes/search';

import { controller as modal } from 'components/Modal2';
import CardContextMenu from 'components/CardContextMenu';

import MaterialCard from '../variants/MaterialCard';
import type { MaterialCardProps, MaterialCardEventsType } from '../variants/MaterialCard/types';

interface MaterialControllerProps extends Omit<MaterialCardProps, 'data' | keyof Omit<MaterialCardEventsType, 'onLayout' | 'onOptionsPress'>> {
  id: number,
  extraData?: Partial<MaterialCardProps['data']> | any,
}

const MaterialController = (props: MaterialControllerProps) => {
  const {
    style,
    radius,
    type,
    id,
    extraData,
    defaultLayout,
    forceLayout,
    hasMarkByIKI,
    hasToolbar,
    hasAuthor,
    hasDescription,
    hasInfo,
    hasTags,
    hasComments,
    hasWebLink,
    hasFooterQueue,
    hasMenuQueue,
    hasMenuComplete,
    hasMenuToCollection,
    hasMenuShare,
    hasMenuDislike,
    hasMenuPlaySummary,
    hasMenuTLDR,
    hasMenuShowRelated,
    hasMenuRemove,
    descriptionLimit,
    tagsLimit,
    authorSize,
    titleSize,
    descriptionSize,
    AuthorComponent,
    ExtraComponent,
    OverlayComponent,
    onLayout,
    onOptionsPress,
  } = props;

  const navigate = useNavigate();
  const dispatcher = useDispatcher();
  const responsive = useResponsive();

  const [isContextOpen, setContextOpen] = useState<boolean>(false);

  const sourceData = useSelector((state) => {
    if (type === 'RagMaterial') {
      return searchStore.selectors.dataById(id)(state);
    }
    return contentStore.selectors.dataById(id)(state);
  });

  const data = useMemo((): MaterialCardProps['data'] => {
    if (!sourceData) {
      return null;
    }
    return ({
      ...sourceData,
      ...extraData,
    });
  }, [sourceData, extraData]);

  const contextMenuOptions = useMemo(() => ({
    hasQueue: hasMenuQueue,
    hasComplete: hasMenuComplete,
    hasToCollection: hasMenuToCollection,
    hasShare: hasMenuShare && responsive.is.mobileL,
    hasLink: hasMenuShare && !responsive.is.mobileL,
    hasDislike: hasMenuDislike,
    hasPlaySummary: hasMenuPlaySummary,
    hasTLDR: hasMenuTLDR,
    hasShowRelated: hasMenuShowRelated,
    hasRemove: hasMenuRemove,
  }), [
    hasMenuQueue,
    hasMenuComplete,
    hasMenuToCollection,
    hasMenuShare,
    hasMenuDislike,
    hasMenuPlaySummary,
    hasMenuTLDR,
    hasMenuShowRelated,
    hasMenuRemove,
    responsive.is.mobileL,
  ]);

  const handleAuthorPress = useCallback((
    event: GestureResponderEvent,
    context: { cardId: number, author: AuthorType | UserType },
  ) => {
    const { author } = context;
    if (guard.isUser(author)) {
      navigate('Profile', {
        login: author.login,
      });
      return;
    }
    if (guard.isAuthor(author) && author.owner) {
      navigate('Profile', {
        login: author.owner.login,
      });
      return;
    }
    if (guard.isAuthor(author) && !author.owner) {
      navigate('Author', {
        id: author.id,
      });
    }
  }, []);

  const handleBodyPress = useCallback(() => {
    if (!data) {
      return;
    }
    const resourceId = id;
    const resourceType = data?.type?.toLowerCase?.();
    if (resourceType && /^https?:\/\//i.test(data?.originalUrl || '')) {
      navigate('Content', { resourceId, resourceType });
    }
  }, [data, id]);

  const handleTagPress = useCallback((event: GestureResponderEvent, context: { cardId: number, tag: string }) => {
    if (!context.tag) {
      return;
    }
    navigate('Ask', { tags: [context.tag] });
  }, []);

  const handleOptionsPress = useCallback((event: GestureResponderEvent) => {
    if (!id) {
      return;
    }
    if (onOptionsPress) {
      onOptionsPress(event, { cardId: id });
      return;
    }
    if (responsive.is.mobileL) {
      modal.menu.cardActions.open({
        callingTarget: event.currentTarget,
        resource: 'content',
        resourceId: id,
        options: contextMenuOptions,
      });
    } else {
      setContextOpen(true);
    }
  }, [id, onOptionsPress, responsive.is.mobileL, contextMenuOptions]);

  const handleOptionsClose = useCallback(() => {
    setContextOpen(false);
  }, []);

  const handleSourcePress = useCallback(() => {
    if (!data?.originalUrl) {
      return;
    }
    if (Platform.OS === 'web' && !!document) {
      const link = document.createElement('a');
      link.setAttribute('href', data?.originalUrl);
      link.setAttribute('target', '_blank');
      link.click();
    }
  }, [data?.originalUrl]);

  const handleCommentPress = useCallback(() => {
    if (!id) {
      return;
    }
    modal.popup.comments.open({
      resource: 'content',
      resourceId: id,
    });
  }, [id]);

  const handleBookmarkPress = useCallback(() => {
    if (!id) {
      return;
    }
    dispatcher.interaction.toggleBookmark('content', id);
  }, [id]);

  return (
    <MaterialCard
      style={style}
      type={type}
      radius={radius}
      data={data}
      defaultLayout={defaultLayout}
      forceLayout={forceLayout}
      hasMarkByIKI={hasMarkByIKI}
      hasToolbar={hasToolbar}
      hasAuthor={hasAuthor}
      hasDescription={hasDescription}
      hasInfo={hasInfo}
      hasTags={hasTags}
      hasComments={hasComments}
      hasWebLink={hasWebLink}
      hasFooterQueue={hasFooterQueue}
      descriptionLimit={descriptionLimit}
      tagsLimit={tagsLimit}
      authorSize={authorSize}
      titleSize={titleSize}
      descriptionSize={descriptionSize}
      AuthorComponent={AuthorComponent}
      ExtraComponent={ExtraComponent}
      OverlayComponent={(
        <>
          {OverlayComponent}
          {!responsive.is.mobileL && (
            <CardContextMenu
              resourceType="material"
              resourceId={id}
              isOpen={isContextOpen}
              hasQueue={contextMenuOptions.hasQueue}
              hasComplete={contextMenuOptions.hasComplete}
              hasToCollection={contextMenuOptions.hasToCollection}
              hasShare={contextMenuOptions.hasShare}
              hasLink={contextMenuOptions.hasLink}
              hasDislike={contextMenuOptions.hasDislike}
              hasPlaySummary={contextMenuOptions.hasPlaySummary}
              hasTLDR={contextMenuOptions.hasTLDR}
              hasShowRelated={contextMenuOptions.hasShowRelated}
              hasRemove={contextMenuOptions.hasRemove}
              onActionPress={handleOptionsClose}
              onOutsidePress={handleOptionsClose}
            />
          )}
        </>
      )}
      onLayout={onLayout}
      onAuthorPress={handleAuthorPress}
      onBodyPress={handleBodyPress}
      onOptionsPress={handleOptionsPress}
      onSourcePress={handleSourcePress}
      onCommentsPress={handleCommentPress}
      onBookmarkPress={handleBookmarkPress}
    />
  );
};

export default memo(MaterialController);
