import {
  type PropsWithChildren, type ReactNode, type CSSProperties,
  useCallback, useMemo, useRef, useEffect,
} from 'react';
import {
  type StyleProp, type GestureResponderEvent, type ViewStyle,
  TouchableOpacity, StyleSheet, Platform,
} from 'react-native';

import { useThemeColor } from 'hooks';

import { unit } from 'utils';
import Lottie from 'components/Lottie';
import { View } from 'components/Themed';

import { useActiveOpacity, useRegularOpacity, useButtonStyles } from './utils';

const defaultProps = {
  type: 'button' as 'button' | 'button-icon',
  lightColor: '#515151',
  darkColor: '#E6E4EA',
  radius: 'half' as 'none' | 'half' | 'full',
  variant: 'contained',
  lightIndicatorColor: '#ffffff',
  darkIndicatorColor: '#ffffff',
  isDisabled: false,
  isLoading: false,
};

type ButtonProps<CT> = {
  style?: StyleProp<ViewStyle>,
  type?: 'button' | 'button-icon',
  lightColor?: string,
  darkColor?: string,
  lightIndicatorColor?: string,
  darkIndicatorColor?: string,
  width?: 'full' | number,
  height?: number,
  radius?: 'none' | 'half' | 'full',
  variant?: 'contained' | 'outlined' | 'text',
  startIcon?: ReactNode,
  endIcon?: ReactNode,
  context?: CT | null,
  isDisabled?: boolean,
  isLoading?: boolean,
  onPress?: (event: GestureResponderEvent, context: CT) => void,
  onMouseEnter?: (event: MouseEvent, context: CT) => void,
  onMouseLeave?: (event: MouseEvent, context: CT) => void,
} & typeof defaultProps;

const Button = <CT, >(props: PropsWithChildren<ButtonProps<CT> & typeof defaultProps>) => {
  const {
    style,
    type,
    lightColor,
    darkColor,
    lightIndicatorColor,
    darkIndicatorColor,
    width,
    height,
    radius,
    variant,
    startIcon,
    endIcon,
    context,
    isDisabled,
    isLoading,
    onPress,
    onMouseEnter,
    onMouseLeave,
    children,
  } = props;
  const containerRef = useRef<HTMLDivElement | any>();

  const handleMouseEnter = useCallback((event: MouseEvent) => {
    onMouseEnter?.(event, context as CT);
  }, [context]);

  const handleMouseLeave = useCallback((event: MouseEvent) => {
    onMouseLeave?.(event, context as CT);
  }, [context]);

  useEffect(() => {
    if (Platform.OS !== 'web' || !containerRef?.current || !(containerRef?.current instanceof HTMLDivElement)) {
      return undefined;
    }
    const { current: container } = containerRef;
    container.addEventListener('mouseenter', handleMouseEnter);
    container.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      container.removeEventListener('mouseenter', handleMouseEnter);
      container.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [handleMouseEnter, handleMouseLeave]);

  const handlePress = useCallback((event: GestureResponderEvent) => {
    if (isDisabled) {
      return;
    }
    onPress?.(event, context as CT);
  }, [onPress, isDisabled, context]);

  const indicatorColor = useThemeColor({ light: lightIndicatorColor, dark: darkIndicatorColor });
  const activeOpacity = useActiveOpacity(isLoading, isDisabled);

  const regularOpacity = useRegularOpacity(isLoading, isDisabled);

  const baseStyles = useButtonStyles(type, { light: lightColor, dark: darkColor }, radius, variant, width, height);

  const buttonStyles = useMemo(() => {
    let result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(baseStyles),
    };
    if (style) {
      result = {
        position: 'relative',
        ...result,
        ...StyleSheet.flatten(style),
      };
    }
    result.opacity = regularOpacity;
    if (Platform.OS === 'web') {
      (result as CSSProperties).transition = 'opacity 300ms ease';
    }
    if (Platform.OS === 'web' && (isDisabled || isLoading)) {
      (result as CSSProperties).pointerEvents = 'none';
    }
    return result;
  }, [baseStyles, style, regularOpacity, isDisabled, isLoading]);

  const contentStyles = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    };
    if (Platform.OS === 'web') {
      (result as CSSProperties).transition = 'opacity 200ms ease';
    }
    if (isLoading) {
      result.opacity = 0.25;
    }
    return result;
  }, [isLoading]);

  const loadingStyles = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0,
    };
    if (Platform.OS === 'web') {
      (result as CSSProperties).transition = 'opacity 200ms ease';
    }
    if (isLoading) {
      result.opacity = 1;
    }
    return result;
  }, [isLoading]);

  return (
    <TouchableOpacity
      ref={containerRef}
      onPress={!isLoading ? handlePress : undefined}
      activeOpacity={activeOpacity}
      style={buttonStyles}
    >
      <View style={contentStyles}>
        {Boolean(startIcon) && <View style={styles.startIcon}>{startIcon}</View>}
        {children}
        {Boolean(endIcon) && <View style={styles.endIcon}>{endIcon}</View>}
      </View>
      <View style={loadingStyles} pointerEvents="none">
        <Lottie name="LoadingCircle" loop color={indicatorColor} />
      </View>
      {/* @todo реализовать индикатор загрузки для других вариантов кнопок */}
    </TouchableOpacity>
  );
};

Button.defaultProps = defaultProps;

const styles = StyleSheet.create({
  startIcon: {
    paddingRight: unit(10),
    alignItems: 'flex-start',
    flex: 1,
  },
  endIcon: {
    paddingLeft: unit(10),
    alignItems: 'flex-end',
    flex: 1,
  },
});

export default Button;
