import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

import type { Resource } from '../types';

const cacheKeyCreator = (state: DefaultRootState, resources: Resource[]) => `typing-${JSON.stringify(resources)}`;

const isAnswerTypingSelector = createCachedSelector(
  (state: DefaultRootState) => state.copilot.typingMap,
  (_: DefaultRootState, resources: Resource[]) => JSON.stringify(resources),
  (typingMap, resourcesJSON) => {
    const resources = JSON.parse(resourcesJSON) as Resource[];
    let result = false;
    Object.values(typingMap).forEach((itemAttribute) => {
      if (result) {
        return;
      }
      resources.forEach((resource) => {
        if (result) {
          return;
        }
        result = resource.type === itemAttribute.resourceType && (resource.id || null) === itemAttribute.resourceId;
      });
    });
    return result;
  },
)(cacheKeyCreator);

export default (resources: Resource[]) => (state: DefaultRootState) => {
  return isAnswerTypingSelector(state, resources);
};
