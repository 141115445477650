import {
  type ReactElement,
  memo, useContext,
} from 'react';
import {
  Platform,
} from 'react-native';

import { useRoute } from 'navigation/hooks';

import { useResponsive } from 'hooks';

import Plus from 'components/Plus';

import useSetDesktopBarConfig from './hooks/useSetDesktopBarConfig';

import { Context } from './context';

import LeftBar from './bars/LeftBar';
import TopBar from './bars/TopBar';
import MobileMenuBar from './bars/MobileMenuBar';
import MobileTopBar from './bars/MobileTopBar';
import type { Config } from './types';

import Provider from './Provider';

const UIBar = (): ReactElement | null => {
  const route = useRoute();
  const responsive = useResponsive();

  const context = useContext<Config>(Context);

  if (Platform.OS !== 'web' || /^Auth\/.*/.test(route?.name || '')) {
    return null;
  }

  if (responsive.isLessThen.tablet) {
    return (
      <>
        <MobileTopBar />
        <MobileMenuBar />
      </>
    );
  }

  return (
    <>
      <TopBar
        hasSearchPanel={context.hasSearchPanel || false}
        onSearchChange={context.onSearchChange}
        headerLeftPanel={context.headerLeftPanel}
        headerMiddlePanel={context.headerMiddlePanel}
        headerRightPanel={context.headerRightPanel}
      />
      <LeftBar />
      <Plus variant="dropdown" hasSummary hasNewCollectionButton />
    </>
  );
};

export {
  useSetDesktopBarConfig,
  Provider as UIBarProvider,
};

export default memo(UIBar);
