import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as noteStore from 'store/nodes/note';
import { MacScrollbar } from 'mac-scrollbar';
import Box from '@mui/joy/Box';
import { useColorScheme } from 'hooks';

import { useDispatcher } from 'store/utils/redux/hooks';

import NewNote from './components/NewNote';
import ItemNote from './components/ItemNote';

type NotesProps = {
  resourceType: 'collection' | 'material',
  resourceId: number,
}

const Notes = (props: NotesProps) => {
  const {
    resourceType,
    resourceId,
  } = props;
  const dispatcher = useDispatcher();
  const colorScheme = useColorScheme();

  const noteIds = useSelector(noteStore.selectors.listByResource(resourceType, resourceId));
  const isLoading = useSelector(noteStore.selectors.isLoading({ resourceType, resourceId }));

  const handleAdd = useCallback((text: string) => {
    dispatcher.note.add({
      text,
      relation: { resourceType, resourceId },
    });
  }, [resourceType, resourceId]);

  const handleRemove = useCallback((id: number, options?: { force?: boolean }) => {
    dispatcher.note.remove({
      id,
      force: options?.force,
    });
  }, []);

  const handleSave = useCallback((id: number, text: string) => {
    dispatcher.note.update({
      id,
      text,
    });
  }, []);

  return (
    <MacScrollbar skin={colorScheme} style={{ position: 'relative' }}>
      <Box
        position="sticky"
        top={0}
        left={2}
        right={0}
        height={4}
        ml={1}
        zIndex={2}
        bgcolor={(theme) => theme.palette.background.body}
      />
      <Box display="flex" flexDirection="column" gap={0.5} my={2}>
        <NewNote onAdd={handleAdd} />
        {noteIds.map((id) => (
          <ItemNote key={id} id={id} onSave={handleSave} onRemove={handleRemove} />
        ))}
      </Box>
    </MacScrollbar>
  );
};

export default memo(Notes);
