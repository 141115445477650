import {
  type ForwardedRef, type RefObject,
  useImperativeHandle,
} from 'react';
import {
  type LexicalEditor,
  $getRoot, $setSelection, CLEAR_HISTORY_COMMAND,
} from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';

export type EditorInputMethodsType = {
  clear: (options: { focus?: boolean }) => void,
  set: (value: string) => void,
  focus: () => void,
};

const useMethods = (editorRef: RefObject<LexicalEditor>, forwardedRef: ForwardedRef<EditorInputMethodsType>) => {
  useImperativeHandle(forwardedRef, () => ({
    clear: ({ focus }) => {
      if (!editorRef.current) {
        return;
      }
      editorRef.current.update(() => {
        $getRoot().clear();
      });
      if (focus) {
        setTimeout(() => {
          editorRef.current?.focus();
        }, 10);
      }
    },
    set: (value: string) => {
      if (!editorRef.current) {
        return;
      }
      editorRef.current.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, 'text/html');
        const nodes = $generateNodesFromDOM(editorRef.current as LexicalEditor, dom);
        const root = $getRoot();
        root.getChildren().forEach((child) => {
          child.remove();
        });
        $setSelection(null);
        root.append(...nodes);
        editorRef.current?.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
      });
    },
    focus: () => {
      if (!editorRef.current) {
        return;
      }
      editorRef.current?.focus();
    },
  }));
};

export default useMethods;
