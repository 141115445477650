import {
  type CSSProperties,
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import {
  Platform, StyleSheet, View,
} from 'react-native';
import { useTheme } from '@mui/joy';

import { unit, guard } from 'utils';
import { useCardContextHandlers, useThemeColor } from 'hooks';

import * as contentStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import * as noteStore from 'store/nodes/note';
import * as userStore from 'store/nodes/user';
import { useRoute } from 'navigation/hooks';

import { GradientView } from 'components/Themed';

import Item from './elements/Item';

const defaultProps = {
  hasQueue: false,
  hasComplete: false,
  hasToCollection: false,
  hasShare: false,
  hasLink: false,
  hasDislike: false,
  hasPlaySummary: false,
  hasTLDR: false,
  hasShowRelated: false,
  hasRemove: false,
};

type CardContextMenuProps = {
  resourceType: 'material' | 'collection' | 'wireframe' | 'note',
  resourceId: number,
  isOpen: boolean,
  hasQueue?: boolean,
  hasComplete?: boolean,
  hasToCollection?: boolean,
  hasShare?: boolean,
  hasLink?: boolean,
  hasDislike?: boolean,
  hasPlaySummary?: boolean,
  hasTLDR?: boolean,
  hasShowRelated?: boolean,
  hasRemove?: boolean,
  onActionPress?: () => void,
  onOutsidePress?: () => void,
} & typeof defaultProps;

const CardContextMenu = (props: CardContextMenuProps) => {
  const {
    resourceType,
    resourceId,
    isOpen,
    hasQueue,
    hasComplete,
    hasToCollection,
    hasShare,
    hasLink,
    hasDislike,
    hasPlaySummary,
    hasTLDR,
    hasShowRelated,
    hasRemove,
    onActionPress,
    onOutsidePress,
  } = props;

  const theme = useTheme();
  const route = useRoute();
  const containerRef = useRef<HTMLDivElement | any>();
  const wrapperShadowColor = useThemeColor({ light: '#00000020', dark: '#00000099' });

  const userProfile = useSelector(userStore.selectors.getMy);

  const [isMount, setMount] = useState<boolean>(false);
  const [isShow, setShow] = useState<boolean>(false);

  const data = useSelector((state: DefaultRootState) => {
    if (resourceType === 'material') {
      return contentStore.selectors.dataById(resourceId)(state);
    }
    if (resourceType === 'collection') {
      return collectionStore.selectors.dataById(resourceId)(state);
    }
    if (resourceType === 'note') {
      return noteStore.selectors.dataById(resourceId)(state);
    }
    return null;
  });

  const transition = 200;

  const {
    handleQueue,
    handleComplete,
    handleToCollection,
    handleShare,
    handleLink,
    handleDislike,
    handlePlaySummary,
    handleTLDR,
    handleShowRelated,
    handleRemove,
  } = useCardContextHandlers(data);

  const handleOutsidePress = useCallback((event: MouseEvent) => {
    const { target } = event;
    event?.preventDefault?.();
    const isOutside = !(target as unknown as HTMLDivElement).closest('[data-type="card-context-menu"]');
    if (isOutside) {
      event?.stopPropagation?.();
      onOutsidePress?.();
      return;
    }
    setTimeout(() => {
      onActionPress?.();
    }, 100);
  }, [onOutsidePress]);

  useEffect(() => {
    if (isOpen && Platform.OS !== 'web') {
      setMount(true);
      setShow(true);
    }
    if (!isOpen && Platform.OS !== 'web') {
      setMount(false);
      setShow(false);
    }
    if (isOpen && Platform.OS === 'web') {
      setMount(true);
      setTimeout(() => { setShow(true); }, 10);
    }
    if (!isOpen && Platform.OS === 'web') {
      setShow(false);
      setTimeout(() => { setMount(false); }, transition);
    }
  }, [isOpen]);

  useEffect(() => {
    const { current: container } = containerRef;
    if (!guard.isHTMLDivElement(container)) {
      return undefined;
    }
    if (isMount) {
      container.setAttribute('data-type', 'card-context-menu');
      document.body.addEventListener('click', handleOutsidePress, true);
    }
    return () => {
      if (isMount) {
        document.body.removeEventListener('click', handleOutsidePress, true);
      }
    };
  }, [isMount]);

  const contextMenuStyle = useMemo(() => {
    const result = {
      ...StyleSheet.flatten(styles.contextMenu),
    };
    if (isShow) {
      result.opacity = 1;
    }
    if (Platform.OS === 'web') {
      (result as CSSProperties).transition = `opacity ${transition}ms ease`;
      (result as CSSProperties).boxShadow = theme.shadow.md;
    }
    return result;
  }, [isShow, wrapperShadowColor, theme]);

  if (resourceType === 'wireframe') {
    return null;
  }

  if (!isMount) {
    return null;
  }

  return (
    <GradientView
      style={contextMenuStyle}
      lightColors={[theme.palette.background.level1, theme.palette.background.level1]}
      darkColors={[theme.palette.background.level1, theme.palette.background.level1]}
      pointerEvents="auto"
    >
      <View ref={containerRef} style={styles.container}>
        {hasQueue && (
          <Item
            isShow
            iconName={!data?.isBookmarked ? 'BookmarkOutline28' : 'BookmarkFill28'}
            text={!data?.isBookmarked ? 'In queue' : 'Queue'}
            onPress={handleQueue}
          />
        )}
        {hasComplete && (
          <Item
            isShow
            iconName={!data?.isCompleted ? 'DoneFill28' : 'DoneAllFill28'}
            text={!data?.isCompleted ? 'Complete' : 'Completed'}
            onPress={handleComplete}
          />
        )}
        {hasToCollection && (
          <Item
            isShow
            iconName="EditOutline28"
            text="To collection"
            onPress={handleToCollection}
          />
        )}
        {hasShare && (
          <Item
            isShow
            iconName="ShareOutline28"
            text="Share"
            onPress={handleShare}
          />
        )}
        {hasLink && (
          <Item
            isShow
            iconName="LinkInclineOutline28"
            text="Copy link"
            onPress={handleLink}
          />
        )}
        {hasDislike && (
          <Item
            isShow
            iconName={data?.isDisliked ? 'ShowOutline28' : 'HideOutline28'}
            text={data?.isDisliked ? 'Show' : 'Hide'}
            onPress={handleDislike}
          />
        )}
        {hasPlaySummary && (
          <Item
            isShow
            iconName="Play"
            text="Play audio summary"
            onPress={handlePlaySummary}
          />
        )}
        {hasTLDR && (
          <Item
            isShow
            iconName="IkiCoPilot"
            text="TLDR with GPT-4"
            onPress={handleTLDR}
          />
        )}
        {hasShowRelated && (
          <Item
            isShow
            iconName="RefreshCCW"
            text="Show related"
            onPress={handleShowRelated}
          />
        )}
        {hasRemove && (
          <Item
            isShow
            iconName="DeleteOutline28"
            iconLightColor="#db3327"
            iconDarkColor="#db3327"
            text="Remove"
            onPress={handleRemove}
          />
        )}
      </View>
    </GradientView>
  );
};

CardContextMenu.defaultProps = defaultProps;

const styles = StyleSheet.create({
  contextMenu: {
    position: 'absolute',
    top: unit(10),
    right: unit(16),
    opacity: 0,
    borderRadius: unit(8),
    zIndex: 100,
  },
  container: {
    position: 'relative',
    cursor: 'default',
    paddingHorizontal: unit(8),
    paddingVertical: unit(16),
  },
  show: {
    opacity: 1,
  },
  icon: {
    marginRight: unit(12),
  },
});

export default memo(CardContextMenu);
