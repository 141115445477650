import { useMemo } from 'react';
import chunk from 'lodash.chunk';

import { useBreakpoint } from 'hooks';

import type { FeedListProps } from '../types';

const usePrepareData = (data: FeedListProps['data'], columnsScheme: FeedListProps['columnsScheme']): {
  rows: FeedListProps['data'][],
  breakpoint: 'xs' | 'sm' | 'md' | 'lg',
  columnCount: number,
} => {
  const breakpoint = useBreakpoint();

  return useMemo(() => {
    let columnCount = 1;
    const points = ['xs', 'sm', 'md', 'lg'];
    for (let i = 0; i < points.length; i += 1) {
      const point = points[i] as keyof FeedListProps['columnsScheme'];
      if (columnsScheme?.[point]) {
        columnCount = columnsScheme?.[point];
      }
      if (point === breakpoint) {
        break;
      }
    }

    let rows = [...data];

    if (rows.length % columnCount !== 0 && columnCount > 1) {
      rows.push(...new Array(columnCount - (rows.length % columnCount)).fill({ type: 'fill' }));
    }

    rows = chunk(rows, columnCount);

    return {
      rows,
      breakpoint,
      columnCount,
    };
  }, [breakpoint, columnsScheme, data]);
};

export default usePrepareData;
