import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import type { SourceMaterialType } from 'app/entities';

const selectorDataById = createCachedSelector(
  (state: DefaultRootState, resourceId: number | null | undefined) => (!resourceId ? null : state.content.data[resourceId]),
  (material): SourceMaterialType | null => {
    if (!material) {
      return null;
    }
    return {
      id: material.id,
      authorUrls: material.authors?.map((author) => author.originalUrl || ''),
      type: material.type,
      title: material.title,
      description: material.description,
      note: null,
      tags: material.tags,
      sectionId: material.sectionId,
      sourceId: material.sourceId,
      originalUrl: material.originalUrl,
      private: material.private,
      isSaved: true,
      image: material.image?.url,
      level: material.level,
      duration: material.duration,
      publishedAt: null,
    };
  },
)(
  (_state_, resourceId) => resourceId,
);

export const sourceDataById = (resourceId?: number | null) => (state: DefaultRootState): SourceMaterialType | null => {
  return selectorDataById(state, resourceId);
};
