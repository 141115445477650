import { createReducer } from '@reduxjs/toolkit';

import { guard } from 'utils';

import type { UserStore } from './types';
import * as actions from './actions';

const initialState: UserStore = {
  my: null,
  data: {},
  idMap: {},
  meta: {
    loadingMap: {},
    subscriptingMap: {},
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.loadProfile, (state, action) => {
    state.meta.loadingMap[action.payload.login] = true;
  })
  .addCase(actions.loadProfileOptimistic, (state, action) => {
    state.meta.loadingMap[action.payload.login] = true;
  })
  .addCase(actions.loadProfileDone, (state, action) => {
    delete state.meta.loadingMap[action.payload.login];
  })
  .addCase(actions.updateProperty, (state, action) => {
    const { name, value } = action.payload;
    let target: string | null = null;
    if (typeof action.payload === 'string') {
      target = action.payload;
    }
    if (target === 'my' && state.my) {
      target = state.my.login;
    }
    if (typeof action.payload === 'number') {
      target = state.idMap[action.payload];
    }
    if (!target || !state.data[target]) {
      return;
    }
    state.data[target][name] = value as never;
  })
  .addCase(actions.setProfile, (state, action) => {
    if (!action.payload) {
      return;
    }
    const { payload } = action;
    const list = guard.isUserArray(payload) ? payload : [payload];
    list.forEach((data) => {
      state.data[data.login] = {
        ...state.data[data.login],
        ...data,
      };
      state.idMap[data.id] = data.login;
      if (data.isMy) {
        state.my = {
          id: data?.id,
          login: data?.login,
        };
      }
    });
  })
  .addCase(actions.setCollections, (state, action) => {
    const login = (action.payload.login === 'my' ? state.my?.login : action.payload.login) ?? null;
    const user = state.data[login];
    if (guard.isUser(user)) {
      user.playlistIds = !Array.isArray(action.payload.collections)
        ? [action.payload.collections.id]
        : action.payload.collections.map((item) => item.id);
    }
  })
  .addCase(actions.addedCollectionPermission, (state, action) => {
    const { collectionId } = action.payload;
    const login = state.my?.login ?? null;
    if (!login) {
      return;
    }
    state.data[login].playlistIds.unshift(collectionId);
  })
  .addCase(actions.removedCollectionPermission, (state, action) => {
    const { collectionId } = action.payload;
    const login = state.my?.login ?? null;
    if (!login) {
      return;
    }
    const index = state.data[login].playlistIds.findIndex((item) => item === collectionId);
    if (index > -1) {
      state.data[login].playlistIds.splice(index, 1);
    }
  })
  .addCase(actions.subscribe, (state, action) => {
    state.meta.subscriptingMap[action.payload.login] = true;
  })
  .addCase(actions.unsubscribe, (state, action) => {
    state.meta.subscriptingMap[action.payload.login] = true;
  })
  .addCase(actions.subscribeSuccess, (state, action) => {
    const { login } = action.payload;
    const userData = state.data[login];
    if (!userData) {
      return;
    }
    userData.isSubscribed = true;
    if (typeof userData.subscribersCount === 'undefined' || userData.subscribersCount === null) {
      userData.subscribersCount = 0;
    }
    userData.subscribersCount += 1;
  })
  .addCase(actions.subscribeDone, (state, action) => {
    delete state.meta.subscriptingMap[action.payload.login];
  })
  .addCase(actions.unsubscribeSuccess, (state, action) => {
    const { login } = action.payload;
    const userData = state.data[login];
    if (!userData) {
      return;
    }
    userData.isSubscribed = false;
    if (typeof userData.subscribersCount === 'undefined' || userData.subscribersCount === null) {
      userData.subscribersCount = 0;
    }
    if (userData.subscribersCount > 0) {
      userData.subscribersCount -= 1;
    }
  })
  .addCase(actions.unsubscribeDone, (state, action) => {
    delete state.meta.subscriptingMap[action.payload.login];
  })
  .addCase(actions.logOut, (state) => {
    state.my = null;
  }));
