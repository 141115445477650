import { createReducer } from '@reduxjs/toolkit';

import type { SearchUserStore } from './types';
import * as actions from './actions';

const initialState: SearchUserStore = {
  list: {
    sequence: [],
    map: {},
    paginationInfo: {
      hasNext: false,
      nextCursor: null,
    },
  },
  meta: {
    isFirstPageLoading: false,
    isFirstPageLoaded: false,
    isNextPageLoading: false,
    isNextPageLoaded: false,
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.clear, (state) => {
    state.list.sequence = [];
    state.list.map = {};
    state.list.paginationInfo = {
      hasNext: false,
      nextCursor: null,
    };
  })
  .addCase(actions.loadNextPage, (state, action) => {
    if (action?.meta?.reload) {
      state.meta.isFirstPageLoading = true;
    } else {
      state.meta.isNextPageLoading = true;
    }
  })
  .addCase(actions.loadNextPageDone, (state, action) => {
    const { reload } = action.payload;
    state.meta.isFirstPageLoading = false;
    state.meta.isNextPageLoading = false;
    if (reload) {
      state.meta.isFirstPageLoaded = true;
    } else {
      state.meta.isNextPageLoaded = true;
    }
  })
  .addCase(actions.addPage, (state, action) => {
    const { payload, meta } = action;
    if (meta.reload) {
      state.list.sequence = [];
      state.list.map = {};
    }
    payload.items.forEach((item) => {
      if (item.id in state.list.map) {
        return;
      }
      state.list.sequence.push({
        id: item.id,
      });
      state.list.map[item.id] = state.list.sequence.length - 1;
    });
    state.list.paginationInfo.nextCursor = payload.paginationInfo.nextCursor;
    state.list.paginationInfo.hasNext = payload.paginationInfo.hasNext;
  }));
