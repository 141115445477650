import { memo, useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { useNavigate } from 'navigation/hooks';

import StyleSheetToCss from 'utils/styleSheetToCss';
import { useThemeColor } from 'hooks';
import Picture from 'components/Picture';

import { useAvatarText } from '../hooks/usePeparedInitials';
import type { PersonProps } from '../types';

const Person = (props: PersonProps) => {
  const {
    style,
    id,
    login,
    type,
    name,
    surname,
    photo,
    profession,
  } = props;

  const [symbolAvatar, setSymbolAvatar] = useState(!photo);
  const navigate = useNavigate();

  const handlePress = useCallback(() => {
    if (type === 'user' && login) {
      navigate('Profile', { login });
    }
    if (type === 'author' && id) {
      navigate('Author', { id });
    }
  }, [type, login, id]);

  const initials = useAvatarText(`${name} ${surname}`);

  const titleColor = useThemeColor({ light: '#000000', dark: '#ffffff' });
  const professionColor = useThemeColor({ light: '#515151', dark: '#9A99A2' });
  const backgroundColor = useThemeColor({ light: '#a6a6a6', dark: '#282828' });

  const classes = useStyle({
    titleColor,
    professionColor,
  });

  const handleErrorImageLoad = useCallback(() => {
    setSymbolAvatar(true);
  }, []);

  return (
    <div className={classes.Person} style={StyleSheetToCss(style)} onClick={handlePress}>
      {symbolAvatar && (
        <div className={classes.initialsContainer} style={{ backgroundColor }}>
          <p className={classes.initials}>{initials}</p>
        </div>
      )}
      {!symbolAvatar && (
        <Picture onError={handleErrorImageLoad} source={photo} size="medium" aspectRatio={1} width={96} height={96} radius={42} />
      )}
      <p className={classes.name}>{`${name} ${surname}`}</p>
      {profession && <p className={classes.profession}>{profession}</p>}
    </div>
  );
};

const useStyle = createUseStyles<
  'Person' | 'initials' | 'name' | 'profession' | 'initialsContainer' | 'shortName',
  {
    titleColor?: string,
    professionColor?: string,
    profession?: string | null,
  }
>({
  Person: {
    width: '6em',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  initials: {
    color: '#ffffff',
    fontSize: '2em',
  },
  name: {
    marginTop: '0.625em',
    marginBottom: '0.3125em',
    paddingBottom: '0.25rem',
    lineHeight: '1em',
    fontSize: '1.1em',
    color: (props) => props.titleColor,
    display: '-webkit-box',
    lineClamp: 2,
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    boxOrient: 'vertical',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  shortName: {
    whiteSpace: 'nowrap',
  },
  profession: {
    lineHeight: '1.2em',
    fontSize: '0.6875em',
    marginTop: '0',
    color: (props) => props.professionColor,
  },
  initialsContainer: {
    width: '6em',
    height: '6em',
    borderRadius: '2.6250em',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
});

export default memo(Person);
