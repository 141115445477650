import { createAction } from '@reduxjs/toolkit';
import camelcaseKeys from 'camelcase-keys';

import type { StateType } from '../types';

export const subscriptionPaymentIntentCreated = createAction('socket/subscriptionPaymentIntentCreated', (
  payload: StateType,
) => ({
  payload: {
    ...camelcaseKeys<typeof payload>(payload, { deep: true }),
    plan: `${payload.plan || 0}`,
  },
}));

export const subscriptionPaymentIntentSucceeded = createAction('socket/subscriptionPaymentIntentSucceeded', (
  payload: StateType,
) => ({
  payload: {
    ...camelcaseKeys<typeof payload>(payload, { deep: true }),
    plan: `${payload.plan || 0}`,
  },
}));

export const customerSubscriptionUpdated = createAction('socket/customerSubscriptionUpdated', (
  payload: StateType,
) => ({
  payload: {
    ...camelcaseKeys<typeof payload>(payload, { deep: true }),
    plan: `${payload.plan || 0}`,
  },
}));

export const customerSubscriptionDeleted = createAction('socket/customerSubscriptionDeleted', (
  payload: StateType,
) => ({
  payload: {
    ...camelcaseKeys<typeof payload>(payload, { deep: true }),
    plan: `${payload.plan || 0}`,
  },
}));
