import {
  Fragment, memo, useCallback, useEffect, useRef,
} from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import * as copilotStore from 'store/nodes/copilot';
import type { Resource } from 'store/nodes/copilot/types';

import Unauthorized from '../ui/Unauthorized';
import Question from '../ui/Question';
import Answer from '../ui/Answer';

type ChatItemsProps = {
  resources: Resource[],
  onMount?: () => void,
  onUnauthorizedMount?: () => void,
  onQuestionMount?: () => void,
  onAnswerMount?: () => void,
  onAnswerSave?: (requestId: string) => void,
  onAnswerReload?: (requestId: string) => void,
  onAnswerAppend?: () => void,
  onAnswerDone?: () => void,
}

const ChatItems = (props: ChatItemsProps) => {
  const {
    resources,
    onMount,
    onUnauthorizedMount,
    onQuestionMount,
    onAnswerMount,
    onAnswerSave,
    onAnswerReload,
    onAnswerAppend,
    onAnswerDone,
  } = props;

  const mountRef = useRef(false);
  const sequence = useSelector(copilotStore.selectors.sequence(resources));

  useEffect(() => {
    const timeout = setTimeout(() => {
      mountRef.current = true;
      onMount?.();
    }, 0);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [onMount]);

  const handleQuestionMount = useCallback(() => {
    if (!mountRef.current) {
      return;
    }
    onQuestionMount?.();
  }, [onQuestionMount]);

  const handleUnauthorizedMount = useCallback(() => {
    if (!mountRef.current) {
      return;
    }
    onUnauthorizedMount?.();
  }, [onUnauthorizedMount]);

  const handleAnswerMount = useCallback(() => {
    if (!mountRef.current) {
      return;
    }
    onAnswerMount?.();
  }, [onAnswerMount]);

  const handleAnswerAppend = useCallback(() => {
    if (!mountRef.current) {
      return;
    }
    onAnswerAppend?.();
  }, [onAnswerAppend]);

  const handleAnswerDone = useCallback(() => {
    if (!mountRef.current) {
      return;
    }
    onAnswerDone?.();
  }, [onAnswerDone]);

  return (
    <>
      {sequence.map((item, key) => (
        <Fragment key={item.id}>
          {item.attribute.type === 'unauthorized' && (
            <Unauthorized
              id={item.id}
              attribute={item.attribute}
              onMount={handleUnauthorizedMount}
            />
          )}
          {item.attribute.type === 'question' && (
            <Question
              id={item.id}
              attribute={item.attribute}
              onMount={handleQuestionMount}
            />
          )}
          {item.attribute.type === 'answer' && (
            <Answer
              id={item.id}
              attribute={item.attribute}
              onMount={handleAnswerMount}
              onAppend={handleAnswerAppend}
              onDone={handleAnswerDone}
              onSave={onAnswerSave}
              onReload={key === sequence.length - 1 ? onAnswerReload : undefined}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};

export default memo(ChatItems);
