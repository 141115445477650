import { createAction } from '@reduxjs/toolkit';
import type { ParamListBase } from '@react-navigation/native';

import type { UserInput } from 'app/entities';

import { prepareActionDone } from 'store/utils/saga/actions';

export const updateData = createAction('PROFILE/UPDATE_DATA', (
  data: Partial<UserInput>,
  successNavigate?: keyof ParamListBase,
) => ({
  payload: {
    data,
    successNavigate,
  },
}));

export const updateDataDone = createAction('PROFILE/UPDATE_DATA_DONE', prepareActionDone);

export const deleteAccount = createAction('PROFILE/DELETE_ACCOUNT');
