import {
  type SagaReturnType,
  takeEvery, cancel, put,
} from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';
import type { NoteType } from 'app/entities';

import * as noteStore from 'store/nodes/note';

import Alert from 'components/Alert';
import network from 'lib/network';
import * as materialStore from 'store/nodes/content';
import { actions } from '../slice';
import * as utils from '../utils';

export const config = {
  action: actions.update.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.update>) {
  const {
    text, id,
  } = action.payload;

  const relation = yield* select(noteStore.selectors.resourceByNoteId(id));

  let url = '';
  if (relation?.resourceType === 'material') {
    url = `/content/${relation.resourceId}/note/${id}`;
  }
  if (relation?.resourceType === 'collection') {
    url = `/playlists/${relation.resourceId}/note/${id}`;
  }

  if (!url) {
    yield cancel(); return;
  }

  const { data, errors } = yield* call(() => (
    network.request <NoteType>(url).body({ text }).post()
  ));

  if (!data || errors) {
    Alert.error('Error creating note');
    yield cancel(); return;
  }
  const item = {
    data: yield* utils.prepareNote(data),
    ...(relation && {
      relation: {
        resourceType: relation.resourceType,
        resourceId: relation.resourceId,
      },
    }),
  };

  yield put(actions.setItem(item));

  if (relation?.resourceId) {
    yield put(materialStore.actions.loadById(relation.resourceId));
  }
}
