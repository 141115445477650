import { delay, put, takeLatest } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';
import * as libraryStore from 'store/nodes/library';
import * as interactionStore from 'store/nodes/interaction';

import * as actions from '../actions';

export const config = {
  action: [
    libraryStore.actions.addPage.type,
    libraryStore.actions.prependToSequence.type,
    libraryStore.actions.removeFromSequence.type,
    interactionStore.actions.toggleBookmark.type,
    interactionStore.actions.toggleLike.type,
    interactionStore.actions.toggleDislike.type,
  ],
  method: takeLatest,
};

export function* func() {
  yield delay(10);
  const librarySequence = yield* select((state) => state.library.list.sequence);
  yield put(actions.updateProperty({ target: 'my', name: 'isLibraryEmpty', value: librarySequence.length === 0 }));
}
