/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
import AuthStart from 'screens/auth/Start';
import AuthSignIn from 'screens/auth/SignIn';
import AuthSignUp from 'screens/auth/SignUp';
import AuthPasswordReset from 'screens/auth/PasswordReset';
import AuthStep1 from 'screens/auth/Step1';
import ContentFeed from 'screens/Content/Feed';
import ContentNews from 'screens/Content/News';
import Ask from 'screens/Ask';
import AskCopilot from 'screens/AskCopilot';
import Community from 'screens/Community';
import Library from 'screens/Library';
import Content from 'screens/Content/Page';
import Collection from 'screens/Collection';
import Profile from 'screens/Profile';
import NotFound from 'screens/NotFound';

import UIBar, { UIBarProvider } from 'components/UIBar';
import ScrollerProvider from 'components/Scroller';

import { Routes, Route } from '../router';
import linking from '../linking';

const Stack = () => {
  return (
    <ScrollerProvider>
      <UIBarProvider>
        <Routes>
          <Route path={linking.Ask} element={<Ask />} />
          <Route path={linking.AskCopilot} element={<AskCopilot />} />
          <Route path={linking.Library} element={<Library />} />
          <Route path={linking.Feed} element={<ContentFeed />} />
          <Route path={linking.News} element={<ContentNews />} />
          <Route path={linking.Content} element={<Content />} />
          <Route path={linking.CommunityPeers} element={<Community />} />
          <Route path={linking.CommunityCollection} element={<Community />} />
          <Route path={linking.Profile} element={<Profile />} />
          <Route path={linking.Author} element={<Profile />} />
          <Route path={linking.Playlist} element={<Collection />} />
          <Route path={linking['Auth/Start']} element={<AuthStart />} />
          <Route path={linking['Auth/SignIn']} element={<AuthSignIn />} />
          <Route path={linking['Auth/SignUp']} element={<AuthSignUp />} />
          <Route path={linking['Auth/PasswordReset']} element={<AuthPasswordReset />} />
          <Route path={linking['Auth/Step1']} element={<AuthStep1 />} />
          <Route path={linking.NotFound} element={<NotFound />} />
        </Routes>
        <UIBar />
      </UIBarProvider>
    </ScrollerProvider>
  );
};

export default Stack;
