import type {
  ResourceType, ItemAttribute, Resource,
} from './types';

export const prepareResources = (text: string) => {
  const patterns: { regex: RegExp, replace: string | any }[] = [
    {
      regex: /\[(content_id|playlist_id|note_id): ([\d, ]+)]/g,
      replace: (match: string, idType: string, ids: string) => {
        return ids.split(',').map((id: string) => `[${idType}: ${id.trim()}]`).join(', ');
      },
    },
    { regex: /\(content_id:\s*?(\d+)\)/g, replace: '[Material:$1]' },
    { regex: /\[(\d+)]/g, replace: '[Material:$1]' },
    { regex: /\((\d+)\)/g, replace: '[Material:$1]' },
    { regex: /\[content_id:\s*?(\d+)]/g, replace: '[Material:$1]' },
    { regex: /\(content_id:\s*?(\d+)\)/g, replace: '[Material:$1]' },
    { regex: /\[playlist_id:\s*?(\d+)]/g, replace: '[Collection:$1]' },
    { regex: /\(playlist_id:\s*?(\d+)\)/g, replace: '[Collection:$1]' },
    { regex: /\[note_id:\s*?(\d+)]/g, replace: '[Note:$1]' },
    { regex: /\(note_id:\s*?(\d+)\)/g, replace: '[Note:$1]' },
  ];

  let result = text;

  patterns.forEach((pattern) => {
    result = result.replace(pattern.regex, pattern.replace);
  });

  return result;
};

export const parseSources = (text: string) => {
  const ids: number[] = [];
  const result: {
    type: 'Material' | 'Collection' | 'Note',
    id: number,
  }[] = [];
  const regex = /\[(Material|Collection|Note):(\d+)]/g;

  Array.from(text.matchAll(regex), (m) => [m[1], m[2]]).forEach(([type, id]) => {
    if (ids.includes(Number(id))) {
      return;
    }
    ids.push(Number(id));
    result.push({
      type: type as 'Material' | 'Collection' | 'Note',
      id: Number(id),
    });
  });

  return result;
};

export const setSourcesReferences = (text: string) => {
  let position = 0;
  const map: Record<string, number> = {};
  const regex = /\[(Material|Collection|Note):(\d+)]/g;

  return text.replaceAll(regex, (_, resourceType, resourceId) => {
    const resourceKey = `${resourceType}:${resourceId}`;
    if (resourceKey in map) {
      return (
        `<span class="resource-ref" data-resource-type="${resourceType}" data-resource-id="${resourceId}">${map[resourceKey]}</span> `
      );
    }
    position += 1;
    map[resourceKey] = position;
    return (
      `<span class="resource-ref" data-resource-type="${resourceType}" data-resource-id="${resourceId}">${position}</span> `
    );
  });
};

export const createItemIndex = (
  data:
    | { resourceType: ResourceType, resourceId?: number | null }
    | { resourceType: ResourceType, resourceId?: number | null, requestId: string, type: ItemAttribute['type'] }
    | { resourceType: ResourceType, resourceId?: number | null, itemId: string },
) => {
  if ('itemId' in data) {
    return `${data.resourceType}::${data.resourceId || 'null'}::${data.itemId}`;
  }
  if ('requestId' in data && 'type' in data) {
    return `${data.resourceType}::${data.resourceId || 'null'}::${data.type}::${data.requestId}`;
  }
  return `${data.resourceType}::${data.resourceId || 'null'}::null::null`;
};

export const createItemId = (
  data: Pick<ItemAttribute, 'requestId' | 'type'>,
) => {
  if ('type' in data && 'requestId' in data) {
    return `${data.type}::${data.requestId}`;
  }
  return 'null::null';
};

export const createAttribute = (data: ItemAttribute): ItemAttribute => ({
  requestId: data.requestId,
  resourceType: data.resourceType,
  resourceId: data.resourceId,
  type: data.type,
});

export const createIndexByResource = (resources: Resource | Resource[]) => (
  (!Array.isArray(resources) ? [resources] : resources).map((resource) => (
    `${resource.type}::${resource.id || 'null'}`
  )).join(';')
);
