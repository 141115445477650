import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  StyleSheet, View,
  type LayoutChangeEvent,
} from 'react-native';
import { Box } from '@mui/joy';

import { useRoute } from 'navigation/hooks';
import { unit } from 'utils';

import { useDispatcher } from 'store/utils/redux/hooks';

import Copilot from 'widgets/Copilot';
import PanelsBar from 'widgets/PanelsBar';
import Notes from 'widgets/Notes';

import MaterialsPanel from '../elements/Materials';
import DescriptionPanel from '../elements/DescriptionPanel';

const LayoutDefault = () => {
  const route = useRoute<'Playlist'>();
  const dispatcher = useDispatcher();

  const { resourceId } = route.params;
  const numericResourceId = Number(resourceId);

  const [direction, setDirection] = useState<'column' | 'row' | undefined>(undefined);

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    if (event.nativeEvent.layout.width <= 768) {
      setDirection('column');
    } else {
      setDirection('row');
    }
  }, []);

  useEffect(() => {
    if (numericResourceId) {
      setTimeout(() => {
        dispatcher.copilot.open();
      }, 100);
    }
    return () => {
      // dispatcher.copilot.clear();
    };
  }, [numericResourceId]);

  const contentMeta = useMemo(() => ({
    label: 'Collection',
    value: 'collection',
  }), []);

  const contentRender = useMemo(() => (
    <View style={styles.LayoutDefault} onLayout={handleLayout}>
      {!!direction && (
        <>
          <Box position="relative" mb={3} alignSelf={direction === 'column' ? 'stretch' : undefined} mt={0}>
            <DescriptionPanel resourceId={numericResourceId} />
          </Box>
          <MaterialsPanel resourceId={numericResourceId} />
        </>
      )}
    </View>
  ), [handleLayout, direction, numericResourceId]);

  const panelsRender = useMemo(() => [
    {
      label: 'AI Copilot',
      value: 'copilot',
      component: (
        <Copilot variant="collection" resourceId={numericResourceId} />
      ),
    },
    {
      label: 'Notes',
      value: 'notes',
      component: (
        <Notes resourceType="collection" resourceId={numericResourceId} />
      ),
    },
  ], [numericResourceId]);

  if (!numericResourceId) {
    return null;
  }

  return (
    <PanelsBar
      contentMeta={contentMeta}
      content={contentRender}
      panels={panelsRender}
      sx={{
        '@media (max-width: 767px)': {
          mx: -2,
        },
      }}
    />
  );
};

const styles = StyleSheet.create({
  LayoutDefault: {
    flexDirection: 'column',
    marginTop: 0,
    marginBottom: unit(32),
  },
});

export default memo(LayoutDefault);
