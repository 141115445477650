import {
  type SagaReturnType, takeEvery, put, cancel,
} from 'redux-saga/effects';

import * as socketStore from 'store/nodes/socket';

import { actions } from '../slice';

export const config = {
  action: [
    socketStore.actions.askSearchClear.type,
  ],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof socketStore.actions.askSearchClear>) {
  const { payload } = action;
  yield put(actions.setSequence({ sequence: [] }));
}
