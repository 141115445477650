import {
  memo, type MouseEvent, useCallback, useEffect, useState,
} from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode, $getSelection, $isRangeSelection, $isRootOrShadowRoot,
} from 'lexical';
import { $createHeadingNode, type HeadingTagType, $isHeadingNode } from '@lexical/rich-text';

import { $setBlocksType } from '@lexical/selection';
import { IconButton } from '@mui/joy';
import Icon from 'ui/Icon';
import {
  $findMatchingParent,
} from '@lexical/utils';

type HeadingPanelProps = {
  editor: ReturnType<typeof useLexicalComposerContext>[0];
};

const HeadingPanel = ({ editor }: HeadingPanelProps) => {
  const [activeHeading, setActiveHeading] = useState<HeadingTagType | null>(null);

  useEffect(() => {
    const unregisterListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            setActiveHeading(null);
            return;
          }
          const anchorNode = selection.anchor.getNode();
          const element = anchorNode.getKey() === 'root'
            ? anchorNode
            : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

          if ($isHeadingNode(element)) {
            setActiveHeading(element.getTag() as HeadingTagType);
          } else {
            setActiveHeading(null);
          }
        });
      },
    );

    return () => {
      unregisterListener();
    };
  }, [editor]);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const handleHeadingClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const headingType = event.currentTarget.value as HeadingTagType;
    editor.update(() => {
      const selection = $getSelection();
      if (activeHeading === headingType) {
        formatParagraph();
        setActiveHeading(null);
      } else {
        $setBlocksType(selection, () => $createHeadingNode(headingType));
        setActiveHeading(headingType);
      }
    });
  }, [editor, activeHeading]);

  return (
    <>
      {['h2', 'h3', 'h4'].map((type) => (
        <IconButton
          key={type}
          value={type}
          aria-pressed={activeHeading === type}
          onClick={handleHeadingClick}
        >
          <Icon name={type} fw weight="regular" color="inherit" />
        </IconButton>
      ))}
    </>
  );
};

export default memo(HeadingPanel);
