import { useEffect, useState } from 'react';

import Storage from '../classes/Storage';

const useStorageSync = (): boolean => {
  const [isSynchronized, setSynchronized] = useState(false);

  useEffect(() => {
    Storage.sync().then(() => {
      setSynchronized(true);
    });
  }, []);

  return isSynchronized;
};

export default useStorageSync;
