import { useMemo } from 'react';
import useThemeColor from 'hooks/useThemeColor';

import type { SelectInputProps } from './types';

export const useColors = (colors: SelectInputProps['colors']) => {
  const textColor = useThemeColor({
    light: colors?.text?.light || '#000000',
    dark: colors?.text?.dark || '#ffffff',
  });
  const controlBackgroundColor = useThemeColor({
    light: colors?.controlBackground?.light || '#ffffff',
    dark: colors?.controlBackground?.dark || '#232326',
  });
  const menuBackgroundColor = useThemeColor({
    light: colors?.menuBackground?.light || '#ffffff',
    dark: colors?.menuBackground?.dark || '#232326',
  });
  const outlineColor = useThemeColor({
    light: colors?.outline?.light || '#497CFF',
    dark: colors?.outline?.dark || '#497CFF',
  });
  const optionHoverColor = useThemeColor({
    light: colors?.optionHover?.light || '#497CFF11',
    dark: colors?.optionHover?.dark || '#497CFF11',
  });
  const optionSelectedColor = useThemeColor({
    light: colors?.optionSelected?.light || '#497CFF33',
    dark: colors?.optionSelected?.dark || '#497CFF33',
  });
  const multiValueColor = useThemeColor({
    light: colors && ('multiValue' in colors) && colors?.multiValue?.light || '#e7e7e7',
    dark: colors && ('multiValue' in colors) && colors?.multiValue?.dark || '#414141',
  });
  return useMemo(() => ({
    textColor,
    controlBackgroundColor,
    menuBackgroundColor,
    outlineColor,
    optionHoverColor,
    optionSelectedColor,
    multiValueColor,
  }), [
    textColor,
    controlBackgroundColor,
    menuBackgroundColor,
    outlineColor,
    optionHoverColor,
    optionSelectedColor,
    multiValueColor,
  ]);
};
