import items from './items';
import page from './page';
import post from './post';
import meta from './meta';
import commentDelete from './delete';
import edit from './edit';
import summary from './summary';

export default {
  items,
  page,
  post,
  meta,
  delete: commentDelete,
  edit,
  summary,
};
