import type { ReactElement, SyntheticEvent } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

import 'react-datepicker/dist/react-datepicker.css';

import TextInput from '../../TextInput';
import type { PickerProps } from './types';

const defaultProps = {
  InputProps: {
    radius: 'half',
    type: 'text',
  },
};

const Picker = (props: PickerProps & typeof defaultProps): ReactElement => {
  const {
    value,
    onChange,
    onFocus,
    onBlur,
    error,
    errorFont,
    InputProps: {
      radius,
      inputFont,
      type,
      placeholder,
      placeholderColor,
    } = {},
    label,
    labelFont,
    width,
  } = props;

  const handleChange = (
    date: Date | [Date | null, Date | null] | /* for selectsRange */ null,
    event: SyntheticEvent<any> | undefined,
  ) => {
    onChange?.(moment(date as Date).format('yyyy-MM-DD'));
  };

  const selected = !value ? null : moment(value as string, 'yyyy-MM-DD').toDate();

  return (
    <DatePicker
      selected={selected}
      onChange={handleChange}
      portalId="#datepicker-portal"
      dateFormat="yyyy.MM.dd"
      customInput={(
        <TextInput
          blocked
          error={error}
          errorFont={errorFont}
          label={label}
          labelFont={labelFont}
          placeholder={placeholder}
          radius={radius}
          inputFont={inputFont}
          type={type}
        />
      )}
    />
  );
};

Picker.defaultProps = defaultProps;

export default Picker;
