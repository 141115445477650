import { createReducer } from '@reduxjs/toolkit';
import md5 from 'blueimp-md5';

import { guard } from 'utils';

import type { LibraryStore } from './types';
import * as actions from './actions';

const initialState: LibraryStore = {
  list: {
    sequence: [],
    map: {},
    paginationInfo: {
      hasNext: false,
      nextCursor: null,
    },
    meta: {
      hasAnyItems: null,
      resultWithFilterHash: md5(JSON.stringify('{}')),
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
  filter: {
    data: {},
    panels: {
      interactionIds: [],
      tagIds: [],
      typeIds: [],
    },
    enabledMap: {},
    meta: {
      isLoading: false,
      isLoaded: false,
    },
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.loadFilters, (state) => {
    state.filter.meta.isLoading = true;
  })
  .addCase(actions.loadFiltersDone, (state) => {
    state.filter.meta.isLoading = false;
    state.filter.meta.isLoaded = true;
  })
  .addCase(actions.setFilters, (state, action) => {
    const { items } = action.payload;
    state.filter.data = {};
    state.filter.panels.tagIds = [];
    state.filter.panels.typeIds = [];
    state.filter.panels.interactionIds = [];
    items.forEach((item) => {
      state.filter.data[item.id] = item;
      if (['TAG'].includes(item.type)) {
        state.filter.panels.tagIds.push(item.id);
      }
      if (['RESOURCE_TYPE', 'CONTENT_TYPE'].includes(item.type)) {
        state.filter.panels.typeIds.push(item.id);
      }
      if (['USER_INTERACTION'].includes(item.type)) {
        state.filter.panels.interactionIds.push(item.id);
      }
    });
  })
  .addCase(actions.enableTempTagFilter, (state, action) => {
    const { item } = action.payload;
    if (!state.filter.panels.tagIds.includes(item.id)) {
      state.filter.data[item.id] = item;
      state.filter.panels.tagIds.push(item.id);
    }
    state.filter.enabledMap[item.id] = true;
  })
  .addCase(actions.enableFilter, (state, action) => {
    const ids = !Array.isArray(action.payload.id) ? [action.payload.id] : action.payload.id;
    ids.forEach((id) => {
      state.filter.enabledMap[id] = true;
    });
  })
  .addCase(actions.disableFilter, (state, action) => {
    const ids = !Array.isArray(action.payload.id) ? [action.payload.id] : action.payload.id;
    ids.forEach((id) => {
      if (state.filter.enabledMap[id]) {
        delete state.filter.enabledMap[id];
      }
      if (state.filter.data[id].isTemp && state.filter.data[id].type === 'TAG') {
        delete state.filter.data[id];
        const deleteIndex = state.filter.panels.tagIds.indexOf(id);
        if (deleteIndex > -1) {
          state.filter.panels.tagIds.splice(deleteIndex, 1);
        }
      }
    });
  })
  .addCase(actions.toggleFilter, (state, action) => {
    const ids = !Array.isArray(action.payload.id) ? [action.payload.id] : action.payload.id;
    ids.forEach((id) => {
      const toEnable = !state.filter.enabledMap[id];
      if (toEnable) {
        state.filter.enabledMap[id] = true;
      } else {
        delete state.filter.enabledMap[id];
      }
      if (!toEnable && state.filter.data[id].isTemp && state.filter.data[id].type === 'TAG') {
        delete state.filter.data[id];
        const deleteIndex = state.filter.panels.tagIds.indexOf(id);
        if (deleteIndex > -1) {
          state.filter.panels.tagIds.splice(deleteIndex, 1);
        }
      }
    });
  })
  .addCase(actions.loadList, (state, action) => {
    state.list.meta.isFirstPageLoading = true;
    state.list.paginationInfo.hasNext = false;
    state.list.paginationInfo.nextCursor = null;
  })
  .addCase(actions.loadNextPage, (state, action) => {
    state.list.meta.isNextPageLoading = true;
  })
  .addCase(actions.loadNextPageDone, (state, action) => {
    const { reload } = action.payload;
    state.list.meta.isFirstPageLoading = false;
    state.list.meta.isNextPageLoading = false;
    if (reload) {
      state.list.meta.isFirstPageLoaded = true;
    } else {
      state.list.meta.isNextPageLoaded = true;
    }
    state.list.meta.resultWithFilterHash = md5(JSON.stringify(state.filter.enabledMap));
  })
  .addCase(actions.addPage, (state, action) => {
    const { items, paginationInfo } = action.payload;
    const { reload } = action.meta;
    if (!items) {
      return;
    }
    if (reload) {
      state.list.sequence = [];
      state.list.map = {};
    }
    if (!state.list.meta.hasAnyItems && items.length > 0) {
      state.list.meta.hasAnyItems = true;
    }
    items.forEach((item) => {
      let type: 'material' | 'collection' | 'note' | 'unknown' = 'unknown';
      if (guard.isMaterial(item)) {
        type = 'material';
      }
      if (guard.isCollection(item)) {
        type = 'collection';
      }
      if (guard.isNote(item)) {
        type = 'note';
      }
      if (`${type}-${item.id}` in state.list.map) {
        return;
      }
      state.list.sequence.push({
        id: item.id,
        type,
      });
      state.list.map[`${type}-${item.id}`] = state.list.sequence.length - 1;
    });
    state.list.paginationInfo.nextCursor = paginationInfo.nextCursor;
    state.list.paginationInfo.hasNext = paginationInfo.hasNext;
  })
  .addCase(actions.prependToSequence, (state, action) => {
    const { resourceType, resourceId } = action.payload;
    if (!resourceId) {
      return;
    }
    state.list.sequence.unshift({
      id: resourceId,
      type: resourceType,
    });
    state.list.map = {};
    state.list.sequence.forEach((item, position) => {
      state.list.map[`${item.type}-${item.id}`] = position;
    });
    if (!state.list.meta.hasAnyItems) {
      state.list.meta.hasAnyItems = true;
    }
  })
  .addCase(actions.removeFromSequence, (state, action) => {
    const { resourceType, resourceId } = action.payload;
    if (!(`${resourceType}-${resourceId}` in state.list.map)) {
      return;
    }
    state.list.sequence.splice(state.list.map[`${resourceType}-${resourceId}`], 1);
    state.list.map = {};
    state.list.sequence.forEach((item, position) => {
      state.list.map[`${item.type}-${item.id}`] = position;
    });
    if (state.list.sequence.length === 0) {
      state.list.meta.hasAnyItems = false;
    }
  })
  .addCase(actions.loadMetaDone, (state, action) => {
    const { meta } = action.payload;
    if (typeof meta.hasAnyItems !== 'undefined') {
      state.list.meta.hasAnyItems = meta.hasAnyItems;
    }
  })
  .addCase(actions.logOut, (state, action) => {
    const cloned = JSON.parse(JSON.stringify(initialState));
    state.list = cloned.list;
    state.filter.enabledMap = {};
  }));
