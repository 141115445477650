import { memo, useMemo } from 'react';
import {
  type StyleProp, StyleSheet, type ViewStyle,
} from 'react-native';

import DropdownPanel from 'components/DropdownPanel';
import { IconButton, Tooltip } from '@mui/joy';

import LibraryFiltersPanel from 'components/UIBar/bars/panels/LibraryFiltersPanel';
import AskFiltersPanel from 'components/UIBar/bars/panels/AskFiltersPanel';
import StaffPicksFiltersPanel from 'components/UIBar/bars/panels/StaffPicksFiltersPanel';

import { useRoute } from 'navigation/hooks';
import {
  isStaffPicksScreen, isAskScreen, isLibraryScreen,
} from 'navigation/guards';
import { unit } from 'utils';

import Icon from 'ui/Icon';
import { useSearchMethod } from 'hooks';

const FilterButton = ({ onPress }: any) => (
  // <TutorialStep stepIndex={5} description="Filter your content">
  <Tooltip arrow variant="outlined" size="sm" title="Search filter" disableInteractive>
    <IconButton
      size="sm"
      color="neutral"
      variant="plain"
      onClick={onPress}
    >
      <Icon name="sliders" size="lg" fw weight="light" color="primary" />
    </IconButton>
  </Tooltip>
  // </TutorialStep>
);

type ScreenFiltersDropdownProps = {
  style?: StyleProp<ViewStyle>,
}

const ScreenFiltersDropdown = (props: ScreenFiltersDropdownProps) => {
  const { style } = props;

  const route = useRoute();
  const { scope } = useSearchMethod();

  const filtersRender = useMemo(() => (
    <>
      {isLibraryScreen(route) && (
        <LibraryFiltersPanel style={styles.filters} />
      )}
      {isAskScreen(route) && (
        <AskFiltersPanel style={styles.filters} />
      )}
      {isStaffPicksScreen(route) && (
        <StaffPicksFiltersPanel style={styles.filters} />
      )}
    </>
  ), [route]);

  if (!isLibraryScreen(route) && !isStaffPicksScreen(route) && !isAskScreen(route)) {
    return null;
  }

  if (isAskScreen(route) && scope === 'Web') {
    return null;
  }

  return (
    <DropdownPanel
      anchor="right"
      style={style}
      panelStyle={styles.panelStyle}
      button={<FilterButton />}
    >
      {filtersRender}
    </DropdownPanel>
  );
};

const styles = StyleSheet.create({
  filters: {
    marginLeft: unit(-24),
    marginRight: unit(-20),
    marginTop: unit(-12),
    marginBottom: unit(-12),
  },
  panelStyle: {
    padding: unit(24),
    borderRadius: unit(8),
  },
});

export default memo(ScreenFiltersDropdown);
