import {
  type SagaReturnType,
  takeLatest, put, cancel,
} from 'redux-saga/effects';

import * as api from 'services/api';

import Alert from 'components/Alert';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.loadSummaryById.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.loadSummaryById>) {
  const {
    resource,
    resourceId,
  } = action.payload;

  const result = yield* call(() => api.resource.comment.summary(resource, resourceId));
  if (result.error || !result.data) {
    Alert.error(result?.error?.message || 'Server error #10');
    yield cancel(); return;
  }

  yield put(actions.setSummaryData(resource, resourceId, result.data));

  yield put(actions.loadSummaryByIdDone(resource, resourceId));
}
