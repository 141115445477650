const openWindow = (url: string, title: string) => {
  if (window.innerWidth <= 599) {
    return window.open(url, '_blank');
  }

  const width = 500;
  const height = 650;
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;

  return window.open(url, 'Stripe', `width=${width},height=${height},left=${left},top=${top}`);
};

export default openWindow;
