import { memo, useCallback, useState } from 'react';
import { Box, CardContent } from '@mui/joy';
import { type AuthorType, type NoteType, type UserType } from 'app/entities';

import TextExpand from 'ui/TextExpand';

import useAuthor from '../model/useAuthor';

import AuthorBlock from '../ui/AuthorBlock';

type NoteProps ={
  data?: NoteType,
  hasAuthor?: boolean,
  isCollapsed?: boolean,
  handleAuthorPress?: (value: UserType | AuthorType) => void
}

const Note = (props: NoteProps) => {
  const {
    data,
    handleAuthorPress,
    hasAuthor, isCollapsed,
  } = props;

  const authorData = useAuthor(data?.user as UserType);

  const handlePress = useCallback(() => {
    if (!authorData) {
      return;
    }
    handleAuthorPress?.(authorData);
  }, [authorData]);

  return (
    <CardContent>
      <Box display="flex" flexDirection="column" gap={1}>
        <TextExpand
          lines={10}
          slotsProps={{
            typography: {
              parseHtml: true,
            },
          }}
        >
          {data?.text}
        </TextExpand>
        {hasAuthor && !!authorData && (
          <AuthorBlock onPress={handlePress} data={authorData} />
        )}
      </Box>
    </CardContent>
  );
};

export default memo(Note);
