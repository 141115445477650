import {
  memo, useCallback, useState,
} from 'react';
import {
  type GestureResponderEvent,
  StyleSheet,
} from 'react-native';
import { unit } from 'utils';

import { View, Text } from 'components/Themed';
import Button from 'components/Button';
import Icon, { type IconNames } from 'components/LegacyIcon';

const defaultProps = {
  iconLightColor: '#000000',
  iconDarkColor: '#ffffff',
};

type ItemProps<CT> = {
  isShow?: boolean,
  iconName: IconNames,
  iconLightColor?: string,
  iconDarkColor?: string,
  text: string,
  context?: CT | null,
  onPress?: (event: GestureResponderEvent, context: CT) => void,
};

const Item = <CT, >(props: ItemProps<CT>) => {
  const {
    isShow,
    iconName,
    iconLightColor,
    iconDarkColor,
    text,
    context,
    onPress,
  } = props;
  const [isHover, setHover] = useState(false);

  const handleEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleLeave = useCallback(() => {
    setHover(false);
  }, []);

  if (!isShow) {
    return null;
  }

  return (
    <Button
      lightColor="transparent"
      darkColor="transparent"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      width="full"
      style={styles.button}
      context={context}
      onPress={onPress}
    >
      <View style={styles.body}>
        {isHover && (
          <View
            style={styles.hover}
            lightColor="#00000011"
            darkColor="#ffffff11"
          />
        )}
        <Icon
          name={iconName}
          size={28}
          lightColor={iconLightColor}
          darkColor={iconDarkColor}
          style={styles.icon}
        />
        <Text
          size={16}
          lightColor={isHover ? '#000000' : '#696969'}
          darkColor={isHover ? '#ffffff' : '#d1cfd7'}
          numberOfLines={1}
        >
          {text}
        </Text>
      </View>
    </Button>
  );
};

Item.defaultProps = defaultProps;

const styles = StyleSheet.create({
  button: {
    position: 'relative',
    justifyContent: 'flex-start',
  },
  body: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: unit(12),
  },
  hover: {
    position: 'absolute',
    top: unit(-4),
    right: unit(-16),
    bottom: unit(-4),
    left: unit(-16),
    borderRadius: unit(81),
  },
});

export default memo(Item);
