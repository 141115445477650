import {
  takeEvery, cancel, put,
  type SagaReturnType,
} from 'redux-saga/effects';

import { actions } from '../slice';

export const config = {
  action: actions.sortItems.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.sortItems>) {
  const { oldIndex, newIndex, id } = action.payload;

  if (!id) {
    yield cancel(); return;
  }

  yield put(actions.moveItems({
    id, oldIndex, newIndex,
  }));
}
