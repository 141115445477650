import { createAction } from '@reduxjs/toolkit';

import type {
  PaginationType,
  MaterialType,
  CollectionType,
} from 'app/entities';

export const clear = createAction('NEWS/CLEAR');

export const setTopic = createAction('NEWS/SET_TOPIC', (topic: string) => ({
  payload: {
    topic,
  },
}));

export const loadNextPage = createAction('NEWS/LOAD_PAGE', (reload = false) => ({
  payload: {
    reload,
  },
}));

export const loadNextPageDone = createAction('NEWS/LOAD_PAGE_DONE', (reload = false) => ({
  payload: {
    reload,
  },
}));

export const addPage = createAction('NEWS/SET_PAGE', (
  items: (MaterialType | CollectionType)[],
  paginationInfo: PaginationType,
  reload = false,
) => ({
  payload: {
    items,
    paginationInfo,
  },
  meta: {
    reload,
  },
}));
