import {
  type ReactElement, type ReactNode,
  memo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet,
} from 'react-native';

import { unit } from 'utils';

import { Text, View } from 'components/Themed';
import Icon, { type IconNames } from 'components/LegacyIcon';

export type ItemProps = {
  style?: StyleProp<ViewStyle>,
  icon: IconNames,
  title: string,
  text: string | ReactNode,
}

const Item = (props: ItemProps): ReactElement => {
  const {
    style,
    icon,
    title,
    text,
  } = props;

  return (
    <View style={[styles.container, style]}>
      <Icon name={icon} size={28} />
      <View style={styles.content}>
        <Text size={22}>{title}</Text>
        {typeof text === 'string' && (
          <Text size={13} style={styles.text}>{text}</Text>
        )}
        {typeof text !== 'string' && (
          text
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  content: {
    flex: 1,
    marginLeft: unit(20),
    marginBottom: unit(4),
  },
  text: {
  },
});

export default memo(Item);
