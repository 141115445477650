import {
  type ReactNode, type ReactElement, useCallback,
} from 'react';
import {
  StyleSheet,
} from 'react-native';
import Modal from 'react-native-modal';

import { useBreakpoint } from 'hooks';

import { GradientView } from 'components/Themed';
import { unit } from 'utils';

type PanelProps = {
  id: string,
  children?: ReactNode,
  isShow?: boolean,
  onShow?: (id: string) => void,
  onHide?: (id: string) => void,
}

const Menu = (props: PanelProps): ReactElement => {
  const {
    id,
    children,
    isShow,
    onShow,
    onHide,
  } = props;

  const breakpoint = useBreakpoint();

  const handleShow = useCallback(() => {
    onShow?.(id);
  }, [id, onShow]);

  const handleHide = useCallback(() => {
    onHide?.(id);
  }, [id, onHide]);

  return (
    <Modal
      isVisible={isShow}
      style={[
        breakpoint === 'xs' && styles.panel,
        breakpoint !== 'xs' && styles.smPanel,
      ]}
      onShow={handleShow}
      onModalHide={handleHide}
      swipeDirection={breakpoint === 'xs' ? ['down'] : []}
    >
      <GradientView
        style={[
          breakpoint === 'xs' && styles.xsView,
          breakpoint !== 'xs' && styles.smView,
        ]}
        lightColors={['#f2f2f1', '#f2f2f1']}
        darkColors={['#222222', '#101010']}
      >
        {children}
      </GradientView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  panel: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  xsView: {
    paddingHorizontal: unit(32),
    paddingTop: unit(24),
    paddingBottom: unit(28),
    borderTopLeftRadius: unit(30),
    borderTopRightRadius: unit(30),
  },
  smPanel: {
  },
  smView: {
  },
});

export default Menu;
