import {
  Fragment, type ReactElement, useCallback, useEffect, useState,
} from 'react';
import { View } from 'react-native';
import * as uuid from 'uuid';
import EventEmitter from 'events';

import LayoutMenu from './elements/Menu';

interface Config {
  layout: 'menu',
  handleBeforeShow: () => void,
  handleShow: () => void,
  handleBeforeHide: () => void,
  handleHide: () => void,
}

interface Payload extends Config {
  id: string,
  element: ReactElement,
}

interface Element {
  id: string,
  layout: 'menu',
  isShow: boolean,
  element: ReactElement,
  handleBeforeShow: () => void,
  handleShow: () => void,
  handleBeforeHide: () => void,
  handleHide: () => void,
}

class Modal {
  private event: EventEmitter;

  constructor() {
    this.event = new EventEmitter();
  }

  public on(eventName: string | symbol, listener: (...args: any[]) => void): void {
    this.event.on(eventName, listener);
  }

  public off(eventName: string | symbol, listener: (...args: any[]) => void): void {
    this.event.off(eventName, listener);
  }

  public removeAllListeners(event?: string | symbol): void {
    this.event.removeAllListeners(event);
  }

  public open(config: Config, element: ReactElement) {
    const id = uuid.v4();
    this.event.emit('open', {
      id,
      element,
      ...config,
    } as Payload);
    return {
      id,
      close: () => this.close(id),
    };
  }

  public close(id: string): void {
    this.event.emit('close', {
      id,
    } as Pick<Payload, 'id'>);
  }
}

export const modal = new Modal();

const ModalContainer = (): ReactElement => {
  const [elements, setElements] = useState<Element[]>([]);

  const handleOpenRequest = useCallback((event: any, payload: Payload) => {
    const state = [...elements];
    state.push({
      id: payload.id,
      layout: payload.layout,
      isShow: true,
      element: payload.element,
      handleBeforeShow: payload.handleBeforeShow,
      handleShow: payload.handleShow,
      handleBeforeHide: payload.handleBeforeHide,
      handleHide: payload.handleHide,
    });
    setElements(state);
    setTimeout(() => {
      payload?.handleBeforeShow?.();
    }, 10);
  }, [elements]);

  const handleCloseRequest = useCallback((event: any, payload: Pick<Payload, 'id'>) => {
    const state = [...elements];
    const index = state.findIndex((item) => item.id === payload.id);
    if (index === -1) {
      return;
    }
    state[index]?.handleBeforeHide?.();
    state[index] = {
      ...state[index],
      isShow: false,
    };
    setElements(state);
  }, [elements]);

  const handleShow = useCallback((id: string) => {
    const element = elements.find((item) => item.id === id);
    element?.handleShow?.();
  }, [elements]);

  const handleHide = useCallback((id: string) => {
    const element = elements.find((item) => item.id === id);
    element?.handleHide?.();
    setTimeout(() => {
      const newElements = [...elements];
      const index = newElements.findIndex((item) => item.id === id);
      if (index === -1) {
        return;
      }
      newElements.splice(index, 1);
      setElements(newElements);
    }, 100);
  }, [elements]);

  useEffect(() => {
    modal.on('open', handleOpenRequest);
    modal.on('close', handleCloseRequest);
    return () => {
      modal.off('open', handleOpenRequest);
      modal.off('close', handleCloseRequest);
    };
  }, [handleCloseRequest, handleOpenRequest]);

  return (
    <View>
      {elements.map((element) => (
        <Fragment key={element.id}>
          {element.layout === 'menu' && (
            <LayoutMenu id={element.id} onShow={handleShow} onHide={handleHide}>{element.element}</LayoutMenu>
          )}
        </Fragment>
      ))}
    </View>
  );
};

export default ModalContainer;
