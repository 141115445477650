import {
  takeEvery, put, cancel,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';

import * as api from 'services/api';

import * as userStore from 'store/nodes/user';
import { select, call } from 'store/utils/saga/effects';
import * as selectors from 'store/nodes/comment/selectors';

import * as actions from '../actions';

export const config = {
  action: actions.editSend.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.editSend>) {
  const {
    resource,
    resourceId,
    id,
    text,
  } = action.payload;

  const userProfile = yield* select(userStore.selectors.getMy);
  const data = yield* select(selectors.comment(id));

  if (data.user?.id !== userProfile?.id) {
    yield put(actions.deleteSendDone());
    yield cancel(); return;
  }

  yield put(actions.editOptimistic(resource, resourceId, id));

  const response = yield* call(() => api.resource.comment.edit(id, { text }));
  if (response.error || !response.data) {
    Alert.error(response.error?.message || 'Server error #9');
    yield put(actions.editRollback(resource, resourceId, id));
    yield put(actions.editSendDone());
    yield cancel(); return;
  }

  yield put(actions.editCommit(resource, resourceId, id));
  yield put(actions.editSendDone());
}
