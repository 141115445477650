import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import * as userProfile from 'store/nodes/user';

import type { DirectionType, CurrentPlanStateType } from '../types';

type ButtonStateType = 'current' | 'incomplete' | 'cancelled' | 'available' | 'unavailable';

const useGetButtonState = () => {
  const user = useSelector(userProfile.selectors.getMy);

  return useCallback((currentPlan: CurrentPlanStateType, planId: string, directions: DirectionType[]): ButtonStateType => {
    if (!user) {
      return 'unavailable';
    }
    if (currentPlan.id === planId && !currentPlan.isPaused && !currentPlan.isIncomplete) {
      return 'current';
    }
    if (currentPlan.id === planId && !currentPlan.isPaused && currentPlan.isIncomplete) {
      return 'incomplete';
    }
    if (currentPlan.id === planId && currentPlan.isPaused) {
      return 'cancelled';
    }
    const allow = directions.find((item) => item.criterion.isLegal === (user.isLegal || false))?.allow;
    if (!allow) {
      return 'unavailable';
    }
    if (currentPlan.id !== planId && !allow[currentPlan.id || '']?.includes(planId)) {
      return 'unavailable';
    }
    return 'available';
  }, [user]);
};

export default useGetButtonState;
