import request from 'services/api/request';
import type { SuccessResult } from 'app/entities';

export default async (id: number): Promise<SuccessResult> => {
  const query: Record<string, any> = {};

  const { error } = await request.delete(`/comment/${id}`, { query });
  if (error) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: {
      success: true,
    },
    error: null,
  };
};
