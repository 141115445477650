import type { DefaultRootState } from 'react-redux';

export const selectLoadNextCursor = (reload: boolean) => (state: DefaultRootState): string | null => {
  const { searchUser } = state;
  const nextCursor = searchUser?.list?.paginationInfo?.nextCursor as string | null | undefined;
  if (reload || !nextCursor) {
    return null;
  }
  return nextCursor;
};

export const listMeta = (state: DefaultRootState) => ({
  firstPage: {
    isLoading: state.searchUser.meta.isFirstPageLoading,
    isLoaded: state.searchUser.meta.isFirstPageLoaded,
  },
  nextPage: {
    isLoading: state.searchUser.meta.isNextPageLoading,
    isLoaded: state.searchUser.meta.isNextPageLoaded,
  },
});

export const list = (state: DefaultRootState) => ({
  sequence: state.searchUser.list.sequence,
  paginationInfo: state.searchUser.list.paginationInfo,
});
