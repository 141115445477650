import {
  useCallback, useMemo, useState,
} from 'react';

const useAnchor = () => {
  const [elementRef, setAnchorElemRef] = useState<HTMLDivElement | null>(null);

  const onRef = useCallback((_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setAnchorElemRef(_floatingAnchorElem);
    }
  }, []);

  return useMemo(() => ({
    onRef,
    elementRef,
  }), [
    onRef,
    elementRef,
  ]);
};

export default useAnchor;
