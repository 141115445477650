import React, { memo, useCallback } from 'react';

import { Box, IconButton, Tooltip } from '@mui/joy';

import Icon from 'ui/Icon';
import { useDispatcher } from 'store/utils/redux/hooks';
import { useSelector } from 'react-redux';
import * as copilotStore from 'store/nodes/copilot';

const CopilotButton = () => {
  const dispatcher = useDispatcher();

  const isShow = useSelector(copilotStore.selectors.rightBarIsShow);
  const rightBarWidth = useSelector(copilotStore.selectors.rightBarWidth);
  const handleCopilot = useCallback(() => {
    if (isShow) {
      dispatcher.copilot.close();
    } else {
      dispatcher.copilot.open();
    }
  }, [isShow]);
  return (
    <Box alignSelf="flex-end" flex={1} mr={isShow ? `${rightBarWidth - 170}px` : 1.5}>
      <Tooltip arrow variant="outlined" size="sm" title={isShow ? 'Close AI Copilot' : 'Open AI Copilot'} disableInteractive>
        <IconButton
          size="sm"
          color="neutral"
          variant="soft"
          onClick={handleCopilot}
        >
          <Icon name={isShow ? 'arrow-right-long-to-line' : 'arrow-left-long-to-line'} fw weight="light" color="primary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(CopilotButton);
