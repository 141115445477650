import {
  type CSSProperties,
  memo, useCallback, useMemo, useState, useEffect,
} from 'react';
import {
  type StyleProp, type ViewStyle, type GestureResponderEvent,
  StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/joy';

import type {
  CollectionType,
} from 'app/entities';
import { useNavigate, useRoute } from 'navigation/hooks';
import {
  isAskScreen, isLibraryScreen,
} from 'navigation/guards';

import { unit, guard, saveLastRoute } from 'utils';
import Storage from 'lib/Storage';

import { useColorScheme } from 'hooks';
import { useDispatcher } from 'store/utils/redux/hooks';
import * as libraryStore from 'store/nodes/library';
import * as userProfile from 'store/nodes/user';

import { View } from 'components/Themed';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Settings from 'components/UIBar/bars/LeftBar/elements/Settings';
import MyProfile from 'components/UIBar/bars/LeftBar/elements/MyProfile';
import BottomPanel from 'components/UIBar/bars/panels/BottomPanel';
import CommunityPanel from 'components/UIBar/bars/panels/CommunityPanel';
import TutorialStep from 'components/TutorialStep';

import Icon from 'ui/Icon';
import ScrollBox from 'ui/ScrollBox';

import SubscriptionBanner from 'widgets/Subscription/Banner';

import Extension from './elements/Extension';
import ThemeSwitcher from './elements/ThemeSwitcher';
import MenuButton from '../../elements/MenuButton';

import CollectionsPanel from '../panels/CollectionsPanel';
import FollowPanel from '../panels/FollowPanel';
import UserPanel from '../panels/UserPanel';

const LeftBar = () => {
  const navigate = useNavigate();
  const dispatcher = useDispatcher();
  const route = useRoute();
  const theme = useTheme();

  const user = useSelector(userProfile.selectors.getMy);

  const userId = useSelector(userProfile.selectors.getIdByLogin('my'));
  const panelInteractions = useSelector(libraryStore.selectors.panelInteractions);
  const colorScheme = useColorScheme();

  const [isSlim, setSlim] = useState(Storage.get('menu.leftBar.width') === '64');

  useEffect(() => {
    const newValue = isSlim ? 64 : 240;
    dispatcher.menu.setLeftBarWidth(newValue);
    Storage.set('menu.leftBar.width', String(newValue));
  }, [isSlim]);

  const getLibraryInteractionByQuery = useCallback((query: any) => {
    let interaction;
    if (query?.interactionTypes?.length > 1) {
      interaction = 'all';
    } else {
      interaction = query?.interactionTypes?.[0]?.toLowerCase?.();
    }
    return interaction;
  }, []);

  const handleToggleSize = useCallback(() => {
    setSlim(!isSlim);
  }, [isSlim]);

  const handleHome = useCallback(() => {
    if (!userId) {
      saveLastRoute();
    }
    dispatcher.search.clear();
    navigate('Ask');
  }, [userId]);

  const handleLibrary = useCallback((event: GestureResponderEvent, context: { interaction: any }) => {
    const { interaction } = context;
    if (!interaction) {
      return;
    }
    if (!userId) {
      saveLastRoute();
    }
    dispatcher.search.clear();
    navigate('Library', { interaction });
  }, [userId]);

  const handleAddPeoplePress = useCallback(() => {
    dispatcher.modal.open('SearchUser');
  }, []);

  const handleCollectionPress = useCallback((event: GestureResponderEvent, context: { data: CollectionType }) => {
    if (context?.data) {
      dispatcher.search.clear();
      navigate('Playlist', { resourceId: context.data.id });
    }
  }, []);

  const handleAddCollectionPress = useCallback(() => {
    dispatcher.modal.open('AddorEditCollection', { create: { materialIds: [] } });
  }, []);

  const containerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> | CSSProperties = ({
      ...StyleSheet.flatten(styles.LeftBar),
      backgroundColor: theme.palette.background.body,
    });
    result.width = unit(isSlim ? 64 : 240);
    if (guard.isWebStyleProp(result)) {
      result.position = 'fixed';
      result.transform = 'translateZ(0)';
      // (result as CSSProperties).transition = 'width 100ms ease';
      if (colorScheme === 'light') {
        result.boxShadow = '0 0 0 1px rgba(0, 0, 0, 0.025)';
      } else {
        result.boxShadow = '0 0 0 1px rgba(0, 0, 0, 0.25)';
      }
    }
    return result as StyleProp<ViewStyle>;
  }, [isSlim, colorScheme, theme]);

  const mainMenuRender = useMemo(() => (
    <>
      <TutorialStep
        placement="left"
        isLast
        stepIndex={5}
        title="Intelligent Knowledge Interface"
        description="Search IKI index or your library for the new stuff. Web search powered co-pilot comes soon."
      >
        <MenuButton
          style={styles.menuItem}
          href="/ask"
          onPress={handleHome}
          iconLeft={(
            <Icon
              name="magnifying-glass-plus"
              fw={24}
              size="lg"
              weight={isAskScreen(route) ? 'regular' : 'light'}
              color={isAskScreen(route) ? 'primary' : 'secondary'}
            />
        )}
          text="Ask IKI"
          isSlim={isSlim}
          isSelected={isAskScreen(route)}
        />
      </TutorialStep>
      {panelInteractions.length === 0 && (
        <TutorialStep placement="left" stepIndex={4} title="Library" description="All your saving live here">
          <MenuButton
            style={styles.menuItem}
            href="/library/all"
            context={{ interaction: 'all' }}
            onPress={handleLibrary}
            iconLeft={(
              <Icon
                name="inbox"
                fw={24}
                size="lg"
                weight={isLibraryScreen(route) ? 'regular' : 'light'}
                color={isLibraryScreen(route) ? 'primary' : 'secondary'}
              />
          )}
            text="Library"
            isSlim={isSlim}
            isSelected={isLibraryScreen(route)}
          />
        </TutorialStep>
      )}
      {panelInteractions.map((item, key) => {
        const interaction = getLibraryInteractionByQuery(item?.query);
        const selected = isLibraryScreen(route) && (route.params as Record<string, string>)?.interaction === interaction;
        const button = (
          <MenuButton
            key={item.id}
            style={[styles.menuItem, key === panelInteractions.length - 1 && { marginBottom: unit(5) }]}
            href={`/library/${interaction}`}
            context={{ interaction }}
            onPress={handleLibrary}
            iconLeft={(
              <Icon
                name={{ all: 'inbox', bookmark: 'bookmark' }[interaction as 'all' | 'bookmark'] || 'inbox'}
                fw={24}
                size="lg"
                weight={selected ? 'regular' : 'light'}
                color={selected ? 'primary' : 'secondary'}
              />
            )}
            text={item.label}
            isSlim={isSlim}
            isSelected={isLibraryScreen(route) && (route.params as Record<string, string>)?.interaction === interaction}
          />
        );
        if (interaction === 'all') {
          return (
            <TutorialStep placement="left" stepIndex={4} title="Library" description="All your saving live here">
              {button}
            </TutorialStep>
          );
        }
        return button;
      })}
    </>
  ), [
    route,
    isSlim,
    panelInteractions,
    handleLibrary,
    handleHome,
  ]);

  return (
    <View
      style={containerStyle}
      pointerEvents="auto"
    >
      <View style={styles.header}>
        <Button type="button-icon" onPress={handleToggleSize} lightColor="transparent" darkColor="transparent" style={styles.hamburger}>
          <Icon name="bars" fw size="lg" weight="light" color="primary" />
        </Button>
        {!isSlim && (
          <UserPanel />
        )}
      </View>
      <ScrollBox display="flex" flexDirection="column" pb={2}>
        {mainMenuRender}
        {!!user && (
          <Divider orientation="horizontal" margin="1.5 0 0 0" />
        )}
        {isSlim && user?.type === 'default' && (
          <MenuButton
            style={styles.menuItem}
            onPress={handleAddPeoplePress}
            iconLeft={<Icon name="users-medical" fw size="lg" weight="light" color="primary" />}
            text="People"
            isSlim={isSlim}
            isSelected={false}
          />
        )}
        {user?.type === 'default' && (
          <>
            {/* <FeedsPanel style={styles.group} isSlim={isSlim} /> */}
            <CommunityPanel style={styles.group} isSlim={isSlim} />
          </>
        )}
        {!!userId && !isSlim && (
          <CollectionsPanel
            style={styles.group}
            onPress={handleCollectionPress}
            onAddPress={handleAddCollectionPress}
          />
        )}
        {!!userId && !isSlim && user?.type === 'default' && (
          <FollowPanel
            style={styles.group}
            onAddPress={handleAddPeoplePress}
          />
        )}
        {!!userId && !isSlim && user?.type === 'default' && (
          <SubscriptionBanner />
        )}
        {!isSlim && !user && (
          <Divider orientation="horizontal" margin="1.5 0 1.25 0" />
        )}
        <MyProfile isSlim={isSlim} />
        {user?.type === 'default' && (
          <Settings isSlim={isSlim} />
        )}
        <ThemeSwitcher isSlim={isSlim} />
        <Extension isSlim={isSlim} />
        {!isSlim && (
          <Divider orientation="horizontal" margin="1.5 0 1.25 0" />
        )}
        <BottomPanel isSlim={isSlim} />
      </ScrollBox>
    </View>
  );
};

const styles = StyleSheet.create({
  LeftBar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
  },
  header: {
    flexDirection: 'row',
  },
  menuItem: {
    marginVertical: unit(2),
  },
  menuItemSlim: {
    marginVertical: unit(2),
  },
  logo: {
    width: unit(40),
    height: unit(40),
    backgroundColor: 'transparent',
  },
  hamburger: {
    marginTop: unit(16),
    marginBottom: unit(8),
    marginLeft: unit(8),
    height: unit(48),
    width: unit(48),
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginBottom: unit(2),
    marginLeft: unit(18),
  },
  group: {
    marginTop: unit(28),
  },
});

export default memo(LeftBar);
