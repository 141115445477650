import type {
  MaterialType,
  PaginationResult,
  PaginationInput,
  CursorType,
} from 'app/entities';
import request from 'services/api/request';

export default async (login?: string, pagination?: PaginationInput): Promise<PaginationResult<MaterialType>> => {
  if (!login) {
    return {
      data: {
        items: [],
        paginationInfo: {
          nextCursor: null,
          hasNext: false,
        },
      },
      error: null,
    };
  }

  const query: Record<string, any> = {
    pageSize: pagination?.pageSize || 10,
  };
  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }

  const { data, error } = await request.get<CursorType<MaterialType>>(`/user/profile/${login}/content`, { query });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: {
      items: data?.items,
      paginationInfo: {
        nextCursor: data?.nextUrl,
        hasNext: !!data?.nextUrl,
      },
    },
    error,
  };
};
