import {
  type PropsWithChildren,
  memo, useMemo, useEffect, useCallback,
} from 'react';
import {
  type ScaledSize,
  Dimensions, Platform,
} from 'react-native';
import { Context } from './elements/Context';

export { useScrollMethods } from './hooks/useScrollMethods';

if (Platform.OS === 'web' && typeof window !== 'undefined') {
  window.history.scrollRestoration = 'manual';
}

const values: Record<string, number> = {};
const dimensions: { window: ScaledSize } = { window: Dimensions.get('window') };

const Scroller = (props: PropsWithChildren<unknown>) => {
  const { children } = props;

  const handleDimensionChange = useCallback(({ window }: { window: ScaledSize }) => {
    dimensions.window = window;
  }, []);

  useEffect(() => {
    const handler = Dimensions.addEventListener('change', handleDimensionChange);
    return () => {
      handler.remove();
    };
  }, []);

  const contextValue = useMemo(() => ({
    setScrollValue: (key: string, value: number) => {
      values[key] = value;
    },
    getScrollValue: (key: string) => ({
      top: values[key] || 0,
      dimension: {
        window: dimensions.window,
      },
    }),
  }), []);

  return (
    <Context.Provider value={contextValue}>{children}</Context.Provider>
  );
};

export default memo(Scroller);
