import {
  type SagaReturnType,
  takeEvery, cancel, put,
} from 'redux-saga/effects';

import type { NoteType } from 'app/entities';

import { call } from 'store/utils/saga/effects';
import network from 'lib/network';
import { actions, type ResourceType } from '../slice';
import * as utils from '../utils';

export const config = {
  action: actions.loadByResource.type,
  method: takeEvery,
};

type dataType = {
  data: NoteType[] | null
  error: Error | null
}

const loadData = async (resourceType: ResourceType, resourceId: number):Promise<dataType> => {
  if (resourceType === 'material') {
    const query = { content_ids: resourceId };
    const { data, errors } = await network
      .request<Record<number, NoteType[]>>('/user/content/notes').query(query).get();

    if (!data || !data[resourceId] || (Array.isArray(errors) && errors.length > 0)) {
      const [error] = errors || [];
      return {
        data: null,
        error: error || new Error('Error loading note'),
      };
    }

    return {
      data: data[resourceId],
      error: null,
    };
  }
  if (resourceType === 'collection') {
    const query = { ids: resourceId };
    const { data, errors } = await network
      .request<Record<number, NoteType[]>>('/user/playlists/notes').query(query).get();

    if (!data || !data[resourceId] || (Array.isArray(errors) && errors.length > 0)) {
      const [error] = errors || [];
      return {
        data: null,
        error: error || new Error('Error loading note'),
      };
    }
    return {
      data: data[resourceId],
      error: null,
    };
  }
  return {
    data: null,
    error: new Error('Unsupported resource type'),
  };
};

export function* func(action: SagaReturnType<typeof actions.loadByResource>) {
  const {
    resourceType, resourceId,
  } = action.payload;

  if (!resourceType || !resourceId) {
    yield put(actions.loadByResourceDone({ resourceType, resourceId }));
    yield cancel(); return;
  }

  const { data, error } = yield* call(() => loadData(resourceType, resourceId));

  if (!data || error) {
    yield put(actions.loadByResourceDone({ resourceType, resourceId }));
    yield cancel(); return;
  }

  const preparedNotes = yield* utils.prepareNote(data);
  yield put(actions.setItem(preparedNotes.map((note: NoteType) => ({
    data: note,
    relation: {
      resourceType,
      resourceId,
    },
  }))));
  yield put(actions.loadByResourceDone({ resourceType, resourceId }));
}
