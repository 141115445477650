import qs from 'query-string';
import moment from 'moment-timezone';

import type {
  CommentType,
  ResourceVariant,
  PaginationInput,
  PaginationResult,
  CursorType,
} from 'app/entities';
import request from 'services/api/request';

export default async (resource: ResourceVariant, resourceId: number, pagination: PaginationInput): Promise<PaginationResult<CommentType>> => {
  const query: Record<string, any> = {
    pageSize: pagination?.pageSize || 10,
  };
  if (pagination?.cursor) {
    query.cursor = pagination?.cursor;
  }

  const { data, error } = await request.get<CursorType<CommentType>>(`/${resource}/${resourceId}/comments`, { query });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  const next = qs.parseUrl(data.nextUrl || '');

  const items = data.items.map((item): CommentType => ({
    ...item,
    createdAt: item.createdAt ? moment.utc(item.createdAt).toISOString() : null,
    updatedAt: item.updatedAt ? moment.utc(item.updatedAt).toISOString() : null,
    deletedAt: item.deletedAt ? moment.utc(item.deletedAt).toISOString() : null,
  }));

  return {
    data: {
      items,
      paginationInfo: {
        hasNext: !!next?.query?.cursor,
        nextCursor: next?.query?.cursor as string || null,
      },
    },
    error: null,
  };
};
