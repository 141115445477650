import {
  takeLatest, put, cancel, take, delay,
} from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import Alert from 'components/Alert';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';
import * as appStore from 'store/nodes/app';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.doSignIn.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.doSignIn>) {
  const { email, password } = action.payload;

  const result = yield* call(() => api.resource.auth.login(email, password));
  if (result?.error || !result?.data) {
    Alert.error(result?.error?.message || 'Server error #5');
    yield put(actions.doSignInDone(result?.error || 'error_login'));
    yield cancel(); return;
  }

  const { access, refresh } = result.data;
  if (!access || !refresh) {
    yield put(actions.doSignInDone('error_login'));
    yield cancel(); return;
  }

  yield* call(() => api.credentials.set(access, refresh));

  yield put(userStore.actions.loadProfile());
  yield take(userStore.actions.loadProfileDone.type);
  yield delay(10);

  yield put(appStore.actions.route());
  yield take(appStore.actions.routed.type);

  yield put(actions.doSignInDone());

  yield* call(() => track('Login'));
}
