import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import sq from 'query-string';

import { useLocation } from '../useLocation';

import linking from '../../linking';
import type { ScreenParams } from '../../types';

export const useRoute = <RouteScreenName extends keyof ScreenParams>() => {
  const location = useLocation();
  let params: Record<string, any> = {};
  let name: keyof ScreenParams = 'NotFound';
  Object.entries(linking).forEach(([screenName, pattern]) => {
    if (name !== 'NotFound') {
      return;
    }
    const match = matchPath(pattern, location.pathname);
    if (match) {
      name = screenName as keyof ScreenParams;
      params = match.params;
    }
    const searchParams = sq.parse(location.search);
    params = { ...params, ...searchParams };
  });
  return useMemo(() => ({
    name,
    params: params as ScreenParams[RouteScreenName],
    isScreen: <ScreenName extends keyof ScreenParams>(screenName: ScreenName): boolean => {
      return screenName === name;
    },
  }), [location, params]);
};
