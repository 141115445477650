import {
  takeEvery, put, cancel, delay, take,
} from 'redux-saga/effects';

import { navigate, getRoute } from 'navigation/methods';

import * as api from 'services/api';

import { call, select } from 'store/utils/saga/effects';
import * as contentStore from 'store/nodes/content';

import * as actions from '../actions';

export const config = {
  action: actions.locationChange.type,
  method: takeEvery,
};

const withSession = () => {
  const route = getRoute();
  if (
    route.isScreen('Index')
    || route.isScreen('Auth/Start')
    || route.isScreen('Auth/SignIn')
    || route.isScreen('Auth/SignUp')
    // || route.isScreen('Auth/Step1')
    // || route.isScreen('Auth/PasswordReset')
  ) {
    navigate('Ask', { replace: true });
  }
};

const withoutSession = () => {
  const route = getRoute();
  if (route.isScreen('Index')) {
    navigate('Auth/Start', { replace: true });
    return;
  }
  if (
    route.isScreen('Content')
    || route.isScreen('Playlist')
    // route.isScreen('Ask')
    // || route.isScreen('Library')
    // || route.isScreen('Profile')
    // || route.isScreen('Content')
    // || route.isScreen('Playlist')
    // || route.isScreen('Feed')
    // || route.isScreen('Author')
    // || route.isScreen('CommunityPeers')
    // || route.isScreen('CommunityCollection')
    || route.isScreen('Auth/Start')
    || route.isScreen('Auth/SignIn')
    || route.isScreen('Auth/SignUp')
    || route.isScreen('Auth/Step1')
    || route.isScreen('Auth/PasswordReset')
  ) {
    return;
  }
  navigate('Auth/Start', { replace: true });
};

export function* func() {
  const route = getRoute();
  document.body.setAttribute('data-rout-name', route.name);

  const hasSession = yield* call(() => api.credentials.hasSession());
  if (!hasSession) {
    yield* call(() => withoutSession());
    yield delay(10);
    yield put(actions.runPageHandler());
    yield cancel(); return;
  }

  yield* call(() => withSession());
  yield delay(10);
  yield put(actions.runPageHandler());

  // @todo Другие вызовы. Вынести в отдельную сагу.
  const filterType = yield* select(contentStore.selectors.filter<string>('type', 'All'));
  if (!filterType.meta.isLoaded) {
    yield put(contentStore.actions.loadFilters());
    yield take(contentStore.actions.loadFiltersDone.type);
  }
}
