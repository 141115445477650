import {
  type CSSProperties,
  memo, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet, TouchableOpacity, Platform,
} from 'react-native';

import { guard, unit } from 'utils';
import { useColorScheme, useThemeColor } from 'hooks';

import { Text, View } from 'components/Themed';
import WebLink from 'components/WebLink';
import CompositePoster from 'components/CompositePoster';
import Info from 'components/Info';
import Tags from 'components/Tags';
import Icon from 'ui/Icon';

import AuthorBlock from '../../../elements/AuthorBlock';
import FooterBlock from '../../../elements/FooterBlock';
import HeaderToolbar from '../../../elements/HeaderToolbar';

import { useDescriptionCrop } from '../../../hooks';

import {
  useInfo, usePosterSources, useTagsCrop, useAuthor,
} from '../hooks';

import type { CollectionLayoutProps } from '../types';

const defaultProps = {
  hasToolbar: true,
  hasAuthor: true,
  hasDescription: true,
  hasInfo: true,
  hasTags: true,
  hasComments: true,
  hasFooterQueue: false,
  authorSize: 16,
  titleSize: 22,
  descriptionSize: 15,
};

const RegularLayout = (props: CollectionLayoutProps & typeof defaultProps) => {
  const {
    data,
    hasMarkByIKI,
    hasToolbar,
    hasAuthor,
    hasDescription,
    hasInfo,
    hasTags,
    hasComments,
    hasWebLink,
    hasFooterQueue,
    descriptionLimit,
    tagsLimit,
    authorSize,
    titleSize,
    descriptionSize,
    AuthorComponent,
    ExtraComponent,
    OverlayComponent,
    isHoverMode,
    isTouchMode,
    onAuthorPress,
    onBodyPress,
    onOptionsPress,
    onSourcePress,
    onCommentsPress,
    onBookmarkPress,
  } = props;

  const theme = useColorScheme();
  const borderColor = useThemeColor({ dark: '#333333', light: '#ffffff' });

  const description = useDescriptionCrop(data?.description, descriptionLimit, ' ');
  const posterSources = usePosterSources(data);
  const tags = useTagsCrop(data?.tags, tagsLimit);
  const info = useInfo(data, hasMarkByIKI);
  const authorData = useAuthor(data);

  const panelStyle = useMemo((): StyleProp<ViewStyle> => [
    styles.panel,
    {
      borderColor,
      borderRadius: unit(6),
    },
  ], [borderColor]);

  const toolbarStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.toolbar),
      opacity: isHoverMode ? 1 : 0,
    };
    if (Platform.OS === 'web') {
      (result as CSSProperties).transition = 'opacity 300ms ease';
    }
    return result;
  }, [isHoverMode, isTouchMode]);

  const containerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> | CSSProperties = ({
      ...StyleSheet.flatten(styles.RegularLayout),
    });
    if (guard.isWebStyleProp(result) && theme === 'light') {
      result.boxShadow = '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.15)';
    }
    return result as StyleProp<ViewStyle>;
  }, [theme]);

  if (!data) {
    return (
      <View style={styles.wireframe} lightColor="#ffffff50" darkColor="#ffffff20" />
    );
  }

  return (
    <TouchableOpacity style={containerStyle} onPress={onBodyPress} activeOpacity={0.8}>
      {!!OverlayComponent && (
        <View pointerEvents="none" style={styles.overlay}>{OverlayComponent}</View>
      )}
      <WebLink href={data?.internalUrl} disabled={!hasWebLink}>
        <CompositePoster sources={posterSources} style={styles.poster} radius={5} aspectRatio={4 / 3}>
          {Platform.OS === 'web' && posterSources.length === 1 && theme === 'light' && (
            <div style={{ flex: 1, background: 'linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.03) 100%)' }} />
          )}
          {Platform.OS === 'web' && posterSources.length === 1 && theme === 'dark' && (
            <div style={{ flex: 1, background: 'rgba(255,255,255,0.05)' }} />
          )}
        </CompositePoster>
        <View style={panelStyle} darkColor="transparent" lightColor="#ffffff">
          {hasAuthor && !!authorData && (
            <AuthorBlock onPress={onAuthorPress} Component={AuthorComponent} data={authorData} style={{ marginBottom: 11 }} />
          )}
          {!!data?.title && (
            <Text size={titleSize} weight="medium" lightColor="#000000" darkColor="#ffffff" style={styles.title}>
              <>
                {data.isPrivate && (
                  <Icon size="sm" marginLeft={0.4} marginRight={1} weight="duotone" name="lock" />
                )}
                {data?.title?.trim?.()}
              </>
            </Text>
          )}
          {hasDescription && !!data?.description && (
            <Text size={descriptionSize} lightColor="#888888" darkColor="#9A99A2" style={styles.description}>{description}</Text>
          )}
          {hasInfo && !!info && (
            <Info list={info} style={styles.info} fontSize={15} />
          )}
          {hasTags && tags?.length > 0 && (
            <Tags data={data} tagsLimit={tagsLimit} sx={{ marginBottom: unit(10) }} />
          )}
          {!!ExtraComponent && ExtraComponent}
          {(isTouchMode || hasComments || hasFooterQueue) && (
            <FooterBlock
              style={styles.footer}
              data={data}
              hasOptions={isTouchMode}
              hasQueue={hasFooterQueue}
              MainComponent={hasComments ? undefined : false}
              onOptionsPress={onOptionsPress}
              onCommentsPress={onCommentsPress}
              onBookmarkPress={onBookmarkPress}
            />
          )}
        </View>
      </WebLink>
      {hasToolbar && !!data && !isTouchMode && (
        <HeaderToolbar
          style={toolbarStyle}
          hasOptions
          hasSource={false}
          onOptionsPress={onOptionsPress}
          onSourcePress={onSourcePress}
        />
      )}
    </TouchableOpacity>
  );
};

RegularLayout.defaultProps = defaultProps;

const styles = StyleSheet.create({
  RegularLayout: {
    position: 'relative',
    zIndex: Platform.OS === 'web' ? 'unset' as unknown as number : 0,
    borderRadius: unit(6),
  },
  wireframe: {
    width: '100%',
    aspectRatio: 6 / 7,
    borderRadius: unit(6),
  },
  overlay: {
    zIndex: 10,
  },
  panel: {
    borderWidth: 1,
    borderRadius: unit(6),
    paddingHorizontal: unit(20),
    paddingTop: unit(20),
    paddingBottom: unit(10),
    zIndex: 1,
  },
  poster: {
    marginBottom: unit(-6),
    zIndex: 2,
  },
  author: {
    marginBottom: unit(11),
  },
  title: {
    marginBottom: unit(11),
  },
  description: {
    marginBottom: unit(10),
  },
  info: {
    marginBottom: unit(10),
  },
  footer: {
    marginTop: unit(-2),
    marginBottom: unit(10),
  },
  toolbar: {
    position: 'absolute',
    top: unit(12),
    right: unit(16),
    zIndex: 2,
  },
});

export default memo(RegularLayout);
