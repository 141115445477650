import { type DefaultRootState } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import type { FilterItemType } from 'app/entities';

const selector = createSelector(
  (state: DefaultRootState) => state.library.filter.enabledMap,
  (state: DefaultRootState) => state.library.filter.data,
  (enabledMap, data) => {
    const list: FilterItemType[] = [];
    Object.keys(enabledMap).forEach((id) => {
      const item = data[id];
      list.push(item);
    });
    return ({
      list,
      isExists: (id: string) => enabledMap[id] === true,
    });
  },
);

export default (state: DefaultRootState) => selector(state);
