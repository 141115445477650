import { useMemo } from 'react';

import subscription from 'data/subscription.json';
import type { PeriodType, PlanDataType } from '../types';

const usePlans = (period: PeriodType): PlanDataType[] => useMemo(() => {
  const currentPeriod = subscription.periods.find((item) => item.id === period);
  if (!currentPeriod || !Array.isArray(currentPeriod.planIds) || currentPeriod.planIds.length === 0) {
    return [];
  }
  return subscription.plans.filter((item) => currentPeriod.planIds.includes(item.identity.planId)).map((item) => {
    const label = { ...item.label };
    (Object.keys(label) as (keyof typeof label)[]).forEach((key) => {
      label[key] = label[key].replace(/\[period]/g, period);
    });
    return ({
      ...item,
      label,
    });
  });
}, [period]);

export default usePlans;
