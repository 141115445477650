import { useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { type RenderBadgeItemPropsInterface } from 'react-native-dropdown-picker';

import { useThemeColor } from 'hooks';

import { Text } from 'components/Themed';
import { unit } from 'utils';

const Badge = (props: RenderBadgeItemPropsInterface<string>) => {
  const {
    label,
    value,
    onPress,
  } = props;

  const borderColor = useThemeColor({ light: '#000000', dark: '#ffffff' });

  const handlePress = useCallback(() => {
    if (value) {
      onPress?.(value);
    }
  }, [onPress, value]);

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={[styles.badge, {
        borderColor,
      }]}
      activeOpacity={0.8}
    >
      <Text size={13} lightColor="#000000" darkColor="#ffffff">{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  badge: {
    paddingHorizontal: unit(8),
    paddingVertical: unit(3),
    borderRadius: unit(5),
    borderWidth: 1,
    marginVertical: unit(2),
  },
  badgeText: {},
});

export default Badge;
