import {
  type CSSProperties,
  memo, useCallback, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet, TouchableOpacity, Platform,
} from 'react-native';
import { useSelector } from 'react-redux';

import { useNavigate } from 'navigation/hooks';

import { useThemeColor, useResponsive } from 'hooks';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as menuStore from 'store/nodes/menu';
import * as contentStore from 'store/nodes/content';

import { unit } from 'utils';
import { Text, View, BlurView } from 'components/Themed';
import Picture from 'components/Picture';
import Icon from 'components/LegacyIcon';

type WatchedProps = {
  style?: StyleProp<ViewStyle>,
}

const Watched = (props: WatchedProps) => {
  const { style } = props;

  const navigate = useNavigate();
  const dispatcher = useDispatcher();
  const responsive = useResponsive();

  const borderBottomColor = useThemeColor({ light: '#929292', dark: '#77767E' });

  const watched = useSelector(contentStore.selectors.dataWatched);
  const leftBarWidth = useSelector(menuStore.selectors.leftBarWidth);

  const handleContent = useCallback(() => {
    navigate('Content', { resourceType: watched?.data?.type?.toLowerCase(), resourceId: watched?.data?.id });
  }, [watched?.data]);

  const handleClose = useCallback(() => {
    dispatcher.content.closeWatched();
  }, []);

  const componentStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(style),
      ...StyleSheet.flatten(styles.Watched),
    };
    if (responsive.isMoreThen.mobileLarge) {
      result.left = unit(leftBarWidth);
      result.bottom = 0;
    }
    if (Platform.OS === 'web') {
      (result as CSSProperties).position = 'fixed';
    }
    return result;
  }, [responsive.isMoreThen.mobileLarge, leftBarWidth]);

  const containerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.container),
    };
    if (responsive.isLessThen.tablet) {
      result.paddingHorizontal = 0;
    }
    return result;
  }, [responsive.isLessThen.tablet]);

  const bodyStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.body),
      borderBottomColor,
    };
    if (responsive.isMoreThen.mobileLarge) {
      result.borderTopLeftRadius = unit(16);
      result.borderTopRightRadius = unit(16);
    }
    return result;
  }, [responsive.isMoreThen.mobileLarge, borderBottomColor]);

  if (!watched || !watched.isShow) {
    return null;
  }

  return (
    <View style={componentStyle} pointerEvents="none">
      <View style={containerStyle} pointerEvents="auto">
        <BlurView style={bodyStyle} darkColor="#00000055" lightColor="#FFFFFF55" factor={8}>
          <Picture source={watched?.data?.image} size="small" aspectRatio={1} style={styles.image} />
          <View style={styles.content}>
            <Text size={11}>Continue learn</Text>
            <Text size={15} ellipsizeMode="tail" numberOfLines={1}>{watched?.data?.title}</Text>
          </View>
          <TouchableOpacity onPress={handleContent} activeOpacity={0.8} style={styles.btnContent}>
            <Icon name="ChevronUp" size={24} />
          </TouchableOpacity>
          <TouchableOpacity onPress={handleClose} activeOpacity={0.8} style={styles.btnClose}>
            <Icon name="Close" size={24} />
          </TouchableOpacity>
        </BlurView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  Watched: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: unit(80),
  },
  container: {
    maxWidth: unit(1280),
    paddingHorizontal: unit(16),
    alignSelf: 'center',
    alignItems: 'stretch',
    width: '100%',
  },
  body: {
    flexDirection: 'row',
    paddingTop: unit(12),
    paddingBottom: unit(12),
    flex: 1,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
  },
  image: {
    width: unit(48),
    height: unit(48),
    marginLeft: unit(8),
    marginRight: unit(16),
  },
  content: {
    marginRight: unit(16),
    flex: 1,
  },
  btnContent: {
    width: unit(28),
    height: unit(28),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: unit(16),
  },
  btnClose: {
    width: unit(28),
    height: unit(28),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: unit(16),
  },
});

export default memo(Watched);
