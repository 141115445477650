import { createReducer } from '@reduxjs/toolkit';

import { guard } from 'utils';

import type { NewsStore } from './types';
import * as actions from './actions';

const initialState: NewsStore = {
  list: {
    sequence: [],
    map: {},
    paginationInfo: {
      hasNext: false,
      nextCursor: null,
    },
    meta: {
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
  filter: {
    topic: '',
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.setTopic, (state, action) => {
    const { payload } = action;
    if (state.filter.topic !== payload.topic) {
      state.filter.topic = payload.topic;
    }
  })
  .addCase(actions.loadNextPage, (state, action) => {
    if (action?.payload?.reload) {
      state.list.meta.isFirstPageLoading = true;
    } else {
      state.list.meta.isNextPageLoading = true;
    }
  })
  .addCase(actions.loadNextPageDone, (state, action) => {
    const { reload } = action.payload;
    state.list.meta.isFirstPageLoading = false;
    state.list.meta.isNextPageLoading = false;
    if (reload) {
      state.list.meta.isFirstPageLoaded = true;
    } else {
      state.list.meta.isNextPageLoaded = true;
    }
  })
  .addCase(actions.addPage, (state, action) => {
    if (!action.payload.items) {
      return;
    }
    if (action.meta.reload) {
      state.list.sequence = [];
      state.list.map = {};
    }
    action.payload.items.forEach((item) => {
      if (item.id in state.list.map) {
        return;
      }
      let type: 'material' | 'collection' | 'unknown' = 'unknown';
      if (guard.isMaterial(item)) {
        type = 'material';
      }
      if (guard.isCollection(item)) {
        type = 'collection';
      }
      state.list.sequence.push({
        id: item.id,
        type,
      });
      state.list.map[item.id] = state.list.sequence.length - 1;
    });
    state.list.paginationInfo.nextCursor = action.payload.paginationInfo.nextCursor;
    state.list.paginationInfo.hasNext = action.payload.paginationInfo.hasNext;
  }));
