import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import {
  TouchableOpacity, StyleSheet, Animated, Easing,
} from 'react-native';
import type { StyleProp, ViewStyle } from 'react-native';

import type { FontSizeVariant, FontWeightVariant } from 'font';

import { useThemeColor } from 'hooks';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';

import { useField } from '../utils/context';
import type { FieldProps } from '../types';

const defaultProps = {
  variant: 'regular',
  colorized: true,
};

type BooleanInputProps = {
  disabled?: boolean,
  style?: StyleProp<ViewStyle>,
  variant?: 'big' | 'regular' | 'small'
  colorized?: boolean
} & FieldProps & typeof defaultProps;

const BooleanInput = (props: BooleanInputProps) => {
  const {
    name,
    label,
    value,
    width,
    onChange,
    colorized,
    variant,
    style,
    disabled,
  } = props;

  const trackColorOn = useThemeColor({
    light: disabled ? '#497CFF55' : '#497CFF',
    dark: disabled ? '#497CFF55' : '#497CFF',
  }) as string;
  const trackColorOff = useThemeColor({
    light: disabled ? '#dfCFD7' : '#D1CFD7',
    dark: disabled ? '#332326' : '#232326',
  }) as string;
  const colorOn = useThemeColor({
    light: disabled ? '#ffffff77' : '#ffffff',
    dark: disabled ? '#ffffff44' : '#ffffff',
  }) as string;
  const colorOff = useThemeColor({
    light: disabled ? '#ffffff77' : '#ffffff',
    dark: disabled ? '#ffffff44' : '#ffffff77',
  }) as string;

  const field = useField(name);

  const onChangeFinal = onChange || field?.onValueChange;
  const valueFinal = Boolean(value || field?.value || false);

  const positionAnim = useRef(new Animated.Value(valueFinal ? 20 : 0)).current;

  useEffect(() => {
    Animated.timing(
      positionAnim,
      {
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic),
        toValue: valueFinal ? 20 : 0,
        duration: 200,
      },
    ).start();
  }, [positionAnim, valueFinal]);

  const handlePress = useCallback(() => {
    const newValue = !valueFinal;
    onChangeFinal?.(newValue);
  }, [onChangeFinal, valueFinal]);

  const labelFontFinal = useMemo(() => ({
    size: 17 as FontSizeVariant,
    weight: 'regular' as FontWeightVariant,
    color: colorOn as string,
  }), [colorOn]);

  const containerStyles = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: field ? 16 : undefined,
      ...StyleSheet.flatten(style),
    };
    if (width === 'full') {
      result.width = '100%';
    } else
    if (typeof width === 'string') {
      result.width = width;
    } else
    if (typeof width === 'number') {
      result.width = unit(width);
    }
    return result;
  }, [style, width, disabled, field]);

  const trackStyles = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.track),
    };
    if (variant === 'small') {
      result.width = unit(44);
      result.height = unit(24);
    }
    return result;
  }, [variant]);

  const thumbStyles = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.thumb),
    };
    if (variant === 'small') {
      result.height = unit(22);
      result.width = unit(22);
    }
    return result;
  }, [variant]);

  const color = positionAnim.interpolate({
    inputRange: [0, 20],
    outputRange: [colorOff, colorOn],
  }) as unknown as string;

  const backgroundColor = positionAnim.interpolate(colorized ? {
    inputRange: [0, 20],
    outputRange: [trackColorOff, trackColorOn],
  } : {
    inputRange: [0, 0],
    outputRange: [trackColorOff, trackColorOff],
  }) as unknown as string;

  return (
    <View
      style={containerStyles}
      pointerEvents={disabled ? 'none' : 'auto'}
    >
      {Boolean(label) && <Text size={labelFontFinal.size} weight={labelFontFinal.weight}>{label}</Text>}
      {!label && <View style={styles.notLabel} />}
      <TouchableOpacity activeOpacity={1} onPress={handlePress} style={styles.container}>
        <Animated.View style={[trackStyles, { backgroundColor }]}>
          <Animated.View style={[thumbStyles, { backgroundColor: color, left: positionAnim }]} />
        </Animated.View>
      </TouchableOpacity>
    </View>
  );
};

BooleanInput.defaultProps = defaultProps;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
  },
  track: {
    width: unit(50),
    height: unit(30),
    borderRadius: unit(50 / 2),
  },
  thumb: {
    height: unit(28),
    width: unit(28),
    borderRadius: unit(28 / 2),
    marginTop: 1,
    marginLeft: 1,
  },
  notLabel: {
    flex: 1,
  },
});

export default BooleanInput;
