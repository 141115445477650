import connect from '../../utils/saga/connect';
import * as loadFilters from './sagas/loadFilters';
import * as loadNextPage from './sagas/loadNextPage';
import * as triggerReloadByText from './sagas/triggerReloadByText';
import * as triggerReloadByFilters from './sagas/triggerReloadByFilters';

export default connect([
  loadFilters,
  loadNextPage,
  triggerReloadByText,
  triggerReloadByFilters,
]);
