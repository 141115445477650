import {
  type ReactElement, type CSSProperties,
  useCallback, useEffect, useMemo, memo,
} from 'react';
import {
  type StyleProp, type ViewStyle, type GestureResponderEvent,
  StyleSheet, TouchableOpacity, useWindowDimensions, Platform,
} from 'react-native';

import type {
  ResourceVariant,
  CommentMessageType,
} from 'app/entities';

import { useResponsive, useThemeColor } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';
import * as playlistStore from 'store/nodes/playlist';
import * as commentStore from 'store/nodes/comment';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import { TextInput } from 'components/Form';
import FeedList from 'components/FeedList';
import Icon from 'components/LegacyIcon';
import Comment from 'components/Comment';
import { controller as modal } from 'components/Modal2';

import Info from './elements/Info';

export interface CommentsProps {
  resource?: ResourceVariant,
  resourceId?: number,
}

const Comments = (props: CommentsProps): ReactElement => {
  const {
    resource,
    resourceId,
  } = props;
  const dimension = useWindowDimensions();
  const dispatcher = useDispatcher();
  const responsive = useResponsive();
  const wrapperShadowColor = useThemeColor({ light: '#0000004d', dark: '#00000099' });

  const text = useSelector((state) => state.comment.input.text);
  const content = useSelector((state) => {
    if (resource === 'content') {
      return contentStore.selectors.dataById(resourceId)(state);
    }
    if (resource === 'playlist') {
      return playlistStore.selectors.dataById(resourceId)(state);
    }
    return null;
  });
  const reply = useSelector(commentStore.selectors.reply);
  const edit = useSelector(commentStore.selectors.edit);
  const list = useSelector(commentStore.selectors.list(resource, resourceId));
  const listMeta = useSelector(commentStore.selectors.listMeta(resource, resourceId));

  const textFinal = edit && edit.text || text || '';

  const handleClose = useCallback(() => {
    modal.popup.comments.close();
  }, []);

  const handleOption = useCallback((event: GestureResponderEvent, data: { id: number | string, isMy: boolean }) => {
    if (!resourceId) {
      return;
    }
    modal.menu.commentOptions.open({
      callingTarget: event.currentTarget,
      resource,
      resourceId,
      commentId: data.id as number,
      isMy: data.isMy,
    });
  }, [resource, resourceId]);

  const handleRefresh = useCallback(() => {
    if (!resource || !resourceId) {
      return;
    }
    dispatcher.comment.loadNextPage(resource, resourceId, true);
  }, [dispatcher.comment, resource, resourceId]);

  const handleNextPage = useCallback(() => {
    if (!resource || !resourceId) {
      return;
    }
    dispatcher.comment.loadNextPage(resource, resourceId);
  }, [dispatcher.comment, resource, resourceId]);

  const handleSend = useCallback(() => {
    if (!resource || !resourceId) {
      return;
    }
    if (edit) {
      dispatcher.comment.editSend(resource, resourceId, edit.id, textFinal);
    } else {
      const message: CommentMessageType = {
        text: textFinal,
      };
      if (reply) {
        message.replyToId = reply.id;
      }
      dispatcher.comment.newSend(resource, resourceId, message);
    }
  }, [dispatcher.comment, edit, reply, resource, resourceId, textFinal]);

  const handleTouchMove = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  const handleReplyCancel = useCallback(() => {
    if (!reply || !resource || !resourceId) {
      return;
    }
    dispatcher.comment.replyClearId(resource, resourceId, reply?.id);
  }, [dispatcher.comment, reply, resource, resourceId]);

  const handleEditCancel = useCallback(() => {
    if (!edit || !resource || !resourceId) {
      return;
    }
    dispatcher.comment.editCancel(resource, resourceId, edit?.id);
  }, [dispatcher.comment, edit, resource, resourceId]);

  const handleTapReply = useCallback((data: { id: number | string, parentId: number }) => {
    console.log('handleTapReply', data.id, data.parentId);
  }, []);

  useEffect(() => {
    if (!listMeta.firstPage.isLoaded) {
      handleRefresh();
    }
    return () => {
      dispatcher.comment.setText('');
    };
  }, [dispatcher.comment, listMeta.firstPage.isLoaded, handleRefresh]);

  const sequenceFinal = useMemo(() => {
    const { commentsCount = 0 } = content?.commentMeta || {};
    if (commentsCount === 0) {
      return [];
    }
    const wireframeCount = commentsCount > 10 ? 10 : commentsCount;
    const wireframes = new Array(wireframeCount).fill(null).map((_, key) => ({
      id: `w-${key}`,
      type: 'wireframe',
    }));
    if (listMeta?.firstPage?.isLoading) {
      return wireframes || [];
    }
    if (listMeta?.nextPage?.isLoading) {
      return list?.sequence || [];
    }
    return list?.sequence || [];
  }, [content?.commentMeta, list?.sequence, listMeta?.firstPage?.isLoading, listMeta?.nextPage?.isLoading]);

  const isEmpty = useMemo(() => {
    const { commentsCount = 0 } = content?.commentMeta || {};
    if (commentsCount === 0) {
      return true;
    }
    return listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && sequenceFinal.length === 0;
  }, [content?.commentMeta, listMeta.firstPage.isLoaded, listMeta.firstPage.isLoading, sequenceFinal.length]);

  const wrapperStyles = useMemo(() => {
    const { height } = dimension;
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.Comments),
      height,
    };
    if (responsive.isMoreThen.mobile) {
      result.height = height - 100;
      result.paddingBottom = unit(40);
      result.borderRadius = unit(24);
    }
    if (Platform.OS === 'web') {
      (result as CSSProperties).boxShadow = `0 0 ${unit(4)} #0000000d, 0 0 ${unit(48)} ${wrapperShadowColor}`;
    }
    return result;
  }, [responsive.isMoreThen.mobile]);

  const renderItem = useCallback((args: { id: number, item: { isShow: boolean } }) => {
    if (args.item.isShow === false) {
      return null;
    }
    return (
      <Comment
        id={args.id}
        style={styles.item}
        onOptions={handleOption}
        onTapReply={handleTapReply}
      />
    );
  }, [handleOption, handleTapReply]);

  return (
    <View
      style={wrapperStyles}
      lightColor="#e7e7e7"
      darkColor="#181818"
      pointerEvents="auto"
    >
      <View style={styles.title}>
        <Text size={22}>Comments</Text>
      </View>
      {sequenceFinal.length > 0 && (
        <View style={styles.list} onTouchMove={handleTouchMove}>
          <FeedList
            scrollable
            data={sequenceFinal}
            paddingHorizontal={16}
            rowGap={8}
            columnGap={0}
            columnsScheme={{
              xs: 1,
            }}
            isInverted
            ItemComponent={renderItem}
            hasNextPage={list.paginationInfo.hasNext}
            onRefresh={handleRefresh}
            onEndReached={handleNextPage}
          />
        </View>
      )}
      {isEmpty && (
        <View style={styles.empty}>
          <Text size={17}>No comments yet</Text>
          <Text size={13} lightColor="#797979" darkColor="#B8B6BF">You can be first</Text>
        </View>
      )}
      {reply && (
        <Info style={styles.info} type="reply" onCloseQuery={handleReplyCancel} userName={reply.data.user?.name || ''} text={reply.data.text || ''} />
      )}
      {edit && (
        <Info style={styles.info} type="edit" onCloseQuery={handleEditCancel} userName={edit.data.user?.name || ''} text={edit.data.text || ''} />
      )}
      <View style={styles.toolbar}>
        <TextInput
          value={textFinal}
          onChange={dispatcher.comment.setText}
          onEnter={handleSend}
          style={{ flex: 1, backgroundColor: 'none' }}
        />
        <TouchableOpacity style={[styles.buttonSend, { opacity: 1 }]} onPress={handleSend} activeOpacity={0.8}>
          <Icon name="ArrowUpCircle" size={32} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  Comments: {
    width: '100%',
    maxWidth: unit(560),
    alignSelf: 'center',
    paddingHorizontal: unit(16),
    paddingTop: unit(40),
    paddingBottom: unit(16),
    borderTopLeftRadius: unit(24),
    borderTopRightRadius: unit(24),
    flexDirection: 'column',
  },
  desktop: {
    paddingBottom: unit(40),
    borderRadius: unit(24),
  },
  backdropCloser: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  title: {
    marginBottom: unit(8),
  },
  list: {
    flex: 1,
    marginHorizontal: unit(-16),
  },
  toolbar: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: unit(16),
  },
  empty: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  reloading: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSend: {
    marginLeft: unit(8),
    marginBottom: unit(5),
  },
  item: {
    width: '100%',
    maxWidth: unit(768),
    alignSelf: 'center',
    marginVertical: unit(4),
  },
  info: {
    marginTop: unit(16),
    marginBottom: unit(-8),
  },
});

export default memo(Comments);
