import {
  type ForwardRefRenderFunction,
  memo, forwardRef, useCallback, useRef,
} from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import LottieView from 'lottie-react-native';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';

import { type Controller } from '../types';
import Popup from '../elements/Popup';

export interface Context {
  originalUrl: string,
  tags: string[],
  title?: string,
  type?: 'course' | 'article' | 'video' | 'source' | 'link' | string,
  description?: string,
  sectionId?: number,
  sourceId?: number,
  image?: string,
  level?: 'junior' | 'middle' | 'senior' | string,
  duration?: string,
}

const MaterialCompleted: ForwardRefRenderFunction<Controller, any> = (props, forwardedRef) => {
  const size = 200;
  const loop = false;

  const dimensions = useWindowDimensions();

  const lottie = useRef<any>(null);

  const handleShow = useCallback(() => {
    if (lottie.current && 'play' in lottie.current) {
      lottie.current.play?.();
    }
  }, [lottie]);

  const handleHide = useCallback(() => {
    if (lottie.current && 'play' in lottie.current) {
      lottie.current.stop?.();
    }
  }, [lottie]);

  return (
    <Popup.Panel ref={forwardedRef} onShow={handleShow} onHide={handleHide} layout="transparent" isBlocked>
      <View style={[styles.container, { height: dimensions.height, width: '100%' }]}>
        <View style={{ height: unit(200) }} />
        <Text size={34}>Material completed</Text>
      </View>
      <LottieView
        ref={lottie}
        style={{
          position: 'absolute',
          width: '100%',
          left: 0,
          top: 0,
        }}
        source={require('../../Lottie/assets/skill-completed.json')}
        loop={loop}
      />
    </Popup.Panel>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

export default memo(forwardRef<Controller, any>(MaterialCompleted));
