import { memo, useCallback } from 'react';
import { StyleSheet, type GestureResponderEvent } from 'react-native';
import { useSelector } from 'react-redux';

import type {
  CollectionType,
} from 'app/entities';
import { useRoute } from 'navigation/hooks';
import { useSharing } from 'hooks';
import { unit } from 'utils';

import * as playlistStore from 'store/nodes/playlist';

import Icon from 'components/LegacyIcon';
import { View } from 'components/Themed';
import Button from 'components/Button';
import { controller as modal } from 'components/Modal2';
import { useDispatcher } from 'store/utils/redux/hooks';

const CollectionActions = () => {
  const route = useRoute<'Content'>();
  const dispatcher = useDispatcher();

  const { resourceId } = route.params;

  const data = useSelector(playlistStore.selectors.dataById(resourceId));
  const playlistSharing = useSharing(data);

  const handleEdit = useCallback((event: GestureResponderEvent, context: CollectionType) => {
    if (!context) {
      return;
    }
    dispatcher.modal.open('AddorEditCollection', { edit: { id: context?.id } });
  }, []);

  const handleDelete = useCallback((event: GestureResponderEvent, context: CollectionType) => {
    if (!context) {
      return;
    }
    dispatcher.playlist.remove({ id: context.id });
  }, []);

  const handleShare = useCallback(() => {
    playlistSharing.share();
  }, [playlistSharing]);

  return (
    <View style={styles.CollectionActions}>
      {data?.isMy && (
        <Button type="button-icon" variant="text" width={42} height={42} style={styles.actionButton} onPress={handleEdit} context={data}>
          <Icon name="EditOutline28" size={28} />
        </Button>
      )}
      {data?.isMy && (
        <Button type="button-icon" variant="text" width={42} height={42} style={styles.actionButton} onPress={handleDelete} context={data}>
          <Icon name="DeleteOutline28" size={28} lightColor="#db3327" darkColor="#db3327" />
        </Button>
      )}
      <View style={styles.space} />
      {playlistSharing.isSupport && (
        <Button type="button-icon" variant="text" width={42} height={42} style={styles.actionButton} onPress={handleShare}>
          <Icon name="ShareOutline28" size={28} />
        </Button>
      )}
      <View style={styles.end} />
    </View>
  );
};

const styles = StyleSheet.create({
  CollectionActions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
  },
  actionButton: {
    marginVertical: unit(-6),
  },
  button: {
    width: unit(42),
    height: unit(42),
    marginVertical: unit(-6),
    marginRight: unit(-12),
    justifyContent: 'center',
    alignItems: 'center',
  },
  space: {
    width: unit(24),
  },
  end: {
    marginRight: unit(-12),
  },
});

export default memo(CollectionActions);
