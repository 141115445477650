import { useCallback, useMemo, useState } from 'react';

import type {
  MaterialType,
  AuthorType,
  UserType,
} from 'app/entities';

export const useTagsCrop = (list?: string[], limit = 0) => useMemo(() => {
  if (!list || !Array.isArray(list) || list.length === 0) {
    return [];
  }
  let result = [...new Set(list)];
  if (limit && limit > 0) {
    result = result.slice(0, limit);
  }
  return result;
}, [list, limit]);

export const useAuthor = (
  author?: AuthorType | AuthorType[] | UserType | UserType[],
): AuthorType | UserType | null => (
  (!Array.isArray(author) ? [author] : author)?.[0] || null
);

export const useLayout = (
  data?: MaterialType | null,
  defaultLayout?: 'regular' | 'horizontal' | 'wireframe' | 'highlight' | 'note',
  forceLayout?: 'regular' | 'horizontal' | 'wireframe' | 'highlight' | 'note',
): 'regular' | 'horizontal' | 'wireframe' | 'highlight' | 'note' => useMemo(() => {
  if (forceLayout) {
    return forceLayout;
  }
  if (data?.highlight?.text) {
    return 'highlight';
  }
  if (data?.note?.text) {
    return 'note';
  }
  return defaultLayout || 'regular';
}, [data, defaultLayout, forceLayout]);

export const useCardSizeModifier = () => useMemo(() => {
  const [size, setSize] = useState<'normal' | 'wide'>();

  const handleLayout = useCallback(({ nativeEvent }: any) => {
    if (nativeEvent.layout.width > 400) {
      setSize('wide');
    } else {
      setSize('normal');
    }
  }, []);

  return [size, handleLayout];
}, []);
