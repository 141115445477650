import {
  takeEvery, put, cancel,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';

import * as api from 'services/api';

import { select, call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as contentStore from 'store/nodes/content';
import * as playlistStore from 'store/nodes/playlist';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.deleteSend.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.deleteSend>) {
  const {
    resource,
    resourceId,
    id,
  } = action.payload;

  const userProfile = yield* select(userStore.selectors.getMy);
  const data = yield* select(selectors.comment(id));

  if (data.user?.id !== userProfile.id) {
    yield put(actions.deleteSendDone());
    yield cancel(); return;
  }

  yield put(actions.deleteOptimistic(resource, resourceId, id));

  if (resource === 'content') {
    const contentOptimistic = yield* select(contentStore.selectors.dataById(resourceId, { mode: 'sub' }));
    yield put(contentStore.actions.setItem(contentOptimistic));
  }
  if (resource === 'playlist') {
    const contentOptimistic = yield* select(playlistStore.selectors.dataById(resourceId, { mode: 'sub' }));
    yield put(playlistStore.actions.setItem({ data: contentOptimistic }));
  }

  const response = yield* call(() => api.resource.comment.delete(id));
  if (response.error) {
    Alert.error(response.error?.message || 'Server error #8');
    yield put(actions.deleteRollback(resource, resourceId, id));
    yield put(actions.deleteSendDone());
    if (resource === 'content') {
      const contentRollback = yield* select(contentStore.selectors.dataById(resourceId, { mode: 'add' }));
      yield put(contentStore.actions.setItem(contentRollback));
    }
    if (resource === 'playlist') {
      const contentRollback = yield* select(playlistStore.selectors.dataById(resourceId, { mode: 'add' }));
      yield put(playlistStore.actions.setItem({ data: contentRollback }));
    }
    yield cancel(); return;
  }

  yield put(actions.deleteCommit(resource, resourceId, id));
  yield put(actions.deleteSendDone());
}
