import {
  type ReactElement, type CSSProperties,
  memo, useCallback, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  Platform, StyleSheet, useWindowDimensions,
} from 'react-native';
import { track } from '@amplitude/analytics-react-native';

import {
  useThemeColor, useResponsive, useColorScheme, useGetOpenLink,
} from 'hooks';
import { unit } from 'utils';

import { Text, View } from 'components/Themed';
import Button from 'components/Button';
import Picture from 'components/Picture';
// import { controller as modal } from 'components/Modal2';

import Item from './elements/Item';

export interface PromoProps {
}

const Promo = (props: PromoProps): ReactElement => {
  const colorScheme = useColorScheme();
  const responsive = useResponsive();
  const wrapperShadowColor = useThemeColor({ light: '#0000004d', dark: '#00000099' });
  const openLink = useGetOpenLink();
  const dimensions = useWindowDimensions();

  // const handleClose = useCallback(() => {
  //   modal.popup.promo.close();
  // }, []);

  const handleUpgrade = useCallback(() => {
    openLink('https://iki.ai/prices');
    track('PROMO:OpenPrices');
  }, []);

  const wrapperStyles = useMemo(() => {
    let result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.container),
    };
    if (responsive.isMoreThen.mobile) {
      result = {
        ...result,
        ...StyleSheet.flatten(styles.desktop),
      };
    }
    if (Platform.OS === 'web') {
      (result as CSSProperties).boxShadow = `0 0 ${unit(4)} #0000000d, 0 0 ${unit(48)} ${wrapperShadowColor}`;
    }
    return result;
  }, [responsive.isMoreThen.mobile, wrapperShadowColor]);

  return (
    <View
      style={wrapperStyles}
      lightColor="#e7e7e7"
      darkColor="#181818"
      pointerEvents="auto"
    >
      <View style={styles.header}>
        <View style={styles.logo}>
          {colorScheme === 'dark' && <Picture source={require('assets/images/logo-circle-white.png')} hasWireframe={false} />}
          {colorScheme === 'light' && <Picture source={require('assets/images/logo-circle-black.png')} hasWireframe={false} />}
        </View>
        <Text size={28} style={styles.title}>Try iki.ai PRO</Text>
        {/* <Button */}
        {/*   type="button-icon" */}
        {/*   variant="text" */}
        {/*   style={styles.closeButton} */}
        {/*   onPress={handleClose} */}
        {/*   width={48} */}
        {/*   height={48} */}
        {/*   radius="full" */}
        {/* > */}
        {/*   <Icon name="Close" size={24} /> */}
        {/* </Button> */}
      </View>
      <View style={[styles.items, responsive.is.mobileL && styles.itemsMobile]}>
        <View style={styles.column}>
          <Item
            icon="Organizing"
            title="Self organizing"
            text="Personal wiki with automatically created threads and auto tags"
          />
          <Item
            icon="Zoom"
            title="Searchable"
            text="All the knowledge you saved is searchable with queries in natural language"
          />
          <Item
            icon="UserPlus"
            title="Collaborative"
            text="Create private iki spaces for your teams, classmates or peers"
          />
        </View>
        <View style={styles.column}>
          <Item
            icon="IkiCoPilot"
            title="IKI co-pilot"
            text="Use AI to enhance your research"
          />
          <Item
            icon="FilePlus"
            title="Save & read"
            text={(
              <Text size={13}>
                Read full articles inside iki without ads
                {dimensions.width >= 700 ? <br /> : '. '}
                Save complete webpage screenshot (searchable with OCR & Object recognition)
                {dimensions.width >= 700 ? <br /> : '. '}
                Listen to TLDR
              </Text>
            )}
          />
          <Item
            icon="Dollar"
            title="Monetize"
            text="Monetize your expertise by creating collections with paywall"
          />
        </View>
      </View>
      <View style={[styles.footer, responsive.is.mobileL && styles.footerMobile]}>
        <Button
          style={[styles.button, responsive.is.mobileL && styles.buttonMobile]}
          width={responsive.is.mobileL ? 'full' : undefined}
          onPress={handleUpgrade}
          type="button"
          variant="contained"
          radius="full"
          lightColor="#497CFF"
          darkColor="#497CFF"
        >
          <Text size={17} weight="medium" lightColor="#ffffff" darkColor="#ffffff">Upgrade to IKI PRO</Text>
        </Button>
        <Text size={!responsive.is.mobileL ? 15 : 13} style={[styles.text, responsive.is.mobileL && styles.textMobile]}>
          From $6 per month in annual plan.
          {responsive.is.mobileL ? <br /> : ' '}
          Monthly plans available.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: unit(768),
    alignSelf: 'center',
    paddingHorizontal: unit(20),
    paddingTop: unit(32),
    paddingBottom: unit(28 + 12),
    borderTopLeftRadius: unit(20),
    borderTopRightRadius: unit(20),
  },
  desktop: {
    borderRadius: unit(20),
    marginTop: unit(40),
    marginBottom: unit(40),
    paddingTop: unit(24),
    paddingBottom: unit(24),
    marginLeft: unit(30),
    marginRight: unit(30),
  },
  header: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  logo: {
    width: unit(30),
    height: unit(30),
    marginRight: unit(18),
  },
  closeButton: {
  },
  title: {
    flex: 1,
  },
  items: {
    marginTop: unit(44),
    flexDirection: 'row',
    columnGap: unit(48),
    rowGap: unit(28),
  },
  itemsMobile: {
    flexDirection: 'column',
  },
  column: {
    flex: 1,
    rowGap: unit(28),
  },
  footer: {
    flexDirection: 'row',
    gap: unit(28),
    marginTop: unit(40),
    alignItems: 'center',
  },
  footerMobile: {
    flexDirection: 'column',
    gap: unit(20),
  },
  button: {
    marginTop: unit(4),
    flex: 0.4,
    minWidth: unit(200),
  },
  buttonMobile: {
    flex: undefined,
    minWidth: 'none',
  },
  text: {
    flex: 0.6,
  },
  textMobile: {
    flex: undefined,
    textAlign: 'center',
  },
});

export default memo(Promo);
