export interface CustomError {
  type: string,
  message: string,
}

interface PrepareActionDoneResult {
  type?: string,
  payload: {
    error?: CustomError | null,
  },
  meta: {
    processButtonName: '',
  }
}

const isError = (error: Error | CustomError | string | null | undefined): error is Error => (
  error !== 'string' && error instanceof Error
);

const isCustomError = (error: Error | CustomError | string | null | undefined): error is CustomError => (
  typeof error !== 'string' && !!error && 'type' in error && 'message' in error
);

const isString = (error: Error | CustomError | string | null | undefined): error is string => (
  typeof error === 'string'
);

/**
 * @deprecated
 * @param error
 */
export const prepareActionDone = (error?: Error | CustomError | string | null | undefined): PrepareActionDoneResult => {
  if (isString(error)) {
    return {
      payload: {
        error: {
          type: error.toLowerCase().replace(/\s/g, '_'),
          message: error,
        },
      },
      meta: {
        processButtonName: '',
      },
    };
  }
  if (isError(error)) {
    return {
      payload: {
        error: {
          type: error.name,
          message: error.message,
        },
      },
      meta: {
        processButtonName: '',
      },
    };
  }
  if (isCustomError(error)) {
    return {
      payload: {
        error: {
          type: error.type,
          message: error.message,
        },
      },
      meta: {
        processButtonName: '',
      },
    };
  }
  return {
    payload: {
    },
    meta: {
      processButtonName: '',
    },
  };
};
