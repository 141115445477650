import {
  type SagaReturnType, takeEvery, put, delay, cancel,
} from 'redux-saga/effects';

import * as socketStore from 'store/nodes/socket';
import { call, select } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import { actions } from '../slice';
import * as selectors from '../selectors';

export const config = {
  action: [
    socketStore.actions.messageInfo.type,
    socketStore.actions.messageWarning.type,
    socketStore.actions.messageError.type,
  ],
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<
    | typeof socketStore.actions.messageInfo
    | typeof socketStore.actions.messageWarning
    | typeof socketStore.actions.messageError
  >,
) {
  const { id, message, context } = action.payload;
  yield put(actions.addToQueue({ id, message, context }));
  yield delay(500);
  const isPrevented = yield* select(selectors.isPrevented(id));
  if (isPrevented) {
    yield put(actions.remove({ id }));
    yield cancel(); return;
  }
  if (action.type === socketStore.actions.messageInfo.type) {
    yield call(() => Alert.info(message, { autoClose: 10000 }));
  }
  if (action.type === socketStore.actions.messageWarning.type) {
    yield call(() => Alert.info(message, { autoClose: 10000 }));
  }
  if (action.type === socketStore.actions.messageError.type) {
    yield call(() => Alert.error(message, { autoClose: 10000 }));
  }
  yield put(actions.remove({ id }));
}
