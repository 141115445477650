import {
  type ReactElement, type PropsWithChildren, useCallback,
} from 'react';
import {
  StyleSheet, TouchableOpacity,
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';

import type { ImageType } from 'app/entities';
import { unit } from 'utils';
import { useThemeColor } from 'hooks';
import { View } from 'components/Themed';

import Icon from 'components/LegacyIcon';

type CustomImageInputProps = {
  value?: ImageType | null,
  render: (value?: ImageType | null) => ReactElement,
  onChange?: (event: {
    type: 'select' | 'delete',
    value: ImageType | null,
  }) => void,
}

const CustomImageInput = (props: PropsWithChildren<CustomImageInputProps>): ReactElement => {
  const {
    value,
    render,
    onChange,
  } = props;

  const deleteColor = useThemeColor({ light: '#ffffff55', dark: '#00000055' });
  const handlePick = useCallback(async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (result.canceled || !result?.assets[0].uri) {
      return;
    }

    const [asset] = result.assets;

    onChange?.({
      type: 'select',
      value: {
        id: 0,
        url: asset.uri,
        width: asset.width,
        height: asset.height,
        createdAt: '',
        updatedAt: '',
      },
    });
  }, [onChange]);

  const handleDelete = useCallback(() => {
    onChange?.({
      type: 'delete',
      value: null,
    });
  }, [onChange]);

  return (
    <View>
      <TouchableOpacity style={styles.touch} onPress={handlePick} activeOpacity={0.8}>
        {render(value)}
        {!!value && (
          <TouchableOpacity style={[styles.deleteButton, { backgroundColor: deleteColor }]} onPress={handleDelete}>
            <Icon name="DeleteOutline28" size={20} lightColor="#db3327" darkColor="#ffffff" />
          </TouchableOpacity>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  preview: {
    width: unit(80),
    height: unit(80),
    borderRadius: unit(40),
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    position: 'absolute',
    top: unit(8),
    padding: unit(5),
    right: unit(8),
    borderRadius: unit(17),
  },
  touch: {
    position: 'relative',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  children: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  itemText: {
    marginLeft: unit(16),
  },
});

export default CustomImageInput;
