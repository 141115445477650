import connect from '../../utils/saga/connect';
import * as loadFilters from './sagas/loadFilters';
import * as loadNextPage from './sagas/loadNextPage';
import * as loadMeta from './sagas/loadMeta';
import * as remove from './sagas/remove';

export default connect([
  loadFilters,
  loadNextPage,
  loadMeta,
  remove,
]);
