import {
  useCallback, memo, useRef, useState, useEffect,
} from 'react';
import {
  type LayoutChangeEvent, type GestureResponderEvent,
  StyleSheet, Platform,
} from 'react-native';

import { View } from 'components/Themed';

import { useCheckTouchSupports } from 'hooks';
import { useLayout } from './hooks';

import RegularLayout from './layouts/RegularLayout';
import HorizontalLayout from './layouts/HorizontalLayout';

import type { CollectionCardProps } from './types';

const CollectionCard = (props: CollectionCardProps) => {
  const {
    style,
    data,
    defaultLayout,
    forceLayout,
    hasMarkByIKI,
    hasToolbar,
    hasAuthor,
    hasDescription,
    hasInfo,
    hasTags,
    hasComments,
    hasWebLink,
    hasFooterQueue,
    descriptionLimit,
    tagsLimit,
    authorSize,
    titleSize,
    descriptionSize,
    AuthorComponent,
    ExtraComponent,
    OverlayComponent,
    onLayout,
    onAuthorPress,
    onBodyPress,
    onOptionsPress,
    onCommentsPress,
    onBookmarkPress,
  } = props;

  const containerRef = useRef<HTMLDivElement | any>();
  const [isHover, setHover] = useState(false);
  const isTouch = useCheckTouchSupports();

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    if (!data?.id) {
      return;
    }
    onLayout?.(event, { cardId: data?.id });
  }, [onLayout, data?.id]);

  const handleAuthorPress = useCallback((event: GestureResponderEvent) => {
    if (!data?.id || !data?.user) {
      return;
    }
    onAuthorPress?.(event, { cardId: data?.id, author: data.user });
  }, [onAuthorPress, data?.id, data?.user]);

  const handleBodyPress = useCallback((event: GestureResponderEvent) => {
    if (!data?.id) {
      return;
    }
    onBodyPress?.(event, { cardId: data?.id });
  }, [onBodyPress, data?.id]);

  const handleOptionsPress = useCallback((event: GestureResponderEvent) => {
    if (!data?.id) {
      return;
    }
    onOptionsPress?.(event, { cardId: data?.id });
  }, [onOptionsPress, data?.id]);

  const handleCommentsPress = useCallback((event: GestureResponderEvent) => {
    if (!data?.id) {
      return;
    }
    onCommentsPress?.(event, { cardId: data?.id });
  }, [onCommentsPress, data?.id]);

  const handleBookmarkPress = useCallback((event: GestureResponderEvent) => {
    if (!data?.id) {
      return;
    }
    onBookmarkPress?.(event, { cardId: data?.id });
  }, [onBookmarkPress, data?.id]);

  const mouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const mouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  const layout = useLayout(data, defaultLayout, forceLayout);

  useEffect(() => {
    if (Platform.OS !== 'web' || !containerRef?.current || !(containerRef?.current instanceof HTMLDivElement)) {
      return undefined;
    }
    const { current: container } = containerRef;
    container.addEventListener('mouseenter', mouseEnter);
    container.addEventListener('mouseleave', mouseLeave);
    return () => {
      container.removeEventListener('mouseenter', mouseEnter);
      container.removeEventListener('mouseleave', mouseLeave);
    };
  }, [mouseEnter, mouseLeave]);

  return (
    <View
      ref={containerRef}
      style={[style, styles.CollectionCard]}
      onLayout={handleLayout}
    >
      {layout === 'regular' && (
        <RegularLayout
          data={data}
          hasMarkByIKI={hasMarkByIKI}
          hasToolbar={hasToolbar}
          hasAuthor={hasAuthor}
          hasDescription={hasDescription}
          hasInfo={hasInfo}
          hasTags={hasTags}
          hasComments={hasComments}
          hasWebLink={hasWebLink}
          hasFooterQueue={hasFooterQueue}
          descriptionLimit={descriptionLimit}
          tagsLimit={tagsLimit}
          authorSize={authorSize}
          titleSize={titleSize}
          descriptionSize={descriptionSize}
          AuthorComponent={AuthorComponent}
          ExtraComponent={ExtraComponent}
          OverlayComponent={OverlayComponent}
          isHoverMode={isHover}
          isTouchMode={isTouch}
          onAuthorPress={handleAuthorPress}
          onBodyPress={handleBodyPress}
          onOptionsPress={handleOptionsPress}
          onCommentsPress={handleCommentsPress}
          onBookmarkPress={handleBookmarkPress}
        />
      )}
      {layout === 'horizontal' && (
        <HorizontalLayout
          data={data}
          hasMarkByIKI={hasMarkByIKI}
          hasToolbar={hasToolbar}
          hasAuthor={hasAuthor}
          hasDescription={hasDescription}
          hasInfo={hasInfo}
          hasTags={hasTags}
          hasComments={hasComments}
          hasWebLink={hasWebLink}
          hasFooterQueue={hasFooterQueue}
          descriptionLimit={descriptionLimit}
          tagsLimit={tagsLimit}
          authorSize={authorSize}
          titleSize={titleSize}
          descriptionSize={descriptionSize}
          AuthorComponent={AuthorComponent}
          ExtraComponent={ExtraComponent}
          OverlayComponent={OverlayComponent}
          isHoverMode={isHover}
          isTouchMode={isTouch}
          onAuthorPress={handleAuthorPress}
          onBodyPress={handleBodyPress}
          onOptionsPress={handleOptionsPress}
          onCommentsPress={handleCommentsPress}
          onBookmarkPress={handleBookmarkPress}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  CollectionCard: {
    alignSelf: 'stretch',
    zIndex: Platform.OS === 'web' ? 'unset' as unknown as number : 0,
  },
});

export default memo(CollectionCard);
