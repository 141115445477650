import { memo, useCallback, useState } from 'react';
import {
  Box, Card, Stack, Chip, Typography,
} from '@mui/joy';

import Icon from 'ui/Icon';

const Summary = (props: { text: string }) => {
  const { text } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = useCallback((event: { preventDefault: () => void; stopPropagation: () => void; }) => {
    event.preventDefault();
    event.stopPropagation();
    setIsExpanded((prev) => !prev);
  }, []);

  if (!text) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{
        '@container (width < 400px )': {
          gap: 1.5,
        },
      }}
    >
      <Stack
        direction="row"
        spacing={1}
      >
        <Chip
          startDecorator={
            <Icon name="lightbulb-on" fw weight="light" color="inherit" />
          }
          onClick={handleExpandClick}
          sx={{
            '--variant-softBg': 'var(--nestedBg)',
            '--variant-softHoverBg': 'var(--nestedHoverBg)',
            '--variant-softActiveColor': 'var(--nestedActiveBg)',
            fontSize: (theme) => theme.typography['body-xs'],
          }}
        >
          AI summary
        </Chip>
      </Stack>
      {isExpanded && (
        <Card
          variant="soft"
          color="neutral"
          size="sm"
          sx={{
            '--Card-radius': (theme) => theme.radius.md,
            backgroundColor: 'var(--nestedBg)',
            transition: 'background-color 200ms ease',
          }}
        >
          <Typography
            level="body-xs"
            fontWeight="normal"
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 8,
              overflow: 'hidden',
            }}
          >
            {text}
          </Typography>
        </Card>
      )}
    </Box>
  );
};

export default memo(Summary);
