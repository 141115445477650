import { type ReactElement } from 'react';
import {
  StyleSheet, type StyleProp, type ViewStyle, TouchableOpacity,
} from 'react-native';

import Icon from 'components/LegacyIcon';
import { Text, View } from 'components/Themed';
import { unit } from 'utils';

type InfoProps = {
  style?: StyleProp<ViewStyle>,
  type: 'reply' | 'edit',
  userName: string,
  text: string,
  onCloseQuery?: () => void,
}

const Info = (props: InfoProps): ReactElement => {
  const {
    style,
    type,
    userName,
    text,
    onCloseQuery,
  } = props;

  return (
    <View style={style}>
      <View style={styles.Info}>
        <Icon lightColor="#000000" darkColor="#ffffff" name={type === 'reply' ? 'ChatOutline28' : 'EditOutline28'} size={28} />
        <View style={styles.body}>
          <View style={styles.userName}><Text size={13} weight="semi">{userName}</Text></View>
          <View style={styles.comment}><Text size={13}>{text}</Text></View>
        </View>
        <TouchableOpacity onPress={onCloseQuery} style={styles.close} activeOpacity={0.8}>
          <Icon lightColor="#888888" darkColor="#9A99A2" name="Remove" size={24} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  Info: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  body: {
    flex: 1,
    marginHorizontal: unit(8),
  },
  userName: {
  },
  comment: {
    maxHeight: unit(64),
    overflow: 'hidden',
  },
  close: {
    padding: unit(4),
  },
});

export default Info;
