import connect from '../../utils/saga/connect';
import * as loadById from './sagas/loadById';
import * as create from './sagas/create';
import * as update from './sagas/update';
import * as remove from './sagas/remove';

export default connect([
  loadById,
  create,
  update,
  remove,
]);
