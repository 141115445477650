import {
  type SagaReturnType, put, cancel, delay, takeEvery,
} from 'redux-saga/effects';

import { getRoute, navigate } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as searchStore from 'store/nodes/search';

import * as actions from '../actions';

export const config = {
  action: [
    actions.triggerSearch.type,
    actions.setMethod.type,
    actions.setFilterTags.type,
    actions.setFilterText.type,
    actions.setFilterTypeValue.type,
    actions.toggleFilterTypeValue.type,
  ],
  method: takeEvery,
};

const getNewMethod = (
  routeName: string,
  currentMethod: 'GlobalFullText' | 'GlobalTitle' | 'LibraryFullText' | 'LibraryTitle' | 'Web',
) => {
  if (routeName === 'Library' && currentMethod !== 'LibraryTitle') {
    return 'LibraryTitle';
  }
  return currentMethod;
};

const getDoNavigate = (
  routeName: string,
  text?: string | null,
) => {
  if (routeName === 'Library') {
    return () => navigate('Library', { interaction: 'search', text });
  }
  return () => null;
};

const getDelayMs = (action: SagaReturnType<typeof actions.setFilterText> | any, defaultValue: number) => {
  let result = defaultValue;
  if (action.type === actions.setFilterText.type && action.payload.immediateUpdate) {
    result = 0;
  }
  return result;
};

export function* func(
  action: SagaReturnType<
    | typeof actions.triggerSearch
    | typeof actions.setMethod
    | typeof actions.setFilterTags
    | typeof actions.setFilterText
    | typeof actions.setFilterTypeValue
    | typeof actions.toggleFilterTypeValue
  >,
) {
  const { triggerReload } = action.payload;
  const isListConsistent = yield* select(searchStore.selectors.isListConsistent);

  if (!triggerReload || isListConsistent) {
    yield cancel(); return;
  }

  const route = yield* call(() => getRoute<'Library'>());
  const method = yield* select(searchStore.selectors.method);
  const text = yield* select(searchStore.selectors.text);

  const newMethod = yield* call(() => getNewMethod(route?.name, method));
  const doNavigate = yield* call(() => getDoNavigate(route?.name, text));

  const delayMs = yield* call(() => getDelayMs(action, 6000));
  yield delay(delayMs);

  yield call(doNavigate);
  yield put(actions.loadNextPage({
    reload: true,
    setMethod: newMethod,
  }));
}
