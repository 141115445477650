import { useCallback, useMemo, useState } from 'react';
import type { LayoutChangeEvent } from 'react-native';

import type {
  MaterialType,
  CollectionType,
} from 'app/entities';
import { useResponsive } from 'hooks';

export const useDescriptionCrop = (text?: string, crop?: number, base = '.'): string => useMemo(() => {
  const cropFinal = crop || 100;
  if (!text) {
    return '';
  }
  if (text.length <= cropFinal) {
    return text;
  }
  const basePosition = text.indexOf(base, cropFinal);
  if (basePosition === -1) {
    return text;
  }
  return `${text.substring(0, basePosition).replace(/[\s,.!]$/, '')}...`;
}, [text, crop, base]);

export const useComments = (data?: MaterialType | CollectionType | null) => useMemo(() => ({
  photos: data?.commentMeta?.userPhotos || [],
  count: data?.commentMeta?.commentsCount || 0,
}), [data?.commentMeta?.commentsCount, data?.commentMeta?.userPhotos]);

export const useElementSize = () => {
  const responsive = useResponsive();
  const [size, setSize] = useState<'m' | 'l' | 'xl'>(responsive.is.mobileL ? 'm' : 'xl');

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    if (event.nativeEvent.layout.width < 460) {
      setSize('m');
      return;
    }
    if (event.nativeEvent.layout.width <= 560) {
      setSize('l');
      return;
    }
    setSize('xl');
  }, []);

  return useMemo(() => ({
    elementSize: size,
    handleElementLayout: handleLayout,
  }), [handleLayout, size]);
};
