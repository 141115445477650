import AsyncStorage from '@react-native-async-storage/async-storage';
import cloneDeep from 'lodash.clonedeep';

import EventEmitter from 'lib/EventEmitter';
import type { EmitterSubscription } from 'lib/EventEmitter/types';

class Storage {
  private readonly emitter = new EventEmitter();

  private data: Record<string, string | null> = {};

  public sync = async (): Promise<void> => {
    const keys = await AsyncStorage.getAllKeys().catch(() => []);
    const pairs = await AsyncStorage.multiGet(keys);
    pairs.forEach(([key, value]) => {
      this.data[key] = value;
    });
    this.emitter.emit('DATA_SYNC', { keys, values: cloneDeep(this.data) });
  };

  public set = (key: string, value: string, callback?: () => void): void => {
    if (this.data[key] === value) {
      return;
    }
    this.data[key] = value;
    AsyncStorage.setItem(key, value).then(() => {
      this.emitter.emit('DATA_SET', { keys: [key], values: { [key]: value } });
      callback?.();
    });
  };

  public setMulti = (pairs: Record<string, string>, callback?: () => void): void => {
    if (Object.keys(pairs).length === 0) {
      return;
    }
    const params: [string, string][] = [];
    const keys: string[] = [];
    const values: Record<string, string | null> = {};
    Object.entries(pairs).forEach(([key, value]) => {
      params.push([key, value]);
      keys.push(key);
      values[key] = value;
    });
    AsyncStorage.multiSet(params).then(() => {
      this.emitter.emit('DATA_SET', { keys, values });
      callback?.();
    });
  };

  public get = (key: string): string | null => {
    return this.data[key] || null;
  };

  public getMulti = (keys: string[]): (string | null)[] => {
    return keys.map((key) => this.data[key] || null);
  };

  public remove = (key: string, callback?: () => void): void => {
    delete this.data[key];
    AsyncStorage.removeItem(key).then(() => {
      this.emitter.emit('DATA_DELETE', { keys: [key] });
      callback?.();
    });
  };

  public addListener = (type: 'DATA_SET' | 'DATA_DELETE' | 'DATA_SYNC', listener: (data: any) => void, context?: any): EmitterSubscription => {
    return this.emitter.addListener(type, listener);
  };
}

export default new Storage();
